// formuläret från startsidan (genvägen) det förenklade
import { connect } from "react-redux";
import { fetchBolag } from "system/actions/sharepointCommon.js";
import { fetchForslag } from "./actions";

import axios from "axios";
import  { infraConfig } from "config/infraConfig";
import { getAxiosConfig } from "system/axiosConfig";

import Button from "components/CustomButtons/CustomButton.jsx";
import React, { Component} from "react";
import LoadingIcon from "components/utils/LoadingIcon";
import {
    FormGroup,
    FormControl,
    ControlLabel
  } from "react-bootstrap";
  import moment from 'moment';
  import 'moment/locale/sv';
  

class ForslagForm extends Component {
    constructor(props){
        super(props);
    
        moment().locale('sv');
        this.state = this.getInititalState();

    
        this.handleFormSave = this.handleFormSave.bind(this);
    }
    getInititalState = () => {
        const initialState = {
            rubrik: '',
            rubrikIsValid: false,
            rubrikError: null,
            bolag: undefined,
            bolagError: null,
            bolagIsValid: false,
            beskrivning: '',
            beskrivningIsValid: false,
            beskrivningError: null,
            isLoadingAddForslag: false,
            addForslagResult: null

        }
        return initialState;
    }
    validateRubrik() {
        if (this.state.rubrik.length > 0)
            this.setState({ 
                rubrikError: null,
                rubrikIsValid: true,
            });
        else 
            this.setState({ 
                rubrikError: 'Rubrik måste anges', 
                rubrikIsValid: false,
            });
    }

    validateBolag() {
        console.log(this.state.bolag);
        if (this.state.bolag !== '' && this.state.bolag !== undefined)
            this.setState({ 
                bolagError: null,
                bolagIsValid: true,
            });
        else 
            this.setState({ 
                bolagError: 'Bolag måste väljas', 
                bolagIsValid: false,
            });
    }

    validateBeskrivning() {
        if (this.state.beskrivning.length > 0)
            this.setState({ 
                beskrivningError: null,
                beskrivningIsValid: true,
            });
        else 
            this.setState({ 
                beskrivningError: 'Beskrivning måste anges', 
                beskrivningIsValid: false,
            });
    }

    handleRubrikChange(event) { 

        this.setState({
            rubrik: event.target.value
        }, () => {this.validateRubrik();});

        
    }

    handleBeskrivningChange(event) { 

        this.setState({
            beskrivning: event.target.value
        }, () => {this.validateBeskrivning();});

        
    }

    handleBolagChange(event) { 
        this.setState({ 
            bolag: event.target.value
        }, () => {this.validateBolag();});

    }
   
    handleFormSave() {
        if (this.state.isLoadingAddForslag) // dubbelklick
            return false;


        this.validateRubrik();
        this.validateBolag();
        this.validateBeskrivning();

        if (this.state.rubrikIsValid
            && this.state.bolagIsValid
            && this.state.beskrivningIsValid)
        {
            var saveData = {
                Rubrik: this.state.rubrik,
                Bolag: this.state.bolag,
                Beskrivning: this.state.beskrivning
            };
    
            //console.log('to save', saveData);
            this.addForslag(saveData);

        } else {
           // console.log('spara validera icke', saveData);
        }

       
    }

    addForslag(forslag) {
    
        this.setState({
            isLoadingAddForslag: true,
            addForslagResult: null
        }, () => {

            axios.post(infraConfig.apiEndpoint.concat('/api/forslag/'), forslag, getAxiosConfig(this.props.user))
            .then((dataResult) => {
               // console.log('after send success', dataResult);
                
                this.resetForm();
                this.props.fetchForslag();

            })
            .catch((error) => { 
                console.log('ERROR');
               });
        });


    }

    resetForm() {
        this.setState(this.getInititalState(), () => {
            this.setState({addForslagResult: true});
        });
        document.getElementById("create-forslag").reset();
    }

    componentDidMount() {
        this.props.fetchBolag();
    }
    loadingIcon() {
        if (this.props.isLoadingBolag ) {
                return (<div><span className="fa fa-spinner fa-spin" /><span> Laddar formulär..</span></div>)
        } else {
            return (<div>
            {this.props.loadingBolagFailed ? <div><span className="fa fa-exclamation-triangle text-danger" /> Kunde inte ladda formuläret.</div> : 
            <div>{this.renderForm()}</div>
            }</div>)
        }
    }
    renderForm() {
        return (
        <div> { this.props.bolag.length > 0 ? <div><br/><br />
        <form id="create-forslag">
            <FormGroup>
            <ControlLabel>
                Rubrik <span className="star">*</span>
            </ControlLabel>
            <FormControl
                type="text"
                name="rubrik"
                className={this.state.rubrikError !== null ? 'error' : null}
                onChange={event => this.handleRubrikChange(event)}
            />
            <span className="text-danger">{this.state.rubrikError}</span>
            </FormGroup>
            <FormGroup>
            <ControlLabel>
                Bolag <span className="star">*</span>
            </ControlLabel>                       
            <FormControl
                type="select"
                componentClass="select" placeholder="select"
                name="bolag"
                className={this.state.bolagError !== null ? 'error' : null}
                onChange={event => this.handleBolagChange(event)}
                >
                <option value="">-- Välj bolag --</option>
                {this.props.bolag.map((item, key) => {                        
                    return <option key={key} value={item.id}>{item.name}</option>
                })}
                </FormControl>
                <span className="text-danger">{this.state.bolagError}</span>
            </FormGroup>

            <FormGroup>
            <ControlLabel>
                Beskrivning <span className="star">*</span>
            </ControlLabel>
            <FormControl
             componentClass="textarea" type="textarea" placeholder=""
                name="beskrivning"
                className={this.state.beskrivningError !== null ? 'error' : null}
                onChange={event => this.handleBeskrivningChange(event)}
            />
            <span className="text-danger">{this.state.beskrivningError}</span>
            </FormGroup>

            <FormGroup>
                <Button
                    bsStyle="primary"
                    fill                    
                    onClick={this.handleFormSave}
                >
                    <LoadingIcon isLoading={this.state.isLoadingAddForslag} text="Sparar.." success="Spara" />
                </Button>
                { this.state.addForslagResult != null ? <p>Förslag är sparat.</p>
                :null}
                </FormGroup>
                </form>
        </div> : null } </div>
        )
    }
    render() {
        return (this.loadingIcon())
    }
}

function mapStateToProps(state) {
    return {
        user: state.oidc.user,
        bolag: state.sharepoint.bolag,
        bolagTimestamp: state.sharepoint.bolagTimestamp,
        isLoadingBolag: state.sharepoint.isLoadingBolag,
        loadingBolagFailed: state.sharepoint.loadingBolagFailed,
    
    };
  }
  

export default connect(mapStateToProps, { fetchBolag, fetchForslag })(ForslagForm);
