import axios from "axios";
import  { infraConfig } from "config/infraConfig";
import { getAxiosConfig } from "system/axiosConfig";
import store from "system/store";
import { ERRORMESSAGES_ADD } from "system/actions";


export const USERPROFILE_GET ="USERPROFILE_GET"
export const USERPROFILE_GET_SUCCESS = "USERPROFILE_GET_SUCCESS";
export const USERPROFILE_GET_FAILED = "USERPROFILE_GET_FAILED";


export function getUserProfile() {
    
    return (dispatch) => {
        dispatch({type: USERPROFILE_GET});

        const user = store.getState().oidc.user;

        axios.get(infraConfig.apiEndpoint.concat('/api/user/profile'), getAxiosConfig(user))
        .then((dataResult) => dispatch({type: USERPROFILE_GET_SUCCESS, payload: dataResult}))
        .catch((error) => { 
            dispatch({type: USERPROFILE_GET_FAILED}); 
            dispatch({type: ERRORMESSAGES_ADD, payload: { type: 'network', message: 'Hämta user profile misslyckades', severity: 'warning'}});
        });        

    }
    
};
