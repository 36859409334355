import React, { Component } from "react";
import { appConfig } from "config/appConfig";

import userManager from "system/userManager";
import { Nav, NavDropdown, MenuItem, Navbar } from "react-bootstrap";
import { connect } from "react-redux";

// import Date from "components/formatting/Date.jsx";
import { clearErrors } from "system/actions";
import { logoutInProgress } from "system/actions/authHelper.js";

import { LinkContainer } from "react-router-bootstrap";

import BplExternalLink from "components/utils/BplExternalLink";
import DocsExternalLink from "components/utils/DocsExternalLink";
import ExternalLink from "components/utils/ExternalLink";
import UtbExternalLink from "components/utils/UtbExternalLink";

import { getClaimArray } from "system/security";
import KrishanteringButton from "components/utils/KrishanteringButton";
import MediaQuery from "react-responsive";

class HeaderLinks extends Component {
	constructor(props) {
		super(props);

		this.state = {
			extended: false,
		};
	}

	handleNotificationClick() {
		this.props.clearErrors();
	}
	handleLinkClick(path) {
		document.documentElement.classList.toggle("nav-open");
		// if (this.props.history !== undefined) this.props.history.push(path);
	}

	renderDockstaLink() {
		// visa om man har sekundär arbetsgivare = Docksta Buss & Truck
		// claim kan vara string eller array
		// näe numera kan det bara vara array
		var secondaryCompany = getClaimArray(
			"IntraSecondaryCompany",
			this.props.userprofile
		);
		if (secondaryCompany != null) {
			if (Array.isArray(secondaryCompany)) {
				var findInArray = secondaryCompany.filter((item) => {
					return item === "Docksta Buss & Truck";
				});
				if (findInArray.length > 0) {
					console.log("is docksta");
					return (
						<MenuItem>
							<ExternalLink
								to="https://abjorks.sharepoint.com/sites/Verkstad-Norr/Delade%20dokument/Forms/AllItems.aspx"
								title="Docksta B&T"
								showAsText
							/>
						</MenuItem>
					);
				}
			} else {
				if (secondaryCompany === "Docksta Buss & Truck") {
					console.log("is docksta");
					return (
						<MenuItem>
							<ExternalLink
								to="https://abjorks.sharepoint.com/sites/Verkstad-Norr/Delade%20dokument/Forms/AllItems.aspx"
								title="Docksta B&T"
								showAsText
							/>
						</MenuItem>
					);
				}
			}
		}
	}

	render() {
		return (
			<div>
				<Nav pullRight={this.props.pullRight}>
					<NavDropdown
						onToggle={(isToggled) => {
							this.setState({
								extended: isToggled,
							});

							console.log("on toggle", isToggled);
						}}
						eventKey={4}
						title={
							<div>
								<i className="fa fa-window-restore" />
								<span className="hidden-xs hidden-sm"> Mina länkar</span>
								<p className="hidden-md hidden-lg">
									Mina länkar
									<b className="caret" />
								</p>
							</div>
						}
						noCaret
						id="basic-nav-dropdown-3"
						bsClass="dropdown-with-icons dropdown"
					>
						{this.state.extended ? (
							<>
								<MenuItem eventKey={4.1}>
									<BplExternalLink
										to="Client/Tidrapport/index.aspx"
										title="Tidrapport"
										icon={<i className="fa fa-clock-o" />}
										showAsText
									/>
								</MenuItem>
								<MenuItem eventKey={4.2}>
									<BplExternalLink
										to="Client/Busstavla/beta/Förartavlan.aspx"
										title="Förartavlan"
										icon={<i className="fa fa-bus" />}
										showAsText
									/>
								</MenuItem>
								<MenuItem eventKey={4.3}>
									<DocsExternalLink
										to="policy/"
										title="Policy &amp; Rutiner"
										icon={<i className="fa fa-book" />}
										showAsText
									/>
								</MenuItem>
								<MenuItem eventKey={4.4}>
									<DocsExternalLink
										to="policy/Busskartor- Hur fungerar bussen, en lathund.html"
										title="Busskartor"
										icon={<i className="fa fa-file-photo-o" />}
										showAsText
									/>
								</MenuItem>

								<MenuItem>
									<UtbExternalLink
										to={appConfig.utbildningUrl}
										icon={<i className="fa fa-graduation-cap" />}
										title="Utbildningsportalen"
										showAsText
									/>
								</MenuItem>
								<MenuItem>
									<ExternalLink
										to="https://online.idha.com"
										title="Web-idha"
										showAsText
									/>
								</MenuItem>
								<MenuItem>
									<ExternalLink
										to="https://portal.ybuss.se/chauffor/login.asp"
										title="Ybuss förare"
										showAsText
									/>
								</MenuItem>
								<MenuItem>
									<ExternalLink
										to={appConfig.lediaTjansterUrl}
										title="Lediga tjänster"
										showAsText
									/>
								</MenuItem>
								{appConfig.dockstabtlink !== undefined ? (
									<MenuItem className="largermenuitem">
										<ExternalLink
											to={appConfig.dockstabtlink}
											title="Docksta B&T dokument"
											showAsText
										/>
									</MenuItem>
								) : null}

								{this.props.user ? this.renderDockstaLink() : null}
							</>
						) : null}
					</NavDropdown>
					<NavDropdown
						eventKey={4}
						title={
							<div>
								<i className="fa fa-list" />
								<p className="hidden-md hidden-lg">
									Meny
									<b className="caret" />
								</p>
							</div>
						}
						noCaret
						id="basic-nav-dropdown-3"
						bsClass="dropdown-with-icons dropdown"
					>
						<LinkContainer
							onClick={() => this.handleLinkClick()}
							to="/dashboard/userinfo"
						>
							<MenuItem>
								<i className="fa fa-user" />
								Användarinfo
							</MenuItem>
						</LinkContainer>

						<MenuItem eventKey={4.6}>
							<div
								className="text-danger"
								onClick={() => {
									this.handleLinkClick();

									this.props.logoutInProgress();
									userManager.removeUser(); // removes the user data from sessionStorage
									userManager.signoutRedirect({
										id_token_hint: this.props.user.id_token,
									});
									window.localStorage.clear();
								}}
							>
								<i className="fa fa-sign-out" /> Logga ut
							</div>
						</MenuItem>
					</NavDropdown>
				</Nav>
				<MediaQuery minWidth={993}>
					<Navbar.Form pullRight>
						<KrishanteringButton />
					</Navbar.Form>
				</MediaQuery>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		user: state.oidc.user,
		userprofile: state.userprofile.userprofile,
		errors: state.errors.errors,
	};
}

export default connect(mapStateToProps, { clearErrors, logoutInProgress })(
	HeaderLinks
);
