import React from "react";
import { connect } from "react-redux";
import { getConfigurationDescription } from './actions';

class ConfigDescription extends React.Component {

  componentDidMount() {
    this.props.getConfigurationDescription();
  }

  render() {
      return (
      <div>api config: {this.props.configDescription}</div>
    );
  }
}

function mapStateToProps(state) {
  return {
      configDescription: state.diagnostics.configDescription
  };
}

export default connect(mapStateToProps, { getConfigurationDescription })(ConfigDescription);

