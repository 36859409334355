import React, { Component} from "react";
import Card from "components/Card/Card";
import DateC from 'components/formatting/Date';
import Currency from 'components/formatting/Currency';
import LoadingIcon from 'components/utils/LoadingIcon';

class IncidentListTable extends Component {
    render() {
        return <Card commandBar={this.props.commandBar} title={this.props.title} content={<table className="table table-striped">
            <thead>
                <tr>
                    <th>Rubrik</th>
                    <th>Datum</th>
                    <th>Kategori</th>
                    <th>Beskrivning</th>
                    <th className="text-right">Kostnad</th>
                </tr>
            </thead>
            { this.props.rowsIsLoading ? <tbody><tr><td colSpan={5}><LoadingIcon isLoading={true} text="Laddar.." loadingFailed={false} /></td></tr></tbody> 
            
            : this.props.rowsLoadingFailed ? <tbody><tr><td colSpan={5}><LoadingIcon isLoading={false} loadingFailed={true} /></td></tr></tbody> 
            : this.props.rows != null ? <tbody>
                { this.props.rows.resultList.map((item, key) => {
                    return (
                    <tr key={key}>
                        <td>{item.rubrik}</td>
                        <td><DateC value={item.skapadDatum} /></td>
                        <td>{item.fordonsskada.forsakringsKategori}</td>
                        <td>{item.fordonsskada.handelseBeskrivning}</td>
                        <td className="text-right"><Currency value={item.fordonsskada.reparationskostnad} /></td>
                    </tr>
                    )
                })}
                
                <tr>
                <td colSpan={4}></td>
                <td className="text-right">Summa: <Currency value={this.props.rows.totalPersonal} /></td>
            </tr>
            </tbody>
            : null
            }
                
                
                
       </table>} />;
    }
}
export default IncidentListTable;