import React, { Component} from "react";
import { Col, Row} from 'react-bootstrap';

import ForslagCard from "./ForslagCard";

class ForslagCardList extends Component {
    
    render() {
        return (
            <Row>
            {  this.props.forslag.length > 0 ? 
                this.props.forslag.map((row, key) => {
                return (
                    <Col lg={3} sm={4} key={key}><ForslagCard item={row} /></Col>
                    
                ) }) : null}            
            </Row>)
    }
}

export default ForslagCardList;