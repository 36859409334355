import React from "react";
import StatsCard from 'components/Card/StatsCard.jsx';
import Button from 'components/CustomButtons/CustomButton.jsx';
import LoadingIcon from "components/utils/LoadingIcon";
import { connect } from "react-redux";
import { fetchGodkandTidrapport, sendLamnaTidrapport } from "./actions"; 
import Date from "components/formatting/Date";
import MobileDesktop from "components/utils/MobileDesktop";
import Card from 'components/Card/Card';

class BplGodkannaTid extends React.Component {
    constructor(props){
        super(props);
        this.handleAcceptTidrapport = this.handleAcceptTidrapport.bind(this);
    }
    
    componentDidMount() {
        this.props.fetchGodkandTidrapport();
    }

    handleAcceptTidrapport() {
		 if (this.props.tidrapport != null) {

            this.props.sendLamnaTidrapport(this.props.tidrapport.id, this.props.tidrapport.isSubmitted);

        }

    }

    render() {
        return (
        
        <MobileDesktop desktopView={<StatsCard
            bigIcon={ this.props.tidrapport != null ? 
            <Button disabled={this.props.tidrapport.isApproved && !this.props.tidrapport.isRevertable} bsStyle={this.props.tidrapport.isApproved ? "success" : "primary"} fill onClick={this.handleAcceptTidrapport}>
            {this.props.isLoadingAcceptTidrapport ? <LoadingIcon loadingFailed={this.props.loadingAcceptTidrapportFailed} isLoading={this.props.isLoadingAcceptTidrapport} text="Sparar.." /> :
            this.props.tidrapport.isSubmitted ? "Inlämnad" : "Lämna in"
            }
            </Button> : null
          }
            statsText={this.props.tidrapport != null ? <span>Period till <Date value={this.props.tidrapport.period} />, {this.props.tidrapport.passCount} pass</span> : "-"}
            statsValue={this.props.tidrapport != null ? this.props.tidrapport.status : "-"}
            statsIconText={<div><LoadingIcon isLoading={this.props.isLoadingTidrapport} loadingFailed={this.props.isLoadingTidrapportFailed} text="Laddar senaste tidrapporten" success={<span>Uppdaterad: <Date withTime value={this.props.tidrapportTimestamp} /></span>} /></div>}
          />} mobileView={
            <Card content={
                <div>
                    <p>{this.props.tidrapport != null ? <span>Period till <Date value={this.props.tidrapport.period} />, {this.props.tidrapport.passCount} pass</span> : "-"}</p>
                    <h3>{this.props.tidrapport != null ? this.props.tidrapport.status : "-"}</h3>
                    <div>
                        { this.props.tidrapport != null ? 
                            <Button block disabled={this.props.tidrapport.isApproved && !this.props.tidrapport.isRevertable} bsStyle={this.props.tidrapport.isApproved ? "success" : "primary"} fill onClick={this.handleAcceptTidrapport}>
                            {this.props.isLoadingAcceptTidrapport ? <LoadingIcon loadingFailed={this.props.loadingAcceptTidrapportFailed} isLoading={this.props.isLoadingAcceptTidrapport} text="Sparar.." /> :
                            this.props.tidrapport.isSubmitted ? "Inlämnad" : "Lämna in"
                            }
                            </Button> : null}

                    </div>

                </div>
            }
            stats={<div><LoadingIcon isLoading={this.props.isLoadingTidrapport} loadingFailed={this.props.isLoadingTidrapportFailed} text="Laddar senaste tidrapporten" success={<span>Uppdaterad: <Date withTime value={this.props.tidrapportTimestamp} /></span>} /></div>}

            />
          } />
        );
    }
}

	function mapStateToProps(state) {
		return {
			user: state.oidc.user,
			tidrapport: state.bpltidrapport.tidrapport,
			tidrapportTimestamp: state.bpltidrapport.tidrapportTimestamp,
			isLoadingTidrapport: state.bpltidrapport.isLoadingTidrapport,
			isLoadingTidrapportFailed: state.bpltidrapport.isLoadingTidrapportFailed,
            isLoadingAcceptTidrapport: state.bpltidrapport.isLoadingAcceptTidrapport,
            loadingAcceptTidrapportFailed: state.bpltidrapport.loadingAcceptTidrapportFailed
		};
	}
  
  export default connect(mapStateToProps, { fetchGodkandTidrapport, sendLamnaTidrapport })(BplGodkannaTid);