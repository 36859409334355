import React from "react";
import { connect } from "react-redux";
import Card from "components/Card/Card.jsx";
import { Grid, Col, Row } from "react-bootstrap";
import { logoutFinished } from "system/actions/authHelper.js";
import {appConfig} from "config/appConfig";
import SimpleStandaloneHeader from "../components/utils/SimpleStandaloneHeader";

class LoggedOutPage extends React.Component {
  
  componentDidMount() {
    this.props.logoutFinished();
  }
  

  render() {
    return (      
      <div className="main-content">
        <SimpleStandaloneHeader />
        <Grid fluid>
          <Row>
            <Col sm={12}>
            <br />
            <Card content={
              <div className="text-center">
              <p><i className="fa fa-sign-out fa-3x"></i></p>
                <p className="text-center">Du är nu utloggad. Klicka <a href="/">här</a> för att använda {appConfig.title} igen.</p>
                
              </div>      
            } />
            </Col>
          </Row>
      </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.oidc.user,
    logoutInProgress: state.authhelper.logoutInProgress
  };
}

export default connect(mapStateToProps, { logoutFinished })(LoggedOutPage);