import axios from "axios";
import  { infraConfig } from "config/infraConfig";
import { getAxiosConfig } from "system/axiosConfig";
import store from "system/store";
import { ERRORMESSAGES_ADD } from "system/actions";

export const NYHETER_GET_ALL_NEWS = "NYHETER_GET_ALL_NEWS";
export const NYHETER_GET_ALL_NEWS_SUCCESS = "NYHETER_GET_ALL_NEWS_SUCCESS";
export const NYHETER_GET_ALL_NEWS_FAILED = "NYHETER_GET_ALL_NEWS_FAILED";

export const NYHETER_GET_KONCERN = "NYHETER_GET_KONCERN";
export const NYHETER_GET_KONCERN_SUCCESS = "NYHETER_GET_KONCERN_SUCCESS";
export const NYHETER_GET_KONCERN_FAILED = "NYHETER_GET_KONCERN_FAILED";

export const NYHETER_GET_KONCERN_COMPACT = "NYHETER_GET_KONCERN_COMPACT";
export const NYHETER_GET_KONCERN_COMPACT_SUCCESS = "NYHETER_GET_KONCERN_COMPACT_SUCCESS";
export const NYHETER_GET_KONCERN_COMPACT_FAILED = "NYHETER_GET_KONCERN_COMPACT_FAILED";

export const NYHETER_GET_REGION = "NYHETER_GET_REGION";
export const NYHETER_GET_REGION_SUCCESS = "NYHETER_GET_REGION_SUCCESS";
export const NYHETER_GET_REGION_FAILED = "NYHETER_GET_REGION_FAILED";

export const NYHETER_GET_REGION_COMPACT = "NYHETER_GET_REGION_COMPACT";
export const NYHETER_GET_REGION_COMPACT_SUCCESS = "NYHETER_GET_REGION_COMPACT_SUCCESS";
export const NYHETER_GET_REGION_COMPACT_FAILED = "NYHETER_GET_REGION_COMPACT_FAILED";


export function getAllNews(type) {

    return (dispatch) => {
        dispatch({type: NYHETER_GET_ALL_NEWS});
        const user = store.getState().oidc.user;
        axios.get(infraConfig.apiEndpoint.concat('/api/nyheter/getall'), getAxiosConfig(user))
        .then((dataResult) => dispatch({type: NYHETER_GET_ALL_NEWS_SUCCESS, payload: dataResult}))
        .catch((error) => { dispatch({type: NYHETER_GET_ALL_NEWS_FAILED}); dispatch({type: ERRORMESSAGES_ADD, payload: { type: 'network', message: 'Hämta Nyheter misslyckades', severity: 'warning'}});});
    }

}

export function getRegionNews(page) {

    return (dispatch) => {
        dispatch({type: NYHETER_GET_REGION});
        const user = store.getState().oidc.user;
        axios.get(infraConfig.apiEndpoint.concat('/api/nyheter/getregion?page=').concat(page), getAxiosConfig(user))
        .then((dataResult) => dispatch({type: NYHETER_GET_REGION_SUCCESS, payload: dataResult}))
        .catch((error) => { dispatch({type: NYHETER_GET_REGION_FAILED}); dispatch({type: ERRORMESSAGES_ADD, payload: { type: 'network', message: 'Hämta Nyheter misslyckades', severity: 'warning'}});});
    }

}

export function getRegionNewsCompact(page) {

    return (dispatch) => {
        dispatch({type: NYHETER_GET_REGION_COMPACT});
        const user = store.getState().oidc.user;
        axios.get(infraConfig.apiEndpoint.concat('/api/nyheter/getregion?compact=true&page=').concat(page), getAxiosConfig(user))
        .then((dataResult) => dispatch({type: NYHETER_GET_REGION_COMPACT_SUCCESS, payload: dataResult}))
        .catch((error) => { dispatch({type: NYHETER_GET_REGION_COMPACT_FAILED}); dispatch({type: ERRORMESSAGES_ADD, payload: { type: 'network', message: 'Hämta Nyheter misslyckades', severity: 'warning'}});});
    }

}

export function getKoncernNews(page) {

    return (dispatch) => {
        dispatch({type: NYHETER_GET_KONCERN});
        const user = store.getState().oidc.user;
        axios.get(infraConfig.apiEndpoint.concat('/api/nyheter/getkoncern?page=').concat(page), getAxiosConfig(user))
        .then((dataResult) => dispatch({type: NYHETER_GET_KONCERN_SUCCESS, payload: dataResult}))
        .catch((error) => { dispatch({type: NYHETER_GET_KONCERN_FAILED}); dispatch({type: ERRORMESSAGES_ADD, payload: { type: 'network', message: 'Hämta Nyheter misslyckades', severity: 'warning'}});});
    }

}

export function getKoncernNewsCompact(page) {

    return (dispatch) => {
        dispatch({type: NYHETER_GET_KONCERN_COMPACT});
        const user = store.getState().oidc.user;
        axios.get(infraConfig.apiEndpoint.concat('/api/nyheter/getkoncern?compact=true&page=').concat(page), getAxiosConfig(user))
        .then((dataResult) => dispatch({type: NYHETER_GET_KONCERN_COMPACT_SUCCESS, payload: dataResult}))
        .catch((error) => { dispatch({type: NYHETER_GET_KONCERN_COMPACT_FAILED}); dispatch({type: ERRORMESSAGES_ADD, payload: { type: 'network', message: 'Hämta Nyheter misslyckades', severity: 'warning'}});});
    }

}
