import React from "react";
import { connect } from "react-redux";
import Card from "components/Card/Card.jsx";
import UserPersonalDetails from "./UserPersonalDetails";

const divLabelStyle = {
  fontSize: "16px"
};

class UserInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAdvanced: false
    };
    this.showAdvanced = this.showAdvanced.bind(this);
  }
  renderArrayOrString(arrayOrString) {
    if (Array.isArray(arrayOrString)) {
      return (
        <ul>
          {arrayOrString.map((row, key) => {
            return (
              <li style={divLabelStyle} key={key}>
                {row}
              </li>
            );
          })}
        </ul>
      );
    } else {
      return <span style={divLabelStyle}>{arrayOrString}</span>;
    }
  }

  showAdvanced() {
    this.setState(prevState => ({
      showAdvanced: !prevState.showAdvanced
    }));
  }
  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-xs-12">
          <Card
            content={
                <UserPersonalDetails user={this.props.user} />
            }
          />
          </div>
        </div>
      </div>
     
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.oidc.user,
    userprofile: state.userprofile.userprofile
  };
}

export default connect(mapStateToProps)(UserInfo);
