import React, { Component} from "react";

class Currency extends Component {


    render() {
        return (<span>{this.props.value} kr</span>);
    }

}
export default Currency;
