import axios from "axios";
import  { infraConfig } from "config/infraConfig";
import { getAxiosConfig } from "system/axiosConfig";
import store from "system/store";
import { ERRORMESSAGES_ADD } from "system/actions";


export const FORDONSKONTROLL_GET_VEHICLES = "FORDONSKONTROLL_GET_VEHICLES";
export const FORDONSKONTROLL_GET_VEHICLES_SUCCESS = "FORDONSKONTROLL_GET_VEHICLES_SUCCESS";
export const FORDONSKONTROLL_GET_VEHICLES_FAILED = "FORDONSKONTROLL_GET_VEHICLES_FAILED";

export const FORDONSKONTROLL_GET_VEHICLECATEGORIES = "FORDONSKONTROLL_GET_VEHICLECATEGORIES";
export const FORDONSKONTROLL_GET_VEHICLECATEGORIES_SUCCESS = "FORDONSKONTROLL_GET_VEHICLECATEGORIES_SUCCESS";
export const FORDONSKONTROLL_GET_VEHICLECATEGORIES_FAILED = "FORDONSKONTROLL_GET_VEHICLECATEGORIES_FAILED";

export const FORDONSKONTROLL_GET_VEHICLEFAULTS = "FORDONSKONTROLL_GET_VEHICLEFAULTS";
export const FORDONSKONTROLL_GET_VEHICLEFAULTS_SUCCESS = "FORDONSKONTROLL_GET_VEHICLEFAULTS_SUCCESS";
export const FORDONSKONTROLL_GET_VEHICLEFAULTS_FAILED = "FORDONSKONTROLL_GET_VEHICLEFAULTS_FAILED";

export const FORDONSKONTROLL_ADD_VEHICLEFAULT = "FORDONSKONTROLL_ADD_VEHICLEFAULT";
export const FORDONSKONTROLL_ADD_VEHICLEFAULT_SUCCESS = "FORDONSKONTROLL_ADD_VEHICLEFAULT_SUCCESS";
export const FORDONSKONTROLL_ADD_VEHICLEFAULT_FAILED = "FORDONSKONTROLL_ADD_VEHICLEFAULT_FAILED";

export function getVehicles() {
    
    return (dispatch) => {
        dispatch({type: FORDONSKONTROLL_GET_VEHICLES});

        const user = store.getState().oidc.user;
        axios.get(infraConfig.apiEndpoint.concat('/api/fordonskontroll/vehicles'), getAxiosConfig(user))
        .then((dataResult) => dispatch({type: FORDONSKONTROLL_GET_VEHICLES_SUCCESS, payload: dataResult}))
        .catch((error) => { dispatch({type: FORDONSKONTROLL_GET_VEHICLES_FAILED}); dispatch({type: ERRORMESSAGES_ADD, payload: { type: 'network', message: 'Hämta fordon misslyckades', severity: 'warning'}});});        

    }
    
};

export function getVehicleCategories() {
    return (dispatch) => {
        dispatch({type: FORDONSKONTROLL_GET_VEHICLECATEGORIES});

        const user = store.getState().oidc.user;
        axios.get(infraConfig.apiEndpoint.concat('/api/fordonskontroll/categories'), getAxiosConfig(user))
        .then((dataResult) => dispatch({type: FORDONSKONTROLL_GET_VEHICLECATEGORIES_SUCCESS, payload: dataResult}))
        .catch((error) => { dispatch({type: FORDONSKONTROLL_GET_VEHICLECATEGORIES_FAILED}); dispatch({type: ERRORMESSAGES_ADD, payload: { type: 'network', message: 'Hämta kategorier misslyckades', severity: 'warning'}});});        

    }

}

export function getVehicleFaults(vehicleNo) {
    return (dispatch) => {
        dispatch({type: FORDONSKONTROLL_GET_VEHICLEFAULTS});

        const user = store.getState().oidc.user;
        axios.get(infraConfig.apiEndpoint.concat('/api/fordonskontroll/faultreports?vehicleNo=').concat(vehicleNo), getAxiosConfig(user))
        .then((dataResult) => dispatch({type: FORDONSKONTROLL_GET_VEHICLEFAULTS_SUCCESS, payload: dataResult}))
        .catch((error) => { dispatch({type: FORDONSKONTROLL_GET_VEHICLEFAULTS_FAILED}); dispatch({type: ERRORMESSAGES_ADD, payload: { type: 'network', message: 'Hämta felrapporter misslyckades', severity: 'warning'}});});        

    }
    
}

export function addVehicleFault(vehicleFault) {
    
    return (dispatch) => {

        dispatch({type: FORDONSKONTROLL_ADD_VEHICLEFAULT});

        const user = store.getState().oidc.user;

        axios.post(infraConfig.apiEndpoint.concat('/api/fordonskontroll/addfault'), vehicleFault, getAxiosConfig(user))
        .then((dataResult) => {
            dispatch({type: FORDONSKONTROLL_ADD_VEHICLEFAULT_SUCCESS, payload: dataResult}); 

            console.log('after send');

            axios.get(infraConfig.apiEndpoint.concat('/api/fordonskontroll/faultreports?vehicleNo=').concat(vehicleFault.vehicleNumber), getAxiosConfig(user))
            .then((dataResult) => dispatch({type: FORDONSKONTROLL_GET_VEHICLEFAULTS_SUCCESS, payload: dataResult}))
            .catch((error) => { dispatch({type: FORDONSKONTROLL_GET_VEHICLEFAULTS_FAILED}); dispatch({type: ERRORMESSAGES_ADD, payload: { type: 'network', message: 'Hämta felrapporter misslyckades', severity: 'warning'}});});        
            
        })
        .catch((error) => { dispatch({type: FORDONSKONTROLL_ADD_VEHICLEFAULT_FAILED}); dispatch({type: ERRORMESSAGES_ADD, payload: { type: 'network', message: 'Lägga till felrapport misslyckades', severity: 'warning'}});});        
    
    }

}