import React from 'react';
import Button from "components/CustomButtons/CustomButton.jsx";

import { LinkContainer } from "react-router-bootstrap";

class MedarbetarundersokningButton extends React.Component {
    render(){

        return (<LinkContainer to="/dashboard/medarbetarundersokning">
                    <Button id="undersokning" fill block bsStyle="success">Medarbetarundersökning</Button>
                </LinkContainer>);
    }
}

export default MedarbetarundersokningButton;
