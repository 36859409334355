import React, { Component} from "react";
import StatsCard from 'components/Card/StatsCard.jsx';
import Card from 'components/Card/Card';

import Button from 'components/CustomButtons/CustomButton.jsx';
import LoadingIcon from "components/utils/LoadingIcon";
import { connect } from "react-redux";
import { fetchAktuelltBplUppdrag, sendAcceptSchema } from "./actions";
import Date from "components/formatting/Date";
import MobileDesktop from "components/utils/MobileDesktop";

class AktuelltUppdrag extends Component {
    constructor(props){
        super(props);

        this.handleAcceptUppdrag = this.handleAcceptUppdrag.bind(this);
    }
    
    componentDidMount() {
        this.props.fetchAktuelltBplUppdrag();        
    }

    handleAcceptUppdrag() {
        if (this.props.aktuelltUppdrag != null){

            this.props.sendAcceptSchema(this.props.aktuelltUppdrag.passStartSchemapostId, this.props.aktuelltUppdrag.bekraftad);

        }

    }

    render() {
        return (
        <MobileDesktop desktopView={
            <StatsCard
                bigIcon={ this.props.aktuelltUppdrag != null ? 
                <Button disabled={!this.props.aktuelltUppdrag.canBekrafta} bsStyle={this.props.aktuelltUppdrag.bekraftad ? "success" : "primary"} fill onClick={this.handleAcceptUppdrag}>
                {this.props.isLoadingAcceptSchema ? <LoadingIcon loadingFailed={this.props.loadingAcceptSchemaFailed} isLoading={this.props.isLoadingAcceptSchema} text="Sparar.." /> :
                this.props.aktuelltUppdrag.bekraftad ? "Bekräftat" : "Bekräfta"
                }
                </Button> : null
          }
            statsText="Aktuellt uppdrag"
            statsValue={this.props.aktuelltUppdrag != null ? this.props.aktuelltUppdrag.beskrivning : "-"}
            statsIconText={<div><LoadingIcon isLoading={this.props.isLoadingAktuelltUppdrag} loadingFailed={this.props.loadingAktuelltUppdragFailed} text="Laddar aktuellt uppdrag.." success={<span>Uppdaterat: <Date withTime value={this.props.aktuelltUppdragTimestamp} /></span>} /></div>}
          />
        } mobileView={
            <Card content={
                <div>
                    <p>Aktuellt uppdrag</p>
                    <h3>{this.props.aktuelltUppdrag != null ? this.props.aktuelltUppdrag.beskrivning : "-"} </h3>
                    <div>
                    { this.props.aktuelltUppdrag != null ? 
                        <Button block disabled={!this.props.aktuelltUppdrag.canBekrafta} bsStyle={this.props.aktuelltUppdrag.bekraftad ? "success" : "primary"} fill onClick={this.handleAcceptUppdrag}>
                        {this.props.isLoadingAcceptSchema ? <LoadingIcon loadingFailed={this.props.loadingAcceptSchemaFailed} isLoading={this.props.isLoadingAcceptSchema} text="Sparar.." /> :
                        this.props.aktuelltUppdrag.bekraftad ? "Befkräftat" : "Bekräfta"
                        }
                        </Button> : null}
                    </div>
                </div>
            }             

            stats={<div><LoadingIcon isLoading={this.props.isLoadingAktuelltUppdrag} loadingFailed={this.props.loadingAktuelltUppdragFailed} text="Laddar aktuellt uppdrag.." success={<span>Uppdaterat: <Date withTime value={this.props.aktuelltUppdragTimestamp} /></span>} /></div>}

            />
        } />
        
        );
    }
}

function mapStateToProps(state) {
    return {
      aktuelltUppdrag: state.bpluppdrag.aktuelltUppdrag,
      aktuelltUppdragTimestamp: state.bpluppdrag.aktuelltUppdragTimestamp,
      isLoadingAktuelltUppdrag: state.bpluppdrag.isLoadingAktuelltUppdrag,
      loadingAktuelltUppdragFailed: state.bpluppdrag.loadingAktuelltUppdragFailed,
      isLoadingAcceptSchema: state.bpluppdrag.isLoadingAcceptSchema,
      loadingAcceptSchemaFailed: state.bpluppdrag.loadingAcceptSchemaFailed
    };
  }
  
  export default connect(mapStateToProps, { sendAcceptSchema, fetchAktuelltBplUppdrag })(AktuelltUppdrag);