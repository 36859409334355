import { BPLUPPDRAG_GET_UPPDRAG, 
  BPLUPPDRAG_GET_UPPDRAG_SUCCESS,
  BPLUPPDRAG_GET_UPPDRAG_FAILED,
  BPLUPPDRAG_GET_SCHEMA, 
  BPLUPPDRAG_GET_SCHEMA_SUCCESS,
  BPLUPPDRAG_GET_SCHEMA_FAILED,  
  BPLUPPDRAG_GET_AKTUELLTUPPDRAG,
  BPLUPPDRAG_GET_AKTUELLTUPPDRAG_SUCCESS, 
  BPLUPPDRAG_GET_AKTUELLTUPPDRAG_FAILED,
  BPLUPPDRAG_SEND_ACCEPTSCHEMA, 
  BPLUPPDRAG_SEND_ACCEPTSCHEMA_SUCCESS,
  BPLUPPDRAG_SEND_ACCEPTSCHEMA_FAILED 
} from "./actions";

const initialState = {
    schema: [],
    isLoadingSchema: false,
    loadingSchemaFailed: false,
    schemaTimestamp: null,

    uppdrag: [],
    isLoadingUppdrag: false,
    loadingUppdragFailed: false,
    uppdragTimestamp: null,

    aktuelltUppdrag: null,
    isLoadingAktuelltUppdrag: false,
    loadingAktuelltUppdragFailed: false,
    aktuelltUppdragTimestamp: null,

    acceptSchemaPost: false,
    isLoadingAcceptSchema: false,
    loadingAcceptSchemaFailed: false

  };

export default function(state = initialState, action) {
  switch (action.type) {
    case BPLUPPDRAG_GET_AKTUELLTUPPDRAG:
      return {...state, isLoadingAktuelltUppdrag: true, loadingAktuelltUppdragFailed: false } 
    case BPLUPPDRAG_GET_AKTUELLTUPPDRAG_SUCCESS:
      return {...state, isLoadingAktuelltUppdrag: false, aktuelltUppdrag: action.payload.data.result, aktuelltUppdragTimestamp: action.payload.data.timestamp } 
      case BPLUPPDRAG_GET_AKTUELLTUPPDRAG_FAILED:
      return {...state, isLoadingAktuelltUppdrag: false,loadingAktuelltUppdragFailed: true, aktuelltUppdrag: null, aktuelltUppdragTimestamp: null } 
    case BPLUPPDRAG_GET_UPPDRAG:
      return { ...state, isLoadingUppdrag: true, loadingUppdragFailed: false }
    case BPLUPPDRAG_GET_UPPDRAG_SUCCESS:
      return { ...state, isLoadingUppdrag: false, uppdrag: action.payload.data.result, uppdragtimestamp: action.payload.data.timestamp };
    case BPLUPPDRAG_GET_UPPDRAG_FAILED:
      return { ...state, isLoadingUppdrag: false, loadingUppdragFailed: true, uppdrag: [], uppdragtimestamp: null };
      case BPLUPPDRAG_GET_SCHEMA:
      return { ...state, isLoadingSchema: true, loadingSchemaFailed: false }
    case BPLUPPDRAG_GET_SCHEMA_SUCCESS:
      return { ...state, isLoadingSchema: false, schema: action.payload.data.result, schemaTimestamp: action.payload.data.timestamp };
    case BPLUPPDRAG_GET_SCHEMA_FAILED:
      return { ...state, isLoadingSchema: false, loadingSchemaFailed: true, schema: [], schemaTimestamp: null };

      case BPLUPPDRAG_SEND_ACCEPTSCHEMA:
      return { ...state, isLoadingAcceptSchema:true, loadingAcceptSchemaFailed: false }
    case BPLUPPDRAG_SEND_ACCEPTSCHEMA_SUCCESS:
      return { ...state, isLoadingAcceptSchema:false, acceptSchemaPost: action.payload.data.result.status }
    case BPLUPPDRAG_SEND_ACCEPTSCHEMA_FAILED:
      return { ...state, isLoadingAcceptSchema:false, loadingAcceptSchemaFailed: true, acceptSchemaPost: false }
    default:
      return state;
  }
}