import { connect } from "react-redux";
import { getKoncernNews } from "./actions";
import NewsItem from "./NewsItem";
import Button from "components/CustomButtons/CustomButton";
import React from 'react';
import Card from 'components/Card/Card';

class KoncernNewsListView extends React.Component {
    constructor(props){
        super(props);
        
        this.state = {
            page: 0,
            pages: [],
            totalRecords: 0
        }
    }
    
    componentDidMount() {

        this.props.getKoncernNews(this.state.page);

    }

    static getDerivedStateFromProps(nextProps, prevState) {

        
        if (nextProps.koncern && nextProps.koncern.totalRecords !== prevState.totalRecords)
        {
            console.log('derived state', nextProps, prevState);

            var pages = [];
            for (var i = 0; i < Math.ceil(nextProps.koncern.totalRecords / 5); i++){
                pages.push(i);
            }
            return { 
                totalRecords: nextProps.koncern.totalRecords,
                pages: pages,
             }
        }
        return null;
    }
    scrollToTop = () => {
        window.scrollTo({
            top: 0
        });
    }
    
    render() {

        return <div className="container-fluid">            
            { this.props.isLoadingKoncern ? <span><i className="fa fa-spinner fa-spin"></i> Laddar nyheter..</span>: null }
            { this.props.koncern && !this.props.isLoadingKoncern
            ? this.props.koncern.items.map((item,key) => {
                return <div className="row" key={item.id}><div className="col-md-12"><Card key={item.id} content={
                    <NewsItem item={item} key={item.id} />
                } /></div></div>
                }) : null}

            { this.state.pages.length > 1 ?
                <div className="row"><div className="col-md-12"><div className="btn-group">
                { this.state.pages.map((item, key) => {
                    const buttonClass = item === this.state.page ? "primary" : "default";

                    return <Button key={key} fill bsStyle={buttonClass} onClick={() => {

                        this.setState({
                            page: item
                        }, () => {
                            this.props.getKoncernNews(this.state.page);
                            this.scrollToTop()
                        })
                    }}>{item + 1}</Button>
                })
                }                        
                </div></div></div> : null

            }
            </div>
    }
}

function mapStateToProps(state) {
    return {
        userprofile: state.userprofile.userprofile,
        koncern: state.news.koncern,
        isLoadingKoncern: state.news.isLoadingKoncern,
        loadingKoncernFailed: state.news.loadingKoncernFailed,

    };
  }
export default connect(mapStateToProps, { getKoncernNews })(KoncernNewsListView);