import {
    NYHETER_GET_ALL_NEWS,
    NYHETER_GET_ALL_NEWS_SUCCESS,
    NYHETER_GET_ALL_NEWS_FAILED,
    NYHETER_GET_KONCERN,
    NYHETER_GET_KONCERN_SUCCESS,
    NYHETER_GET_KONCERN_FAILED,
    NYHETER_GET_KONCERN_COMPACT,
    NYHETER_GET_KONCERN_COMPACT_SUCCESS,
    NYHETER_GET_KONCERN_COMPACT_FAILED,
    NYHETER_GET_REGION,
    NYHETER_GET_REGION_SUCCESS,
    NYHETER_GET_REGION_FAILED,
    NYHETER_GET_REGION_COMPACT,
    NYHETER_GET_REGION_COMPACT_SUCCESS,
    NYHETER_GET_REGION_COMPACT_FAILED
} from "./actions";

const initialState = {
    allNews: [],
    isLoadingAllNews: false,
    loadingAllNewsFailed: false,

    koncern: undefined,
    isLoadingKoncern:false,
    loadingKoncernFailed: false,
    
    koncernCompact: undefined,
    isLoadingKoncernCompact: false,
    loadingKoncernCompactFailed: false,

    region: undefined,
    isLoadingRegion: false,
    loadingRegionFailed: false,
    
    regionCompact: undefined,
    isLoadingRegionCompact: false,
    loadingRegionCompactFailed: false
};
export default function(state = initialState, action) {
    switch (action.type) {
        case NYHETER_GET_ALL_NEWS:
            return {...state, isLoadingAllNews: true, loadingAllNewsFailed: false }
        case NYHETER_GET_ALL_NEWS_SUCCESS:
            return {...state, isLoadingAllNews: false, loadingAllNewsFailed: false, allNews: action.payload.data.result }
        case NYHETER_GET_ALL_NEWS_FAILED:
            return {...state, isLoadingAllNews: false, loadingAllNewsFailed: true, allNews: [] }
        
        case NYHETER_GET_KONCERN:
                return {...state, isLoadingKoncern: true, loadingKoncernFailed: false }
        case NYHETER_GET_KONCERN_SUCCESS:
                return {...state, isLoadingKoncern: false, loadingKoncernFailed: false, koncern: action.payload.data.result }
        case NYHETER_GET_KONCERN_FAILED:
            return {...state, isLoadingKoncern: false, loadingKoncernFailed: true, koncern: undefined }
    
        case NYHETER_GET_KONCERN_COMPACT:
                return {...state, isLoadingKoncernCompact: true, loadingKoncernCompactFailed: false }
        case NYHETER_GET_KONCERN_COMPACT_SUCCESS:
                return {...state, isLoadingKoncernCompact: false, loadingKoncernCompactFailed: false, koncernCompact: action.payload.data.result }
        case NYHETER_GET_KONCERN_COMPACT_FAILED:
            return {...state, isLoadingKoncernCompact: false, loadingKoncernCompactFailed: true, koncernCompact: undefined }

        case NYHETER_GET_REGION:
                return {...state, isLoadingRegion: true, loadingRegionFailed: false }
        case NYHETER_GET_REGION_SUCCESS:
                return {...state, isLoadingRegion: false, loadingRegionFailed: false, region: action.payload.data.result }
        case NYHETER_GET_REGION_FAILED:
            return {...state, isLoadingRegion: false, loadingRegionFailed: true, region: undefined }
    
        case NYHETER_GET_REGION_COMPACT:
                return {...state, isLoadingRegionCompact: true, loadingRegionCompactFailed: false }
        case NYHETER_GET_REGION_COMPACT_SUCCESS:
                return {...state, isLoadingRegionCompact: false, loadingRegionCompactFailed: false, regionCompact: action.payload.data.result }
        case NYHETER_GET_REGION_COMPACT_FAILED:
            return {...state, isLoadingRegionCompact: false, loadingRegionCompactFailed: true, regionCompact: undefined }

        default:
            return state;
    }
}
