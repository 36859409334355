import dashboardRoutes from './dashboard';
import logo from "assets/customer/bn/logo-small.png";
import sideBarBg from "assets/customer/bn/sidebar-bg.jpg";
import HeaderLinks from "./HeaderLinks";

export const appConfig = {
    name:'bnprod',
    instrumentationKey: '5139df39-2fb7-49c8-be7c-69ea296f67b2',
    lediaTjansterUrl: 'https://www.bybergnordin.se/jobba-hos-oss',
    dockstabtlink: 'https://bybnor.sharepoint.com/sites/BYB-Verkstad/Delade dokument/Forms/AllItems.aspx',
    title: "Min Sida",
    dashboard: dashboardRoutes,
    logoSmall: logo,
    sideBarBg: sideBarBg,
    headerLinks: HeaderLinks,
    utbildningUrl: 'https://utbildningbn.azurewebsites.net/',
}