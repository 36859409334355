import { routerReducer } from 'react-router-redux';
import { combineReducers } from 'redux';
import { reducer as oidcReducer } from 'redux-oidc';
import authHelper from './authHelperReducer';

import subscriptionsReducer from './subscriptions';
import diagnosticsReducer from 'diagnostics/reducer';

import lonReducer from 'dashboard/Lonebesked/reducer';
import kontekReducer from 'dashboard/KontekOnlineLonebesked/reducer';

import intygReducer from 'dashboard/Utbildning/reducer';
import bplGodkannaTidReducer from 'dashboard/BplGodkannaTid/reducer'
import bplMinaUppdragReducer from 'dashboard/BplMinaUppdrag/reducer';
import fordonskontrollReducer from 'dashboard/Fordonskontroll/reducer';
import incidentReducer from 'dashboard/Incidenter/reducer';
import newsReducer from 'dashboard/Nyheter/reducer';
import undersokningReducer from 'dashboard/Medarbetarundersokning/reducer';
import krishanteringReducer from 'dashboard/Krishantering/reducer';
import sharepointCommonReducer from './sharepointCommonReducer';
import forslagReducer from 'dashboard/Forslagslada/reducer';
import policyDocsReducer from 'dashboard/PolicyDocs/reducer';
import ecodrivingReducer from 'dashboard/EcoDriving/reducer';

import userprofileRedcuer from 'system/userprofile/reducer';

import errorReducer from './errorReducer';

const reducer = combineReducers(
  {
    oidc: oidcReducer,
    userprofile: userprofileRedcuer,
    authhelper: authHelper,
    routing: routerReducer,
    subscriptions: subscriptionsReducer,
    errors: errorReducer,
    diagnostics: diagnosticsReducer,
    lonebesked: lonReducer,
    kontek: kontekReducer,
    intyg: intygReducer,
    utvardering: intygReducer,
    undersokning: undersokningReducer,
    bpltidrapport: bplGodkannaTidReducer,
    news: newsReducer,
    bpluppdrag: bplMinaUppdragReducer,
    fordonskontroll: fordonskontrollReducer,
    krishantering: krishanteringReducer,
    sharepoint: sharepointCommonReducer,
    incident: incidentReducer,
    forslag: forslagReducer,
    policyDocs: policyDocsReducer,
    ecodriving: ecodrivingReducer
  }
);

export default reducer;
