import { ECODRIVING_GET_DETAIL, 
    ECODRIVING_GET_DETAIL_FAILED,
    ECODRIVING_GET_DETAIL_SUCCESS,
    ECODRIVING_GET_OVERVIEW,
    ECODRIVING_GET_OVERVIEW_FAILED,
    ECODRIVING_GET_OVERVIEW_SUCCESS
  } from "./actions";
  
  const initialState = {
      overview: null,
      overviewLoading: false,
      overviewFailed: false,
      overviewTimestamp: null,
  
      detail: [],
      detailLoading: false,
      detailFailed: false,
      detailTimestamp: null,
  
    };
  
  export default function(state = initialState, action) {
    switch (action.type) {
      case ECODRIVING_GET_DETAIL:
        return {...state, detailLoading: true, detailFailed: false } 
      case ECODRIVING_GET_DETAIL_SUCCESS:
        return {...state, detailLoading: false, detail: action.payload.data.result, detailTimestamp: action.payload.data.timestamp } 
      case ECODRIVING_GET_DETAIL_FAILED:
        return {...state, detailLoading: false, detailFailed: true, detail: [], detailTimestamp: null } 

        case ECODRIVING_GET_OVERVIEW:
            return {...state, overviewLoading: true, overviewFailed: false } 
          case ECODRIVING_GET_OVERVIEW_SUCCESS:
            return {...state, overviewLoading: false, overview: action.payload.data.result, overviewTimestamp: action.payload.data.timestamp } 
          case ECODRIVING_GET_OVERVIEW_FAILED:
            return {...state, overviewLoading: false, overviewFailed: true, overview: null, overviewTimestamp: null } 
    
      default:
        return state;
    }
  }