// ser ut ungefär som i sharepoint, men listar "mina" incidenter

import React, { Component} from "react";
import Date from "components/formatting/Date";
import {Modal } from 'react-bootstrap';
import Button from "components/CustomButtons/CustomButton.jsx";
class ForslagList extends Component {
    constructor(props){
        super(props);
        this.state = {
            showAtgard: false,
            atgardText: '',
            atgardTitle: ''
        };
    }
    
    showAtgardInfo(info, title) {
       // console.log('atgard', info);
        this.setState({
            atgardText: info,
            atgardTitle: title,
            showAtgard: true
        });
    }
    handleClose() {
        this.setState({
            atgardText: '',
            showAtgard:false
        });
    }
    render() {
        return (
            <div>
                <table className="table table-striped">
            <thead>
                <tr>
                    <th>Rubrik</th>
                    <th>Datum</th>
                    <th>Beskrivning</th>
                    <th>Skapad Av</th>
                    <th>Kommentarer</th>
                    <th>Åtgärder</th>
                </tr>
            </thead>
            <tbody>                    
                {  this.props.forslag.length > 0 ? 
                    this.props.forslag.map((row, key) => {
                    return (
                        <tr key={key}>
                            <td>
                                {row.rubrik}
                            </td>                          
                            <td>
                                <Date value={row.datumForslag} />
                            </td>
                            <td>{row.beskrivning}</td>
                            <td>{row.skapadAv}</td>
                            <td>{row.kommentarer !== null && row.kommentarer.length > 0 ? <Button fill bsSize="small" bsStyle="primary" onClick={() => this.showAtgardInfo(row.kommentarer, 'Kommentarer')} >Kommentarer</Button> : null}</td>
                            <td>{row.atgarder !== null && row.atgarder.length > 0 ? <Button fill bsSize="small" bsStyle="primary" onClick={() => this.showAtgardInfo(row.atgarder, 'Åtgärder')} >Åtgärder</Button> : null}</td>
                        </tr>
                    ) }) : null}
                
        </tbody>
        </table>
                
            <Modal show={this.state.showAtgard} onHide={() => this.handleClose()}>
            <Modal.Header closeButton>
              <Modal.Title>{this.state.atgardTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{this.state.atgardText}</p>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={() => this.handleClose()}>Stäng</Button>
            </Modal.Footer>
          </Modal>
          </div>)
    }
}

export default ForslagList;