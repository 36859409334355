import axios from "axios";
import  { infraConfig } from "config/infraConfig";
import { getAxiosConfig } from "system/axiosConfig";

import store from "system/store";
import { ERRORMESSAGES_ADD } from "system/actions";

export const SHAREPOINT_GET_BOLAG = "SHAREPOINT_GET_BOLAG";
export const SHAREPOINT_GET_BOLAG_SUCCESS = "SHAREPOINT_GET_BOLAG_SUCCESS";
export const SHAREPOINT_GET_BOLAG_FAILED = "SHAREPOINT_GET_BOLAG_FAILED";


export function fetchBolag() {
    
    return (dispatch) => {
        dispatch({type: SHAREPOINT_GET_BOLAG});

        const user = store.getState().oidc.user;
        axios.get(infraConfig.apiEndpoint.concat('/api/spcommon/bolag'), getAxiosConfig(user))
        .then((dataResult) => dispatch({type: SHAREPOINT_GET_BOLAG_SUCCESS, payload: dataResult}))
        .catch((error) => { dispatch({type: SHAREPOINT_GET_BOLAG_FAILED}); dispatch({type: ERRORMESSAGES_ADD, payload: { type: 'network', message: 'Hämta lista med bolag misslyckades', severity: 'warning'}});});

    }
    
};