import React, { Component } from "react";
import CustomButton from "../CustomButtons/CustomButton.jsx";
import LoadingIcon from "./LoadingIcon";
import { infraConfig } from "config/infraConfig";
import { getAxiosConfig } from "system/axiosConfig";
import axios from "axios";
import { connect } from "react-redux";
import { getClaimSingle } from "system/security";
import { openInNewTabOrDont as openInNewTab } from "./openInNewTabOrDont";

class BplExternalLink extends Component {
	constructor(props) {
		super(props);

		this.handleClick = this.handleClick.bind(this);
		this.state = {
			isLoading: true,
			linkToken: "",
			bplBaseUrl: "",
		};

		var _this = this;
		axios
			.get(
				infraConfig.apiEndpoint.concat("/api/bpl/linktoken/"),
				getAxiosConfig(this.props.user)
			)
			.then((result) => {
				_this.setState({
					isLoading: false,
					linkToken: result.data.result.token,
					bplBaseUrl: result.data.result.bplBaseUrl,
				});
			})
			.catch((error) => {
				_this.setState({
					isLoading: false,
					linkToken: "",
					bplBaseUrl: "",
				});
				
			});

		console.log("render bpl link");
	}

	// rendera en länk som vars klick anropar linktoken, och sen login.aspx?bp
	handleClick() {
		if (this.state.linkToken !== "") {
			// toggle the side-nav-bar in mobile mode
			document.documentElement.classList.toggle("nav-open");

			// bygg loginurl
			var url = this.state.bplBaseUrl
				.concat("/login.aspx?u=")
				.concat(getClaimSingle("BplUsername", this.props.userprofile))
				.concat("&t=")
				.concat(encodeURIComponent(this.state.linkToken))
				.concat("&ReturnURL=")
				.concat(
					encodeURIComponent(
						this.state.bplBaseUrl.concat("/").concat(this.props.to)
					)
				);

			openInNewTab(url);
		}
	}

	render() {
		return this.props.showAsText ? (
			<div onClick={this.handleClick}>
				{" "}
				{this.props.icon !== undefined ? (
					this.props.icon
				) : (
					<i className="fa fa-external-link" />
				)}{" "}
				{this.props.title}{" "}
				{this.state.isLoading ? (
					<LoadingIcon isLoading={this.state.isLoading} />
				) : null}
			</div>
		) : (
			<CustomButton
				active={this.state.linkToken !== ""}
				block={this.props.block}
				bsStyle={this.props.bsStyle}
				fill
				onClick={this.handleClick}
			>
				{this.state.isLoading ? (
					<LoadingIcon isLoading={this.state.isLoading} text="Öppnar.." />
				) : (
					this.props.title
				)}
			</CustomButton>
		);
	}
}

function mapStateToProps(state) {
	return {
		user: state.oidc.user,
		userprofile: state.userprofile.userprofile,
	};
}

export default connect(mapStateToProps)(BplExternalLink);
