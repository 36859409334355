import React, { Component } from "react";
import { connect } from "react-redux";
import LoginPage from "../account/loginPage";
import LoadingIcon from "components/utils/LoadingIcon";

import UserProfileHC from "./Main/UserProfileHC.jsx";
      
class Dashboard extends Component {
  
  render() {

    return (
      this.props.isLoadingUser ? <LoadingIcon isLoading={true} text="Laddar.." /> :
      !this.props.user || this.props.user.expired ? <LoginPage {...this.props} /> : <UserProfileHC {...this.props} />);

    }
}

function mapStateToProps(state) {
  return {
    user: state.oidc.user,
    isLoadingUser: state.oidc.isLoadingUser
  };
}
function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
