import React, { Component } from 'react';
import { Router, browserHistory } from 'react-router';
import store from 'system/store';
import { syncHistoryWithStore } from 'react-router-redux';

import App from 'app';
import LoginPage from 'account/loginPage';
import HelpPage from 'account/helpPage';
import Diagnostics from 'diagnostics';

import MyCallbackPage from 'account/callback';
import { appConfig } from 'config/appConfig';
import Dashboard from 'dashboard/dashboard';
import LoggedOutPage from 'account/loggedoutPage';
import ReactAI from 'react-appinsights';

const history = syncHistoryWithStore(browserHistory, store); 

ReactAI.init({instrumentationKey:appConfig.instrumentationKey}, browserHistory);

const routes = {
  path:"/",
  component: App,
  indexRoute: {
    onEnter: (nextState, replace) => replace('/dashboard/start')
  },
  childRoutes: [
    {
      path:"/dashboard",
      indexRoute: {
        onEnter: (nextState, replace) => replace('/dashboard/start')
      },
      component: Dashboard,
      childRoutes: appConfig.dashboard
    },
    {
      path:"/callback",
      component: MyCallbackPage
    },
    {
      path:"/reset",
      component: HelpPage
    },
    {
      path:"/help",
      component: HelpPage
    },
    {
      path:"/diagnostics",
      component: Diagnostics
    },
    {
      path:"/login",
      component: LoginPage
    },
    {
      path:"/loggedout",
      component: LoggedOutPage
    }
    
  ]  
}

class Routes extends Component {
  
  render() {
    return (
      <Router history={history} routes={routes} />
    );
  }
  }
 
  export default Routes;
