import React from 'react';
import { connect } from "react-redux";
import axios from 'axios';
import  { infraConfig } from "config/infraConfig";
import { getAxiosConfig } from "system/axiosConfig";
import Button from "components/CustomButtons/CustomButton";
import NewsComment from "./NewsComment";

const MAX_COMMENT_LENGTH = 400;

class NewsComments extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            collapsed: true,
            comments: [],
            loadingComments: false,
            newCommentBodyText: '',
            isAddingComment: false,
            addingCommentFailed: false,
            loadingCommentsFailed: false,
            a: false,
            commentError: null
        }
        
        this.commentIsValid = true;

    }

    componentDidMount() {
        this.setState(prevState => ({
            a: !prevState.a
          }), () => {
            this.loadComments();
          });       
    }

    handleDeleteComment(id) {
        console.log('deleting', id);
        this.setState(prevState => ({
            comments: prevState.comments.filter(el => el.id !== id )
        }), () => {
            this.loadComments();
        });
    }

    addComment() {

        this.validateCommentBox();

        if (this.commentIsValid) {
            this.setState({
                isAddingComment: true,
                addingCommentFailed: false
            })
            axios.post(infraConfig.apiEndpoint.concat('/api/nyheter/addcomment'), {
                bodyText: this.state.newCommentBodyText,
                newsItemId: this.props.newsId
            }, getAxiosConfig(this.props.user))
            .then((dataResult) => {
                
                this.setState(prevState => ({
                    comments: [dataResult.data.result].concat(prevState.comments),
                    newCommentBodyText: '',
                    isAddingComment: false,
                    addingCommentFailed: false
                  }), () => {
                    this.loadComments();
                  });    

            }).catch(() => {
                this.setState({
                    addingCommentFailed: true,
                    isAddingComment: false
                })
            });
        }
        

    }

    loadComments() {

        console.log('laddar kommentarer');
        this.setState({
            loadingComments: true,
            // comments:[],
            loadingCommentsFailed: false
        }, () => {
            axios.get(infraConfig.apiEndpoint.concat('/api/nyheter/getcomments?newsId=').concat(this.props.newsId), getAxiosConfig(this.props.user))
            .then((dataResult) => {
                
                this.setState({
                    loadingComments: false,
                    comments: dataResult.data.result
                });
            }).catch(() => {
                this.setState({
                    loadingComments: false,
                    comments: [],
                    loadingCommentsFailed: true
                });
            });
        });



    }

    validateCommentBox() {
        if (this.state.newCommentBodyText.length > 0 && this.state.newCommentBodyText.length <= MAX_COMMENT_LENGTH) {
            this.commentIsValid = true;

            this.setState({
                commentError: null
            });
        } else {
            this.commentIsValid = false;
            this.setState({
                commentError: `Kommentar får inte vara tomt och inte överskrida ${String(MAX_COMMENT_LENGTH)} tecken`
            })
        }

    }

    render() {
        // const myUserId = getClaimSingle("IntraUserId", this.props.userprofile);

        return <div><p className="list-group-item-download-file" onClick={() => {
            this.setState(prevState => ({
                collapsed: !prevState.collapsed
              }), () => {
                  if (!this.state.collapsed) {
                    //this.loadComments();
                  }
              });


            }}>Kommentarer ({this.state.loadingComments 
                ? <i className="fa fa-spinner fa-spin"></i> 
                : this.state.loadingCommentsFailed ? <i className="fa fa-exclamation-triangle text-danger"></i>
                : this.state.comments.length}) {this.state.collapsed ? <i className="fa fa-sort-down text-muted"></i>: <i className="fa fa-sort-up text-muted"></i>}</p>

        { !this.state.collapsed ? <ul className="list-group">

        { this.state.loadingCommentsFailed ? <li className="list-group-item">Kommentarer kunde inte läsas in för tillfället.</li> : null }

            { !this.state.loadingCommentsFailed && this.state.comments.map((comment, key) => {
                return <NewsComment _handleDelete={this.handleDeleteComment.bind(this)} item={comment} key={comment.id} maxLength={MAX_COMMENT_LENGTH}/>
            }) }
            { !this.state.loadingCommentsFailed ? 
                <li className="list-group-item">
                    <div className="form-group">
            <div className="pull-right news-comment-charcounter">{this.state.newCommentBodyText.length} / {String(MAX_COMMENT_LENGTH)}</div>
                        <textarea className={this.state.commentError !== null ? 'form-control error' : 'form-control'} placeholder="Skriv din kommentar här." value={this.state.newCommentBodyText} 
                        
                        onChange={(event) => {
                            this.setState({
                                newCommentBodyText: event.target.value.substring(0, MAX_COMMENT_LENGTH)
                            }, () => {
                                this.validateCommentBox();
                            });
                        }}></textarea>
                        <span className="text-danger">{this.state.commentError}</span>
                    </div>
                    <Button onClick={() => {
                        this.addComment();
                    }} fill bsStyle="primary">
                        {this.state.isAddingComment ? <span><i className="fa fa-spinner fa-spin"></i> Sparar..</span> : <span>Spara</span>}</Button>
                        { this.state.addingCommentFailed ? <span className="text-danger"><i className="fa fa-times"></i>Ett fel inträffade när kommentaren skulle sparas</span> : null}
                </li>
            : null }
            

            </ul> : null }
            
            </div>
      

    }
}

function mapStateToProps(state) {
    return {
      user: state.oidc.user,
      userprofile: state.userprofile.userprofile
    };
  }
export default connect(mapStateToProps)(NewsComments);
