// formuläret från startsidan (genvägen) det förenklade
import { connect } from "react-redux";
import axios from "axios";
import  { infraConfig } from "config/infraConfig";
import { getAxiosConfig } from "system/axiosConfig";
import Switch from "react-bootstrap-switch";
import SelectVehicleForm from "./SelectVehicleForm";

import SavingButton from "components/CustomButtons/SavingButton.jsx"
import Datetime from "react-datetime";
import React, { Component} from "react";
import {
    Row,
    Col,
    FormGroup,
    FormControl,
    ControlLabel
  } from "react-bootstrap";
  import Checkbox from "components/CustomCheckbox/CustomCheckbox.jsx";
  
  import moment from 'moment';
  import 'moment/locale/sv';
  

class FormArbetsmiljo extends Component {
    constructor(props){
        super(props);
        
        moment().locale('sv');
        this.state = this.getInititalState();

        this.handleFormSave = this.handleFormSave.bind(this);
        this.handleSelectVehicle = this.handleSelectVehicle.bind(this);

        this.datumIsValid = true;
        this.platsIsValid = true;
        this.fordonIsValid = true;

    }

    getInititalState = () => {
        const initialState = {
            datumError: null,
            datum: moment(new Date()),
            plats: '',
            platsError: null,
            fordon: '',
            fordonError: null,
            personskador: false,
            arbetsmoment: [],
            arbetsmoment_eget: '',
            vadHande: [],
            vadHande_eget: '',
            varNagot: [],
            varNagot_eget: '',
            forhallanden: [],
            forhallanden_eget: '',
            forslag: [],
            forslag_eget: '',
            savingStatus: 0,
            file: null,
            attachmentNames: []

        }
        return initialState;
    }

    validatePlats() {
        if (this.state.plats.length > 0) {
            this.platsIsValid = true;
            this.setState({ 
                platsError: null
            });
            } else {
                this.platsIsValid = false;
                this.setState({ 
                    platsError: 'Plats måste anges'
                });
    
            } 
    }

    validateDatum() {

        var parsed = moment(this.state.datum, 'YYYY-MM-DD', true);

        if (parsed.isValid()) {
            this.datumIsValid = true;
            this.setState({ 
                datumError: null
            });
        }
        else {
            this.datumIsValid = false;
            this.setState({ 
                datumError: 'Datum har fel format'
            });

        }
    }
    validateFordon() {
        
        console.log('validate fordon', this.state.fordon);

        var vehicleExists = this.checkIfVehicleExists(this.state.fordon);

        if (vehicleExists)
        {
            this.fordonIsValid = true;   
            this.setState({
                fordonError: null
            });
        } else {
            this.fordonIsValid = false;
            this.setState({
                fordonError: 'Ett giltigt fordon måste väljas från listan'
            });
        }

        
    }

    handleSelectVehicle(vehicleName) {
        console.log('byta fordon', vehicleName);

        if (vehicleName !== this.state.fordon) {
            
            this.setState({
                fordon: vehicleName
            }, () => { this.validateFordon(); });
        }
    }
    handleDatumChange(date) { 

        this.setState({ 
            datum: date
        }, () => {this.validateDatum();});

    }

    handlePlatsChange(event) { 
        this.setState({ 
            plats: event.target.value
        }, () => {this.validatePlats();});

    }

    handlePersonskadorChange(el, value){        
        this.setState({
            personskador: value
        });        
    }    

    handleArbetsmomentEgetChange(event) {
        this.setState({
            arbetsmoment_eget: event.target.value
        })
    }

    handleVadHandeEgetChange(event) {
        this.setState({
            vadHande_eget: event.target.value
        })
    }

    handleVarNagotEgetChange(event) {
        this.setState({
            varNagot_eget: event.target.value
        })
    }
    handleForhallandenEgetChange(event) {
        this.setState({
            forhallanden_eget: event.target.value
        })
    }
    handleForslagEgetChange(event) {
        this.setState({
            forslag_eget: event.target.value
        })
    }

    handleArbetsmomentChange(event, rowItem) {
        //console.log(event.target.checked, rowItem);

        var newArbetsmoment = this.state.arbetsmoment;

        if (event.target.checked) {
            newArbetsmoment.push(rowItem);
        } else {
            newArbetsmoment = newArbetsmoment.filter((item) => item.id !== rowItem.id);
        }

        this.setState({
            arbetsmoment: newArbetsmoment
        });
    }
    handleVadHandeChange(event, rowItem) {
        //console.log(event.target.checked, rowItem);

        var newVadHande = this.state.vadHande;

        if (event.target.checked) {
            newVadHande.push(rowItem);
        } else {
            newVadHande = newVadHande.filter((item) => item.id !== rowItem.id);
        }

        this.setState({
            vadHande: newVadHande
        });
    }
    handleVarNagotChange(event, rowItem) {
        //console.log(event.target.checked, rowItem);

        var newVarNagot = this.state.varNagot;

        if (event.target.checked) {
            newVarNagot.push(rowItem);
        } else {
            newVarNagot = newVarNagot.filter((item) => item.id !== rowItem.id);
        }

        this.setState({
            varNagot: newVarNagot
        });

    }
    handleForhallandenChange(event, rowItem) {
        //console.log(event.target.checked, rowItem);

        var newForhallanden = this.state.forhallanden;

        if (event.target.checked) {
            newForhallanden.push(rowItem);
        } else {
            newForhallanden = newForhallanden.filter((item) => item.id !== rowItem.id);
        }

        this.setState({
            forhallanden: newForhallanden
        });

    }
    handleForslagChange(event, rowItem) {
        //console.log(event.target.checked, rowItem);

        var newForslag = this.state.forslag;

        if (event.target.checked) {
            newForslag.push(rowItem);
        } else {
            newForslag = newForslag.filter((item) => item.id !== rowItem.id);
        }

        this.setState({
            forslag: newForslag
        });
    }

    checkIfVehicleExists(name){
        if(name == undefined || name == ""){
            return "Inget Fordon";
        }
        else{
        var item = this.props.vehicles.filter((item) => { return item.name === name })[0];        
        return item !== undefined;
        }
    }

    handleFormSave() {        

        this.validateDatum();
        this.validatePlats();
        this.validateFordon();

        if (this.datumIsValid
            && this.platsIsValid 
            && this.fordonIsValid)
        {
            var saveData = {
                Datum: this.state.datum.format(),
                Plats: this.state.plats,
                Fordon: this.state.fordon,
                Personskador: this.state.personskador,
                Arbetsmoment: this.state.arbetsmoment,
                VadHande: this.state.vadHande,
                VarNagot: this.state.varNagot,
                Forhallanden: this.state.forhallanden,
                Forslag: this.state.forslag,
                AttachmentUploadBlobNames: this.state.attachmentNames
            };
            if (this.state.vadHande_eget !== '')
                saveData.VadHande.push({
                id: 'vadHande_eget', 
                label: this.state.vadHande_eget,
                order: 1000,
                value: this.state.vadHande_eget
            });
            if (this.state.varNagot_eget !== '')
                saveData.VarNagot.push({
                    id: 'varNagot_eget',
                    label: this.state.varNagot_eget,
                    order: 1000,
                    value: this.state.varNagot_eget
                });
            if (this.state.forhallanden_eget !== '')
                saveData.Forhallanden.push({
                    id: 'forhallanden_eget',
                    label: this.state.forhallanden_eget,
                    order: 1000,
                    value: this.state.forhallanden_eget
                });
            if (this.state.forslag_eget !== '')
                saveData.Forslag.push({
                    id: 'forslag_eget',
                    label: this.state.forslag_eget,
                    order: 1000,
                    value: this.state.forslag_eget,
                });
            if (this.state.arbetsmoment_eget !== '')
                saveData.Arbetsmoment.push({
                    id: 'arbetsmoment_eget',
                    label: this.state.arbetsmoment_eget,
                    order: 1000,
                    value: this.state.arbetsmoment_eget,
                });
    
            //console.log('to save', saveData);
                this.addIncident(saveData);

        } else {
           // console.log('spara validera icke', saveData);
        }

       
    }

    addIncident(incident) {
        this.setState({
            savingStatus: 1
        }, () => {
            
            axios.post(infraConfig.apiEndpoint.concat('/api/incident/addarbetsmiljo'), incident, getAxiosConfig(this.props.user))
            .then((dataResult) => {
               // console.log('after send success', dataResult);
                
                this.setState({
                    savingStatus: 2
                });
                
                this.resetForm();

            })
            .catch((error) => { 
                this.setState({
                    savingStatus: 3
                });
                console.log('ERROR');
               });
        });

    }

    onFileUploadChange(e) {
        e.preventDefault();

        this.setState({file:e.target.files[0]})
      }
           
      onAddImageClick(e) {    
        this.fileUpload(this.state.file);
      }
      onRemoveImageItem(name) {
        this.setState({
            attachmentNames: this.state.attachmentNames.filter(item => item.name !== name)
        });
      }
      fileUpload(file) {
        var axiosConfig = getAxiosConfig(this.props.user);
        axiosConfig.headers["Content-Type"] = "multipart/form-data";
    
        const formData = new FormData();
        formData.append('file',file)
    
        axios.post(infraConfig.apiEndpoint.concat('/api/incident/uploadimage'), formData, axiosConfig)
        .then((dataResult) => {
    
            if (dataResult.data.result !== false)
            {
                this.setState(prevState => {
                    const attachmentNames = prevState.attachmentNames.concat(dataResult.data.result);
                    return {
                        attachmentNames,
                        file: null
                    }
                }, () => {
                    //console.log(this.state);
                });
        
            }
            
        })
        .catch((error) => { 
            //console.log('ERROR', error);
        });
        
      }
      triggerInputFile = () => this.fileInput.click()

    resetForm() {
        var savingStatus = this.state.savingStatus;
        this.setState(this.getInititalState(), () => {
           this.setState({ savingStatus: savingStatus});
        });
        
        document.getElementById("create-incident").reset();
    }
    
    render() {
        return (<div> { this.props.formOptions != null ? <div>
        
            <div className="list-group">
                <div className="list-group-item active">Foton</div>
                <div className="list-group-item">
                    <input ref={fileInput => this.fileInput = fileInput} onChange={event => this.onFileUploadChange(event)} type="file" className="hidden" />
                    <div className="btn-group">
                        <button className="btn btn-primary btn-fill" onClick={event => this.triggerInputFile(event)}> Välj fil {this.state.file ? <span>({this.state.file.name})</span> : null}</button>
                        <button className="btn btn-primary btn-fill" onClick={event => this.onAddImageClick(event)} type="submit">Lägg till</button>
                    </div>
                </div>
                { this.state.attachmentNames.map((item, key) => {
                    return <div className="list-group-item" key={key}><button onClick={event => this.onRemoveImageItem(item.name)} className="btn btn-warning btn-fill"><span className="fa fa-remove" /></button> {decodeURI(item.shortName).replace('+',' ')}</div>
                })}
            </div>
    
    
            <form id="create-incident">
                <FormGroup>
                    <ControlLabel>
                        Datum för incident <span className="star">*</span>
                    </ControlLabel>
                          <Datetime
                          onChange={this.handleDatumChange.bind(this)}
                          
                          timeFormat={false}                     
                             inputProps={{ placeholder: "Datum för incident" ,className: this.state.datumError !== null ? 'form-control error' : 'form-control' }}
                            defaultValue={new Date()}
                          />
                    <span className="text-danger">{this.state.datumError}</span>
                </FormGroup>
                <FormGroup>
                <ControlLabel>
                        Plats <span className="star">*</span>
                </ControlLabel>
                <FormControl
                    type="text"
                    name="plats"
                    onChange={event => this.handlePlatsChange(event)}
                    className={this.state.platsError !== null ? 'error' : null}
                />
                    <span className="text-danger">{this.state.platsError}</span>
                </FormGroup>
                <Row>
                    <Col xs={12}>
                        <SelectVehicleForm vehicleError={this.state.fordonError} vehicles={this.props.vehicles} handleSelectVehicle={this.handleSelectVehicle} />
                    </Col>
                </Row>
                <FormGroup>
                        <Switch onText="Ja" offText="Nej" defaultValue={this.state.personskador} onChange={(el, state) => this.handlePersonskadorChange(el, state)} /> Personskador?
                </FormGroup>
                <FormGroup>
                <ControlLabel>Arbetsmoment</ControlLabel>
            
                <div>{ this.props.formOptions != null ? 
                        this.props.formOptions.arbetsmoment.map((row, key) => {
                            return (<Checkbox onClick={event => this.handleArbetsmomentChange(event, row)}  label={row.label} number={row.id} key={key} />);
                        
                        })
                        : null
                        }
                        </div>
                </FormGroup>
                <FormGroup>
                    <ControlLabel>
                        Ange ett eget värde:
                    </ControlLabel>
                    <FormControl
                        type="text"
                        name="arbetsmomenteget"
                        onChange={event => this.handleArbetsmomentEgetChange(event)}
                    />
                    </FormGroup>
    
                              
                <FormGroup>
    
                <ControlLabel>Vad hände</ControlLabel>
            
                <div>{ this.props.formOptions != null ? 
                        this.props.formOptions.vadHande.map((row, key) => {
                            return (<Checkbox onClick={event => this.handleVadHandeChange(event, row)}  label={row.label} number={row.id} key={key} />);
                        
                        })
                        : null
                        }
                        </div>
                </FormGroup>
                <FormGroup>
                    <ControlLabel>
                        Ange ett eget värde:
                    </ControlLabel>
                    <FormControl
                        type="text"
                        name="vadhandeeget"
                        onChange={event => this.handleVadHandeEgetChange(event)}
                    />
                    </FormGroup>
    
                              
                <FormGroup>
                <ControlLabel>Var något tillfälligt annorlunda på arbetsplatsen?</ControlLabel>
                    
                    <div>{ this.props.formOptions != null ? 
                            this.props.formOptions.varNagot.map((row, key) => {
                                return (<Checkbox onClick={event => this.handleVarNagotChange(event, row)}  label={row.label} number={row.id} key={key} />);
                            
                            })
                            : null
                            }</div>
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>
                            Ange ett eget värde:
                        </ControlLabel>
                        <FormControl
                            type="text"
                            name="varnagoteget"
                            onChange={event => this.handleVarNagotEgetChange(event)}
                        />
                    </FormGroup>
                    <FormGroup>
                    <ControlLabel>Förhållanden som var som de brukar men som kan ha påverkat händelsen</ControlLabel>
                    <div>{ this.props.formOptions != null ? 
                            this.props.formOptions.forhallanden.map((row, key) => {
                                return (<Checkbox onClick={event => this.handleForhallandenChange(event, row)} label={row.label} number={row.id} key={key} />);
                            
                            })
                            : null
                            }</div>
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>
                            Ange ett eget värde:
                        </ControlLabel>
                        <FormControl
                            type="text"
                            name="forhallandeneget"
                            onChange={event => this.handleForhallandenEgetChange(event)}
                        />
                        </FormGroup>
                    <FormGroup>
                    <ControlLabel>Förslag till åtgärder så att liknande händelser undviks i framtiden</ControlLabel>
                    <div>{ this.props.formOptions != null ? 
                            this.props.formOptions.forslag.map((row, key) => {
                                return (<Checkbox onClick={event => this.handleForslagChange(event, row)} label={row.label} number={row.id} key={key} />);
                            
                            })
                            : null
                            }</div>
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>
                            Ange ett eget värde:
                        </ControlLabel>
                        <FormControl
                            type="text"
                            name="forslageget"
                            onChange={event => this.handleForslagEgetChange(event)}
                        />
                        </FormGroup>
                    <FormGroup>
    
                    <SavingButton 
                        text="Spara"
                        inProgressText="Sparar.."
                        completeMessage="Avvikelse har sparats"
                        errorMessage="Ett fel inträffade"
                        status={this.state.savingStatus}
                        onClick={this.handleFormSave}
                    />
                    </FormGroup>
                    </form>
            </div> : null } </div>)
    }
}

function mapStateToProps(state) {
    return {
        user: state.oidc.user
    };
  }
  

export default connect(mapStateToProps)(FormArbetsmiljo);
