import React from 'react';
import "assets/css/krishantering.css";

class Busschaufforer extends React.Component {
/*
I anslutning till checklistan för busschaufförer (enbart de som har befattning bussförare) skall även kontaktperson visas. Kontaktperson är närmsta chef (och det finns i personalregistret). Även telefonnummer till kontaktpersonen skall visas och det måste hämtas från personalregistret (så detta måste nog bli en slagning till, men lösning är valfri bara det hämtas på något sätt).

*/
    render() {
        return(        
        <div>
            <h4 className="nomargin">Du råkar ut för olycka med personskador</h4>
            <p>Larma SOS 112 (hellre en gång för mycket än för lite)</p>
            <p className="small-text"><label>Kom ihåg: </label>
            Vart har olyckan skett<br />
                        Vägnummer<br />
                        Ort/plats<br />
                        Vad har skett<br />
                        Finns personskador<br />
                        Finns risk att skadorna kan förvärras (brand explosion kläm)
            </p>

            <p>Säkra platsen</p>
            <p className="small-text"><label>Kom ihåg: </label>Sätt ut varningstriangel så att andra trafikanter ej förvärrar olyckan</p>

            <p>Vid behov inled akut räddningsarbete (ABC)</p>
            <p className="small-text"><label>Kom ihåg: </label>Kolla om sjukvårdspersonal finns bland resenärerna</p>

            <p>Meddela trafikledningen</p>
            <p className="small-text"><label>Kom ihåg: </label>Trafikledningen ombesörjer att Du får den hjälp som du behöver samt tillkallar ev reservfordon/förare mm</p>

            <p>Ge besked till resenärerna om vad som händer</p>
            <p className="small-text"><label>Kom ihåg: </label>Alla resenärer sitter kvar i bussen och vill få lägesinformation</p>

            <p>Prata inte med tidningar, TV mm utan hänvisa till företagets trafikledning</p>
            <p className="small-text"><label>Kom ihåg: </label>Säg gärna att Ni inte orkar svara på frågor just nu, ett svar som brukar accepteras i ett krisläge.</p>

            <h4>Om <strong>skadan är lindrig</strong> och du ej är i behov av akut hjälp (inga passagerare), eller vid <strong>incident/tillbud</strong></h4>
            <p>Meddela trafikledningen</p>
            <p className="small-text"><label>Kom ihåg: </label>Redogör för händelsen, ev förseningar, bussbyten m.m.<br/>
            Dokumentera olycksplatsen ev skador med din mobilkamera
            </p>
        </div>)
    } 
}

export default Busschaufforer;
