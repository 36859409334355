import React, { Component} from "react";
import {Table} from 'react-bootstrap';
import Time from 'components/formatting/Time';
import DateC from 'components/formatting/Date';

class HuvudUppdragTable extends Component{

   
    renderList() {
          return (
                <Table striped hover responsive>
                    <thead>
                        <tr>
                            <th>Datum</th>
                            <th>Start</th>
                            <th>Avg.tid</th>
                            <th>Slut</th>
                            <th>Beskrivning</th>
                            <th>Fordon</th>
                            <th>Notering</th>
                        </tr>
                    </thead>
                    <tbody>                    
                        {  this.props.uppdrag.length > 0 ? 
                            this.props.uppdrag.map((row, key) => {
                            return (
                                <tr key={key}>
                                    <td>
                                        <DateC value={row.starttid} />
                                    </td>
                                    <td>
                                        <Time value={row.starttid} />
                                    </td>
                                    <td>
                                        <Time value={row.turStarttid} />
                                    </td>
                                    <td>
                                        <Time value={row.sluttid} />
                                    </td>
                                    <td>
                                        {row.beskrivning}
                                    </td>
                                    <td>
                                        {row.fordonStr}
                                    </td>
                                    <td>
                                        {row.anmarkning}
                                    </td>
                                </tr>
                            ) }) : null}
                        
                </tbody>
              </Table>
            );
      }

    render() {
       
        return (this.renderList());
    }
}
export default HuvudUppdragTable;