import { USERPROFILE_GET, 
    USERPROFILE_GET_SUCCESS,
    USERPROFILE_GET_FAILED} from "./actions";

    const initialState = {
        userprofile: null,
        userprofileIsLoading: false,
        userprofileLoadingFailed: false,
    
      };
    
    export default function(state = initialState, action) {
      switch (action.type) {
        case USERPROFILE_GET:
          return {...state, userprofileIsLoading: true, userprofileLoadingFailed: false } 
        case USERPROFILE_GET_SUCCESS:
          return {...state, userprofileIsLoading: false, userprofileLoadingFailed: false, userprofile: action.payload.data.result } 
        case USERPROFILE_GET_FAILED:
          return {...state, userprofileIsLoading: false, userprofileLoadingFailed: true, userprofile: null}         
        default:
          return state;
      }
    }