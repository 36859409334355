import { appConfig as localConfig } from './local/appConfig';
import { appConfig as devConfig } from './dev/appConfig';
import { appConfig as bnprodConfig } from './bnprod/appConfig';
import { appConfig as vhprodConfig } from './vhprod/appConfig';
import { appConfig as falcksprodConfig } from './falcksprod/appConfig';
import { appConfig as td2prodConfig } from './td2prod/appConfig';

var hostnameLookup = window.location.hostname;

const exportedConfig = hostnameLookup === "minsidadev.azurewebsites.net" ? devConfig
: hostnameLookup === "minsidabn.azurewebsites.net" ? bnprodConfig
: hostnameLookup === "minsidabn-staging.azurewebsites.net" ? bnprodConfig
: hostnameLookup === "minsida.bybergnordin.se" ? bnprodConfig
: hostnameLookup === "minsidatd2.azurewebsites.net" ? td2prodConfig
: hostnameLookup === "minsidatd2-staging.azurewebsites.net" ? td2prodConfig
: hostnameLookup === "minsida.ppsp.se" ? td2prodConfig
: hostnameLookup === "minsida-vastanhede.azurewebsites.net" ? vhprodConfig
: hostnameLookup === "vastanhedeminsida.se" ? vhprodConfig
: hostnameLookup === "www.vastanhedeminsida.se" ? vhprodConfig
: hostnameLookup === "minsidavh.azurewebsites.net" ? vhprodConfig
: hostnameLookup === "minsidavh-staging.azurewebsites.net" ? vhprodConfig
: hostnameLookup === "minsidafalcks.azurewebsites.net" ? falcksprodConfig
: hostnameLookup === "minsidafalcks-staging.azurewebsites.net" ? falcksprodConfig
: localConfig;

// export const appConfig = vhprodConfig; // test
export const appConfig = exportedConfig;
