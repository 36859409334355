import { connect } from "react-redux";
import { fetchEcodrivingOverview, fetchEcodrivingDetail} from "./actions";
import Percentage from "components/formatting/Percentage";
import LeafPercentageIcon from './LeafPercentageIcon';

import LoadingIcon from "components/utils/LoadingIcon";
import Card from 'components/Card/Card.jsx';
import StatsCard from "components/Card/StatsCard.jsx";
import Datetime from 'react-datetime';
import NumberFormat from 'components/formatting/Number';
import 'assets/css/detailcard.css';

import {
  FormGroup
} from "react-bootstrap";

import React, { Component} from "react";
import {
    Row,
    Col,
    Well
  } from "react-bootstrap";
import moment from 'moment';
import 'moment/locale/sv';

class EcoDriving extends Component {
  constructor(props){
    super(props);

    moment().locale('sv');

    this.state = {
      detailsDate: new Date()
    };
  }
    componentDidMount()
    {
      this.props.fetchEcodrivingDetail(moment(this.state.detailsDate).toISOString());
      this.props.fetchEcodrivingOverview();
    }

    render() {
  return (<div className="container-fluid">
    <Row>
      <Col sm={12}>
        <Card content={<div>
          <h4>Välkommen!</h4>
         <p>Här ser du en presentation på hur din bränsleförbrukning är jämfört med dina kollegor. Du jämförs på varje tur du kör och kan se resultaten i procent (%) per dag, per vecka och per månad.</p>
        <ul className="clean-list">
          <li>Över 100% = Du förbrukar mer än dina kollegor</li>
          <li>100% = Snittet bland kollegorna</li>
          <li>Under 100% = Du förbrukar mindre än dina kollegor</li>
        </ul>
        <p className="top-extra-margin">Du kan välja ett specifikt datum och se hur varje tur gick den dagen.</p>
        <ul className="clean-list">
          <li>Rött löv = Du förbrukar mer än dina kollegor</li>
          <li>Orange löv = Du kör som snittet</li>
          <li>Grönt löv = Du förbrukar mindre än dina kollegor</li>
        </ul>
        </div>} />
      </Col>
    </Row>
    <Row>     
            <Col lg={3} sm={6}>
              <StatsCard
                bigIcon={this.props.overview ? <LeafPercentageIcon value={this.props.overview.ecoIndexDay} /> : null}
                statsText="Min dag"
                statsValue={this.props.overview ? <Percentage value={this.props.overview.ecoIndexDay} maxDecimals={2} /> : "-"}
                statsIconText={ <LoadingIcon text="Laddar.." isLoading={this.props.overviewLoading} loadingFailed={this.props.overviewFailed} success={this.props.overview ? this.props.overview.dayLabel : "-"} />}
              />
            </Col>
            <Col lg={3} sm={6}>
              <StatsCard
                bigIcon={this.props.overview ? <LeafPercentageIcon value={this.props.overview.ecoIndexWeek} /> : null}
                statsText="Min Vecka"
                statsValue={this.props.overview ? <Percentage value={this.props.overview.ecoIndexWeek} maxDecimals={2} /> : "-"}
                statsIconText={ <LoadingIcon text="Laddar.." isLoading={this.props.overviewLoading} loadingFailed={this.props.overviewFailed} success={this.props.overview ? this.props.overview.weekLabel : "-"} />}
              />
            </Col>
            <Col lg={3} sm={6}>
              <StatsCard
                bigIcon={this.props.overview ? <LeafPercentageIcon value={this.props.overview.ecoIndexMonth} /> : null}
                statsText="Min Månad"
                statsValue={this.props.overview ? <Percentage value={this.props.overview.ecoIndexMonth} maxDecimals={2} /> : "-"}
                statsIconText={ <LoadingIcon text="Laddar.." isLoading={this.props.overviewLoading} loadingFailed={this.props.overviewFailed} success={this.props.overview ? this.props.overview.monthLabel : "-"} />}
              />
            </Col>
            <Col lg={3} sm={6}>
              <StatsCard
                bigIcon={<i className="pe-7s-graph1 text-info" />}
                statsText="Min förändring mot föregående månad"
                statsValue={this.props.overview ? <Percentage value={this.props.overview.ecoIndexMonth - this.props.overview.ecoIndexPrevMonth} maxDecimals={2} /> : "-"}
                statsIconText={ <LoadingIcon text="Laddar.." isLoading={this.props.overviewLoading} loadingFailed={this.props.overviewFailed} success={null} />}
              />
            </Col>
        </Row>
        <Row>
          <Col lg={12}>
          <Card 
          content={
            <div>
              <div className="row">
                <div className="col-xs-12 col-sm-6 col-md-8">
                  <h4 className="title">Dagens Turer</h4>
                </div>
                <div className="col-xs-12 col-sm-6 col-md-4">
                  <FormGroup>
                        <Datetime
                            inputProps={{ placeholder: "Datum", className: "form-control text-center" }}
                            dateFormat="YYYY-MM-DD"
                            timeFormat={false}
                            closeOnSelect={true}
                            onChange={(newDate) => {
                              this.setState({
                                detailsDate: newDate
                              }, () => {
                                this.props.fetchEcodrivingDetail(moment(this.state.detailsDate).toISOString());
                              });
                            }}
                            value={this.state.detailsDate}
                            className="datetime-width"
                            />
                    </FormGroup>
                </div>
              </div>
            <div className="hidden-xs">
              <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Tur</th>
                      <th>Buss</th>
                      <th>Jämförelseindex</th>
                      <th>Distans (km)</th>
                      <th>Medeltemperatur (C)</th>
                      <th>Snittförbrukning (l/mil)</th>
                    </tr>
                  </thead>
                  <tbody>
                    { this.props.detailLoading ? <tr>
                      <td colSpan={6}><LoadingIcon isLoading={true} text="Laddar.." /></td>
                    </tr>
                    : this.props.detailFailed ? <tr><td colSpan={6}><LoadingIcon isLoading={false} loadingFailed={true} /></td></tr>
                    : this.props.detail.length > 0 ? this.props.detail.map((prop, key) => {
                      return <tr key={key}>
                        <td>{prop.turbeskrivning}</td>
                        <td>{prop.fordonRegistreringsnummer}</td>
                        <td><NumberFormat value={prop.ecoIndex} nullChar="-" decimals={2} /></td>
                        <td><NumberFormat value={prop.distance} nullChar="-" decimals={2} /></td>
                        <td><NumberFormat value={prop.outsideTemp} nullChar="-" decimals={2} /></td>
                        <td><NumberFormat value={prop.avgFuelUsed} nullChar="-" decimals={2} /></td>
                      </tr>
                    }) : null}
                  </tbody>
                </table>
            </div>
            <div className="hidden-sm hidden-md hidden-lg"><br></br>
            { this.props.detailLoading ? 
                      <LoadingIcon isLoading={true} text="Laddar.." />
                    : this.props.detailFailed ? <p><LoadingIcon isLoading={false} loadingFailed={true} /></p>
                    : this.props.detail.length > 0 ? this.props.detail.map((prop, key) => {
                      return <div key={key} className="detailcard-card">
                        <div className="row">
                          <div className="col-xs-6">
                            <div className="detailcard"><div className="detailcard-label">Tur</div>
                            {prop.turbeskrivning}
                            </div>                        
                          </div>
                          <div className="col-xs-6">
                            <div className="detailcard"><div className="detailcard-label">Fordon</div>
                            {prop.fordonRegistreringsnummer}
                            </div>      
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xs-6">
                            <div className="detailcard"><div className="detailcard-label">Jämförelseindex</div>
                            <NumberFormat value={prop.ecoIndex} nullChar="-" decimals={2} />
                            </div>                        
                          </div>
                          <div className="col-xs-6">
                            <div className="detailcard"><div className="detailcard-label">Distans (km)</div>
                            <NumberFormat value={prop.distance} nullChar="-" decimals={2} />
                            </div>      
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xs-6">
                            <div className="detailcard"><div className="detailcard-label">Medeltemperatur (C)</div>
                            <NumberFormat value={prop.outsideTemp} nullChar="-" decimals={2} />
                            </div>                        
                          </div>
                          <div className="col-xs-6">
                            <div className="detailcard"><div className="detailcard-label">Snittförbrukning (l/mil)</div>
                            <NumberFormat value={prop.avgFuelUsed} nullChar="-" decimals={2} />
                            </div>      
                          </div>
                        </div>
                      </div>
                    }) : null}
            </div>
            </div>
            } />
          </Col>
        </Row>
    </div>)
    }
}

function mapStateToProps(state) {
    return {
			detail: state.ecodriving.detail,
			detailLoading: state.ecodriving.detailLoading,
			detailFailed: state.ecodriving.detailFailed,
			overview: state.ecodriving.overview,
			overviewLoading: state.ecodriving.overviewLoading,
			overviewFailed: state.ecodriving.overviewFailed,
    };
  }

export default connect(mapStateToProps, { fetchEcodrivingOverview, fetchEcodrivingDetail }) (EcoDriving)