import { connect } from "react-redux";
import { fetchForslag } from "./actions";
import LoadingIcon from "components/utils/LoadingIcon";
import Card from 'components/Card/Card.jsx';
import MobileDesktop from 'components/utils/MobileDesktop';

import ForslagForm from "./ForslagForm";
import ForslagCardList from "./ForslagCardList";
import ForslagList from "./ForslagList";

import React, { Component} from "react";
import {
    Grid,
    Row,
    Col,
  } from "react-bootstrap";
  import Button from "components/CustomButtons/CustomButton";
  
class Forslagslada extends Component {
    constructor(props){
        super(props);

        this.state = { showNewForm: false};
    }
    componentDidMount()
    {
        this.props.fetchForslag();
    }
    handleShowNewForm() {
        this.setState(prevState => ({
            showNewForm: !prevState.showNewForm
          }));

    }

    render() {
        return (<Grid fluid>
            <Row>
                <Col lg={12} xs={12}>
                <Card content={<div><Button fill bsStyle="primary" onClick={this.handleShowNewForm.bind(this)}><i className={!this.state.showNewForm ? "fa fa-plus" : "fa fa-minus"} /> Skapa ny</Button>
                    { this.state.showNewForm ? <ForslagForm  /> : null }</div>} />     
                
                </Col>
            </Row>
            <Row>
                <Col sm={12}>                
                    <Card title="Förslag" content={<LoadingIcon text="Laddar förslag.." isLoading={this.props.isLoadingForslag} loadingFailed={this.props.loadingForslagFailed} 
                    success={<MobileDesktop desktopView={<ForslagList forslag={this.props.forslag} />} mobileView={<ForslagCardList forslag={this.props.forslag} />} />}>
                    </LoadingIcon>} />        
                </Col>
            </Row>
        </Grid>)
    }
}

function mapStateToProps(state) {
    return {
        bolag: state.sharepoint.bolag,
        forslag: state.forslag.forslag,
        forslagTimestamp: state.forslag.forslagTimestamp,
        isLoadingForslag: state.forslag.isLoadingForslag,
        loadingForslagFailed: state.forslag.loadingForslagFailed,
    };
  }

export default connect(mapStateToProps, { fetchForslag }) (Forslagslada)