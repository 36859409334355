import React, { Component } from "react";
import CustomButton from "../CustomButtons/CustomButton.jsx";
import LoadingIcon from "./LoadingIcon";
import { infraConfig } from "config/infraConfig";
import { getAxiosConfig } from "system/axiosConfig";
import axios from "axios";
import { connect } from "react-redux";
import { openInNewTabOrDont as openInNewTab } from "./openInNewTabOrDont";

class UtbExternalLink extends Component {
	constructor(props) {
		super(props);

		this.handleClick = this.handleClick.bind(this);
		this.state = {
			isLoading: false,
		};
	}

	// rendera en länk som vars klick anropar linktoken, och sen login.aspx?bp
	handleClick() {
		// console.log('klickeklick', this.props.to);

		// toggle the side-nav-bar in mobile mode
		document.documentElement.classList.toggle("nav-open");

		this.setState({ isLoading: true });
		var _this = this;

		axios
			.get(
				infraConfig.apiEndpoint.concat("/api/utbildning/userprops"),
				getAxiosConfig(this.props.user)
			)
			.then((result) => {
				// console.log('klickeklick token', result);

				// bygg loginurl
				var url = this.props.to
					.concat("/ExternalLogin?externalUserId=")
					.concat(encodeURIComponent(result.data.result.externalUserId))
					.concat("&companyId=")
					.concat(encodeURIComponent(result.data.result.companyId))
					.concat("&displayName=")
					.concat(encodeURIComponent(result.data.result.displayName))
					.concat("&email=")
					.concat(encodeURIComponent(result.data.result.email));

				console.log("klickeklick url", url);
				// redirecta till login
				openInNewTab(url);
				_this.setState({ isLoading: false });
			})
			.catch((error) => {
				_this.setState({ isLoading: false });
				
			});
	}

	render() {
		return this.props.showAsText ? (
			<div onClick={this.handleClick}>
				{this.props.icon !== undefined ? (
					this.props.icon
				) : (
					<i className="fa fa-external-link" />
				)}{" "}
				{this.props.title}{" "}
				{this.state.isLoading ? (
					<LoadingIcon isLoading={this.state.isLoading} />
				) : null}
			</div>
		) : (
			<CustomButton
				block={this.props.block}
				bsStyle={this.props.bsStyle}
				fill
				onClick={this.handleClick}
			>
				{this.state.isLoading ? (
					<LoadingIcon isLoading={this.state.isLoading} text="Öppnar.." />
				) : (
					this.props.title
				)}
			</CustomButton>
		);
	}
}

function mapStateToProps(state) {
	return {
		user: state.oidc.user,
		userprofile: state.userprofile.userprofile,
	};
}

export default connect(mapStateToProps)(UtbExternalLink);
