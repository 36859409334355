import { ERRORMESSAGES_ADD, ERRORMESSAGES_CLEAR } from "../actions";

const initialState = {
  errors: []
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ERRORMESSAGES_ADD:
      action.payload.timestamp = new Date();
      return { ...state, errors: state.errors.concat(action.payload)}
    case ERRORMESSAGES_CLEAR:
      return { ...state, errors: [] }
    default:
      return state;
  }
}
