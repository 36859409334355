import {
  LONEBESKED_GET_LONESPEC,
  LONEBESKED_GET_LONESPEC_SUCCESS,
  LONEBESKED_GET_LONESPEC_FAILED,
  LONEBESKED_GET_DOWNLOAD,
  LONEBESKED_GET_DOWNLOAD_SUCCESS,
  LONEBESKED_GET_DOWNLOAD_FAILED,
} from "./actions";
const initialState = {
    lonebesked: [],
    isLoadinglonebesked: false,
    loadinglonebeskedFailed: false,

    download: [],
    isLoadingdownload: false,
    loadingdownloadFailed: false,
};
export default function(state = initialState, action) {
    switch (action.type) {
        case LONEBESKED_GET_LONESPEC:
            return {...state, isLoadinglonebesked: true, loadinglonebeskedFailed: false }
        case LONEBESKED_GET_LONESPEC_SUCCESS:
            return {...state, isLoadinglonebesked: false, lonebesked: action.payload.data.result }
        case LONEBESKED_GET_LONESPEC_FAILED:
            return {...state, isLoadinglonebesked: false,loadinglonebeskedFailed: true, lonebesked: [] }

            case LONEBESKED_GET_DOWNLOAD:
                return {...state, isLoadingdownload: true, loadingdownloadFailed: false }
            case LONEBESKED_GET_DOWNLOAD_SUCCESS:
                return {...state, isLoadingdownload: false, download: action.payload.data.result }
            case LONEBESKED_GET_DOWNLOAD_FAILED:
                return {...state, isLoadingdownload: false,loadingdownloadFailed: true, download: [] }

        default:
            return state;
    }
  }
