import React from 'react';
import {Grid, Col, Row } from "react-bootstrap";
import Card from 'components/Card/Card.jsx';
import { getUndersokning } from "./actions";
import { connect } from "react-redux";
import Forare from "./forare";
import Tjansteman from "./tjansteman";
import LoadingIcon from "components/utils/LoadingIcon";
class Medarbetarundersokning extends React.Component {
  componentDidMount() {
  this.props.getUndersokning();
  }
    render(){
      return (
        <div>
        <div id="forare">
          <Grid fluid>
              <Row>
                  <Col sm={12}>
                      <Card title="Medarbetarundersökning" content={
  <LoadingIcon text="Laddar undersökning.." isLoading={this.props.isLoadingundersokning} loadingFailed={this.props.loadingundersokningFailed} success={
                                <Forare undersokning={this.props.undersokning}/>
                                } />
                    } />
                  </Col>
              </Row>
          </Grid>
        </div>
        <div id="tjansteman">
                <Grid fluid>
                    <Row>
                        <Col sm={12}>
                            <Card title="Medarbetarundersökning" content={
        <LoadingIcon text="Laddar undersökning.." isLoading={this.props.isLoadingundersokning} loadingFailed={this.props.loadingundersokningFailed} success={
                                      <Tjansteman undersokning={this.props.undersokning}/>
                                      } />
                          } />
                        </Col>
                    </Row>
                </Grid>
              </div></div>);
    }
}
function mapStateToProps(state) {
    return {
        undersokning: state.undersokning.undersokning,
        isLoadingundersokning: state.undersokning.isLoadingundersokning,
        loadingundersokningFailed: state.undersokning.loadingundersokningFailed,
    };
  }
export default connect(mapStateToProps, {getUndersokning })(Medarbetarundersokning);
