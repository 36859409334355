// formuläret från startsidan (genvägen) det förenklade
import { connect } from "react-redux";
import axios from "axios";
import  { infraConfig } from "config/infraConfig";
import { getAxiosConfig } from "system/axiosConfig";

import SavingButton from "components/CustomButtons/SavingButton.jsx"
import Datetime from "react-datetime";
import React, { Component} from "react";
import {
    FormGroup,
    FormControl,
    ControlLabel
  } from "react-bootstrap";
  import moment from 'moment';
  import 'moment/locale/sv';
  

class FormTrafikinformation extends Component {

  constructor(props){
    super(props);
    
    moment().locale('sv');

    this.state = this.getInititalState();

    this.handleFormSave = this.handleFormSave.bind(this);

    this.hallplatskategoriIsValid = true;
    this.datumIsValid = true;
    this.platsIsValid = true;
    this.handelseBeskrivningIsValid = true;
    

  }

  getInititalState = () => {
    const initialState = {
        hallplatskategori: undefined,
        hallplatskategoriError: null,        
        datumError: null,
        handelseBeskrivningError: null,
        datum: moment(new Date()),
        plats: '',
        platsError: null,
        handelseBeskrivning : '',
        savingStatus: 0,
        file: null,
        attachmentNames: []
    }
    return initialState;

  }
  
  validatePlats() {
      if (this.state.plats.length > 0) {
          this.platsIsValid = true;
          this.setState({ 
              platsError: null
          });
          } else {
              this.platsIsValid = false;
              this.setState({ 
                  platsError: 'Plats måste anges'
              });

          } 
  }

  validateHallplatskategori() {

    if (this.state.hallplatskategori !== '' && this.state.hallplatskategori !== undefined){
        this.hallplatskategoriIsValid = true;
        this.setState({ 
            hallplatskategoriError: null
        });
    }
    else {
        this.hallplatskategoriIsValid = false;
        this.setState({ 
            hallplatskategoriError: 'Rubrik måste väljas'
        });
    }
}
validateDatum() {

    var parsed = moment(this.state.datum, 'YYYY-MM-DD', true);

    if (parsed.isValid()) {
        this.datumIsValid = true;
        this.setState({ 
            datumError: null
        });
    }
    else {
        this.datumIsValid = false;
        this.setState({ 
            datumError: 'Datum har fel format'
        });

    }
}

validateHandelseBeskrivning() {
    if (this.state.handelseBeskrivning.length > 0) {
        this.handelseBeskrivningIsValid = true;
        this.setState({ 
            handelseBeskrivningError: null
        });

    } else {
        this.handelseBeskrivningIsValid = false;
        this.setState({ 
            handelseBeskrivningError: 'Händelsebeskrivning måste anges'
        });

    } 
}

handleHallplatskategoriChange(event) { 
    this.setState({ 
        hallplatskategori: event.target.value
    }, () => {this.validateHallplatskategori();});

}

handleDatumChange(date) { 

    this.setState({ 
        datum: date
    }, () => {this.validateDatum();});

}

handlePlatsChange(event) { 
    this.setState({ 
        plats: event.target.value
    }, () => {this.validatePlats();});

}

handleHandelseBeskrivningChange(event){
    this.setState({handelseBeskrivning: event.target.value}, () => { this.validateHandelseBeskrivning()});
}

  handleFormSave() {
   
    this.validateHandelseBeskrivning();
    this.validateHallplatskategori();
    this.validateDatum();
    this.validatePlats();

    if (this.hallplatskategoriIsValid 
        && this.datumIsValid
        && this.platsIsValid
        && this.handelseBeskrivningIsValid)
    {
        var saveData = {
            Hallplatskategori: this.state.hallplatskategori,
            Datum: this.state.datum.format(),
            Plats: this.state.plats,
            HandelseBeskrivning: this.state.handelseBeskrivning,
            AttachmentUploadBlobNames: this.state.attachmentNames
        };
  
        //console.log('to save', saveData);
            this.addIncident(saveData);

    } else {
        //console.log('spara validera icke', saveData);
    }

   
}

  addIncident(incident) {
    this.setState({
        savingStatus: 1
    }, () => {

        axios.post(infraConfig.apiEndpoint.concat('/api/incident/addtrafikinformation'), incident, getAxiosConfig(this.props.user))
        .then((dataResult) => {
          //  console.log('after send success', dataResult);
            
            this.setState({
                savingStatus: 2
            });
            
            this.resetForm();

        })
        .catch((error) => { 
            this.setState({
                savingStatus: 3
            });
            console.log('ERROR');
           });
    });

  }
  onFileUploadChange(e) {
    e.preventDefault();

    this.setState({file:e.target.files[0]})
  }
       
  onAddImageClick(e) {    
    this.fileUpload(this.state.file);
  }
  onRemoveImageItem(name) {
    this.setState({
        attachmentNames: this.state.attachmentNames.filter(item => item.name !== name)
    });
  }
  fileUpload(file) {
    var axiosConfig = getAxiosConfig(this.props.user);
    axiosConfig.headers["Content-Type"] = "multipart/form-data";

    const formData = new FormData();
    formData.append('file',file)

    axios.post(infraConfig.apiEndpoint.concat('/api/incident/uploadimage'), formData, axiosConfig)
    .then((dataResult) => {

        if (dataResult.data.result !== false)
        {
            this.setState(prevState => {
                const attachmentNames = prevState.attachmentNames.concat(dataResult.data.result);
                return {
                    attachmentNames,
                    file: null
                }
            }, () => {
               // console.log(this.state);
            });
    
        }
        
    })
    .catch((error) => { 
       // console.log('ERROR', error);
    });
    
  }
  triggerInputFile = () => this.fileInput.click()
  resetForm() {
    var savingStatus = this.state.savingStatus;
    this.setState(this.getInititalState(), () => {
       this.setState({ savingStatus: savingStatus});
    });

      
      document.getElementById("create-incident").reset();
  }

  

render() {
    return (
    <div> { this.props.formOptions != null ? <div>
   
   <div className="list-group">
        <div className="list-group-item active">Foton</div>
        <div className="list-group-item">
            <input ref={fileInput => this.fileInput = fileInput} onChange={event => this.onFileUploadChange(event)} type="file" className="hidden" />
            <div className="btn-group">
                <button className="btn btn-primary btn-fill" onClick={event => this.triggerInputFile(event)}> Välj fil {this.state.file ? <span>({this.state.file.name})</span> : null}</button>
                <button className="btn btn-primary btn-fill" onClick={event => this.onAddImageClick(event)} type="submit">Lägg till</button>
            </div>
        </div>
        { this.state.attachmentNames.map((item, key) => {
            return <div className="list-group-item" key={key}><button onClick={event => this.onRemoveImageItem(item.name)} className="btn btn-warning btn-fill"><span className="fa fa-remove" /></button> {decodeURI(item.shortName).replace('+',' ')}</div>
        })}
    </div>
   
    <form id="create-incident">        
   
    <FormGroup>
        <ControlLabel>
            Rubrik <span className="star">*</span>
        </ControlLabel>                       
        <FormControl
            type="select"
            componentClass="select" placeholder="select"
            name="hallplatskategori"
            className={this.state.hallplatskategoriError !== null ? 'error' : null}
            onChange={event => this.handleHallplatskategoriChange(event)}
            >
            <option value="">-- Välj rubrik --</option>
            {this.props.formOptions.hallplatskategori.map((item, key) => {                        
                return <option key={key} value={item.value}>{item.label}</option>
            })}
            </FormControl>
            <span className="text-danger">{this.state.hallplatskategoriError}</span>
        </FormGroup>
        
        <FormGroup>
            <ControlLabel>
                Datum för incident <span className="star">*</span>
            </ControlLabel>
                  <Datetime
                  onChange={this.handleDatumChange.bind(this)}
                  
                  timeFormat={false}                     
                     inputProps={{ placeholder: "Datum för incident" ,className: this.state.datumError !== null ? 'form-control error' : 'form-control' }}
                    defaultValue={new Date()}
                  />
            <span className="text-danger">{this.state.datumError}</span>
        </FormGroup>
        <FormGroup>
        <ControlLabel>
                Plats <span className="star">*</span>
        </ControlLabel>
        <FormControl
            type="text"
            name="plats"
            onChange={event => this.handlePlatsChange(event)}
            className={this.state.platsError !== null ? 'error' : null}
        />
            <span className="text-danger">{this.state.platsError}</span>
        </FormGroup>
        <FormGroup>
            <ControlLabel>
            Vad inträffade? <span className="star">*</span>
            </ControlLabel>
            <FormControl
                componentClass="textarea" type="textarea" placeholder=""
                className={this.state.handelseBeskrivningError !== null ? 'error' : null}
                name="handelsebeskrivning"
                onChange={event => this.handleHandelseBeskrivningChange(event)}
            />
            <span className="text-danger">{this.state.handelseBeskrivningError}</span>

        </FormGroup>
        <FormGroup>            
            <SavingButton 
                text="Spara"
                inProgressText="Sparar.."
                completeMessage="Avvikelse har sparats"
                errorMessage="Ett fel inträffade"
                status={this.state.savingStatus}
                onClick={this.handleFormSave}
            />
            </FormGroup>
            </form>
    </div> : null } </div>
    )
    }
  
}

function mapStateToProps(state) {
    return {
        user: state.oidc.user,
    };
  }
  
export default connect(mapStateToProps)(FormTrafikinformation);
