import React from 'react';
import {Grid, Col, Row } from "react-bootstrap";
import Card from 'components/Card/Card.jsx';
import { getIntyg, getUtvardering } from "./actions";
import { connect } from "react-redux";
import ShowIntyg from "./Showintyg";
import Utvardering from "./Utvardering";
import LoadingIcon from "components/utils/LoadingIcon";
class Intyg extends React.Component {
  componentDidMount() {
this.props.getIntyg();
this.props.getUtvardering();
  }
    render(){
      return (
        <div>
          <Grid fluid>
              <Row>
                  <Col sm={6}>
                      <Card title="Välj Utbildningsintyg" content={
  <LoadingIcon text="Laddar utbildningsintyg.." isLoading={this.props.isLoadingintyg} loadingFailed={this.props.loadingintygFailed} success={
                                <ShowIntyg intyg={this.props.intyg}/>
                                } />
                    } />
                  </Col>


                  <Col sm={6}>
                      <Card title="Lämna utvärdering" content={
  <LoadingIcon text="Laddar utvärdering.." isLoading={this.props.isLoadingutvardering} loadingFailed={this.props.loadingutvarderingFailed} success={
                                <Utvardering utvardering={this.props.utvardering}/>
                                } />
                    } />
                  </Col>
              </Row>
          </Grid>
        </div>);
    }
}
function mapStateToProps(state) {
    return {
        intyg: state.intyg.intyg,
        isLoadingintyg: state.intyg.isLoadingintyg,
        loadingintygFailed: state.intyg.loadingintygFailed,

        utvardering: state.utvardering.utvardering,
        isLoadingutvardering: state.utvardering.isLoadingutvardering,
        loadingutvarderingFailed: state.utvardering.loadingutvarderingFailed,


    };
  }
export default connect(mapStateToProps, { getIntyg, getUtvardering })(Intyg, Utvardering);
