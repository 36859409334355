import React from 'react';

class Trafikledare extends React.Component {

    render() {
        return(<div>
            <p>Kontrollera att räddningstjänst är larmad</p>
            <p>Ta fram ev ersättnings förare/fordon</p>
            <p>Stäm av med myndighetsperson på plats om fordon får flyttas. Säkerställ att polis gett klartecken till att fordon får tas i trafik.</p>
            <ul>
                <li>Fordon ska vara tillgängligt för teknisk kontroll?</li>
                <li>Fordon får fraktas till verkstad</li>
            </ul>
            <p>Be föraren åter kontrollera om någon är skadad. (Undvik bedömningsfrågor, endast Ja eller Nej frågor)</p>
            <ul><li>Är föraren skadad, chockad kan bedömning bli osäker</li></ul>
            <p>Ge stöd åt föraren om vilken information den ska delge passagerare, ex att ambulans är på väg. Sitt lugnt kvar så kommer ny buss.</p>
            <ul><li>Passagerare som vill något annat kontakta bolaget senare </li></ul>
            <p>Skicka ut personal till platsen för att ta hand om förare och representera bolaget.</p>
            <h4>Företagets utsände</h4>
            <p>Denne person fungerar som trafikledningens förlängda arm ute på plats för händelsen. Så snart krisgruppen är aktiverad rapporterar denne direkt till krisgruppen. Den utsände sköter allt av praktisk och lokal karaktär i samråd med berörda myndigheter på plats. Krisgruppen tar alla större beslut samt hanterar media.</p>
            <h4>Uppgifter för utsänd</h4>
            <ul>
                <li>Bistå polis, räddningsledare och andra inblandade.</li>
                <li>Ombesörja ev hemtransport eller transport till samlingsplats för icke skadade, efter avstämning med polis, läkare el dyl.</li>
                <li>Säkra tillsammans med räddningsledning att inblandade i olycka som sänds hem inte blir lämnade ensam.</li>
                <li>Se till att bagage och tillhörigheter blir omhändertagna efter att ha stämt av med polis och förvara bagaget på stöldsäker plats.</li>
                <li>Stäm av med polis ang teknisk utrustning.</li>
                <li>Ta fram namn på passagerarna, stäm av med polis.</li>
            </ul>
            </div>)
    } 
}

export default Trafikledare;
