/*
  Valideringsfel från client side visas direkt på kontrollen, hanteras av formik + yup
  Valideringsfel från server visas i en react-bootstrap Alert, via formiks status-objekt.

*/

import React, { Component } from "react";
import {
  Panel,
  Button,
  Row,
  Col,
  Alert,
  ListGroup
} from "react-bootstrap";
import '../../assets/css/forms.css';
import { Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { infraConfig } from "config/infraConfig";
import { getAxiosConfig } from "system/axiosConfig";
import { ListItem } from "react-bootstrap/lib/Media";
import FormElement from "./FormElement";

let xsNumCols = 12;
let smNumCols = 6;
let mdNumCols = smNumCols;
let lgNumCols = mdNumCols;

/*
  errors är ModelState från backend
  ex:
    {
        "firstName": [
            "Förnamn är obligatoriskt"
        ],
        "lastName": [
            "Efternamn är obligatorskt"
        ]
    }

*/
const ErrorList = ({ errors }) => {
  let flatErrors = [];

  for (var error in errors) {
    flatErrors = [...flatErrors, ...errors[error]];
  }

  return (
    <ListGroup>
      {flatErrors.map(error => {
        return <ListItem>{error}</ListItem>;
      })}
    </ListGroup>
  );
};

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("Ange ett förnamn"),
  lastName: Yup.string().required("Ange ett efternamn"),
  email: Yup.string()
    .email("Ange en epostadress med formatet xxxx@xxx.xx")
    .required("Ange en epostadress"),
  address: Yup.string().required("Ange en gatuadress"),
  zipCode: Yup.string()
    .matches(
      /^\d{5}$/,
      "Ange ett postnummer med formatet XXXXX som bara innehåller siffror"
    )
    .required("Ange ett postnummer"),
  city: Yup.string().required("Ange en postort"),
  phoneNumber: Yup.string()
    .matches(
      /^(?!0)\d+$/,
      "Ange ett svenskt telefonnummer med riktnummer och utan mellanslag"
    )
    .required("Ange ett telefonnummer"),
  relativeName: Yup.string(),
  relativePhone: Yup.string().matches(
    /^(?!0)\d+$/,
    "Ange ett svenskt telefonnummer med riktnummer och utan mellanslag"
  )
});

export default class UserPersonalDetailsForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errorSaving: false
    };
  }

  scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  formatPhoneNumberOnLoad(phoneNumber) {
    if(!phoneNumber) return '';

    let newNumber = phoneNumber.replace(/\D/g,''); //remove all non-numeric characters, '\D' matches all non-numeric
    if(newNumber.substring(0,4) === "0046") {
      newNumber = newNumber.substring(4); //strip 0046
    }
    if(newNumber.substring(0,2) === "46") {
      newNumber = newNumber.substring(2); //strip 46 (the + from +46 was stripped in step 1)
    }

    return newNumber;
  }

  handleSubmitForm = (values, setSubmitting, setStatus) => {
    const postValues = {...values, phoneNumber: "+46 " + values.phoneNumber, relativePhone: "+46 " + values.relativePhone};
    axios
      .post(
        infraConfig.apiEndpoint.concat("/api/user/profile/details"),
        postValues,
        getAxiosConfig(this.props.user)
      )
      .then(() => {
        setStatus({ error: false });
        setSubmitting(false);
        this.props.handleSuccessfulUpdate(postValues);
      })
      .catch(error => {
        let errorStatus;
        if (
          error.response &&
          error.response.data &&
          error.response.data.modelState
        ) {
          errorStatus = error.response.data.modelState;
        } else {
          errorStatus = {
            general: "Okänt fel inträffade"
          };
        }
        setStatus({ errors: errorStatus });
        setSubmitting(false);
        this.setState({ errorSaving: true });
        this.scrollToTop();
      });
  };

  render() {
    const {
      firstName,
      lastName,
      email,
      address,
      zipCode,
      city,
      relativeName,
    } = this.props.userDetails;
    let phoneNumber = this.formatPhoneNumberOnLoad(this.props.userDetails.phoneNumber);
    let relativePhone = this.formatPhoneNumberOnLoad(this.props.userDetails.relativePhone);
    

    const { readOnly } = this.props;

    return (
      <div className="form-container">
        <Formik
          initialValues={{
            firstName: firstName || "",
            lastName: lastName || "",
            email: email || "",
            address: address || "",
            zipCode: zipCode || "",
            city: city || "",
            phoneNumber: phoneNumber || "",
            relativeName: relativeName || "",
            relativePhone: relativePhone || ""
          }}
          validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={(values, { setSubmitting, resetForm, setStatus }) => {
            this.handleSubmitForm(values, setSubmitting, setStatus);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            isValid,
            status
          }) => (
            
            <form onSubmit={handleSubmit}>
              {status && status.errors && (
                <Alert bsStyle="danger">
                  <h4>Fel inträffade när formuläret skulle sparas</h4>
                  <p>
                    <ErrorList errors={status.errors} />
                  </p>
                </Alert>
              )}
              <Panel>
                <Panel.Heading>
                  <Panel.Title componentClass="h3">Namn</Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                  <Row>
                    <Col
                      xs={xsNumCols}
                      sm={smNumCols}
                      md={mdNumCols}
                      lg={lgNumCols}
                    >
                      <FormElement
                        name="firstName"
                        label="Förnamn"
                        placeholder="Förnamn"
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        readOnly={readOnly}
                      />
                    </Col>
                    <Col
                      xs={xsNumCols}
                      sm={smNumCols}
                      md={mdNumCols}
                      lg={lgNumCols}
                    >
                      <FormElement
                        name="lastName"
                        label="Efternamn"
                        placeholder="Efternamn"
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        readOnly={readOnly}
                      />
                    </Col>
                  </Row>
                </Panel.Body>
              </Panel>

              <Panel>
                <Panel.Heading>
                  <Panel.Title componentClass="h3">Adress</Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                  <FormElement
                    name="address"
                    label="Adress"
                    placeholder="Postadress"
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    readOnly={readOnly}
                  />
                  <Row>
                    <Col xs={xsNumCols} sm={3} md={2} lg={2}>
                      <FormElement
                        name="zipCode"
                        label="Postnummer"
                        placeholder="Postnummer"
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        readOnly={readOnly}
                      />
                    </Col>
                    <Col xs={xsNumCols} sm={9} md={10} lg={10}>
                      <FormElement
                        name="city"
                        label="Postort"
                        placeholder="Postort"
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        readOnly={readOnly}
                      />
                    </Col>
                  </Row>
                </Panel.Body>
              </Panel>

              <Panel>
                <Panel.Heading>
                  <Panel.Title componentClass="h3">Kontakt</Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                  <FormElement
                    name="phoneNumber"
                    label="Telefonnummer"
                    placeholder="701112233"
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    readOnly={readOnly}
                    addonBefore="+46"
                  />
                  <FormElement
                    name="email"
                    label="Epostadress"
                    placeholder="epost@epost.se"
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    readOnly={readOnly}
                  />
                </Panel.Body>
              </Panel>
              <Panel>
                <Panel.Heading>
                  <Panel.Title componentClass="h3">
                    Uppgifter om närmast anhörig
                  </Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                  <FormElement
                    name="relativeName"
                    label="Namn"
                    placeholder="Förnamn Efternamn"
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    readOnly={readOnly}
                  />                  
                  <FormElement
                    name="relativePhone"
                    label="Telefonnummer"
                    placeholder="701112233"
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    readOnly={readOnly}
                    addonBefore="+46"
                  />
                </Panel.Body>
              </Panel>
              {!readOnly && (
                <Panel>
                  <Button
                    className="btn-fill"
                    disabled={isSubmitting}
                    bsStyle="danger"
                    onClick={() => this.props.abortEdit()}
                  >
                    Avbryt
                  </Button>{" "}
                  <Button
                    className="btn-fill"
                    disabled={isSubmitting && isValid}
                    bsStyle="success"
                    type="submit"
                  >
                    Spara
                  </Button>
                  <p/>
                </Panel>
              )}
            </form>
          )}
        </Formik>
      </div>
    );
  }
}
