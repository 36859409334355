import React, { Component } from "react";
import axios from "axios";
import { infraConfig } from "config/infraConfig";
import { getAxiosConfig } from "system/axiosConfig";
import { Button, Alert } from "react-bootstrap";
import UserPersonalDetailsForm from "./UserPersonalDetailsForm";
import LoadingOverlay from "react-loading-overlay";

export default class UserPersonalDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userDetails: {
        firstName: "",
        lastName: "",
        email: "",
        address: "",
        zipCode: "",
        city: "",
        phoneNumber: "",
        relativeName: "",
        relativeRelation: "",
        relativePhone: ""
      },
      userDetailsLoaded: false,
      userDetailsLoadError: "",
      editIsActive: false,
      successfulUpdate: false
    };
  }

  loadUserDetails = () => {
    const user = this.props.user;
    axios
      .get(
        infraConfig.apiEndpoint.concat("/api/user/profile/details"),
        getAxiosConfig(user)
      )
      .then(dataresult => {
        let userDetails = {
          firstName: dataresult.data.result.firstName,
          lastName: dataresult.data.result.lastName,
          email: dataresult.data.result.email,
          address: dataresult.data.result.address,
          zipCode: dataresult.data.result.zipCode,
          city: dataresult.data.result.city,
          phoneNumber: dataresult.data.result.phoneNumber,
          relativeName: dataresult.data.result.relativeName,
          relativeRelation: dataresult.data.result.relativeRelation,
          relativePhone: dataresult.data.result.relativePhone
        };
        this.setState({
          userDetails: userDetails,
          userDetailsLoaded: true
        });
      })
      .catch(error => {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.errorMessage
        ) {
          this.setState({
            userDetailsLoadError: error.response.data.errorMessage
          });
        } else {
          this.setState({
            userDetailsLoadError:
              "Ett oväntat fel uppstod vid laddning av användaruppgifter."
          });
          window.scrollTo({
            top: 0,
            behaviour: 'smooth'
          });
        }
      });
  };

  componentDidMount() {
    this.loadUserDetails();
  }

  render() {
    return (
      <React.Fragment>
        {/* Error loading user details from server */}
        {this.state.userDetailsLoadError && (
          <Alert bsStyle="danger">
            <h4>Ett fel uppstod</h4>
            <p>{this.state.userDetailsLoadError}</p>
            <p>
              <Button
                fill="true"
                onClick={() => {
                  this.setState({
                    userDetailsLoadError: "",
                    userDetailsLoaded: false
                  });
                  this.loadUserDetails();
                }}
              >
                Försök igen
              </Button>
            </p>
          </Alert>
        )}

        {/* Loading and showing user details */}
        {!this.state.userDetailsLoadError && (
          <LoadingOverlay
            active={!this.state.userDetailsLoaded}
            spinner
            text="Laddar användaruppgifter"
          >
            <React.Fragment>
              {this.state.successfulUpdate && (
                <Alert bsStyle="success">
                  <h5>Dina uppgifter är uppdaterade</h5>
                </Alert>
              )}
              <UserPersonalDetailsForm
                user={this.props.user}
                userDetails={this.state.userDetails}
                abortEdit={() => this.setState({ editIsActive: false })}
                handleSuccessfulUpdate={newUserDetails => {
                  this.setState({
                    userDetails: newUserDetails,
                    editIsActive: false,
                    successfulUpdate: true
                  });
                }}
                readOnly={!this.state.editIsActive}
              />

              {!this.state.editIsActive && (
                <Button
                  className="btn-fill"
                  disabled={!this.state.userDetailsLoaded}
                  bsStyle="primary"
                  onClick={() =>
                    this.setState({
                      editIsActive: true,
                      successfulUpdate: false
                    })
                  }
                >
                  Ändra mina uppgifter
                </Button>
              )}
            </React.Fragment>
          </LoadingOverlay>
        )}
      </React.Fragment>
    );
  }
}
