
// VH eftersom vi för tillfället bara har en devmiljö kan man här då hårdkoda appconfig och låta infraConfig vara
// eg skulle man haft en devmiljö för varje kund
import { infraConfig } from './infraConfig';
import logo from "assets/customer/bn/logo-small.png";
import sideBarBg from "assets/customer/bn/sidebar-bg.jpg";

import dashboardRoutes from '../local/dashboard'; 
import HeaderLinks from "./HeaderLinks"; // notera att dena pekar på "rätta" komponenten

export const appConfig = {
    name:'dev',
    instrumentationKey: '0fae6044-fbe4-4a7b-b243-e87615e701c9', // minsidadev instrumentationKey
    title: "Min Sida (dev)", 
    infra: infraConfig,
    dashboard: dashboardRoutes,
    logoSmall: logo,
    sideBarBg: sideBarBg,
    headerLinks: HeaderLinks,
    outputToConsole: true,
    utbildningUrl: 'https://cloudplanningutbdemo.azurewebsites.net/'

}