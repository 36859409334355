
import React, { Component} from "react";
import Button from "components/CustomButtons/CustomButton";

class SavingButton extends Component {
constructor(props){
    super(props);
    this.handleClick = this.handleClick.bind(this);
}
    // status
    // 0 = oklickad
    // 1 = in progress
    // 2 = success
    // 3 = fel


handleClick() {
    if (this.props.status !== 1)
        this.props.onClick();
}
  render() {
return (<div>
    <Button fill bsStyle="primary" onClick={this.handleClick}> 
        { this.props.status === 0 ?
        <span>{this.props.text}</span> 
        : this.props.status === 1 ?
        <span><span className="fa fa-spinner fa-spin" /> {this.props.inProgressText }</span>
        : this.props.status === 2 ?
        <span><span className="fa fa-check" /> {this.props.completeMessage}</span>
        : this.props.status === 3 ?
        <span><span className="fa fa-exclamation-triangle text-danger" /> {this.props.errorMessage}</span> 
        : null 
        }
    </Button>
    </div>)
  }
}

export default SavingButton;
