
import React, { Component} from "react";
import {Grid, Row, Col} from "react-bootstrap";
import Date from "components/formatting/Date";
import 'assets/css/detailcard.css';
import Button from "components/CustomButtons/CustomButton.jsx"
class IncidentCard extends Component {

    constructor(props){
        super(props);

        this.state = {
            showMore: false
        }
    }
    componentDidMount() {
    }
    render() {
        return (
        <div className="card"><div className="content">
        <Grid fluid>
            <Row>
                <Col xs={6}>
                    <div className="detailcard"><div className="detailcard-label">Skapad</div>
                        <Date value={this.props.item.skapadDatum} />
                    </div>
                    
                </Col>
                
                <Col xs={6}>
                    <div className="detailcard pull-right"><div className="detailcard-label">Status</div>
                        {this.props.item.status}
                    </div>
                    
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <div className="detailcard"><div className="detailcard-label">Rubrik</div>
                        {this.props.item.rubrik}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <div className="detailcard"><div className="detailcard-label">Bolag</div>
                        {this.props.item.bolag}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <div className="detailcard"><div className="detailcard-label">Avvikelsedatum</div>
                    <Date value={this.props.item.incidentDatum} />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <div className="detailcard"><div className="detailcard-label">Skapad Av</div>
                        {this.props.item.skapadAv}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <div className="detailcard"><div className="detailcard-label">Ansvarig</div>
                        {this.props.item.incidentAnsvarig}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <div className="detailcard"><div className="detailcard-label">Åtgärd</div>
                        {this.props.item.atgard}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <div className="detailcard"><div className="detailcard-label">Plats</div>
                        {this.props.item.plats}
                    </div>
                </Col>
            </Row>
            {this.state.showMore ? this.renderDetaljer(this.props.item) : <Row><Col xs={12}><Button block fill bsStyle="primary" onClick={() => { 
                this.setState({
                    showMore: true
                });
            }}>Visa detaljer</Button></Col></Row>} 
        </Grid></div></div>)
    }
    renderDetaljer(item) {
        switch (item.typ) {
            case "Försäkringsärende":
                return <Row>
                    <Col xs={12}>
                        <div className="detailcard"><div className="detailcard-label">Registreringsnummer</div>
                            {item.forsakring.registreringsNummer}
                        </div>
                    </Col>
                    <Col xs={12}>
                        <div className="detailcard"><div className="detailcard-label">Internummer</div>
                            {item.forsakring.internnummer}
                        </div>
                    </Col>
                    <Col xs={12}>
                        <div className="detailcard"><div className="detailcard-label">Skadeplatskategori</div>
                        {item.forsakring.skadeplatskategori.map((row,key) => {
                            return <span key={key}>{row}<br /></span>
                        })}
                        </div>
                    </Col>
                    <Col xs={12}>
                        <div className="detailcard"><div className="detailcard-label">Polis inkopplad</div>
                            {item.forsakring.polis ? "Ja" : "Nej"}
                        </div>
                    </Col>
                    <Col xs={12}>
                        <div className="detailcard"><div className="detailcard-label">Räddningstjänst inkopplad</div>
                            {item.forsakring.raddningstjanst ? "Ja" : "Nej"}
                        </div>
                    </Col>
                    <Col xs={12}>
                        <div className="detailcard"><div className="detailcard-label">Skador på fordon</div>
                            {item.forsakring.skadaPaFordon ? "Ja" : "Nej"}
                        </div>
                    </Col>
                    <Col xs={12}>
                        <div className="detailcard"><div className="detailcard-label">Försäkringskategori</div>
                            {item.forsakring.forsakringsKategori}
                        </div>
                    </Col>
                    <Col xs={12}>
                        <div className="detailcard"><div className="detailcard-label">Kategorispecificering</div>
                            {item.forsakring.kategoriSpecificering}
                        </div>
                    </Col>
                    <Col xs={12}>
                        <div className="detailcard"><div className="detailcard-label">Anlitades ersattningsfordon</div>
                            {item.forsakring.ersattningsfordon ? "Ja" : "Nej"}
                        </div>
                    </Col>
                    <Col xs={12}>
                        <div className="detailcard"><div className="detailcard-label">Beskrivning synliga skador</div>
                            {item.forsakring.synligaSkador}
                        </div>
                    </Col>
                    <Col xs={12}>
                        <div className="detailcard"><div className="detailcard-label">Personskada, kontaktuppgifter</div>
                            {item.forsakring.personskadekontaktuppgifter}
                        </div>
                    </Col>
                    <Col xs={12}>
                        <div className="detailcard"><div className="detailcard-label">Personskada, kontaktuppgifter motpart</div>
                            {item.forsakring.motpartskontaktuppgifter}
                        </div>
                    </Col>
                </Row>;
            case "Hållplatsinformation":
                return <Row>
                    <Col xs={12}>
                        <div className="detailcard"><div className="detailcard-label">Kategori</div>
                            {item.hallplatsInfo.hallplatskategori}
                        </div>
                    </Col>
                    <Col xs={12}>
                        <div className="detailcard"><div className="detailcard-label">Händelsebeskrivning</div>
                            {item.hallplatsInfo.handelseBeskrivning}
                        </div>
                    </Col>
                </Row>;
            case "Arbetsmiljöfrågor":
                return <Row>
                    <Col xs={12}>
                        <div className="detailcard"><div className="detailcard-label">Personskador</div>
                            {item.arbetsMiljo.personskador ? "Ja" : "Nej"}
                        </div>
                    </Col>
                    <Col xs={12}>
                        <div className="detailcard"><div className="detailcard-label">Arbetsmoment</div>
                            {item.arbetsMiljo.arbetsmoment.map((row,key) => {
                        return <span key={key}>{row}<br /></span>
                    })}
                        </div>
                    </Col>
                    <Col xs={12}>
                        <div className="detailcard"><div className="detailcard-label">Vad hände</div>
                            {item.arbetsMiljo.vadHande.map((row,key) => {
                        return <span key={key}>{row}<br /></span>
                    })}
                        </div>
                    </Col>
                    <Col xs={12}>
                        <div className="detailcard"><div className="detailcard-label">Var något tillfälligt annorlunda på platsen</div>
                            {item.arbetsMiljo.varNagot.map((row,key) => {
                        return <span key={key}>{row}<br /></span>
                        })}
                        </div>
                    </Col>
                    <Col xs={12}>
                        <div className="detailcard"><div className="detailcard-label">Förhållanden som var som de brukar men kan ha påverkat händelsen</div>
                            {item.arbetsMiljo.forhallanden.map((row,key) => {
                                return <span key={key}>{row}<br /></span>
                            })}
                        </div>
                    </Col>
                    <Col xs={12}>
                        <div className="detailcard"><div className="detailcard-label">Förslag till åtgärder så att liknande händelser undviks i framtiden</div>
                            {item.arbetsMiljo.forslag.map((row,key) => {
                                return <span key={key}>{row}<br /></span>
                            })}
                        </div>
                    </Col>
                </Row>;
            case "Hot och våld":
                return <Row>
                    <Col xs={12}>
                        <div className="detailcard"><div className="detailcard-label">Händelsebeskrivning</div>
                            {item.hotOchVald.handelseBeskrivning}
                        </div>
                    </Col>
                    <Col xs={12}>
                        <div className="detailcard"><div className="detailcard-label">Personskador information</div>
                            {item.hotOchVald.externaPersonskadorInfo}
                        </div>
                    </Col>                   
                </Row>
                                                
            default: 
                return null;
        }
    }
}


export default IncidentCard;