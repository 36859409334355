import React, { Component } from "react";

class ColViewSwitch extends Component {

    render() {
        return (
        <div>
          <div className="hidden-xs hidden-sm hidden-md">{this.props.lg}</div> 
          <div className="hidden-xs hidden-sm hidden-lg">{this.props.md}</div>
          <div className="hidden-xs hidden-md hidden-lg">{this.props.sm}</div>
          <div className="hidden-sm hidden-md hidden-lg">{this.props.xs}</div>
        </div>
        );
    }
}

export default ColViewSwitch;