import Start from "dashboard/Start/Start";
import BplMinaUppdragPage from "dashboard/BplMinaUppdrag/BplMinaUppdragPage.jsx";
import Fordonskontroll from "dashboard/Fordonskontroll/Fordonskontroll.jsx";
import Incidentrapporter from "dashboard/Incidenter/Incidentrapporter.jsx";
import IncidenterFormH from "dashboard/Incidenter/FormH.jsx";
import KontekOnlineLonebesked from "dashboard/KontekOnlineLonebesked/KontekOnlineLonebesked.jsx";
import UserInfo from "dashboard/Main/UserInfo";
import NyheterRegionList from "dashboard/Nyheter/RegionNewsListView.jsx";
import NyheterKoncernList from "dashboard/Nyheter/KoncernNewsListView.jsx";
import RegionNyhet from "dashboard/Nyheter/RegionNewsShowFull.jsx";
import KoncernNyhet from "dashboard/Nyheter/KoncernNewsShowFull.jsx";
import Utbildning from "dashboard/Utbildning/Utbildning.jsx";
import Forslagslada from "dashboard/Forslagslada/Forslagslada";
import Krishantering from "dashboard/Krishantering/Krishantering.jsx";
import EcoDriving from "dashboard/EcoDriving/EcoDriving.jsx"
import BplSchema from "dashboard/BplMinaUppdrag/Schema.jsx";
import ForsakringUppfoljning from "dashboard/Incidenter/ForsakringUppfoljning/ForsakringUppfoljning";
import DashboardHelp from "dashboard/Main/DashboardHelp";

var dashboardRoutes = [
  {
    path: "/dashboard/start",
    name: "Start",
    icon: "pe-7s-news-paper",
    component: Start
  },
  {
    path: "/dashboard/nyheter",
    name: "Nyheter",
    icon: "pe-7s-speaker",
    collapse: true,
    requireClaim: "NewsEnabled",
    childRoutes: [
      {
        path: "/dashboard/nyheter/region",
        component: NyheterRegionList,
        mini: "R",
        name: "Region",
      },
      {
        path: "/dashboard/nyheter/koncern",
        component: NyheterKoncernList,
        mini: "K",
        name: "Koncern",
      },
      {
        path: "/dashboard/nyheter/koncern/:newsId",
        name: "Nyhet",
        icon: "pe-7s-speaker",
        component: KoncernNyhet,
        mini: "N",
        hideFromMenu: true
      },
      {
        path: "/dashboard/nyheter/region/:newsId",
        name: "Nyhet",
        icon: "pe-7s-speaker",
        component: RegionNyhet,
        mini: "N",
        hideFromMenu: true
      }
    ]
  },  
  {
    path: "/dashboard/minauppdrag",
    name: "Mina Uppdrag",
    icon: "pe-7s-bottom-arrow",
    requireClaim: "MinaUppdragEnabled",
    component: BplMinaUppdragPage
  },
  {
    path: "/dashboard/schema",
    name: "Schema",
    icon: "pe-7s-note2",
    requireClaim: "SchemaEnabled",
    component: BplSchema
  },
  {
    path: "/dashboard/fordonskontroll",
    name: "Felrapportering",
    icon: "pe-7s-car",
    requireClaim: "FkEnabled",
    component: Fordonskontroll
  },
  {
    path: "/dashboard/avvikelser",
    name: "Avvikelser",
    icon: "pe-7s-light",
    requireClaim: "IncidentsEnabled",
    component: Incidentrapporter
  },
  {
    path: "/dashboard/nyavvikelse",
    name: "Ny avvikelse",
    icon: "pe-7s-light",
    requireClaim: "IncidentsEnabled",
    component: IncidenterFormH,
    hideFromMenu:true
  },
  {
    path: "/dashboard/konteklonebesked",
    name: "Lönespecar Kontek",
    icon: "pe-7s-credit",
    component: KontekOnlineLonebesked,
    requireClaimValue: ["LonSystem", "Kontek"]
  },
  {
    path: "/dashboard/krishantering",
    name: "Krishantering",
    icon: "pe-7s-help2",
    component: Krishantering
  },
  {
    path: "/dashboard/forslag",
    name: "Förslagslåda",
    icon: "pe-7s-comment",
    requireClaim: "ForslagEnabled",
    component: Forslagslada
  },
  {
    path: "/dashboard/userinfo",
    name: "Användarinformation",
    icon: "pe-7s-user",
    component: UserInfo,
    hideFromMenu: true
  },  
  {
    path: "/dashboard/ecodriving",
    name: "Sparsam körning",
    icon: "pe-7s-leaf",
    component: EcoDriving,
    hideFromMenu: false
  },
  {
    //pe-7s-notebook
    path: "/dashboard/utbildning",
    name: "Utbildningsintyg",
    icon: "pe-7s-study",
    component: Utbildning,
    hideFromMenu: false
  },
  {
    path: "/dashboard/help",
    name: "Hjälp",
    icon: "pe-7s-help1",
    component: DashboardHelp,
  },

];
export default dashboardRoutes;
