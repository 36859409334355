import axios from "axios";
import  { infraConfig } from "config/infraConfig";
import { getAxiosConfig } from "system/axiosConfig";
import store from "system/store";
import { ERRORMESSAGES_ADD } from "system/actions";


export const BPLGODKANNATID_GET_TIDRAPPORT ="BPLGODKANNATID_GET_TIDRAPPORT"
export const BPLGODKANNATID_GET_TIDRAPPORT_SUCCESS = "BPLGODKANNATID_GET_TIDRAPPORT_SUCCESS";
export const BPLGODKANNATID_GET_TIDRAPPORT_FAILED = "BPLGODKANNATID_GET_TIDRAPPORT_FAILED";

export const BPLGODKANNATID_SEND_TIDRAPPORT = "BPLGODKANNATID_SEND_TIDRAPPORT";
export const BPLGODKANNATID_SEND_TIDRAPPORT_SUCCESS = "BPLGODKANNATID_SEND_TIDRAPPORT_SUCCESS";
export const BPLGODKANNATID_SEND_TIDRAPPORT_FAILED = "BPLGODKANNATID_SEND_TIDRAPPORT_FAILED";



export function fetchGodkandTidrapport() {
    
    return (dispatch) => {
        dispatch({type: BPLGODKANNATID_GET_TIDRAPPORT});

        const user = store.getState().oidc.user;
        axios.get(infraConfig.apiEndpoint.concat('/api/bpl/tidrapport/getsenaste'), getAxiosConfig(user))
        .then((dataResult) => dispatch({type: BPLGODKANNATID_GET_TIDRAPPORT_SUCCESS, payload: dataResult}))
        .catch((error) => { dispatch({type: BPLGODKANNATID_GET_TIDRAPPORT_FAILED}); dispatch({type: ERRORMESSAGES_ADD, payload: { type: 'network', message: 'Hämta senaste tidrapporten misslyckades', severity: 'warning'}});});        

    }
    
};


export function sendLamnaTidrapport(tidrapportId, undo) {

    return (dispatch) => {

        dispatch({type: BPLGODKANNATID_SEND_TIDRAPPORT});

        const user = store.getState().oidc.user;

        axios.post(infraConfig.apiEndpoint.concat('/api/bpl/tidrapport/accept'), { TidrapportId: tidrapportId, Undo: undo }, getAxiosConfig(user))
        .then((dataResult) => {
            dispatch({type: BPLGODKANNATID_SEND_TIDRAPPORT_SUCCESS, payload: dataResult}); 

            console.log('after send');
            axios.get(infraConfig.apiEndpoint.concat('/api/bpl/tidrapport/getsenaste'), getAxiosConfig(user))
            .then((dataResult) => dispatch({type: BPLGODKANNATID_GET_TIDRAPPORT_SUCCESS, payload: dataResult}))
            .catch((error) => { dispatch({type: BPLGODKANNATID_GET_TIDRAPPORT_FAILED}); dispatch({type: ERRORMESSAGES_ADD, payload: { type: 'network', message: 'Hämta senaste tidrapporten misslyckades efter inlämnande', severity: 'warning'}});});        
            
        })
        .catch((error) => { dispatch({type: BPLGODKANNATID_SEND_TIDRAPPORT_FAILED}); dispatch({type: ERRORMESSAGES_ADD, payload: { type: 'network', message: 'Acceptering av senaste tidrapport misslyckades', severity: 'warning'}});});        
    
    }

};