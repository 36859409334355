import React from 'react';

export default function Number(props){

    const decimals = props.decimals ? props.decimals : 0;

    const value = props.value 
    ? parseFloat(props.value.toFixed(decimals)) 
    : props.nullChar ? props.nullChar : null;

    return <span>{value}</span>
}