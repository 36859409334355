import React, { Component } from "react";
import Card from '../Card/Card';
import { Row, Col } from 'react-bootstrap';

class LoadingIcon extends Component {

    render() {

        return (
            this.props.fullScreen ? <Row><Col xs={12}>
            { this.props.loadingFailed ? <Card ctTextCenter textCenter content={<div><span className="fa fa-exclamation-triangle text-danger fa-4x" /><br /><br /><span>Ett fel inträffade</span></div>} />
            : this.props.isLoading ? <Card ctTextCenter textCenter content={<div><span className="fa fa-spinner fa-spin fa-4x loading-icon" /><br /><br /> {this.props.text ? this.props.text : null}</div>} />
            : this.props.success}
            </Col></Row> : 
            <span>            
            { this.props.loadingFailed ? <span><span className="fa fa-exclamation-triangle text-danger" /> Ett fel inträffade</span> 
            : this.props.isLoading ? <span><span className="fa fa-spinner fa-spin" /> {this.props.text ? this.props.text : null}</span>
            : this.props.success}
            </span>
        );
    }
}

export default LoadingIcon;