import React from "react";
import { connect } from "react-redux";
import Card from "components/Card/Card.jsx";
import CustomButton from "components/CustomButtons/CustomButton.jsx";
import {appConfig} from "config/appConfig";
import * as serviceWorker from 'serviceWorker';

class Reset extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      showResetButton: true
    }
  }

  onResetClick = () => {
        
    window.localStorage.clear(); 
    serviceWorker.unregister();
    this.setState({
      showResetButton: false
    })
    console.log('rensat');
  }

  render() {

    return (     
      <Card title="Återställning av MinSida-appen" content={
        <div>
          <p>Återställ appen genom att klicka på knappen. Då rensas appens cache och appens servicejobb avinstalleras.</p>
          { this.state.showResetButton ? <CustomButton bsStyle="success" fill onClick={this.onResetClick}>Återställ</CustomButton> : <p>Appen är nu återställd. Klicka <a href="/">här</a> för att använda {appConfig.title} på nytt</p> }
        </div> 
      } />) 
  }
}

export default connect()(Reset);