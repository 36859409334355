import React from 'react';
import { Col, Row } from "react-bootstrap";
import Card from 'components/Card/Card.jsx';
import { getLonespec } from "./actions";
import { connect } from "react-redux";
import 'assets/css/lonebesked.css';

import LonebeskedList from './LonebeskedList';

import LoadingIcon from "components/utils/LoadingIcon";
class KontekOnlineLonebesked extends React.Component {
  componentDidMount() {
      this.props.getLonespec();
  }
    render() {
      return (
        <div className="container-fluid">
            <Row>              
                  <Col sm={6}>
                    <Card title="Välj Lönespecifikation" content={
                              <LoadingIcon text="Laddar lönebesked.." isLoading={this.props.isLoadinglonebesked} loadingFailed={this.props.loadinglonebeskedFailed} success={
                                this.props.lonebesked ? <LonebeskedList user={this.props.user} lonebesked={this.props.lonebesked}/> : null
                              } />
                  } />
                </Col> 
            </Row>
        </div>);
    }
}
function mapStateToProps(state) {
    return {
        user: state.oidc.user,
        lonebesked: state.kontek.lonebesked,
        isLoadinglonebesked: state.kontek.isLoadinglonebesked,
        loadinglonebeskedFailed: state.kontek.loadinglonebeskedFailed,
    };
  }
export default connect(mapStateToProps, { getLonespec })(KontekOnlineLonebesked);
