
import dashboardRoutes from '../tdprod/dashboard';
import logo from "assets/customer/td/logo-small.png";
import sideBarBg from "assets/customer/td/sidebar-bg.jpg";
import HeaderLinks from '../tdprod/HeaderLinks';

export const appConfig = {
    name:'td2prod',
    instrumentationKey: 'a2f39c9b-08d7-4f2f-9262-611173eaf133',
    lediaTjansterUrl: 'https://jobb.transdev.se/',
    title: "Min Sida", 
    dashboard: dashboardRoutes,
    logoSmall: logo,
    sideBarBg: sideBarBg,
    headerLinks: HeaderLinks
}