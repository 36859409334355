import React from 'react';
import { connect } from "react-redux";
import { fetchBossInfo } from "./actions";
import LoadingIcon from "components/utils/LoadingIcon";
import Card from "components/Card/Card";

class BossInfo extends React.Component {

    componentDidMount() {
        this.props.fetchBossInfo();
    }

    render() { 
        return (<Card
        content={
            <div><p><b>Kontaktuppgifter i händelse av kris (t.ex. trafikolycka)</b></p>
            <p>Trafikledningen är bemannad dygnet runt. Dagtid vardag av personal lokalt på depån. Övrig tid av våra ”nattugglor”.<br />
            <br />
            Trafikområde Timrå: 010-722 03 19<br />
            Trafikområde Kramfors: 010-722 03 39<br />
            Trafikområde Örnsköldsvik: 010-722 03 59<br />
            Trafikområde Härnösand: 010-722 03 69<br />
            Trafikområde Sollefteå: 010-722 03 79<br />
            Trafikområde Umeå: 010-722 03 89<br />
            Trafikområde Ljungandalen: 010-722 03 92<br />
            Taxi Ö-vik/Höga Kusten: 010-722 03 99<br />
            Taxi Kramfors: 010-722 03 98</p>
            </div>
        }
        
        />);
    }
}



function mapStateToProps(state) {
    return {
      bossInfo: state.krishantering.bossInfo,
      bossInfoTimestamp: state.krishantering.bossInfoTimestamp,
      isLoadingBossInfo: state.krishantering.isLoadingBossInfo,
      loadingBossInfoFailed: state.krishantering.loadingBossInfoFailed
    };
  }
  
  export default connect(mapStateToProps, { fetchBossInfo })(BossInfo);