
export function getAxiosConfig (user) {
    return {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization':'Bearer '.concat(user.id_token),
            'Content-Type': 'application/json'
        }
    }
};
