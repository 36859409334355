import axios from "axios";
import { infraConfig } from "config/infraConfig";
import { getAxiosConfig } from "system/axiosConfig";
import store from "system/store";
import { ERRORMESSAGES_ADD } from "system/actions";

export const UTBILDNING_GET_INTYG = "UTBILDNING_GET_INTYG";
export const UTBILDNING_GET_INTYG_SUCCESS = "UTBILDNING_GET_INTYG_SUCCESS";
export const UTBILDNING_GET_INTYG_FAILED = "UTBILDNING_GET_INTYG_FAILED";

export const UTBILDNING_POST_UTVARDERING = "UTBILDNING_POST_UTVARDERING_INTYG";
export const UTBILDNING_POST_UTVARDERING_SUCCESS =
  "UTBILDNING_POST_UTVARDERING_SUCCESS";
export const UTBILDNING_POST_UTVARDERING_FAILED =
  "UTBILDNING_POST_UTVARDERING_FAILED";

export const UTBILDNING_GET_UTVARDERING = "UTBILDNING_GET_UTVARDERING";
export const UTBILDNING_GET_UTVARDERING_SUCCESS =
  "UTBILDNING_GET_UTVARDERING_SUCCESS";
export const UTBILDNING_GET_UTVARDERING_FAILED =
  "UTBILDNING_GET_UTVARDERING_FAILED";

export const UTBILDNING_GET_DOWNLOAD = "UTBILDNING_GET_DOWNLOAD";
export const UTBILDNING_GET_DOWNLOAD_SUCCESS =
  "UTBILDNING_GET_DOWNLOAD_SUCCESS";
export const UTBILDNING_GET_DOWNLOAD_FAILED = "UTBILDNING_GET_DOWNLOAD_FAILED";

export function getIntyg() {
  return dispatch => {
    dispatch({ type: UTBILDNING_GET_INTYG });

    const user = store.getState().oidc.user;
    axios
      .get(
        infraConfig.apiEndpoint.concat("/api/intyg/get"),
        getAxiosConfig(user)
      )
      .then(dataResult =>
        dispatch({ type: UTBILDNING_GET_INTYG_SUCCESS, payload: dataResult })
      )
      .catch(error => {
        dispatch({ type: UTBILDNING_GET_INTYG_FAILED });
        dispatch({
          type: ERRORMESSAGES_ADD,
          payload: {
            type: "network",
            message: "Hämta intyg misslyckades",
            severity: "warning"
          }
        });
      });
  };
}
export function sendUtvardering() {
  return dispatch => {
    dispatch({ type: UTBILDNING_POST_UTVARDERING });

    const user = store.getState().oidc.user;
    axios
      .get(
        infraConfig.apiEndpoint.concat("/api/utvardering/send"),
        getAxiosConfig(user)
      )
      .then(dataResult =>
        dispatch({
          type: UTBILDNING_POST_UTVARDERING_SUCCESS,
          payload: dataResult
        })
      )
      .catch(error => {
        dispatch({ type: UTBILDNING_POST_UTVARDERING_FAILED });
        dispatch({
          type: ERRORMESSAGES_ADD,
          payload: {
            type: "network",
            message: "Skicka utvärdering misslyckades",
            severity: "warning"
          }
        });
      });
  };
}
export function getUtvardering() {
  return dispatch => {
    dispatch({ type: UTBILDNING_GET_UTVARDERING });

    const user = store.getState().oidc.user;
    axios
      .get(
        infraConfig.apiEndpoint.concat("/api/utvardering/get"),
        getAxiosConfig(user)
      )
      .then(dataResult => {
        dispatch({
          type: UTBILDNING_GET_UTVARDERING_SUCCESS,
          payload: dataResult
        });
        if (dataResult.data.result === false) {
          document.getElementById("content").innerHTML =
            "Du har inte gått någon utbildning dom senaste 21 dagarna.";
        }
      })
      .catch(error => {
        dispatch({ type: UTBILDNING_GET_UTVARDERING_FAILED });
        dispatch({
          type: ERRORMESSAGES_ADD,
          payload: {
            type: "network",
            message: "Hämta utvärdering misslyckades",
            severity: "warning"
          }
        });
      });
  };
}

export function downloadIntyg(filurl) {
   return (dispatch) => {
      dispatch({type: UTBILDNING_GET_DOWNLOAD});

        const user = store.getState().oidc.user;
        axios.get(infraConfig.apiEndpoint.concat('/api/spcommon/downloadfile?filurl=').concat(filurl), getAxiosConfig(user))
        .then((dataResult) => {
            dispatch({type: UTBILDNING_GET_DOWNLOAD_SUCCESS, payload: dataResult});
            var byte = base64ToArrayBuffer(dataResult.data.result);
            var name = filurl.split('/');
            var g = name.length-1;
            console.log("Filename: " + name.length)
            saveByteArray(name[g], byte);

        })
        .catch((error) => { dispatch({type: UTBILDNING_GET_DOWNLOAD_FAILED}); dispatch({type: ERRORMESSAGES_ADD, payload: { type: 'network', message: 'Hämta Lönespec misslyckades', severity: 'warning'}});});

   }
   function base64ToArrayBuffer(base64) {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }
  function saveByteArray(Name, byte) {
    var blob = new Blob([byte], {
      type:
        "application/vnd.openxmlformats-officedocument.wordprocessingml.documentapplication/vnd.openxmlformats-officedocument.wordprocessingml.document"
    });
    var link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    var fileName = Name;
    link.download = fileName;
    link.dispatchEvent(
      new MouseEvent("click", { bubbles: true, cancelable: true, view: window })
    );
    //link.click();
  }
}
