import axios from "axios";
import { infraConfig } from "config/infraConfig";
import { getAxiosConfig } from "system/axiosConfig";
import store from "system/store";
import { ERRORMESSAGES_ADD } from "system/actions";

export const MEDARBETARE_POST_UNDERSOKNING = "MEDARBETARE_POST_UNDERSOKNING";
export const MEDARBETARE_POST_UNDERSOKNING_SUCCESS =
  "MEDARBETARE_POST_UNDERSOKNING_SUCCESS";
export const MEDARBETARE_POST_UNDERSOKNING_FAILED =
  "MEDARBETARE_POST_UNDERSOKNING_FAILED";

export const MEDARBETARE_GET_UNDERSOKNING = "MEDARBETARE_GET_UNDERSOKNING";
export const MEDARBETARE_GET_UNDERSOKNING_SUCCESS =
  "MEDARBETARE_GET_UNDERSOKNING_SUCCESS";
export const MEDARBETARE_GET_UNDERSOKNING_FAILED =
  "MEDARBETARE_GET_UNDERSOKNING_FAILED";

export function sendUndersokning() {
  return dispatch => {
    dispatch({ type: MEDARBETARE_POST_UNDERSOKNING });

    const user = store.getState().oidc.user;
    axios
      .get(
        infraConfig.apiEndpoint.concat("/api/undersokning/send"),
        getAxiosConfig(user)
      )
      .then(dataResult => {
        dispatch({
          type: MEDARBETARE_POST_UNDERSOKNING_SUCCESS,
          payload: dataResult
        });
        console.log("RESULTAT ");
        console.log(dataResult.data.result[0]);
      })
      .catch(error => {
        dispatch({ type: MEDARBETARE_POST_UNDERSOKNING_FAILED });
        dispatch({
          type: ERRORMESSAGES_ADD,
          payload: {
            type: "network",
            message: "Skicka undersökning misslyckades",
            severity: "warning"
          }
        });
      });
  };
}
export function getUndersokning() {
  return dispatch => {
    dispatch({ type: MEDARBETARE_GET_UNDERSOKNING });

    const user = store.getState().oidc.user;
    axios
      .get(
        infraConfig.apiEndpoint.concat("/api/undersokning/get"),
        getAxiosConfig(user)
      )
      .then(dataResult => {
        dispatch({
          type: MEDARBETARE_GET_UNDERSOKNING_SUCCESS,
          payload: dataResult
        });
        console.log("CHECK UNDERSÖKNING");
        if (dataResult.data.result[0].datum === true) {
          console.log("Undersökning aktiverad")
          var style = document.createElement('style');
          style.type = 'text/css';
          style.innerHTML = '#undersok{display:block !important}';
          document.getElementsByTagName('head')[0].appendChild(style);
        }

        if (dataResult.data.result[0].contenttype === "forare") {
        var forare = document.querySelector("#forare");
        forare.style.display = "block";
        }
        else{
          var Tjansteman = document.querySelector("#tjansteman");
          Tjansteman.style.display = "block";
        }
      })
      .catch(error => {
        dispatch({ type: MEDARBETARE_GET_UNDERSOKNING_FAILED });
        dispatch({
          type: ERRORMESSAGES_ADD,
          payload: {
            type: "network",
            message: "Hämta undersökning misslyckades",
            severity: "warning"
          }
        });
      });
  };
}
