import React, { Component} from "react";
import {Table} from 'react-bootstrap';
import Time from 'components/formatting/Time';
import DateC from 'components/formatting/Date';

class UppdragTable extends Component{

   
    renderList() {
          return (
                <Table striped hover responsive>
                    <thead>
                        <tr>
                            <th>Datum</th>
                            <th>Start</th>
                            <th>Slut</th>
                            <th>Beskrivning</th>
                            <th>Tjänst</th>
                            <th>Fordon</th>
                            <th>Startplats</th>
                            <th>Slutplats</th>
                            <th>Notering</th>
                        </tr>
                    </thead>
                    <tbody>                    
                        { this.props.uppdrag.length > 0 ? 
                            this.props.uppdrag.map((row, key) => {
                                return (row.uppdrag.map((subrow, key) => {
                                    return (
                                        (!this.props.showFramAter && subrow.ejFramAterkorning) || this.props.showFramAter ? 
                                        <tr key={subrow.id}>
                                            <td>
                                                <DateC value={subrow.starttid} />
                                            </td>
                                            <td>
                                                <Time value={subrow.starttid} />
                                            </td>
                                            <td>
                                                <Time value={subrow.sluttid} />
                                            </td>
                                            <td>
                                                {subrow.beskrivning}
                                            </td>
                                            <td>
                                                {subrow.tjanst}
                                            </td>
                                            <td>
                                                {subrow.fordonStr}
                                            </td>
                                            <td>
                                                {subrow.startplats}
                                            </td>
                                            <td>
                                                {subrow.slutplats}
                                            </td>
                                            <td>
                                                {subrow.anmarkning}
                                            </td>
                                        </tr>
                                        : null
                                    )
                                })
                            )
                             }) : null
                             
                        }

                </tbody>
              </Table>
              
            );
      }

    render() {
       
        return (this.renderList());
    }
}

  export default UppdragTable;