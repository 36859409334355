import React from 'react';

class Trafikledare extends React.Component {

    render() {
        return(<div>
            <p>1.	Kontrollera om larm har gått till räddningstjänsten</p>
            <p>2.	Oavsett vad som har hänt är det viktigt att trafikledningen i första hand utgår från att föraren inte bör köra vidare. Även om t ex skolan i gärna vill att vi ska köra eleverna fram till skolan där deras krisgrupp tar vid så måste vi vara 100 % säkra på att den förare som ska fullfölja körningen är kapabel till detta och att det utifrån situation är lämpligt </p>
            <p>3.	Vid trafikolycka/incident stäm av med myndighetsperson på plats om fordonet får flyttas, samt om det behöver vara tillgängligt på depå eller verkstad för ex teknisk undersökning i efterhand. Säkerställ alltid att polis gett klartecken till att fordonet får tas i trafik innan så görs </p>
            <p>4.	Be föraren att återigen kontrollera med resenärerna om det är någon som känner sig skadad på något sätt? Undvik bedömningsfrågor utan ställ i möjligaste mån frågor som besvaras med ”ja” eller ”nej”. Föraren kan vara skärrad eller chockad och därför göra oriktiga bedömningar</p>
            <p>5.	Vilken information ska föraren ge till de resenärer som är kvar på platsen? Här är det viktigt att försöka gå ut med ett rakt besked t ex ”en ambulans är på väg för att ta hand om de skadade. Sitt lugnt kvar så kommer det att komma en ny buss och förare som kör er vidare”. Be sedan resenärer som vill något övrigt att kontakta bolaget senare</p>
            <p>6.	Skicka ut personal till platsen för ta hand om föraren och representera företaget gentemot passagerare och eventuell räddningspersonal</p>
            <p>7.	Informera krisgruppen. Vid en katastrof (se definition sid 3) ska bolags-vd/krisledaren även kalla samtliga ägare i Björks &amp; Byberg till krismötet. I andra fall gör vd normalt bedömningen om händelsen är av den karaktären att ägarna behöver kallas, eller om det är tillräckligt att de informeras i efterhand. Vd eller dennes ställföreträdare är operativt ansvarig för händelser inom sitt område. Information till krisgruppen utförs via sms till samtliga gruppmedlemmar + aktuell trafikledning. Därefter ringer trafikledaren upp vd eller dennes ställföreträdare och meddelar:</p>
            <ul>
                <li>Vad som har hänt</li>
                <li>Plats för det inträffade</li>
                <li>Antal inblandade</li>
                <li>Vilka konsekvenser händelsen kan få</li>
            </ul>
            <p><strong>OBS! Tänk på att aldrig använda jourtelefonen vid telefonmöten mm. Den måste vara öppen för inkommande samtal.</strong></p>

            <h4>Företagets utsände på olycksplatsen (punkt 6 ovan)</h4>
            <p>Denne person fungerar som trafikledningens förlängda arm ute på platsen för händelsen. Så snart krisgruppen är aktiverad rapporterar personen direkt till krisledaren. Den utsände sköter allt av praktisk och lokal karaktär i samråd med berörda myndigheter på plats. Krisledaren tar alla större beslut samt hanterar media. </p>
            <p><strong>Arbetsuppgifter företagets utsände:</strong></p>
            <ul>
                <li>Bistå polis, räddningsledare och andra inblandade på plats</li>
                <li>Ombesörj eventuell hemtransport eller transport till samlingsplats för icke skadade, efter avstämning med polis och läkare på olycksplatsen</li>
                <li>Säkra tillsammans med räddningsledning att inblandade i olycka som sänds hem inte blir lämnade helt ensamma</li>
                <li>Se till att bagage och tillhörigheter blir omhändertagna efter att ha stämt av med polis och förvara bagaget på stöldsäker plats</li>
                <li>Stäm av med polis gällande den tekniska utrustningen ombord, får färdskrivardata kopieras, kan biljettmaskin plockas ur osv </li>
                <li>Ta fram namn på passagerarna, stäm av med polis</li>
            </ul>
            </div>)
    } 
}

export default Trafikledare;
