import React from "react";
import ReactDOM from "react-dom";
import ErrorBoundaryGlobal from "./components/errorBoundaries/errorBoundaryGlobal";
import { Provider } from 'react-redux';
import { OidcProvider, processSilentRenew } from 'redux-oidc';
import store from './system/store';
import userManager from "./system/userManager";
import Routes from "./system/routes/routes.js";
import "./assets/sass/light-bootstrap-dashboard.css?v=1.1.2";
import './index.css';
import "./assets/css/pe-icon-7-stroke.css";
import * as serviceWorker from './serviceWorker';
import { appConfig } from './config/appConfig';
// you'll need this for older browsers
// require("es6-promise").polyfill();

console.log('ENV',appConfig.name);

if (window.location.pathname === '/silent-renew') {
  console.log('silent renew called');
  processSilentRenew();
} else {


  ReactDOM.render(
    <div>
      <ErrorBoundaryGlobal>
        <Provider store={store}>
          <OidcProvider store={store} userManager={userManager}>
           <Routes />
        </OidcProvider>
      </Provider> 
      </ErrorBoundaryGlobal>
    </div>,
    document.getElementById("root")
  );
}



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
