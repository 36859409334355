import store from "system/store";
import { getAxiosConfig } from "system/axiosConfig";
import axios from "axios";
import  { infraConfig } from "config/infraConfig";

export function openFileWindow(url) {
    
    //console.log('opening url', url);

    // window.open(url, '_blank');
    window.location.href = url;
}


export function downloadFile(filurl) {
 
         const user = store.getState().oidc.user;
         axios.get(infraConfig.apiEndpoint.concat('/api/spcommon/downloadfile?filurl=').concat(filurl), getAxiosConfig(user))
         .then((dataResult) => {
             var byte = base64ToArrayBuffer(dataResult.data.result);
             var name = filurl.split('/');
             var g = name.length-1;
             console.log("Filename: " + name.length)
             saveByteArray(name[g], byte);
 
         })
         .catch((error) => { 
            console.log('fel vid filhämtning');
         });
 
    }
   
 
function base64ToArrayBuffer(base64) {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
       var ascii = binaryString.charCodeAt(i);
       bytes[i] = ascii;
    }
    return bytes;
    
 }

 function saveByteArray(Name, byte) {
    var blob = new Blob([byte], {type: "application/vnd.openxmlformats-officedocument.wordprocessingml.documentapplication/vnd.openxmlformats-officedocument.wordprocessingml.document"});
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    var fileName = Name;
    link.download = fileName;
    link.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));
    //link.click();
}