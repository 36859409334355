import React from 'react';
import Card from 'components/Card/Card.jsx';

class NoAccess extends React.Component {
    
    render(){ 
        return (<Card content={<div><h4>Sidan kan inte visas.</h4>
        <p>Detta kan bero på att din användare inte har behörighet att visa sidan.</p></div>
        } />
        );
    }
}

export default NoAccess;