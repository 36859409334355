import axios from "axios";
import  { infraConfig } from "config/infraConfig";
import { getAxiosConfig } from "system/axiosConfig";
import store from "system/store";
import { ERRORMESSAGES_ADD } from "system/actions";


export const INCIDENT_GET_INCIDENTRAPPORTER = "INCIDENT_GET_INCIDENTRAPPORTER";
export const INCIDENT_GET_INCIDENTRAPPORTER_SUCCESS = "INCIDENT_GET_INCIDENTRAPPORTER_SUCCESS";
export const INCIDENT_GET_INCIDENTRAPPORTER_FAILED = "INCIDENT_GET_INCIDENTRAPPORTER_FAILED";

export const INCIDENT_GET_FORMOPTIONS = "INCIDENT_GET_FORMOPTIONS";
export const INCIDENT_GET_FORMOPTIONS_SUCCESS = "INCIDENT_GET_FORMOPTIONS_SUCCESS";
export const INCIDENT_GET_FORMOPTIONS_FAILED = "INCIDENT_GET_FORMOPTIONS_FAILED";

export const INCIDENT_FORSAKRINGUPPFOLJNING_GET = "INCIDENT_FORSAKRINGUPPFOLJNING_GET";
export const INCIDENT_FORSAKRINGUPPFOLJNING_GET_SUCCESS = "INCIDENT_FORSAKRINGUPPFOLJNING_GET_SUCCESS";
export const INCIDENT_FORSAKRINGUPPFOLJNING_GET_FAILED = "INCIDENT_FORSAKRINGUPPFOLJNING_GET_FAILED";


export function fetchIncidentRapporter() {
    return (dispatch) => {
        dispatch({type: INCIDENT_GET_INCIDENTRAPPORTER});

        const user = store.getState().oidc.user;
        axios.get(infraConfig.apiEndpoint.concat('/api/incident/list'), getAxiosConfig(user))
        .then((dataResult) => dispatch({type: INCIDENT_GET_INCIDENTRAPPORTER_SUCCESS, payload: dataResult}))
        .catch((error) => { dispatch({type: INCIDENT_GET_INCIDENTRAPPORTER_FAILED}); dispatch({type: ERRORMESSAGES_ADD, payload: { type: 'network', message: 'Hämta incidentrapporter misslyckades', severity: 'warning'}});});

    }

};

export function fetchFormOptions() {
    
    return (dispatch) => {
        dispatch({type: INCIDENT_GET_FORMOPTIONS});

        const user = store.getState().oidc.user;
        axios.get(infraConfig.apiEndpoint.concat('/api/incident/formoptions'), getAxiosConfig(user))
        .then((dataResult) => dispatch({type: INCIDENT_GET_FORMOPTIONS_SUCCESS, payload: dataResult}))
        .catch((error) => { dispatch({type: INCIDENT_GET_FORMOPTIONS_FAILED}); dispatch({type: ERRORMESSAGES_ADD, payload: { type: 'network', message: 'Hämta formulärdata för incidentrapport misslyckades', severity: 'warning'}});});

    }
    
};

export function fetchForsakringUppfoljning(year, month) {
    
    return (dispatch) => {
        dispatch({type: INCIDENT_FORSAKRINGUPPFOLJNING_GET});

        const user = store.getState().oidc.user;
        axios.get(infraConfig.apiEndpoint.concat('/api/incident/forsakringuppfoljning?year=').concat(year).concat('&month=').concat(month), getAxiosConfig(user))
        .then((dataResult) => dispatch({type: INCIDENT_FORSAKRINGUPPFOLJNING_GET_SUCCESS, payload: dataResult}))
        .catch((error) => { dispatch({type: INCIDENT_FORSAKRINGUPPFOLJNING_GET_FAILED}); dispatch({type: ERRORMESSAGES_ADD, payload: { type: 'network', message: 'Hämta försäkringsuppföljning misslyckades', severity: 'warning'}});});        

    }
    
};