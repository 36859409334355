import { connect } from "react-redux";
import React, { Component } from "react";
import { Collapse } from "react-bootstrap";
import { Link } from "react-router";
// this is used to create scrollbars on windows devices like the ones from apple devices
import PerfectScrollbar from "react-perfect-scrollbar";
import 'react-perfect-scrollbar/dist/css/styles.css';
import { checkDashboardAccess } from "system/security";

import { appConfig } from "config/appConfig";


const bgImage = { backgroundImage: "url(" + appConfig.sideBarBg + ")" };


class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openAvatar: false,      
      isWindows: navigator.platform.indexOf("Win") > -1 ? true : false,
      width: window.innerWidth
    };
    // this.handleLinkClick = this.handleLinkClick.bind(this);
  }
  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }
  // if the windows width changes CSS has to make some changes
  // this functions tell react what width is the window
  updateDimensions() {
    this.setState({ width: window.innerWidth });
  }
  componentDidMount() {
    this.updateDimensions();
    // add event listener for windows resize
    window.addEventListener("resize", this.updateDimensions.bind(this));


  }

  handleLinkClick(path) {
    document.documentElement.classList.toggle("nav-open");
    // if (this.props.history !== undefined) this.props.history.push(path);
  }

  render() {
    return (
      <div className="sidebar" data-color="black" data-image={bgImage}>
        <div className="sidebar-background" style={bgImage} />
       
       <div className="logo">
          <a
            href="/"
            className="simple-text logo-mini"
          >
            <div className="logo-img">
              <img src={appConfig.logoSmall} alt="react-logo" />
            </div>
          </a>
          <a
            href="/"
            className="simple-text logo-normal"
          >
            {appConfig.title}
          </a>
        </div>
        <div className="sidebar-wrapper" ref="sidebarWrapper">
          <ul className="nav">
            {/* If we are on responsive, we want both links from navbar and sidebar
                            to appear in sidebar, so we render here HeaderLinks */}
            {this.state.width <= 992 ? <appConfig.headerLinks /> : null}
            {/*
                            here we render the links in the sidebar
                            if the link is simple, we make a simple link, if not,
                            we have to create a collapsible group,
                            with the speciffic parent button and with it's children which are the links
                        */}
            {appConfig.dashboard.map((prop, key) => {
              var st = {};
              
              if (!checkDashboardAccess(prop, this.props.userprofile)) {
                return true;
              }

              if (prop.hideFromMenu !== undefined && prop.hideFromMenu === true){
                return true;
              }

              st[prop["state"]] = !this.state[prop.state];
              if (prop.collapse) {
                return (
                  <li className={this.activeRoute(prop.path)} key={key}>
                    <a href="/#" onClick={(e) => {e.preventDefault(); this.setState(st);}}>
                      <i className={prop.icon} />
                      <p>
                        {prop.name}
                        <b
                          className={
                            this.state[prop.state]
                              ? "caret rotate-180"
                              : "caret"
                          }
                        />
                      </p>
                    </a>
                    <Collapse in={this.state[prop.state]}>
                      <ul className="nav">
                        {prop.childRoutes.map((prop, key) => {
                          if (prop.hideFromMenu !== undefined && prop.hideFromMenu === true){
                            return true;
                          }
                          
                          return (
                            <li
                              className={this.activeRoute(prop.path)}
                              key={key}
                            >
                              <Link
                                to={prop.path}
                                className="nav-link"
                                activeClassName="active"
                                onClick={() => this.handleLinkClick(prop.path)}
                                >
                                <span className="sidebar-mini">
                                  {prop.mini}
                                </span>
                                <span className="sidebar-normal">
                                  {prop.name}
                                </span>
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    </Collapse>
                  </li>
                );
              } else {
                if (prop.redirect) {
                  return null;
                } else {
                  return (
                    <li className={this.activeRoute(prop.path)} key={key}>
                      <Link
                        to={prop.path}
                        className="nav-link"
                        activeClassName="active"
                        onClick={() => this.handleLinkClick(prop.path)}
                      >
                        <i className={prop.icon} />
                        <p>{prop.name}</p>
                      </Link>
                    </li>
                  );
                }
              }
            })}            
            <li>
            <div className="sidebar-version-number">
              <div className="sidebar-version-number-line"></div>
              <div className="sidebar-version-number-build">Build: {process.env.REACT_APP_BUILD_DATE}</div>
              </div>
            </li>

          </ul>
        </div>
      </div>
    );
  }
}


function mapStateToProps(state) {
  return {
    user: state.oidc.user
  };
}

export default connect(mapStateToProps)(Sidebar);