// formuläret från startsidan (genvägen) det förenklade
import { connect } from "react-redux";
import axios from "axios";
import { getUndersokning } from "./actions";
import  { infraConfig } from "config/infraConfig";
import { getAxiosConfig } from "system/axiosConfig";
import SavingButton from "components/CustomButtons/SavingButton.jsx"
import Datetime from "react-datetime";
import React, { Component} from "react";
import {
    FormGroup,
    FormControl,
    ControlLabel,
    Radio,
  } from "react-bootstrap";

  import moment from 'moment';
  import 'moment/locale/sv';


class Tjansteman extends Component {

  constructor(props){
    super(props);

    moment().locale('sv');

    this.state = { validated: false };
    this.state = this.getInititalState();

    this.handleFormSave = this.handleFormSave.bind(this);
      this.arbetsmiljo1IsValid = false;
      this.arbetsmiljo2IsValid = false;
      this.arbetsmiljo3IsValid = false;
      this.arbetsmiljo4IsValid = false;
      this.arbetsmiljo5IsValid = false;
      this.arbetsmiljo6IsValid = false;
      this.arbetsmiljo7IsValid = false;
      this.arbetsmiljo8IsValid = false;
      this.arbetsmiljo9IsValid = false;
      this.arbetstriv1IsValid = false;
      this.arbetstriv2IsValid = false;
      this.arbetstriv3IsValid = false;
      this.arbetstriv4IsValid = false;
    this.arbetstriv5IsValid= false;
      this.arbetstriv6IsValid = false;
      this.arbetstriv7IsValid = false;
      this.ledarskapet1IsValid = false;
      this.ledarskapet2IsValid = false;
      this.ledarskapet3IsValid = false;
    this.ledarskapet4IsValid= false;
      this.ledarskapet5IsValid = false;
      this.ledarskapet6IsValid = false;
      this.ledarskapet7IsValid = false;
      this.ledarskapet8IsValid = false;
      this.arbetsuppgifter1IsValid = false;
      this.arbetsuppgifter2IsValid = false;
      this.arbetsuppgifter3IsValid = false;
      this.arbetsuppgifter4IsValid = false;
    this.arbetsuppgifter5IsValid= false;
      this.arbetsuppgifter6IsValid = false;
      this.kompetensutv1IsValid = false;
      this.kompetensutv2IsValid = false;
      this.kompetensutv3IsValid = false;
      this.kompetensutv4IsValid = false;
      this.kompetensutv5IsValid = false;
      this.information1IsValid = false;
      this.information2IsValid = false;
      this.information3IsValid = false;
      this.information4IsValid = false;
      this.information5IsValid = false;
      this.kunder1IsValid = false;
      this.kunder2IsValid = false;
      this.kunder3IsValid = false;
      this.kunder4IsValid = false;
      this.kunder5IsValid = false;
    this.foretaget1IsValid= false;
    this.foretaget2IsValid= false;
      this.foretaget3IsValid = false;
      this.foretaget4IsValid = false;
    this.buttonerror= "";
  }
  handleFormSave() {
    if(!this.arbetsmiljo1IsValid){this.setState({arbetsmiljo1Error:   'Måste anges'});}
    if(!this.arbetsmiljo2IsValid){this.setState({arbetsmiljo2Error:   'Måste anges'});}
    if(!this.arbetsmiljo3IsValid){this.setState({arbetsmiljo3Error:   'Måste anges'});}
    if(!this.arbetsmiljo4IsValid){this.setState({arbetsmiljo4Error:   'Måste anges'});}
    if(!this.arbetsmiljo5IsValid){this.setState({arbetsmiljo5Error:   'Måste anges'});}
    if(!this.arbetsmiljo6IsValid){this.setState({arbetsmiljo6Error:   'Måste anges'});}
    if(!this.arbetsmiljo7IsValid){this.setState({arbetsmiljo7Error:   'Måste anges'});}
    if(!this.arbetsmiljo8IsValid){this.setState({arbetsmiljo8Error:   'Måste anges'});}
    if(!this.arbetstriv1IsValid){this.setState({arbetstriv1Error:   'Måste anges'});}
    if(!this.arbetstriv2IsValid){this.setState({arbetstriv2Error:   'Måste anges'});}
    if(!this.arbetstriv3IsValid){this.setState({arbetstriv3Error:   'Måste anges'});}
    if(!this.arbetstriv4IsValid){this.setState({arbetstriv4Error:   'Måste anges'});}
    if(!this.arbetstriv5IsValid){this.setState({arbetstriv5Error:   'Måste anges'});}
    if(!this.arbetstriv6IsValid){this.setState({arbetstriv6Error:   'Måste anges'});}
    if(!this.ledarskapet1IsValid){this.setState({ledarskapet1Error:   'Måste anges'});}
    if(!this.ledarskapet2IsValid){this.setState({ledarskapet2Error:   'Måste anges'});}
    if(!this.ledarskapet3IsValid){this.setState({ledarskapet3Error:   'Måste anges'});}
    if(!this.ledarskapet4IsValid){this.setState({ledarskapet4Error:   'Måste anges'});}
    if(!this.ledarskapet5IsValid){this.setState({ledarskapet5Error:   'Måste anges'});}
    if(!this.ledarskapet6IsValid){this.setState({ledarskapet6Error:   'Måste anges'});}
    if(!this.ledarskapet7IsValid){this.setState({ledarskapet7Error:   'Måste anges'});}
    if(!this.arbetsuppgifter1IsValid){this.setState({arbetsuppgifter1Error:   'Måste anges'});}
    if(!this.arbetsuppgifter2IsValid){this.setState({arbetsuppgifter2Error:   'Måste anges'});}
    if(!this.arbetsuppgifter3IsValid){this.setState({arbetsuppgifter3Error:   'Måste anges'});}
    if(!this.arbetsuppgifter4IsValid){this.setState({arbetsuppgifter4Error:   'Måste anges'});}
    if(!this.arbetsuppgifter5IsValid){this.setState({arbetsuppgifter5Error:   'Måste anges'});}
    if(!this.kompetensutv1IsValid){this.setState({kompetensutv1Error:   'Måste anges'});}
    if(!this.kompetensutv1IsValid){this.setState({kompetensutv2Error:   'Måste anges'});}
    if(!this.kompetensutv1IsValid){this.setState({kompetensutv3Error:   'Måste anges'});}
    if(!this.kompetensutv1IsValid){this.setState({kompetensutv4Error:   'Måste anges'});}
    if(!this.information1IsValid){this.setState({information1Error:   'Måste anges'});}
    if(!this.information2IsValid){this.setState({information2Error:   'Måste anges'});}
    if(!this.information3IsValid){this.setState({information3Error:   'Måste anges'});}
    if(!this.information4IsValid){this.setState({information4Error:   'Måste anges'});}
    if(!this.kunder1IsValid){this.setState({kunder1Error:   'Måste anges'});}
    if(!this.kunder2IsValid){this.setState({kunder2Error:   'Måste anges'});}
    if(!this.kunder3IsValid){this.setState({kunder3Error:   'Måste anges'});}
    if(!this.kunder4IsValid){this.setState({kunder4Error:   'Måste anges'});}
    if(!this.foretaget1IsValid){this.setState({foretaget1Error:   'Måste anges'});}
    if(!this.foretaget2IsValid){this.setState({foretaget2Error:   'Måste anges'});}

    if (this.arbetsmiljo1IsValid
    && this.arbetsmiljo2IsValid
    && this.arbetsmiljo3IsValid
    && this.arbetsmiljo4IsValid
    && this.arbetsmiljo5IsValid
    && this.arbetsmiljo6IsValid
    && this.arbetsmiljo7IsValid
    && this.arbetsmiljo8IsValid
    && this.arbetsmiljo1IsValid
    && this.arbetsmiljo1IsValid

    && this.arbetstriv1IsValid
    && this.arbetstriv2IsValid
    && this.arbetstriv3IsValid
    && this.arbetstriv4IsValid
    && this.arbetstriv5IsValid
    && this.arbetstriv6IsValid

    && this.ledarskapet1IsValid
    && this.ledarskapet2IsValid
    && this.ledarskapet3IsValid
    && this.ledarskapet4IsValid
    && this.ledarskapet5IsValid
    && this.ledarskapet6IsValid
    && this.ledarskapet7IsValid

    && this.arbetsuppgifter1IsValid
    && this.arbetsuppgifter2IsValid
    && this.arbetsuppgifter3IsValid
    && this.arbetsuppgifter4IsValid
    && this.arbetsuppgifter5IsValid

    && this.kompetensutv1IsValid
    && this.kompetensutv2IsValid
    && this.kompetensutv3IsValid
    && this.kompetensutv4IsValid

    && this.information1IsValid
    && this.information2IsValid
    && this.information3IsValid
    && this.information4IsValid

    && this.kunder1IsValid
    && this.kunder2IsValid
    && this.kunder3IsValid
    && this.kunder4IsValid

    && this.foretaget1IsValid
    && this.foretaget2IsValid
    )
    {
        var saveData = {
            arbetsmiljo1: this.state.arbetsmiljo1,
            arbetsmiljo2: this.state.arbetsmiljo2,
            arbetsmiljo3: this.state.arbetsmiljo3,
            arbetsmiljo4: this.state.arbetsmiljo4,
            arbetsmiljo5: this.state.arbetsmiljo5,
            arbetsmiljo6: this.state.arbetsmiljo6,
            arbetsmiljo7: this.state.arbetsmiljo7,
            arbetsmiljo8: this.state.arbetsmiljo8,
            arbetsmiljo9: this.state.arbetsmiljo9,

            arbetstriv1: this.state.arbetstriv1,
            arbetstriv2: this.state.arbetstriv2,
            arbetstriv3: this.state.arbetstriv3,
            arbetstriv4: this.state.arbetstriv4,
            arbetstriv5: this.state.arbetstriv5,
            arbetstriv6: this.state.arbetstriv6,
            arbetstriv7: this.state.arbetstriv7,

            ledarskapet1: this.state.ledarskapet1,
            ledarskapet2: this.state.ledarskapet2,
            ledarskapet3: this.state.ledarskapet3,
            ledarskapet4: this.state.ledarskapet4,
            ledarskapet5: this.state.ledarskapet5,
            ledarskapet6: this.state.ledarskapet6,
            ledarskapet7: this.state.ledarskapet7,
            ledarskapet8: this.state.ledarskapet8,

            arbetsuppgifter1: this.state.arbetsuppgifter1,
            arbetsuppgifter2: this.state.arbetsuppgifter2,
            arbetsuppgifter3: this.state.arbetsuppgifter3,
            arbetsuppgifter4: this.state.arbetsuppgifter4,
            arbetsuppgifter5: this.state.arbetsuppgifter5,
            arbetsuppgifter6: this.state.arbetsuppgifter6,

            kompetensutv1: this.state.kompetensutv1,
            kompetensutv2: this.state.kompetensutv2,
            kompetensutv3: this.state.kompetensutv3,
            kompetensutv4: this.state.kompetensutv4,
            kompetensutv5: this.state.kompetensutv5,

            information1: this.state.information1,
            information2: this.state.information2,
            information3: this.state.information3,
            information4: this.state.information4,
            information5: this.state.information5,

            kunder1: this.state.kunder1,
            kunder2: this.state.kunder2,
            kunder3: this.state.kunder3,
            kunder4: this.state.kunder4,
            kunder5: this.state.kunder5,

            foretaget1: this.state.foretaget1,
            foretaget2: this.state.foretaget2,
            foretaget3: this.state.foretaget3,
            foretaget4: this.state.foretaget4,

            utvecklingsprojekt1: this.state.utvecklingsprojekt1,
            utvecklingsprojekt2: this.state.utvecklingsprojekt2,

            kortfri1: this.state.kortfri1

        };
       console.log(saveData);
    this.addUtvardering(saveData);
     }
     else{
        this.setState({buttonerror:   'Alla fält har inte fyllts i'});
console.log("Error")
   }

}
addUtvardering(saveData) {
    this.setState({buttonerror:   ''});
  console.log("Send!")
  this.setState({
      savingStatus: 1
  }, () => {

      axios.post(infraConfig.apiEndpoint.concat('/api/undersokning/send'), saveData, getAxiosConfig(this.props.user))
      .then((dataResult) => {
          console.log('after send success', dataResult.data.result);
          this.setState({buttonmessage:   dataResult.data.result})
          if(dataResult.data.result == "Du har redan skickat in ett svar."){
            this.setState({buttonerror2:  "Du har redan skickat in ett svar."});
            this.setState({
                savingStatus: 3
            });

          }
          else{
          this.setState({
              savingStatus: 2
          });
        }
          this.resetForm();
      })
      .catch((error) => {
        this.setState({buttonerror2:  "Ett fel inträffade"});
          this.setState({
              savingStatus: 3
          });
          console.log('ERROR');
         });
  });

}
resetForm() {
  var savingStatus = this.state.savingStatus;
  this.setState(this.getInititalState(), () => {
     this.setState({ savingStatus: savingStatus});
  });


    document.getElementById("create-Tjansteman").reset();
}
  getInititalState = () => {
    const initialState = {
        datumError: null,
        val1Error: null,
        arbetsmiljo1IsValid: '' ,

        savingStatus: 0
    }
    return initialState;
  }

render() {
    return (

<div id="content">
<p>Engagerade och motiverade medarbetare är helt avgörande för företagets framgång. Vi strävar därför efter att skapa en bra arbetsmiljö där medarbetare trivs och känner sig delaktiga. Vi genomför vartannat år en större medarbetarundersökning med syfte att ta reda på hur våra medarbetare uppfattar sin arbetssituation och Byberg & Nordin / Docksta Buss & Truck som arbetsgivare, och nu är det dags igen. Undersökningen kommer att pågå till och med den 30 september. Målet med undersökningen är att den ska resultera i förslag på utvecklingsområden och hjälpa företaget att prioritera förbättringsinsatser framåt. Använd gärna fritextrutorna för att förtydliga och vidareutveckla dina svar på kryssfrågorna.</p>
    <p>Tack för din medverkan!</p>
    <p>Tomas Byberg, ägare/vd Byberg & Nordin</p>
    <form
      id="create-Tjansteman"
       >
           <h2>Arbetsmiljö</h2>
           <br />
        <FormGroup>
        <ControlLabel>
                <b>Jag har den utrustning jag behöver för att utföra mitt arbete</b> <span className="star">*</span>
        </ControlLabel>
        <br/>
        <Radio type='integer' value="4) Håller helt med" onClick={event => {this.setState({arbetsmiljo1: event.target.value, arbetsmiljo1Error: ''}); this.arbetsmiljo1IsValid = true;}} inline name="arbetsmiljo1">4) Håller helt med</Radio><br/>
        <Radio type='integer' value="3) Håller med till viss del" onClick={event => {this.setState({arbetsmiljo1: event.target.value, arbetsmiljo1Error: ''}); this.arbetsmiljo1IsValid = true;}} inline name="arbetsmiljo1">3) Håller med till viss del</Radio><br/>
        <Radio type='integer' value="2) Håller inte med" onClick={event => {this.setState({arbetsmiljo1: event.target.value, arbetsmiljo1Error: ''}); this.arbetsmiljo1IsValid = true;}} inline name="arbetsmiljo1">2) Håller inte med</Radio><br/>
        <Radio type='integer' value="1) Håller inte alls med" onClick={event => {this.setState({arbetsmiljo1: event.target.value, arbetsmiljo1Error: ''}); this.arbetsmiljo1IsValid = true;}} inline name="arbetsmiljo1">1) Håller inte alls med</Radio><br/>
            <span className="text-danger">{this.state.arbetsmiljo1Error}</span>
        </FormGroup>
        <br />
        <FormGroup>
        <ControlLabel>
                <b>Jag känner att jag kan påverka arbetsmiljön på min arbetsplats</b> <span className="star">*</span>
        </ControlLabel>
        <br/>
        <Radio type='integer' value="4) Håller helt med" onClick={event => {this.setState({arbetsmiljo2: event.target.value, arbetsmiljo2Error: ''}); this.arbetsmiljo2IsValid = true;}} inline name="arbetsmiljo2">4) Håller helt med</Radio><br/>
        <Radio type='integer' value="3) Håller med till viss del" onClick={event => {this.setState({arbetsmiljo2: event.target.value, arbetsmiljo2Error: ''}); this.arbetsmiljo2IsValid = true;}} inline name="arbetsmiljo2">3) Håller med till viss del</Radio><br/>
        <Radio type='integer' value="2) Håller inte med" onClick={event => {this.setState({arbetsmiljo2: event.target.value, arbetsmiljo2Error: ''}); this.arbetsmiljo2IsValid = true;}} inline name="arbetsmiljo2">2) Håller inte med</Radio><br/>
        <Radio type='integer' value="1) Håller inte alls med" onClick={event => {this.setState({arbetsmiljo2: event.target.value, arbetsmiljo2Error: ''}); this.arbetsmiljo2IsValid = true;}} inline name="arbetsmiljo2">1) Håller inte alls med</Radio><br/>
            <span className="text-danger">{this.state.arbetsmiljo2Error}</span>
        </FormGroup>
        <br />
        <FormGroup>
        <ControlLabel>
                        <b>Jag tycker att jag kan påverka mina arbetstider i tillräcklig utsträckning</b> <span className="star">*</span>
        </ControlLabel>
        <br/>
        <Radio type='integer' value="4) Håller helt med" onClick={event => {this.setState({arbetsmiljo3: event.target.value, arbetsmiljo3Error: ''}); this.arbetsmiljo3IsValid = true;}} inline name="arbetsmiljo3">4) Håller helt med</Radio><br/>
        <Radio type='integer' value="3) Håller med till viss del" onClick={event => {this.setState({arbetsmiljo3: event.target.value, arbetsmiljo3Error: ''}); this.arbetsmiljo3IsValid = true;}} inline name="arbetsmiljo3">3) Håller med till viss del</Radio><br/>
        <Radio type='integer' value="2) Håller inte med" onClick={event => {this.setState({arbetsmiljo3: event.target.value, arbetsmiljo3Error: ''}); this.arbetsmiljo3IsValid = true;}} inline name="arbetsmiljo3">2) Håller inte med</Radio><br/>
        <Radio type='integer' value="1) Håller inte alls med" onClick={event => {this.setState({arbetsmiljo3: event.target.value, arbetsmiljo3Error: ''}); this.arbetsmiljo3IsValid = true;}} inline name="arbetsmiljo3">1) Håller inte alls med</Radio><br/>
            <span className="text-danger">{this.state.arbetsmiljo3Error}</span>
        </FormGroup>
        <br />
        <FormGroup>
        <ControlLabel>
                <b>Jag känner mig trygg på min arbetsplats</b> <span className="star">*</span>
        </ControlLabel>
        <br/>
        <Radio type='integer' value="4) Håller helt med" onClick={event => {this.setState({arbetsmiljo4: event.target.value, arbetsmiljo4Error: ''}); this.arbetsmiljo4IsValid = true;}} inline name="arbetsmiljo4">4) Håller helt med</Radio><br/>
        <Radio type='integer' value="3) Håller med till viss del" onClick={event => {this.setState({arbetsmiljo4: event.target.value, arbetsmiljo4Error: ''}); this.arbetsmiljo4IsValid = true;}} inline name="arbetsmiljo4">3) Håller med till viss del</Radio><br/>
        <Radio type='integer' value="2) Håller inte med" onClick={event => {this.setState({arbetsmiljo4: event.target.value, arbetsmiljo4Error: ''}); this.arbetsmiljo4IsValid = true;}} inline name="arbetsmiljo4">2) Håller inte med</Radio><br/>
        <Radio type='integer' value="1) Håller inte alls med" onClick={event => {this.setState({arbetsmiljo4: event.target.value, arbetsmiljo4Error: ''}); this.arbetsmiljo4IsValid = true;}} inline name="arbetsmiljo4">1) Håller inte alls med</Radio><br/>
            <span className="text-danger">{this.state.arbetsmiljo4Error}</span>
        </FormGroup>
        <br />
        <FormGroup>
        <ControlLabel>
                <b>På min arbetsplats bedrivs ett förebyggande arbete för att förhindra olycksfall och ohälsa</b> <span className="star">*</span>
        </ControlLabel>
        <br/>
        <Radio type='integer' value="4) Håller helt med" onClick={event => {this.setState({arbetsmiljo5: event.target.value, arbetsmiljo5Error: ''}); this.arbetsmiljo5IsValid = true;}} inline name="arbetsmiljo5">4) Håller helt med</Radio><br/>
        <Radio type='integer' value="3) Håller med till viss del" onClick={event => {this.setState({arbetsmiljo5: event.target.value, arbetsmiljo5Error: ''}); this.arbetsmiljo5IsValid = true;}} inline name="arbetsmiljo5">3) Håller med till viss del</Radio><br/>
        <Radio type='integer' value="2) Håller inte med" onClick={event => {this.setState({arbetsmiljo5: event.target.value, arbetsmiljo5Error: ''}); this.arbetsmiljo5IsValid = true;}} inline name="arbetsmiljo5">2) Håller inte med</Radio><br/>
        <Radio type='integer' value="1) Håller inte alls med" onClick={event => {this.setState({arbetsmiljo5: event.target.value, arbetsmiljo5Error: ''}); this.arbetsmiljo5IsValid = true;}} inline name="arbetsmiljo5">1) Håller inte alls med</Radio><br/>
            <span className="text-danger">{this.state.arbetsmiljo5Error}</span>
        </FormGroup>
        <br />
        <FormGroup>
        <ControlLabel>
                <b>Det hålls ordning och reda i personalutrymmen och allmänna utrymmen</b> <span className="star">*</span>
        </ControlLabel>
        <br/>
        <Radio type='integer' value="4) Håller helt med" onClick={event => {this.setState({arbetsmiljo6: event.target.value, arbetsmiljo6Error: ''}); this.arbetsmiljo6IsValid = true;}} inline name="arbetsmiljo6">4) Håller helt med</Radio><br/>
        <Radio type='integer' value="3) Håller med till viss del" onClick={event => {this.setState({arbetsmiljo6: event.target.value, arbetsmiljo6Error: ''}); this.arbetsmiljo6IsValid = true;}} inline name="arbetsmiljo6">3) Håller med till viss del</Radio><br/>
        <Radio type='integer' value="2) Håller inte med" onClick={event => {this.setState({arbetsmiljo6: event.target.value, arbetsmiljo6Error: ''}); this.arbetsmiljo6IsValid = true;}} inline name="arbetsmiljo6">2) Håller inte med</Radio><br/>
        <Radio type='integer' value="1) Håller inte alls med" onClick={event => {this.setState({arbetsmiljo6: event.target.value, arbetsmiljo6Error: ''}); this.arbetsmiljo6IsValid = true;}} inline name="arbetsmiljo6">1) Håller inte alls med</Radio><br/>
            <span className="text-danger">{this.state.arbetsmiljo6Error}</span>
        </FormGroup>
        <br />
        <FormGroup>
        <ControlLabel>
                <b>Totalt sett är jag nöjd med arbetsmiljön på min arbetsplats</b> <span className="star">*</span>
        </ControlLabel>
        <br/>
        <Radio type='integer' value="4) Håller helt med" onClick={event => {this.setState({arbetsmiljo7: event.target.value, arbetsmiljo7Error: ''}); this.arbetsmiljo7IsValid = true;}} inline name="arbetsmiljo7">4) Håller helt med</Radio><br/>
        <Radio type='integer' value="3) Håller med till viss del" onClick={event => {this.setState({arbetsmiljo7: event.target.value, arbetsmiljo7Error: ''}); this.arbetsmiljo7IsValid = true;}} inline name="arbetsmiljo7">3) Håller med till viss del</Radio><br/>
        <Radio type='integer' value="2) Håller inte med" onClick={event => {this.setState({arbetsmiljo7: event.target.value, arbetsmiljo7Error: ''}); this.arbetsmiljo7IsValid = true;}} inline name="arbetsmiljo7">2) Håller inte med</Radio><br/>
        <Radio type='integer' value="1) Håller inte alls med" onClick={event => {this.setState({arbetsmiljo7: event.target.value, arbetsmiljo7Error: ''}); this.arbetsmiljo7IsValid = true;}} inline name="arbetsmiljo7">1) Håller inte alls med</Radio><br/>
            <span className="text-danger">{this.state.arbetsmiljo7Error}</span>
        </FormGroup>
        <br />
        <FormGroup>
        <ControlLabel>
                <b>Jag upplever att arbetsmiljön på min arbetsplats har förbättrats en del under det senaste året</b> <span className="star">*</span>
        </ControlLabel>
        <br/>
        <Radio type='integer' value="JA" onClick={event => {this.setState({arbetsmiljo8: event.target.value, arbetsmiljo8Error: ''}); this.arbetsmiljo8IsValid = true;}} inline name="arbetsmiljo8">JA</Radio><br/>
        <Radio type='integer' value="NEJ" onClick={event => {this.setState({arbetsmiljo8: event.target.value, arbetsmiljo8Error: ''}); this.arbetsmiljo8IsValid = true;}} inline name="arbetsmiljo8">NEJ</Radio><br/>
        <Radio type='integer' value="OFÖRÄNDRAT" onClick={event => {this.setState({arbetsmiljo8: event.target.value, arbetsmiljo8Error: ''}); this.arbetsmiljo8IsValid = true;}} inline name="arbetsmiljo8">OFÖRÄNDRAT</Radio><br/>
        <Radio type='integer' value="VET EJ" onClick={event => {this.setState({arbetsmiljo8: event.target.value, arbetsmiljo8Error: ''}); this.arbetsmiljo8IsValid = true;}} inline name="arbetsmiljo8">VET EJ</Radio><br/>
            <span className="text-danger">{this.state.arbetsmiljo8Error}</span>
        </FormGroup>
        <br />
<FormGroup>
  <ControlLabel>
          <b>Förslag på åtgärder hur arbetsmiljön på min arbetsplats kan förbättras:</b>
  </ControlLabel>
  <FormControl
  componentClass="textarea" placeholder=""
  type="textarea"
  id="textfield"
  onChange={event => {this.setState({arbetsmiljo9: event.target.value});}}
  name="extraText"
  />
           </FormGroup>
<h2>Arbetskamrater och trivsel</h2>
<br />
<FormGroup>
        <ControlLabel>
                <b>På min arbetsplats har vi ett gott samarbete</b> <span className="star">*</span>
        </ControlLabel>
        <br/>
        <Radio type='integer' value="4) Håller helt med" onClick={event => {this.setState({arbetstriv1: event.target.value, arbetstriv1Error: ''}); this.arbetstriv1IsValid = true;}} inline name="arbetstriv1">4) Håller helt med</Radio><br/>
        <Radio type='integer' value="3) Håller med till viss del" onClick={event => {this.setState({arbetstriv1: event.target.value, arbetstriv1Error: ''}); this.arbetstriv1IsValid = true;}} inline name="arbetstriv1">3) Håller med till viss del</Radio><br/>
        <Radio type='integer' value="2) Håller inte med" onClick={event => {this.setState({arbetstriv1: event.target.value, arbetstriv1Error: ''}); this.arbetstriv1IsValid = true;}} inline name="arbetstriv1">2) Håller inte med</Radio><br/>
        <Radio type='integer' value="1) Håller inte alls med" onClick={event => {this.setState({arbetstriv1: event.target.value, arbetstriv1Error: ''}); this.arbetstriv1IsValid = true;}} inline name="arbetstriv1">1) Håller inte alls med</Radio><br/>
            <span className="text-danger">{this.state.arbetstriv1Error}</span>
        </FormGroup>
        <br />
        <FormGroup>
        <ControlLabel>
                <b>I min grupp diskuterar vi regelbundet vad vi kan förbättra</b> <span className="star">*</span>
        </ControlLabel>
        <br/>
        <Radio type='integer' value="4) Håller helt med" onClick={event => {this.setState({arbetstriv2: event.target.value, arbetstriv2Error: ''}); this.arbetstriv2IsValid = true;}} inline name="arbetstriv2">4) Håller helt med</Radio><br/>
        <Radio type='integer' value="3) Håller med till viss del" onClick={event => {this.setState({arbetstriv2: event.target.value, arbetstriv2Error: ''}); this.arbetstriv2IsValid = true;}} inline name="arbetstriv2">3) Håller med till viss del</Radio><br/>
        <Radio type='integer' value="2) Håller inte med" onClick={event => {this.setState({arbetstriv2: event.target.value, arbetstriv2Error: ''}); this.arbetstriv2IsValid = true;}} inline name="arbetstriv2">2) Håller inte med</Radio><br/>
        <Radio type='integer' value="1) Håller inte alls med" onClick={event => {this.setState({arbetstriv2: event.target.value, arbetstriv2Error: ''}); this.arbetstriv2IsValid = true;}} inline name="arbetstriv2">1) Håller inte alls med</Radio><br/>
            <span className="text-danger">{this.state.arbetstriv2Error}</span>
        </FormGroup>
        <br />
        <FormGroup>
        <ControlLabel>
                <b>Jag känner förtroende för mina arbetskamrater</b> <span className="star">*</span>
        </ControlLabel>
        <br/>
        <Radio type='integer' value="4) Håller helt med" onClick={event => {this.setState({arbetstriv3: event.target.value, arbetstriv3Error: ''}); this.arbetstriv3IsValid = true;}} inline name="arbetstriv3">4) Håller helt med</Radio><br/>
        <Radio type='integer' value="3) Håller med till viss del" onClick={event => {this.setState({arbetstriv3: event.target.value, arbetstriv3Error: ''}); this.arbetstriv3IsValid = true;}} inline name="arbetstriv3">3) Håller med till viss del</Radio><br/>
        <Radio type='integer' value="2) Håller inte med" onClick={event => {this.setState({arbetstriv3: event.target.value, arbetstriv3Error: ''}); this.arbetstriv3IsValid = true;}} inline name="arbetstriv3">2) Håller inte med</Radio><br/>
        <Radio type='integer' value="1) Håller inte alls med" onClick={event => {this.setState({arbetstriv3: event.target.value, arbetstriv3Error: ''}); this.arbetstriv3IsValid = true;}} inline name="arbetstriv3">1) Håller inte alls med</Radio><br/>
            <span className="text-danger">{this.state.arbetstriv3Error}</span>
        </FormGroup>
        <br />
        <FormGroup>
        <ControlLabel>
                <b>När jag berättar om mitt jobb för min familj/mina vänner är jag oftast positiv</b> <span className="star">*</span>
        </ControlLabel>
        <br/>
        <Radio type='integer' value="4) Håller helt med" onClick={event => {this.setState({arbetstriv4: event.target.value, arbetstriv4Error: ''}); this.arbetstriv4IsValid = true;}} inline name="arbetstriv4">4) Håller helt med</Radio><br/>
        <Radio type='integer' value="3) Håller med till viss del" onClick={event => {this.setState({arbetstriv4: event.target.value, arbetstriv4Error: ''}); this.arbetstriv4IsValid = true;}} inline name="arbetstriv4">3) Håller med till viss del</Radio><br/>
        <Radio type='integer' value="2) Håller inte med" onClick={event => {this.setState({arbetstriv4: event.target.value, arbetstriv4Error: ''}); this.arbetstriv4IsValid = true;}} inline name="arbetstriv4">2) Håller inte med</Radio><br/>
        <Radio type='integer' value="1) Håller inte alls med" onClick={event => {this.setState({arbetstriv4: event.target.value, arbetstriv4Error: ''}); this.arbetstriv4IsValid = true;}} inline name="arbetstriv4">1) Håller inte alls med</Radio><br/>
            <span className="text-danger">{this.state.arbetstriv4Error}</span>
        </FormGroup>
        <br />
        <FormGroup>
        <ControlLabel>
                <b>Totalt sett trivs jag bra på arbetsplatsen och med mina arbetskamrater</b> <span className="star">*</span>
        </ControlLabel>
        <br/>
        <Radio type='integer' value="4) Håller helt med" onClick={event => {this.setState({arbetstriv5: event.target.value, arbetstriv5Error: ''}); this.arbetstriv5IsValid = true;}} inline name="arbetstriv5">4) Håller helt med</Radio><br/>
        <Radio type='integer' value="3) Håller med till viss del" onClick={event => {this.setState({arbetstriv5: event.target.value, arbetstriv5Error: ''}); this.arbetstriv5IsValid = true;}} inline name="arbetstriv5">3) Håller med till viss del</Radio><br/>
        <Radio type='integer' value="2) Håller inte med" onClick={event => {this.setState({arbetstriv5: event.target.value, arbetstriv5Error: ''}); this.arbetstriv5IsValid = true;}} inline name="arbetstriv5">2) Håller inte med</Radio><br/>
        <Radio type='integer' value="1) Håller inte alls med" onClick={event => {this.setState({arbetstriv5: event.target.value, arbetstriv5Error: ''}); this.arbetstriv5IsValid = true;}} inline name="arbetstriv5">1) Håller inte alls med</Radio><br/>
            <span className="text-danger">{this.state.arbetstriv5Error}</span>
        </FormGroup>
        <br />
        <FormGroup>
        <ControlLabel>
                <b>Jag upplever att trivseln på arbetsplatsen totalt sett har blivit bättre under det senaste året</b> <span className="star">*</span>
        </ControlLabel>
        <br/>
        <Radio type='integer' value="JA" onClick={event => {this.setState({arbetstriv6: event.target.value, arbetstriv6Error: ''}); this.arbetstriv6IsValid = true;}} inline name="arbetstriv6">JA</Radio><br/>
        <Radio type='integer' value="NEJ" onClick={event => {this.setState({arbetstriv6: event.target.value, arbetstriv6Error: ''}); this.arbetstriv6IsValid = true;}} inline name="arbetstriv6">NEJ</Radio><br/>
        <Radio type='integer' value="OFÖRÄNDRAT" onClick={event => {this.setState({arbetstriv6: event.target.value, arbetstriv6Error: ''}); this.arbetstriv6IsValid = true;}} inline name="arbetstriv6">OFÖRÄNDRAT</Radio><br/>
        <Radio type='integer' value="VET EJ" onClick={event => {this.setState({arbetstriv6: event.target.value, arbetstriv6Error: ''}); this.arbetstriv6IsValid = true;}} inline name="arbetstriv6">VET EJ</Radio><br/>
            <span className="text-danger">{this.state.arbetstriv6Error}</span>
        </FormGroup>
        <br />
        <FormGroup>
  <ControlLabel>
          <b>Förslag på åtgärder för hur trivseln kollegor emellan kan förbättras på arbetsplatsen:</b>
  </ControlLabel>
  <FormControl
  componentClass="textarea" placeholder=""
  type="textarea"
  id="textfield"
  onChange={event => {this.setState({arbetstriv7: event.target.value});}}
  name="extraText"
  />
</FormGroup>
<h2>Ledarskapet</h2>
<br />
<FormGroup>
        <ControlLabel>
                <b>Min närmsta chef ger mig feedback på mina prestationer</b> <span className="star">*</span>
        </ControlLabel>
        <br/>
        <Radio type='integer' value="4) Håller helt med" onClick={event => {this.setState({ledarskapet1: event.target.value, ledarskapet1Error: ''}); this.ledarskapet1IsValid = true;}} inline name="ledarskapet1">4) Håller helt med</Radio><br/>
        <Radio type='integer' value="3) Håller med till viss del" onClick={event => {this.setState({ledarskapet1: event.target.value, ledarskapet1Error: ''}); this.ledarskapet1IsValid = true;}} inline name="ledarskapet1">3) Håller med till viss del</Radio><br/>
        <Radio type='integer' value="2) Håller inte med" onClick={event => {this.setState({ledarskapet1: event.target.value, ledarskapet1Error: ''}); this.ledarskapet1IsValid = true;}} inline name="ledarskapet1">2) Håller inte med</Radio><br/>
        <Radio type='integer' value="1) Håller inte alls med" onClick={event => {this.setState({ledarskapet1: event.target.value, ledarskapet1Error: ''}); this.ledarskapet1IsValid = true;}} inline name="ledarskapet1">1) Håller inte alls med</Radio><br/>
            <span className="text-danger">{this.state.ledarskapet1Error}</span>
        </FormGroup>
        <br />
        <FormGroup>
        <ControlLabel>
                <b>Min närmsta chef har rimliga krav på min arbetsinsats</b> <span className="star">*</span>
        </ControlLabel>
        <br/>
        <Radio type='integer' value="4) Håller helt med" onClick={event => {this.setState({ledarskapet2: event.target.value, ledarskapet2Error: ''}); this.ledarskapet2IsValid = true;}} inline name="ledarskapet2">4) Håller helt med</Radio><br/>
        <Radio type='integer' value="3) Håller med till viss del" onClick={event => {this.setState({ledarskapet2: event.target.value, ledarskapet2Error: ''}); this.ledarskapet2IsValid = true;}} inline name="ledarskapet2">3) Håller med till viss del</Radio><br/>
        <Radio type='integer' value="2) Håller inte med" onClick={event => {this.setState({ledarskapet2: event.target.value, ledarskapet2Error: ''}); this.ledarskapet2IsValid = true;}} inline name="ledarskapet2">2) Håller inte med</Radio><br/>
        <Radio type='integer' value="1) Håller inte alls med" onClick={event => {this.setState({ledarskapet2: event.target.value, ledarskapet2Error: ''}); this.ledarskapet2IsValid = true;}} inline name="ledarskapet2">1) Håller inte alls med</Radio><br/>
            <span className="text-danger">{this.state.ledarskapet2Error}</span>
        </FormGroup>
        <br />
        <FormGroup>
        <ControlLabel>
                <b>Jag känner mig uppmuntrad att komma med nya idréer och förslag till förbättringar</b> <span className="star">*</span>
        </ControlLabel>
        <br/>
        <Radio type='integer' value="4) Håller helt med" onClick={event => {this.setState({ledarskapet3: event.target.value, ledarskapet3Error: ''}); this.ledarskapet3IsValid = true;}} inline name="ledarskapet3">4) Håller helt med</Radio><br/>
        <Radio type='integer' value="3) Håller med till viss del" onClick={event => {this.setState({ledarskapet3: event.target.value, ledarskapet3Error: ''}); this.ledarskapet3IsValid = true;}} inline name="ledarskapet3">3) Håller med till viss del</Radio><br/>
        <Radio type='integer' value="2) Håller inte med" onClick={event => {this.setState({ledarskapet3: event.target.value, ledarskapet3Error: ''}); this.ledarskapet3IsValid = true;}} inline name="ledarskapet3">2) Håller inte med</Radio><br/>
        <Radio type='integer' value="1) Håller inte alls med" onClick={event => {this.setState({ledarskapet3: event.target.value, ledarskapet3Error: ''}); this.ledarskapet3IsValid = true;}} inline name="ledarskapet3">1) Håller inte alls med</Radio><br/>
            <span className="text-danger">{this.state.ledarskapet3Error}</span>
        </FormGroup>
        <br />
        <FormGroup>
        <ControlLabel>
                <b>Jag är i stora drag nöjd med min närmsta chef</b> <span className="star">*</span>
        </ControlLabel>
        <br/>
        <Radio type='integer' value="4) Håller helt med" onClick={event => {this.setState({ledarskapet4: event.target.value, ledarskapet4Error: ''}); this.ledarskapet4IsValid = true;}} inline name="ledarskapet4">4) Håller helt med</Radio><br/>
        <Radio type='integer' value="3) Håller med till viss del" onClick={event => {this.setState({ledarskapet4: event.target.value, ledarskapet4Error: ''}); this.ledarskapet4IsValid = true;}} inline name="ledarskapet4">3) Håller med till viss del</Radio><br/>
        <Radio type='integer' value="2) Håller inte med" onClick={event => {this.setState({ledarskapet4: event.target.value, ledarskapet4Error: ''}); this.ledarskapet4IsValid = true;}} inline name="ledarskapet4">2) Håller inte med</Radio><br/>
        <Radio type='integer' value="1) Håller inte alls med" onClick={event => {this.setState({ledarskapet4: event.target.value, ledarskapet4Error: ''}); this.ledarskapet4IsValid = true;}} inline name="ledarskapet4">1) Håller inte alls med</Radio><br/>
            <span className="text-danger">{this.state.ledarskapet4Error}</span>
        </FormGroup>
        <br />
        <FormGroup>
        <ControlLabel>
                <b>Jag har förtroende för företagets VD</b> <span className="star">*</span>
        </ControlLabel>
        <br/>
        <Radio type='integer' value="4) Håller helt med" onClick={event => {this.setState({ledarskapet5: event.target.value, ledarskapet5Error: ''}); this.ledarskapet5IsValid = true;}} inline name="ledarskapet5">4) Håller helt med</Radio><br/>
        <Radio type='integer' value="3) Håller med till viss del" onClick={event => {this.setState({ledarskapet5: event.target.value, ledarskapet5Error: ''}); this.ledarskapet5IsValid = true;}} inline name="ledarskapet5">3) Håller med till viss del</Radio><br/>
        <Radio type='integer' value="2) Håller inte med" onClick={event => {this.setState({ledarskapet5: event.target.value, ledarskapet5Error: ''}); this.ledarskapet5IsValid = true;}} inline name="ledarskapet5">2) Håller inte med</Radio><br/>
        <Radio type='integer' value="1) Håller inte alls med" onClick={event => {this.setState({ledarskapet5: event.target.value, ledarskapet5Error: ''}); this.ledarskapet5IsValid = true;}} inline name="ledarskapet5">1) Håller inte alls med</Radio><br/>
            <span className="text-danger">{this.state.ledarskapet5Error}</span>
        </FormGroup>
        <br />
        <FormGroup>
        <ControlLabel>
                <b>Jag tycker att ledarskapet i mitt företag är bra totalt sett</b> <span className="star">*</span>
        </ControlLabel>
        <br/>
        <Radio type='integer' value="4) Håller helt med" onClick={event => {this.setState({ledarskapet6: event.target.value, ledarskapet6Error: ''}); this.ledarskapet6IsValid = true;}} inline name="ledarskapet6">4) Håller helt med</Radio><br/>
        <Radio type='integer' value="3) Håller med till viss del" onClick={event => {this.setState({ledarskapet6: event.target.value, ledarskapet6Error: ''}); this.ledarskapet6IsValid = true;}} inline name="ledarskapet6">3) Håller med till viss del</Radio><br/>
        <Radio type='integer' value="2) Håller inte med" onClick={event => {this.setState({ledarskapet6: event.target.value, ledarskapet6Error: ''}); this.ledarskapet6IsValid = true;}} inline name="ledarskapet6">2) Håller inte med</Radio><br/>
        <Radio type='integer' value="1) Håller inte alls med" onClick={event => {this.setState({ledarskapet6: event.target.value, ledarskapet6Error: ''}); this.ledarskapet6IsValid = true;}} inline name="ledarskapet6">1) Håller inte alls med</Radio><br/>
            <span className="text-danger">{this.state.ledarskapet6Error}</span>
        </FormGroup>
        <br />
        <FormGroup>
        <ControlLabel>
                <b>Jag upplever att ledarskapet i företaget har förbättrats under det senaste året</b> <span className="star">*</span>
        </ControlLabel>
        <br/>
        <Radio type='integer' value="JA" onClick={event => {this.setState({ledarskapet7: event.target.value, ledarskapet7Error: ''}); this.ledarskapet7IsValid = true;}} inline name="ledarskapet7">JA</Radio><br/>
        <Radio type='integer' value="NEJ" onClick={event => {this.setState({ledarskapet7: event.target.value, ledarskapet7Error: ''}); this.ledarskapet7IsValid = true;}} inline name="ledarskapet7">NEJ</Radio><br/>
        <Radio type='integer' value="OFÖRÄNDRAT" onClick={event => {this.setState({ledarskapet7: event.target.value, ledarskapet7Error: ''}); this.ledarskapet7IsValid = true;}} inline name="ledarskapet7">OFÖRÄNDRAT</Radio><br/>
        <Radio type='integer' value="VET EJ" onClick={event => {this.setState({ledarskapet7: event.target.value, ledarskapet7Error: ''}); this.ledarskapet7IsValid = true;}} inline name="ledarskapet7">VET EJ</Radio><br/>
            <span className="text-danger">{this.state.ledarskapet7Error}</span>
        </FormGroup>
        <br />
        <FormGroup>
  <ControlLabel>
          <b>Förslag på hur ledarskapet kan förbättras:</b>
  </ControlLabel>
  <FormControl
  componentClass="textarea" placeholder=""
  type="textarea"
  id="textfield"
  onChange={event => {this.setState({ledarskapet8: event.target.value});}}
  name="extraText"
  />
</FormGroup>
<h2>Arbetsuppgifter</h2>
<br />
<FormGroup>
        <ControlLabel>
                <b>Jag har god kännedom om vilka arbetsuppgifter jag förväntas göra</b> <span className="star">*</span>
        </ControlLabel>
        <br/>
        <Radio type='integer' value="4) Håller helt med" onClick={event => {this.setState({arbetsuppgifter1: event.target.value, arbetsuppgifter1Error: ''}); this.arbetsuppgifter1IsValid = true;}} inline name="arbetsuppgifter1">4) Håller helt med</Radio><br/>
        <Radio type='integer' value="3) Håller med till viss del" onClick={event => {this.setState({arbetsuppgifter1: event.target.value, arbetsuppgifter1Error: ''}); this.arbetsuppgifter1IsValid = true;}} inline name="arbetsuppgifter1">3) Håller med till viss del</Radio><br/>
        <Radio type='integer' value="2) Håller inte med" onClick={event => {this.setState({arbetsuppgifter1: event.target.value, arbetsuppgifter1Error: ''}); this.arbetsuppgifter1IsValid = true;}} inline name="arbetsuppgifter1">2) Håller inte med</Radio><br/>
        <Radio type='integer' value="1) Håller inte alls med" onClick={event => {this.setState({arbetsuppgifter1: event.target.value, arbetsuppgifter1Error: ''}); this.arbetsuppgifter1IsValid = true;}} inline name="arbetsuppgifter1">1) Håller inte alls med</Radio><br/>
            <span className="text-danger">{this.state.arbetsuppgifter1Error}</span>
        </FormGroup>
        <br />
        <FormGroup>
        <ControlLabel>
                <b> I mitt arbete får jag användning för mina kunskaper och färdigheter</b> <span className="star">*</span>
        </ControlLabel>
        <br/>
        <Radio type='integer' value="4) Håller helt med" onClick={event => {this.setState({arbetsuppgifter2: event.target.value, arbetsuppgifter2Error: ''}); this.arbetsuppgifter2IsValid = true;}} inline name="arbetsuppgifter2">4) Håller helt med</Radio><br/>
        <Radio type='integer' value="3) Håller med till viss del" onClick={event => {this.setState({arbetsuppgifter2: event.target.value, arbetsuppgifter2Error: ''}); this.arbetsuppgifter2IsValid = true;}} inline name="arbetsuppgifter2">3) Håller med till viss del</Radio><br/>
        <Radio type='integer' value="2) Håller inte med" onClick={event => {this.setState({arbetsuppgifter2: event.target.value, arbetsuppgifter2Error: ''}); this.arbetsuppgifter2IsValid = true;}} inline name="arbetsuppgifter2">2) Håller inte med</Radio><br/>
        <Radio type='integer' value="1) Håller inte alls med" onClick={event => {this.setState({arbetsuppgifter2: event.target.value, arbetsuppgifter2Error: ''}); this.arbetsuppgifter2IsValid = true;}} inline name="arbetsuppgifter2">1) Håller inte alls med</Radio><br/>
            <span className="text-danger">{this.state.arbetsuppgifter2Error}</span>
        </FormGroup>
        <br />
        <FormGroup>
        <ControlLabel>
                <b> Jag har klart för mig hur jag ska prioritera under perioder när det är mycket att göra</b> <span className="star">*</span>
        </ControlLabel>
        <br/>
        <Radio type='integer' value="4) Håller helt med" onClick={event => {this.setState({arbetsuppgifter3: event.target.value, arbetsuppgifter3Error: ''}); this.arbetsuppgifter3IsValid = true;}} inline name="arbetsuppgifter3">4) Håller helt med</Radio><br/>
        <Radio type='integer' value="3) Håller med till viss del" onClick={event => {this.setState({arbetsuppgifter3: event.target.value, arbetsuppgifter3Error: ''}); this.arbetsuppgifter3IsValid = true;}} inline name="arbetsuppgifter3">3) Håller med till viss del</Radio><br/>
        <Radio type='integer' value="2) Håller inte med" onClick={event => {this.setState({arbetsuppgifter3: event.target.value, arbetsuppgifter3Error: ''}); this.arbetsuppgifter3IsValid = true;}} inline name="arbetsuppgifter3">2) Håller inte med</Radio><br/>
        <Radio type='integer' value="1) Håller inte alls med" onClick={event => {this.setState({arbetsuppgifter3: event.target.value, arbetsuppgifter3Error: ''}); this.arbetsuppgifter3IsValid = true;}} inline name="arbetsuppgifter3">1) Håller inte alls med</Radio><br/>
            <span className="text-danger">{this.state.arbetsuppgifter3Error}</span>
        </FormGroup>
        <br />
        <FormGroup>
        <ControlLabel>
                <b> Jag känner ansvar för mitt arbetsområde och mina arbetsuppgifter</b> <span className="star">*</span>
        </ControlLabel>
        <br/>
        <Radio type='integer' value="4) Håller helt med" onClick={event => {this.setState({arbetsuppgifter4: event.target.value, arbetsuppgifter4Error: ''}); this.arbetsuppgifter4IsValid = true;}} inline name="arbetsuppgifter4">4) Håller helt med</Radio><br/>
        <Radio type='integer' value="3) Håller med till viss del" onClick={event => {this.setState({arbetsuppgifter4: event.target.value, arbetsuppgifter4Error: ''}); this.arbetsuppgifter4IsValid = true;}} inline name="arbetsuppgifter4">3) Håller med till viss del</Radio><br/>
        <Radio type='integer' value="2) Håller inte med" onClick={event => {this.setState({arbetsuppgifter4: event.target.value, arbetsuppgifter4Error: ''}); this.arbetsuppgifter4IsValid = true;}} inline name="arbetsuppgifter4">2) Håller inte med</Radio><br/>
        <Radio type='integer' value="1) Håller inte alls med" onClick={event => {this.setState({arbetsuppgifter4: event.target.value, arbetsuppgifter4Error: ''}); this.arbetsuppgifter4IsValid = true;}} inline name="arbetsuppgifter4">1) Håller inte alls med</Radio><br/>
            <span className="text-danger">{this.state.arbetsuppgifter4Error}</span>
        </FormGroup>
        <br />
        <FormGroup>
        <ControlLabel>
                <b> Totalt sett trivs jag bra med mina arbetsuppgifter</b> <span className="star">*</span>
        </ControlLabel>
        <br/>
        <Radio type='integer' value="4) Håller helt med" onClick={event => {this.setState({arbetsuppgifter5: event.target.value, arbetsuppgifter5Error: ''}); this.arbetsuppgifter5IsValid = true;}} inline name="arbetsuppgifter5">4) Håller helt med</Radio><br/>
        <Radio type='integer' value="3) Håller med till viss del" onClick={event => {this.setState({arbetsuppgifter5: event.target.value, arbetsuppgifter5Error: ''}); this.arbetsuppgifter5IsValid = true;}} inline name="arbetsuppgifter5">3) Håller med till viss del</Radio><br/>
        <Radio type='integer' value="2) Håller inte med" onClick={event => {this.setState({arbetsuppgifter5: event.target.value, arbetsuppgifter5Error: ''}); this.arbetsuppgifter5IsValid = true;}} inline name="arbetsuppgifter5">2) Håller inte med</Radio><br/>
        <Radio type='integer' value="1) Håller inte alls med" onClick={event => {this.setState({arbetsuppgifter5: event.target.value, arbetsuppgifter5Error: ''}); this.arbetsuppgifter5IsValid = true;}} inline name="arbetsuppgifter5">1) Håller inte alls med</Radio><br/>
            <span className="text-danger">{this.state.arbetsuppgifter5Error}</span>
        </FormGroup>
        <br />
        <FormGroup>
  <ControlLabel>
          <b>Förslag på åtgärder för hur mina arbetsuppgifter kan förbättras:</b>
  </ControlLabel>
  <FormControl
  componentClass="textarea" placeholder=""
  type="textarea"
  id="textfield"
  onChange={event => {this.setState({arbetsuppgifter6: event.target.value});}}
  name="extraText"
  />
</FormGroup>
<h2>Kompetensutveckling</h2>
<br />
<FormGroup>
        <ControlLabel>
                <b> Med den kompetens jag har idag kan jag utföra mitt arbete på ett bra sätt</b> <span className="star">*</span>
        </ControlLabel>
        <br/>
        <Radio type='integer' value="4) Håller helt med" onClick={event => {this.setState({kompetensutv1: event.target.value, kompetensutv1Error: ''}); this.kompetensutv1IsValid = true;}} inline name="kompetensutv1">4) Håller helt med</Radio><br/>
        <Radio type='integer' value="3) Håller med till viss del" onClick={event => {this.setState({kompetensutv1: event.target.value, kompetensutv1Error: ''}); this.kompetensutv1IsValid = true;}} inline name="kompetensutv1">3) Håller med till viss del</Radio><br/>
        <Radio type='integer' value="2) Håller inte med" onClick={event => {this.setState({kompetensutv1: event.target.value, kompetensutv1Error: ''}); this.kompetensutv1IsValid = true;}} inline name="kompetensutv1">2) Håller inte med</Radio><br/>
        <Radio type='integer' value="1) Håller inte alls med" onClick={event => {this.setState({kompetensutv1: event.target.value, kompetensutv1Error: ''}); this.kompetensutv1IsValid = true;}} inline name="kompetensutv1">1) Håller inte alls med</Radio><br/>
            <span className="text-danger">{this.state.kompetensutv1Error}</span>
        </FormGroup>
        <br />
<FormGroup>
        <ControlLabel>
                <b> Jag håller mig uppdaterad inom mitt yrkesområde (t.ex. via nätverksträffar, internet, facktidskrifter)</b> <span className="star">*</span>
        </ControlLabel>
        <br/>
        <Radio type='integer' value="4) Håller helt med" onClick={event => {this.setState({kompetensutv2: event.target.value, kompetensutv2Error: ''}); this.kompetensutv2IsValid = true;}} inline name="kompetensutv2">4) Håller helt med</Radio><br/>
        <Radio type='integer' value="3) Håller med till viss del" onClick={event => {this.setState({kompetensutv2: event.target.value, kompetensutv2Error: ''}); this.kompetensutv2IsValid = true;}} inline name="kompetensutv2">3) Håller med till viss del</Radio><br/>
        <Radio type='integer' value="2) Håller inte med" onClick={event => {this.setState({kompetensutv2: event.target.value, kompetensutv2Error: ''}); this.kompetensutv2IsValid = true;}} inline name="kompetensutv2">2) Håller inte med</Radio><br/>
        <Radio type='integer' value="1) Håller inte alls med" onClick={event => {this.setState({kompetensutv2: event.target.value, kompetensutv2Error: ''}); this.kompetensutv2IsValid = true;}} inline name="kompetensutv2">1) Håller inte alls med</Radio><br/>
            <span className="text-danger">{this.state.kompetensutv2Error}</span>
        </FormGroup>
        <br />
        <FormGroup>
        <ControlLabel>
                <b> Ju bättre jag presterar desto större blir mina möjligheter till utveckling (nya arbetsuppgifter, större ansvar)</b> <span className="star">*</span>
        </ControlLabel>
        <br/>
        <Radio type='integer' value="4) Håller helt med" onClick={event => {this.setState({kompetensutv3: event.target.value, kompetensutv3Error: ''}); this.kompetensutv3IsValid = true;}} inline name="kompetensutv3">4) Håller helt med</Radio><br/>
        <Radio type='integer' value="3) Håller med till viss del" onClick={event => {this.setState({kompetensutv3: event.target.value, kompetensutv3Error: ''}); this.kompetensutv3IsValid = true;}} inline name="kompetensutv3">3) Håller med till viss del</Radio><br/>
        <Radio type='integer' value="2) Håller inte med" onClick={event => {this.setState({kompetensutv3: event.target.value, kompetensutv3Error: ''}); this.kompetensutv3IsValid = true;}} inline name="kompetensutv3">2) Håller inte med</Radio><br/>
        <Radio type='integer' value="1) Håller inte alls med" onClick={event => {this.setState({kompetensutv3: event.target.value, kompetensutv3Error: ''}); this.kompetensutv3IsValid = true;}} inline name="kompetensutv3">1) Håller inte alls med</Radio><br/>
            <span className="text-danger">{this.state.kompetensutv3Error}</span>
        </FormGroup>
        <br />
        <FormGroup>
        <ControlLabel>
                <b> Jag tycker att kompetensutvecklingen totalt sett är bra</b> <span className="star">*</span>
        </ControlLabel>
        <br/>
        <Radio type='integer' value="4) Håller helt med" onClick={event => {this.setState({kompetensutv4: event.target.value, kompetensutv4Error: ''}); this.kompetensutv4IsValid = true;}} inline name="kompetensutv4">4) Håller helt med</Radio><br/>
        <Radio type='integer' value="3) Håller med till viss del" onClick={event => {this.setState({kompetensutv4: event.target.value, kompetensutv4Error: ''}); this.kompetensutv4IsValid = true;}} inline name="kompetensutv4">3) Håller med till viss del</Radio><br/>
        <Radio type='integer' value="2) Håller inte med" onClick={event => {this.setState({kompetensutv4: event.target.value, kompetensutv4Error: ''}); this.kompetensutv4IsValid = true;}} inline name="kompetensutv4">2) Håller inte med</Radio><br/>
        <Radio type='integer' value="1) Håller inte alls med" onClick={event => {this.setState({kompetensutv4: event.target.value, kompetensutv4Error: ''}); this.kompetensutv4IsValid = true;}} inline name="kompetensutv4">1) Håller inte alls med</Radio><br/>
            <span className="text-danger">{this.state.kompetensutv4Error}</span>
        </FormGroup>
        <br />
        <FormGroup>
  <ControlLabel>
          <b>Förslag på åtgärder för hur kompetensutvecklingen kan förbättras:</b>
  </ControlLabel>
  <FormControl
  componentClass="textarea" placeholder=""
  type="textarea"
  id="textfield"
  onChange={event => {this.setState({kompetensutv5: event.target.value});}}
  name="extraText"
  />
</FormGroup>
<h2>Information</h2>
<br />
<FormGroup>
        <ControlLabel>
                <b> Jag tycker att Min Sida (och tjänstemannawebben för de som har tillgång) innehåller relevant information</b> <span className="star">*</span>
        </ControlLabel>
        <br/>
        <Radio type='integer' value="4) Håller helt med" onClick={event => {this.setState({information1: event.target.value, information1Error: ''}); this.information1IsValid = true;}} inline name="information1">4) Håller helt med</Radio><br/>
        <Radio type='integer' value="3) Håller med till viss del" onClick={event => {this.setState({information1: event.target.value, information1Error: ''}); this.information1IsValid = true;}} inline name="information1">3) Håller med till viss del</Radio><br/>
        <Radio type='integer' value="2) Håller inte med" onClick={event => {this.setState({information1: event.target.value, information1Error: ''}); this.information1IsValid = true;}} inline name="information1">2) Håller inte med</Radio><br/>
        <Radio type='integer' value="1) Håller inte alls med" onClick={event => {this.setState({information1: event.target.value, information1Error: ''}); this.information1IsValid = true;}} inline name="information1">1) Håller inte alls med</Radio><br/>
            <span className="text-danger">{this.state.information1Error}</span>
        </FormGroup>
        <br />
        <FormGroup>
        <ControlLabel>
                <b> Jag tycker att våra avdelnings-/gruppmöten är givande</b> <span className="star">*</span>
        </ControlLabel>
        <br/>
        <Radio type='integer' value="4) Håller helt med" onClick={event => {this.setState({information2: event.target.value, information2Error: ''}); this.information2IsValid = true;}} inline name="information2">4) Håller helt med</Radio><br/>
        <Radio type='integer' value="3) Håller med till viss del" onClick={event => {this.setState({information2: event.target.value, information2Error: ''}); this.information2IsValid = true;}} inline name="information2">3) Håller med till viss del</Radio><br/>
        <Radio type='integer' value="2) Håller inte med" onClick={event => {this.setState({information2: event.target.value, information2Error: ''}); this.information2IsValid = true;}} inline name="information2">2) Håller inte med</Radio><br/>
        <Radio type='integer' value="1) Håller inte alls med" onClick={event => {this.setState({information2: event.target.value, information2Error: ''}); this.information2IsValid = true;}} inline name="information2">1) Håller inte alls med</Radio><br/>
            <span className="text-danger">{this.state.information2Error}</span>
        </FormGroup>
        <br />
        <FormGroup>
        <ControlLabel>
                <b> Jag kan i generella drag redogöra för företagets övergripande mål och vision</b> <span className="star">*</span>
        </ControlLabel>
        <br/>
        <Radio type='integer' value="4) Håller helt med" onClick={event => {this.setState({information3: event.target.value, information3Error: ''}); this.information3IsValid = true;}} inline name="information3">4) Håller helt med</Radio><br/>
        <Radio type='integer' value="3) Håller med till viss del" onClick={event => {this.setState({information3: event.target.value, information3Error: ''}); this.information3IsValid = true;}} inline name="information3">3) Håller med till viss del</Radio><br/>
        <Radio type='integer' value="2) Håller inte med" onClick={event => {this.setState({information3: event.target.value, information3Error: ''}); this.information3IsValid = true;}} inline name="information3">2) Håller inte med</Radio><br/>
        <Radio type='integer' value="1) Håller inte alls med" onClick={event => {this.setState({information3: event.target.value, information3Error: ''}); this.information3IsValid = true;}} inline name="information3">1) Håller inte alls med</Radio><br/>
            <span className="text-danger">{this.state.information3Error}</span>
        </FormGroup>
        <br />
        <FormGroup>
        <ControlLabel>
                <b>Jag tycker att informationen inom företaget har blivit bättre totalt sett under det senaste året</b> <span className="star">*</span>
        </ControlLabel>
        <br/>
        <Radio type='integer' value="JA" onClick={event => {this.setState({information4: event.target.value, information4Error: ''}); this.information4IsValid = true;}} inline name="information4">JA</Radio><br/>
        <Radio type='integer' value="NEJ" onClick={event => {this.setState({information4: event.target.value, information4Error: ''}); this.information4IsValid = true;}} inline name="information4">NEJ</Radio><br/>
        <Radio type='integer' value="OFÖRÄNDRAT" onClick={event => {this.setState({information4: event.target.value, information4Error: ''}); this.information4IsValid = true;}} inline name="information4">OFÖRÄNDRAT</Radio><br/>
        <Radio type='integer' value="VET EJ" onClick={event => {this.setState({information4: event.target.value, information4Error: ''}); this.information4IsValid = true;}} inline name="information4">VET EJ</Radio><br/>
            <span className="text-danger">{this.state.information4Error}</span>
        </FormGroup>
        <br />
        <FormGroup>
  <ControlLabel>
          <b>Förslag på åtgärder för hur informationen kan förbättras:</b>
  </ControlLabel>
  <FormControl
  componentClass="textarea" placeholder=""
  type="textarea"
  id="textfield"
  onChange={event => {this.setState({information5: event.target.value});}}
  name="extraText"
  />
</FormGroup>
<h2>Kunder</h2>
<br />
<FormGroup>
        <ControlLabel>
                        <b> Jag känner till våra kommersiella resor (tex programresor, konsert, teater, shopping, spa och kryssningar) och kan översikligt berätta om dem för kunder, vänner och bekanta</b> <span className="star">*</span>
        </ControlLabel>
        <br/>
        <Radio type='integer' value="4) Håller helt med" onClick={event => {this.setState({kunder1: event.target.value, kunder1Error: ''}); this.kunder1IsValid = true;}} inline name="kunder1">4) Håller helt med</Radio><br/>
        <Radio type='integer' value="3) Håller med till viss del" onClick={event => {this.setState({kunder1: event.target.value, kunder1Error: ''}); this.kunder1IsValid = true;}} inline name="kunder1">3) Håller med till viss del</Radio><br/>
        <Radio type='integer' value="2) Håller inte med" onClick={event => {this.setState({kunder1: event.target.value, kunder1Error: ''}); this.kunder1IsValid = true;}} inline name="kunder1">2) Håller inte med</Radio><br/>
        <Radio type='integer' value="1) Håller inte alls med" onClick={event => {this.setState({kunder1: event.target.value, kunder1Error: ''}); this.kunder1IsValid = true;}} inline name="kunder1">1) Håller inte alls med</Radio><br/>
            <span className="text-danger">{this.state.kunder1Error}</span>
        </FormGroup>
        <br />
        <FormGroup>
        <ControlLabel>
                <b> Jag känner till våra kunders önskemål och förväntningar</b> <span className="star">*</span>
        </ControlLabel>
        <br/>
        <Radio type='integer' value="4) Håller helt med" onClick={event => {this.setState({kunder2: event.target.value, kunder2Error: ''}); this.kunder2IsValid = true;}} inline name="kunder2">4) Håller helt med</Radio><br/>
        <Radio type='integer' value="3) Håller med till viss del" onClick={event => {this.setState({kunder2: event.target.value, kunder2Error: ''}); this.kunder2IsValid = true;}} inline name="kunder2">3) Håller med till viss del</Radio><br/>
        <Radio type='integer' value="2) Håller inte med" onClick={event => {this.setState({kunder2: event.target.value, kunder2Error: ''}); this.kunder2IsValid = true;}} inline name="kunder2">2) Håller inte med</Radio><br/>
        <Radio type='integer' value="1) Håller inte alls med" onClick={event => {this.setState({kunder2: event.target.value, kunder2Error: ''}); this.kunder2IsValid = true;}} inline name="kunder2">1) Håller inte alls med</Radio><br/>
            <span className="text-danger">{this.state.kunder2Error}</span>
        </FormGroup>
        <br />
        <FormGroup>
        <ControlLabel>
                <b> Inom företaget gör vi allt för att överträffa kundernas behov så snabbt och effektivt som möjligt</b> <span className="star">*</span>
        </ControlLabel>
        <br/>
        <Radio type='integer' value="4) Håller helt med" onClick={event => {this.setState({kunder3: event.target.value, kunder3Error: ''}); this.kunder3IsValid = true;}} inline name="kunder3">4) Håller helt med</Radio><br/>
        <Radio type='integer' value="3) Håller med till viss del" onClick={event => {this.setState({kunder3: event.target.value, kunder3Error: ''}); this.kunder3IsValid = true;}} inline name="kunder3">3) Håller med till viss del</Radio><br/>
        <Radio type='integer' value="2) Håller inte med" onClick={event => {this.setState({kunder3: event.target.value, kunder3Error: ''}); this.kunder3IsValid = true;}} inline name="kunder3">2) Håller inte med</Radio><br/>
        <Radio type='integer' value="1) Håller inte alls med" onClick={event => {this.setState({kunder3: event.target.value, kunder3Error: ''}); this.kunder3IsValid = true;}} inline name="kunder3">1) Håller inte alls med</Radio><br/>
            <span className="text-danger">{this.state.kunder3Error}</span>
        </FormGroup>
        <br />
        <FormGroup>
        <ControlLabel>
                        <b> Jag rekommenderar våra kommersiella resor till mina vänner och bekanta</b> <span className="star">*</span>
        </ControlLabel>
        <br/>
        <Radio type='integer' value="4) Håller helt med" onClick={event => {this.setState({kunder4: event.target.value, kunder4Error: ''}); this.kunder4IsValid = true;}} inline name="kunder4">4) Håller helt med</Radio><br/>
        <Radio type='integer' value="3) Håller med till viss del" onClick={event => {this.setState({kunder4: event.target.value, kunder4Error: ''}); this.kunder4IsValid = true;}} inline name="kunder4">3) Håller med till viss del</Radio><br/>
        <Radio type='integer' value="2) Håller inte med" onClick={event => {this.setState({kunder4: event.target.value, kunder4Error: ''}); this.kunder4IsValid = true;}} inline name="kunder4">2) Håller inte med</Radio><br/>
        <Radio type='integer' value="1) Håller inte alls med" onClick={event => {this.setState({kunder4: event.target.value, kunder4Error: ''}); this.kunder4IsValid = true;}} inline name="kunder4">1) Håller inte alls med</Radio><br/>
            <span className="text-danger">{this.state.kunder4Error}</span>
        </FormGroup>
        <br />
        <FormGroup>
  <ControlLabel>
                        <b>Förslag på åtgärder för hur kännedomen internt om våra kommersiella resor kan förbättras:</b>
  </ControlLabel>
  <FormControl
  componentClass="textarea" placeholder=""
  type="textarea"
  id="textfield"
  onChange={event => {this.setState({kunder5: event.target.value});}}
  name="extraText"
  />
</FormGroup>
<h2>Företaget</h2>
<br />
<FormGroup>
        <ControlLabel>
                <b> Jag är stolt över att arbeta i företaget</b> <span className="star">*</span>
        </ControlLabel>
        <br/>
        <Radio type='integer' value="4) Håller helt med" onClick={event => {this.setState({foretaget1: event.target.value, foretaget1Error: ''}); this.foretaget1IsValid = true;}} inline name="foretaget1">4) Håller helt med</Radio><br/>
        <Radio type='integer' value="3) Håller med till viss del" onClick={event => {this.setState({foretaget1: event.target.value, foretaget1Error: ''}); this.foretaget1IsValid = true;}} inline name="foretaget1">3) Håller med till viss del</Radio><br/>
        <Radio type='integer' value="2) Håller inte med" onClick={event => {this.setState({foretaget1: event.target.value, foretaget1Error: ''}); this.foretaget1IsValid = true;}} inline name="foretaget1">2) Håller inte med</Radio><br/>
        <Radio type='integer' value="1) Håller inte alls med" onClick={event => {this.setState({foretaget1: event.target.value, foretaget1Error: ''}); this.foretaget1IsValid = true;}} inline name="foretaget1">1) Håller inte alls med</Radio><br/>
            <span className="text-danger">{this.state.foretaget1Error}</span>
        </FormGroup>
        <br />
        <FormGroup>
        <ControlLabel>
                <b> Jag skulle rekommendera mitt företag som en bra arbetsgivare till mina vänner och bekanta</b> <span className="star">*</span>
        </ControlLabel>
        <br/>
        <Radio type='integer' value="4) Håller helt med" onClick={event => {this.setState({foretaget2: event.target.value, foretaget2Error: ''}); this.foretaget2IsValid = true;}} inline name="foretaget2">4) Håller helt med</Radio><br/>
        <Radio type='integer' value="3) Håller med till viss del" onClick={event => {this.setState({foretaget2: event.target.value, foretaget2Error: ''}); this.foretaget2IsValid = true;}} inline name="foretaget2">3) Håller med till viss del</Radio><br/>
        <Radio type='integer' value="2) Håller inte med" onClick={event => {this.setState({foretaget2: event.target.value, foretaget2Error: ''}); this.foretaget2IsValid = true;}} inline name="foretaget2">2) Håller inte med</Radio><br/>
        <Radio type='integer' value="1) Håller inte alls med" onClick={event => {this.setState({foretaget2: event.target.value, foretaget2Error: ''}); this.foretaget2IsValid = true;}} inline name="foretaget2">1) Håller inte alls med</Radio><br/>
            <span className="text-danger">{this.state.foretaget2Error}</span>
        </FormGroup>
        <br />
        <FormGroup>
  <ControlLabel>
          <b>Vilka är dina förväntningar på ditt företag för framtiden?</b>
  </ControlLabel>
  <FormControl
  componentClass="textarea" placeholder=""
  type="textarea"
  id="textfield"
  onChange={event => {this.setState({foretaget3: event.target.value});}}
  name="extraText"
  />
</FormGroup>
<br />
<FormGroup>
  <ControlLabel>
          <b>Har du någonting annat som du vill framföra till företagsledningen?</b>
  </ControlLabel>
  <FormControl
  componentClass="textarea" placeholder=""
  type="textarea"
  id="textfield"
  onChange={event => {this.setState({foretaget4: event.target.value});}}
  name="extraText"
  />
</FormGroup>
{/*<h2>Utvecklingsprojekt</h2>*/}
{/*<p>Under det senaste året har vi genomfört två större utvecklingsprojekt – skadeförebyggande arbete tillsammans med Trygg Hansa samt Sparsam körning - och vi vill gärna veta mer om hur du har upplevt dem hittills och hur du skulle önska att vi arbetar vidare med dem.</p>*/}
{/*<br />*/}
{/*<FormGroup>*/}
{/*  <ControlLabel>*/}
{/*          <b>I november 2019 lanserade vi en ny funktion i Min Sida för inrapportering av fordonsskador och möjligheten att följa sina egna och trafikområdets försäkringsärenden och kostnader månadsvis. Inför detta åkte företagsledningen tillsammans med försäkringsbolaget Trygg Hansa runt på depåerna för att informera om försäkringen, den nya digitala skaderapporten, vår nya förebyggande rutin med skadesamtal och coachning samt den depåtävling som skulle genomföras under 6 månader som en kickstart på projektet. Nu vill vi veta hur du har upplevt det första året med digital skaderapportering och uppföljning via Min Sida. Dela gärna med dig om du har tankar och önskemål kring hur vi kan jobba vidare för att minska frekvensen skador och hålla den fortsatt låg. </b>*/}
{/*  </ControlLabel>*/}
{/*  <FormControl*/}
{/*  componentClass="textarea" placeholder=""*/}
{/*  type="textarea"*/}
{/*  id="textfield"*/}
{/*  onChange={event => {this.setState({utvecklingsprojekt1: event.target.value});}}*/}
{/*  name="extraText"*/}
{/*  />*/}
{/*</FormGroup>*/}
{/*<br />*/}
{/*<FormGroup>*/}
{/*  <ControlLabel>*/}
{/*          <b>I början på 2020 smög vi igång med ännu en nyhet i Min Sida, nämligen möjligheten för bussförare att följa sin drivmedelsförbrukning. Nu planerar vi för att ta projektet Sparsam körning till nästa nivå. Vi tar gärna dina tankar och tips kring hur vi kan göra det på bästa sätt för att minska vår miljöpåverkan och våra drivmedelskostnader.</b>*/}
{/*  </ControlLabel>*/}
{/*  <FormControl*/}
{/*  componentClass="textarea" placeholder=""*/}
{/*  type="textarea"*/}
{/*  id="textfield"*/}
{/*  onChange={event => {this.setState({utvecklingsprojekt2: event.target.value});}}*/}
{/*  name="extraText"*/}
{/*  />*/}
{/*    </FormGroup>*/}
{/*    <FormGroup>*/}
{/*  <ControlLabel>*/}
{/*          <b>Vill du framföra något annat till företagsledningen eller förtydliga/kommentera något till dina svar på de 5 ovanstående frågorna?</b>*/}
{/*  </ControlLabel>*/}
{/*  <FormControl*/}
{/*  componentClass="textarea" placeholder=""*/}
{/*  type="textarea"*/}
{/*  id="textfield"*/}
{/*  onChange={event => {this.setState({kortfri1: event.target.value});}}*/}
{/*  name="extraText"*/}
{/*  />*/}
{/*    </FormGroup>*/}
<br />
<FormGroup>
    <SavingButton
            text="Spara"
            inProgressText="Sparar.."
            completeMessage= {this.state.buttonmessage}
            errorMessage={this.state.buttonerror2}
            status={this.state.savingStatus}
            onClick={this.handleFormSave}
        />
        <span className="text-danger">{this.state.buttonerror}</span>
</FormGroup>
</form>
    </div>
    )
    }


}

function mapStateToProps(state) {
    return {
        user: state.oidc.user,
    };
  }

export default connect(mapStateToProps, { getUndersokning})(Tjansteman);
