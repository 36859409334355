import { createStore, applyMiddleware, compose } from 'redux';
import { browserHistory } from 'react-router';
import { routerMiddleware } from 'react-router-redux';
import { loadUser } from 'redux-oidc';
import reducer from './reducer';
import userManager from './userManager';
import promise from "redux-promise";
import thunk from "redux-thunk";
import { insightsMonitor } from 'redux-appinsights';
import { appConfig } from "config/appConfig";

// create the middleware with the userManager
// const oidcMiddleware = createOidcMiddleware(userManager, () => true, false, '/callback');

const loggerMiddleware = store => next => action => {

  if (appConfig.outputToConsole) {
    
    console.log("Action type:", action.type);
    console.log("Action payload:", action.payload);
    console.log("State before:", store.getState());
    
    next(action);

    console.log("State after:", store.getState());  
  } else {
    next(action);
  }

};

const initialState = {  };

const createStoreWithMiddleware = compose(
  applyMiddleware(thunk, promise, loggerMiddleware, routerMiddleware(browserHistory), insightsMonitor())
)(createStore);

const store = createStoreWithMiddleware(reducer, initialState);

loadUser(store, userManager);
export default store;

