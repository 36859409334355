import {
    FORSLAG_GET_FORSLAGLIST,
    FORSLAG_GET_FORSLAGLIST_SUCCESS,
    FORSLAG_GET_FORSLAGLIST_FAILED,
    FORSLAG_CREATE_FORSLAG,
    FORSLAG_CREATE_FORSLAG_SUCCESS,
    FORSLAG_CREATE_FORSLAG_FAILED,
} from "./actions";
  
const initialState = {
    forslag: [],
    forslagTimestamp: null,
    isLoadingForslag: false,
    loadingForslagFailed: false,

    addForslagResult: null,
    addForslagTimestamp: null,
    isLoadingAddForslag: false,
    addForslagFailed: false,

};
  
export default function(state = initialState, action) {
    switch (action.type) {
        case FORSLAG_GET_FORSLAGLIST:
            return {...state, isLoadingForslag: true, loadingForslagFailed: false } 
        case FORSLAG_GET_FORSLAGLIST_SUCCESS:
            return {...state, isLoadingForslag: false, forslag: action.payload.data.result, forslagTimestamp: action.payload.data.timestamp } 
        case FORSLAG_GET_FORSLAGLIST_FAILED:
            return {...state, isLoadingForslag: false, loadingForslagFailed: true, forslag: [], forslagTimestamp: null } 
         
        case FORSLAG_CREATE_FORSLAG:
            return {...state, isLoadingAddForslag: true, addForslagFailed: false, addForslag:null } 
        case FORSLAG_CREATE_FORSLAG_SUCCESS:
            return {...state, isLoadingAddForslag: false, addForslagResult: action.payload.data.result, addForslagTimestamp: action.payload.data.timestamp } 
        case FORSLAG_CREATE_FORSLAG_FAILED:
            return {...state, isLoadingAddForslag: false, addForslagFailed: true, addForslagResult: null, addForslagTimestamp: null } 

        default:
            return state;
    }
}