import { KRISHANTERING_GET_DOCUMENTS, 
    KRISHANTERING_GET_DOCUMENTS_SUCCESS,
    KRISHANTERING_GET_DOCUMENTS_FAILED,  
    KRISHANTERING_GET_FILE,
    KRISHANTERING_GET_FILE_SUCCESS,
    KRISHANTERING_GET_FILE_FAILED,
    KRISHANTERING_GET_BOSSINFO,
    KRISHANTERING_GET_BOSSINFO_SUCCESS,
    KRISHANTERING_GET_BOSSINFO_FAILED,
  } from "./actions";
  
  const initialState = {
        documents: [],
        isLoadingDocuments: false,
        loadingDocumentsFailed: false,
        documentsTimestamp: null,

        checklist: [],
        isLoadingChecklist: false,
        loadingChecklistFailed: false,
        checklistTimestamp: null,

        downloadFile: '',
        isLoadingDownloadFile: false,
        loadingDownloadFileFailed: false,
        downloadFileTimestamp: null,

        bossInfo: null,
        isLoadingBossInfo: false,
        loadingBossInfoFailed: false,
        bossInfoTimestamp: null
    };
  
  export default function(state = initialState, action) {
    switch (action.type) {
        case KRISHANTERING_GET_DOCUMENTS:
            return { ...state, isLoadingDocuments: true, loadingDocumentsFailed: false }
        case KRISHANTERING_GET_DOCUMENTS_SUCCESS:
            return { ...state, isLoadingDocuments: false, documents: action.payload.data.result, documentsTimestamp: action.payload.data.timestamp };
        case KRISHANTERING_GET_DOCUMENTS_FAILED:
            return { ...state, isLoadingDocuments: false, loadingDocumentsFailed: true, documents: [], documentsTimestamp: null };

        case KRISHANTERING_GET_BOSSINFO:
            return { ...state, isLoadingBossInfo: true, loadingBossInfoFailed: false }
        case KRISHANTERING_GET_BOSSINFO_SUCCESS:
            return { ...state, isLoadingBossInfo: false, bossInfo: action.payload.data.result, bossInfoTimestamp: action.payload.data.timestamp };
        case KRISHANTERING_GET_BOSSINFO_FAILED:
            return { ...state, isLoadingBossInfo: false, loadingBossInfoFailed: true, bossInfo: null, bossInfoTimestamp: null };

        case KRISHANTERING_GET_FILE:
            return { ...state, isLoadingDownloadFile: true, loadingDownloadFileFailed: false }
        case KRISHANTERING_GET_FILE_SUCCESS:
            return { ...state, isLoadingDownloadFile: false, downloadFile: action.payload.data.result, downloadFileTimestamp: action.payload.data.timestamp };
        case KRISHANTERING_GET_FILE_FAILED:
            return { ...state, isLoadingDownloadFile: false, loadingDownloadFileFailed: true, downloadFile: [], downloadFileTimestamp: null };

        default:
            return state;
    }
  }