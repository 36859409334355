import {
    SHAREPOINT_GET_BOLAG,
    SHAREPOINT_GET_BOLAG_SUCCESS,
    SHAREPOINT_GET_BOLAG_FAILED,
} from "system/actions/sharepointCommon.js";
  
const initialState = {
   
    bolag: [],
    bolagTimestamp: null,
    isLoadingBolag: false,
    loadingBolagFailed: false,

};
  
export default function(state = initialState, action) {
    switch (action.type) {
        
        case SHAREPOINT_GET_BOLAG:
            return {...state, isLoadingBolag: true, loadingBolagFailed: false } 
        case SHAREPOINT_GET_BOLAG_SUCCESS:
            return {...state, isLoadingBolag: false, bolag: action.payload.data.result, bolagTimestamp: action.payload.data.timestamp } 
        case SHAREPOINT_GET_BOLAG_FAILED:
            return {...state, isLoadingBolag: false, loadingBolagFailed: true, bolag: [], bolagTimestamp: null } 

        default:
            return state;
    }
}