import { Component } from "react";

class MobileDesktop extends Component {
    constructor() {
        super();
        this.state = {
          width: window.innerWidth,
        };
      }

      componentWillMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);
      }
    // make sure to remove the listener
    // when the component is not mounted anymore
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }
    handleWindowSizeChange = () => {
        this.setState({ width: window.innerWidth });
      };

    render() {
        const { width } = this.state;
        const isMobile = width < 992;

        return (
            isMobile && this.props.mobileView != null 
            ? this.props.mobileView 
            : this.props.desktopView != null 
              ? this.props.desktopView 
              : null
        );
    }
}

export default MobileDesktop;