import React from 'react';
import Button from "components/CustomButtons/CustomButton.jsx";

import { LinkContainer } from "react-router-bootstrap";

class KrishanteringButton extends React.Component {
    render(){

        // TODO: VH har en annan krishanterings"modul" men knappen är samma, flytta?
        return (<LinkContainer to="/dashboard/krishantering">
                    <Button fill block bsStyle="danger">Vid kris klicka här</Button>
                </LinkContainer>);
    }
}

export default KrishanteringButton;