import React, { Component } from "react";

import userManager from "system/userManager";
import { Nav, NavDropdown, MenuItem, Navbar } from "react-bootstrap";
import { connect } from "react-redux";

// import Date from "components/formatting/Date.jsx";
import { clearErrors } from "system/actions";
import { logoutInProgress } from "system/actions/authHelper.js";

import { LinkContainer } from "react-router-bootstrap";

import BplExternalLink from "components/utils/BplExternalLink";
import ExternalLink from "components/utils/ExternalLink";
import KrishanteringButton from "components/utils/KrishanteringButton";
import MobileDesktop from "components/utils/MobileDesktop";

class HeaderLinks extends Component {
	constructor(props) {
		super(props);

		this.state = {
			extended: false,
		};
	}

	handleNotificationClick() {
		this.props.clearErrors();
	}
	handleLinkClick(path) {
		document.documentElement.classList.toggle("nav-open");
		// if (this.props.history !== undefined) this.props.history.push(path);
	}

	render() {
		return (
			<div>
				<Nav pullRight={this.props.pullRight}>
					<NavDropdown
						onToggle={(isToggled) => {
							this.setState({
								extended: isToggled,
							});
						}}
						eventKey={4}
						title={
							<div>
								<i className="fa fa-window-restore" />
								<span className="hidden-xs hidden-sm"> Mina länkar</span>
								<p className="hidden-md hidden-lg">
									Mina länkar
									<b className="caret" />
								</p>
							</div>
						}
						noCaret
						id="basic-nav-dropdown-3"
						bsClass="dropdown-with-icons dropdown"
					>
						{this.state.extended ? (
							<>
								<MenuItem eventKey={4.1}>
									<BplExternalLink
										to="Client/Tidrapport/"
										title="Tidrapport"
										icon={<i className="fa fa-clock-o" />}
										showAsText
									/>
								</MenuItem>
								<MenuItem eventKey={4.2}>
									<BplExternalLink
										to="client/forartavla"
										title="Förartavlan"
										icon={<i className="fa fa-bus" />}
										showAsText
									/>
								</MenuItem>
								<MenuItem>
									<ExternalLink
										to="https://vhkov.azurewebsites.net"
										icon={<i className="fa fa-graduation-cap" />}
										title="Utbildningsportalen"
										showAsText
									/>
								</MenuItem>
							</>
						) : null}
					</NavDropdown>

					<NavDropdown
						eventKey={4}
						title={
							<div>
								<i className="fa fa-list" />
								<p className="hidden-md hidden-lg">
									Meny
									<b className="caret" />
								</p>
							</div>
						}
						noCaret
						id="basic-nav-dropdown-3"
						bsClass="dropdown-with-icons dropdown"
					>
						<LinkContainer
							onClick={() => this.handleLinkClick()}
							to="/dashboard/userinfo"
						>
							<MenuItem>
								<i className="fa fa-user" />
								Användarinfo
							</MenuItem>
						</LinkContainer>

						<MenuItem eventKey={4.6}>
							<div
								className="text-danger"
								onClick={() => {
									this.handleLinkClick();

									this.props.logoutInProgress();
									userManager.removeUser(); // removes the user data from sessionStorage
									userManager.signoutRedirect({
										id_token_hint: this.props.user.id_token,
									});
									window.localStorage.clear();
								}}
							>
								<i className="fa fa-sign-out" /> Logga ut
							</div>
						</MenuItem>
					</NavDropdown>
				</Nav>
				<Navbar.Collapse>
					<Navbar.Form pullRight>
						<MobileDesktop desktopView={<KrishanteringButton />} />
					</Navbar.Form>
				</Navbar.Collapse>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		user: state.oidc.user,
		errors: state.errors.errors,
	};
}

export default connect(mapStateToProps, { clearErrors, logoutInProgress })(
	HeaderLinks
);
