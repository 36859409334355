import React from "react";
// import Card from "components/Card/Card.jsx";
import Reset from "components/Support/Reset";
// import Debuginfo from "components/Support/Debuginfo";

class DashboardHelp extends React.Component {
   
  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-xs-12">
            <Reset />
          </div>
        </div>
        {/* <div className="row">
          <div className="col-xs-12">
            <Debuginfo />
          </div>
        </div> */}
      </div>
     
    );
  }
}


export default DashboardHelp;