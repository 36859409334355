import { connect } from "react-redux";
import { getRegionNews } from "./actions";
import NewsItem from "./NewsItem";
import { getClaimSingle } from "system/security";
import Button from "components/CustomButtons/CustomButton";
import React from 'react';
import Card from 'components/Card/Card';

class RegionNewsListView extends React.Component {
    constructor(props){
        super(props);
        
        this.state = {
            regionTitle: 'Region',
            page: 0,
            pages: [],
            totalRecords: 0
        }
    }
    
    componentDidMount() {

        this.props.getRegionNews(this.state.page);

        const intraCompanyArea = getClaimSingle("IntraCompanyArea", this.props.userprofile);
        if (intraCompanyArea !== undefined && intraCompanyArea !== '')
        {
            this.setState({
                regionTitle: intraCompanyArea
            });     
        }

    }

    static getDerivedStateFromProps(nextProps, prevState) {

        
        if (nextProps.region && nextProps.region.totalRecords !== prevState.totalRecords)
        {
            console.log('derived state', nextProps, prevState);

            var pages = [];
            for (var i = 0; i < Math.ceil(nextProps.region.totalRecords / 5); i++){
                pages.push(i);
            }
            return { 
                totalRecords: nextProps.region.totalRecords,
                pages: pages,
             }
        }
        return null;
    }

    scrollToTop = () => {
        window.scrollTo({
            top: 0
        });
    }
    
    render() {

        return <div className="container-fluid">            
            { this.props.isLoadingRegion ? <span><i className="fa fa-spinner fa-spin"></i> Laddar nyheter..</span>: null }
            { this.props.region && !this.props.isLoadingRegion
            ? this.props.region.items.map((item,key) => {
                return <div className="row" key={item.id}><div className="col-md-12"><Card key={item.id} content={
                    <NewsItem item={item} key={item.id} />
                } /></div></div>
                }) : null}

            { this.state.pages.length > 1 ?
                <div className="row"><div className="col-md-12"><div className="btn-group">
                { this.state.pages.map((item, key) => {
                    const buttonClass = item === this.state.page ? "primary" : "default";

                    return <Button key={key} fill bsStyle={buttonClass} onClick={() => {

                        this.setState({
                            page: item
                        }, () => {
                            this.props.getRegionNews(this.state.page);
                            this.scrollToTop()
                        })

                    }}>{item + 1}</Button>
                })
                }                        
                </div></div></div> : null

            }
            </div>
    }
}

function mapStateToProps(state) {
    return {
        userprofile: state.userprofile.userprofile,
        region: state.news.region,
        isLoadingRegion: state.news.isLoadingRegion,
        loadingRegionFailed: state.news.loadingRegionFailed,

    };
  }
export default connect(mapStateToProps, { getRegionNews })(RegionNewsListView);