import { connect } from "react-redux";
import { getRegionNewsCompact,getKoncernNewsCompact } from "./actions";
import NewsItemCompact from "./NewsItemCompact";
import { getClaimSingle } from "system/security";

import React from 'react';
import Card from 'components/Card/Card';

class StartNewsListView extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            regionTitle: 'Region'
        }
    }
    
    componentDidMount(){
        this.props.getRegionNewsCompact(0);
        this.props.getKoncernNewsCompact(0);

        const intraCompanyArea = getClaimSingle("IntraCompanyArea", this.props.userprofile);
        if (intraCompanyArea !== undefined && intraCompanyArea !== '')
        {
            this.setState({
                regionTitle: intraCompanyArea
            });     
        }

    }
    
    render() {

        return <div className="row">
                <div className="col-md-6">
                    <Card title={this.state.regionTitle} content={
                    <div>                        
                        { this.props.isLoadingRegion ? <span><i className="fa fa-spinner fa-spin"></i> Laddar nyheter..</span>: null}
                        { this.props.region ? this.props.region.items
                        .filter((item, index) => index < 5)
                        .map((item,key) => {
                            return <NewsItemCompact item={item} key={key} type="region" />;
                            }) : null}
                        </div>
                        } />
                </div>
                <div className="col-md-6">
                    <Card title="Koncern" content={
                     <div>
                    { this.props.isLoadingKoncern ? <span><i className="fa fa-spinner fa-spin"></i> Laddar nyheter..</span>: null}
                     { this.props.koncern ? this.props.koncern.items
                     .filter((item, index) => index < 5)
                     .map((item,key) => {
                         return <NewsItemCompact item={item} key={key} type="koncern" />;
                         }) : null}
                     </div>
                     } />
                </div>
            </div>
           
    }
}

function mapStateToProps(state) {
    return {
        userprofile: state.userprofile.userprofile,

        region: state.news.regionCompact,
        isLoadingRegion: state.news.isLoadingRegionCompact,
        loadingRegionFailed: state.news.loadingRegionCompactFailed,

        koncern: state.news.koncernCompact,
        isLoadingKoncern: state.news.isLoadingKoncernCompact,
        loadingKoncernFailed: state.news.loadingKoncernCompactFailed
    };
  }
export default connect(mapStateToProps, { getRegionNewsCompact, getKoncernNewsCompact })(StartNewsListView);