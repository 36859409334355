import { AUTH_LOGOUTFINISHED, AUTH_LOGOUTINPROGRESS } from "system/actions/authHelper.js";

const initialState = {
  logoutInProgress: false
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case AUTH_LOGOUTFINISHED:
      return {...state, logoutInProgress: false } 
    case AUTH_LOGOUTINPROGRESS:
      return {...state, logoutInProgress: true } 
    default:
      return state;
  }
}

