import React from 'react';
import {Grid, Col, Row } from "react-bootstrap";
import FordonsFelList from "./FordonsFelList";
import NyttFordonsFelForm from "./NyttFordonsFelForm";
import SelectVehicleForm from "./SelectVehicleForm";
import Card from 'components/Card/Card.jsx';
import { connect } from "react-redux";
import { getVehicles, getVehicleCategories,getVehicleFaults } from "./actions";
import LoadingIcon from "components/utils/LoadingIcon";

class Fordonskontroll extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            selectedVehicleId : ''
        }
        this.handleSelectVehicle = this.handleSelectVehicle.bind(this);
    }
    componentDidMount() {
        this.props.getVehicles();
    }

    handleSelectVehicle(vehicleReg) {
        console.log('byta fordon', vehicleReg);

        if (vehicleReg !== this.state.selectedVehicleId){
            
            this.setState({
                selectedVehicleId: vehicleReg === "" ? '' : vehicleReg
            });
            this.props.getVehicleFaults(vehicleReg);
        }
    }

    render(){ 
        return (<Grid fluid>
            <Row>
                <Col sm={12}>
                    <Card title="Välj fordon" content={<div>
                        <LoadingIcon text="Laddar fordon.." isLoading={this.props.isLoadingVehicles} loadingFailed={this.props.loadingVehiclesFailed} success={
                            <SelectVehicleForm requiredLabel vehicles={this.props.vehicles} handleSelectVehicle={this.handleSelectVehicle} />
                        } />
                    </div>} />
                    {this.state.selectedVehicleId !== '' 
                    ? <Card title="Ny felrapport" content={<NyttFordonsFelForm selectedVehicleId={this.state.selectedVehicleId} />} /> 
                    : null}
                </Col>
                <Col sm={12}>
                    {this.state.selectedVehicleId !== '' ? <Card title="Aktiva felrapporter" content={<FordonsFelList />} /> : null}
                </Col>
            </Row>
        </Grid>);
    }
}

function mapStateToProps(state) {
    return {
        vehicles: state.fordonskontroll.vehicles,
        isLoadingVehicles: state.fordonskontroll.isLoadingVehicles,
        loadingVehiclesFailed: state.fordonskontroll.loadingVehiclesFailed,
    };
  }
  
  export default connect(mapStateToProps, { getVehicles, getVehicleCategories, getVehicleFaults })(Fordonskontroll);