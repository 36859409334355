import {
    INCIDENT_GET_FORMOPTIONS, 
    INCIDENT_GET_FORMOPTIONS_SUCCESS, 
    INCIDENT_GET_FORMOPTIONS_FAILED,
    INCIDENT_GET_INCIDENTRAPPORTER,
    INCIDENT_GET_INCIDENTRAPPORTER_SUCCESS,
    INCIDENT_GET_INCIDENTRAPPORTER_FAILED,
    INCIDENT_FORSAKRINGUPPFOLJNING_GET,
    INCIDENT_FORSAKRINGUPPFOLJNING_GET_FAILED,
    INCIDENT_FORSAKRINGUPPFOLJNING_GET_SUCCESS
} from "./actions";
  
const initialState = {
    incidentRapporter: [],
    incidentRapporterTimestamp: null,
    isLoadingIncidentRapporter: false,
    loadingIncidentRapporterFailed: false,

    forsakringUppfoljning: null,
    isLoadingForsakringUppfoljning: false,
    loadingForsakringUppfoljningFailed: false,

    formOptions: null,
    formOptionsTimestamp: null,
    isLoadingFormOptions: false,
    loadingFormOptionsFailed: false
    
};
  
export default function(state = initialState, action) {
    switch (action.type) {
        case INCIDENT_GET_INCIDENTRAPPORTER:
            return {...state, isLoadingIncidentRapporter: true, loadingIncidentRapporterFailed: false } 
        case INCIDENT_GET_INCIDENTRAPPORTER_SUCCESS:
            return {...state, isLoadingIncidentRapporter: false, incidentRapporter: action.payload.data.result, incidentRapporterTimestamp: action.payload.data.timestamp } 
        case INCIDENT_GET_INCIDENTRAPPORTER_FAILED:
            return {...state, isLoadingIncidentRapporter: false, loadingIncidentRapporterFailed: true, incidentRapporter: [], incidentRapporterTimestamp: null } 

        case INCIDENT_GET_FORMOPTIONS:
            return {...state, isLoadingFormOptions: true, loadingFormOptionsFailed: false } 
        case INCIDENT_GET_FORMOPTIONS_SUCCESS:
            return {...state, isLoadingFormOptions: false, formOptions: action.payload.data.result, formOptionsTimestamp: action.payload.data.timestamp } 
        case INCIDENT_GET_FORMOPTIONS_FAILED:
            return {...state, isLoadingFormOptions: false,loadingFormOptionsFailed: true, formOptions: null, formOptionsTimestamp: null } 

        case INCIDENT_FORSAKRINGUPPFOLJNING_GET:
            return {...state, isLoadingForsakringUppfoljning: true, loadingForsakringUppfoljningFailed: false } 
        case INCIDENT_FORSAKRINGUPPFOLJNING_GET_SUCCESS:
            return {...state, isLoadingForsakringUppfoljning: false, loadingForsakringUppfoljningFailed: false, forsakringUppfoljning: action.payload.data.result } 
        case INCIDENT_FORSAKRINGUPPFOLJNING_GET_FAILED:
            return {...state, isLoadingForsakringUppfoljning: false, loadingForsakringUppfoljningFailed: true, forsakringUppfoljning: null } 
        default:
            return state;
    }
}