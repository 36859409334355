import React from 'react';

class Krisledare extends React.Component {

    render() {
        return(<div>
        <p>1.	Stäm av händelsen med trafikledaren och gå igenom checklista för trafikledare för att ta reda på vad som har hänt är vilka åtgärder som är gjorda hittills. Låt därefter trafikledaren lösa det praktiska med ersättningsfordon, ny förare eller liknande (beroende på händelse) </p>
        <p>2.	Sammankalla krisgruppen så snart som möjligt för en första avstämning på telefon, om detta inte redan är gjort av trafik-/driftledare eller jour. Kallelse skickas ut på sms med text:</p>
        <ul>
            <li>Kallelse till krisgruppsmöte </li>
            <li>klockslag för mötet </li>
            <li>telefonnummer och möteskod</li>
        </ul>
        <p>3.	Första mötet: Börja med att strukturera upp vad som inträffat och se till att hela gruppen har en enhetlig bild av situationen</p>
        <p>Fördela följande roller och arbetsuppgifter (en person kan tilldelas flera roller);</p>
        <ul>
            <li>Krisledare och biträdande krisledare </li>
            <li>Presstalesperson (normalt krisledaren)</li>
            <li>Kommunikationssamordnare (se rutiner för kriskommunikation)</li>
            <li>Inhämta information om föraren (ålder, arbetsmängd dagarna före händelsen, ev sjukdomshistorik m.m. som kan vara relevant)</li>
            <li>Inhämta information om fordonet (däck, alkolås, service, rapporterade fel m.m. som kan vara relevant)</li>
            <li>Sekreterare för mötet (för protokoll över rollfördelning, beslut m.m.) </li>
        </ul>
        <p>4.	Se till att trafikledningen/växel öppnas/bemannas (om det är utanför kontorstid) och informera om vem som är företagets talesperson (namn och kontaktuppgifter)</p>
        <p>5.	Säkerställ att kundkontakter hanteras, prioriteras och sköts korrekt, oavsett om det är via telefon, e-post eller sociala medier</p>
        <p>6.	Säkerställ att medarbetare, uppdragsgivare och media informeras enligt bifogade kriskommunikationsrutiner</p>
        <p>7.	Skaffa kommunikationsvägar in till räddningstjänst, polis, sjukhus m.m.</p>
        <p>8.	Bestäm vem som tar hand om praktiska kontakter (fack, arbetsmiljöverket, försäkringsbolag, kunder, skola)</p>
        <p>9.	Bestäm vilka som ska besöka eventuella skadade på sjukhus</p>
        <p>10.	Vid medarbetares dödfall, följ separat checklista</p>
        <p>11.	Kalla vid behov till informationsmöte på arbetsplatsen samma dag. Se till att de medarbetare som vill ha hjälp och stöd får den hjälp de behöver.</p>
        <p>12.	Återsamla krisgruppen en gång i timmen, oftare vid behov, under den akuta fasen</p>
        <p>13.	Utvärdera kontinuerligt att arbetet fungerar tillfredsställande. Klarar varje ansvarig av sina områden eller behöver man hjälp i denna situation?</p>
        <p>14.	Undersök om medarbetare måste avlösas under dagen för att få möjlighet till samtal. Gäller såväl trafikledning som medlemmar i krisgruppen. Säg ifrån om du själv behöver en paus och delegera arbetet till din biträdande krisledare</p>
        <p>15.	Tag tidigt kontakt med övriga som berörts av olyckan och boka eventuella möten </p>
        <p>16.	När den akuta fasen är över är det viktigt att prioritera efterarbetet. Se sidan 5 i Krishanteringsplanen. </p>
        </div>)
    } 
}

export default Krisledare;
