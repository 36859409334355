import React from 'react';
import { Row, Col } from "react-bootstrap";
import BplMinaUppdrag from "./BplMinaUppdrag";

class BplMinaUppdragPage extends React.Component {
   
    render(){ return (
        <div className="container-fluid">
            <Row>
                <Col sm={12}>
                    <BplMinaUppdrag />

                </Col>
            </Row>
        </div>

    );
    }
}

  
  export default BplMinaUppdragPage;