import React from 'react';
import Button from 'components/CustomButtons/CustomButton.jsx';
import { connect } from "react-redux";
import { getVehicleCategories, getVehicleFaults } from "./actions";
import LoadingIcon from "components/utils/LoadingIcon";
import axios from "axios";
import  { infraConfig } from "config/infraConfig";
import { getAxiosConfig } from "system/axiosConfig";

import {
    FormGroup,
    FormControl,
    ControlLabel
  } from "react-bootstrap";

class NyttFordonsFelForm extends React.Component {
    
    constructor(props)
    {
        super(props);

        this.state = {
            category: '',
            categoryError: null,
            categoryValid: false,
            reportText: '',
            reportTextError: null,
            reportTextValid: false,
            addVehicleFaultResult: null,
            isLoadingAddVehicleFault: false
        }
    }
    componentDidMount(){
        this.props.getVehicleCategories();

    }
    
    handleCategoryChange(event) {
        this.setState({
            category: event.target.value
        }, () => {
            this.validateCategory();
        });
        
    }

    validateCategory() {
        this.state.category === '' 
        ? this.setState({
          categoryError: (
              <small className="text-danger">
                Du måste välja en kategori.
              </small>
            ),
            categoryValid: false,
          })
        : this.setState({ categoryError: null, categoryValid: true });
    }

    handleReportTextChange(event) {
        //console.log('text changed', event);
        this.setState({
            reportText: event.target.value
        }, () => {
            this.validateReportText();
        });

       
    }

    validateReportText() {
        this.state.reportText.length < 1
        ? this.setState({
          reportTextError: (
              <small className="text-danger">
                Felrapporten får inte vara tom.
              </small>
            ),
            reportTextValid: false
          })
        : this.setState({ reportTextError: null, reportTextValid: true});
    }

    handleSaveSubmit() {
        if (this.state.isLoadingAddVehicleFault) // dubbelklick
            return false;
        
        this.validateCategory();
        this.validateReportText();

        var fault = {
            faultCategoryNumber : this.state.category,
            vehicleNumber: this.props.selectedVehicleId,
            text: this.state.reportText,
        };
        
        //console.log('SAVE Nytt fordonskontroll', fault);

        if (this.state.categoryValid && this.state.reportTextValid)
            this.addVehicleFault(fault);
    }

    addVehicleFault(vehicleFault) {
            
        this.setState({
            isLoadingAddVehicleFault: true,
            addVehicleFaultResult: null
        }, () => {

            axios.post(infraConfig.apiEndpoint.concat('/api/fordonskontroll/addfault'), vehicleFault, getAxiosConfig(this.props.user))
            .then((dataResult) => {
                //console.log('after send success', dataResult);
                
                document.getElementById("fordonfel-form").reset();

                this.props.getVehicleFaults(this.props.selectedVehicleId);
                if (dataResult.data.result) {
                    this.setState({
                        addVehicleFaultResult: true,
                        isLoadingAddVehicleFault: false,
                        category: '',
                        reportText: ''
                });
                } else {
                    this.setState({
                        addVehicleFaultResult: false,
                        isLoadingAddVehicleFault: false,
                    });
                }
                

            })
            .catch((error) => { 
                console.log('ERROR');
               });
        });


        
    }
    

    render(){ 
        return (
            <form id="fordonfel-form">
                <FormGroup>
                    <ControlLabel>
                    Kategori: <span className="star">*</span>
                    </ControlLabel>
                    <LoadingIcon loadingFailed={this.props.loadingVehicleCategoriesFailed} isLoading={this.props.isLoadingVehicleCategories} success={
                        <FormControl
                        type="select"
                        componentClass="select"
                        name="category"
                        value={this.state.category}
                        className={this.state.categoryError !== null ? 'error' : null}
                        onChange={event => this.handleCategoryChange(event)}
                        >
                        <option value="">-- Välj en kategori --</option>
                        {this.props.vehicleCategories.map((item, key) => {
                            return <option key={key} value={item.id}>{item.name}</option>
                        })}
                        </FormControl>
                    } />
                    {this.state.categoryError}
                </FormGroup>
                <FormGroup>
                    <ControlLabel>
                    Felrapport: <span className="star">*</span>
                    </ControlLabel>
                    <FormControl
                    componentClass="textarea" placeholder=""
                    type="textarea"
                    value={this.state.reportText}
                    name="reportText"
                    className={this.state.reportTextError !== null ? 'error' : null}
                    onChange={event => this.handleReportTextChange(event)}
                    />
                    {this.state.reportTextError}
                </FormGroup>
                <FormGroup>
                    <Button
                    bsStyle="primary"
                    fill                    
                    onClick={this.handleSaveSubmit.bind(this)}
                >
                    <LoadingIcon isLoading={this.state.isLoadingAddVehicleFault} text="Sparar.." success="Spara" />
                </Button>
                </FormGroup>
                { this.state.addVehicleFaultResult === true ? <p className="alert alert-success">Felrapporten är sparad. Det kan ta några minuter för den att synas i listan över aktiva felrapporter.</p> : null }
                { this.state.addVehicleFaultResult === false ? <p className="alert alert-danger">Ett fel inträffade när felrapporten skulle sparas. Försök igen och om felet kvarstår kontakta support.</p> : null }
            </form>);
    }
}

function mapStateToProps(state) {
    return {
        user: state.oidc.user,
        vehicleCategories: state.fordonskontroll.vehicleCategories,
        isLoadingVehicleCategories: state.fordonskontroll.isLoadingvehicleCategories,
        loadingVehicleCategoriesFailed: state.fordonskontroll.loadingVehicleCategoriesFailed,
    };
  }
  
export default connect(mapStateToProps, { getVehicleCategories, getVehicleFaults })(NyttFordonsFelForm);