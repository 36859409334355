import React from "react";
import {
  FormGroup,
  ControlLabel,
  FormControl,
  HelpBlock,
  InputGroup
} from "react-bootstrap";

const FormElement = ({
  name,
  label,
  placeholder,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  readOnly,
  addonBefore
}) => {
  const wrapInputGroup = () => {
    if (addonBefore) {
      return (
        <InputGroup>
          {addonBefore && <InputGroup.Addon>{addonBefore}</InputGroup.Addon>}
          {getFormControl()}
        </InputGroup>
      );
    } else {
      return getFormControl();
    }
  };

  const getFormControl = () => {
    return (
      <FormControl
          name={name}
          type="text"
          value={values[name]}
          placeholder={placeholder}
          onChange={handleChange}
          onBlur={handleBlur}
        />
    )
  }

  return (
    <FormGroup
      controlId={name}
      validationState={
        readOnly
          ? null
          : errors && errors[name] && touched && touched[name]
          ? "error"
          : "success"
      }
    >
      <ControlLabel>{label}</ControlLabel>
      {!readOnly && (
        <React.Fragment>
          {wrapInputGroup()}
          <FormControl.Feedback />
          {errors && errors[name] && touched && touched[name] && (
            <HelpBlock>{errors[name]}</HelpBlock>
          )}
        </React.Fragment>
      )}
      {readOnly && (
        <FormControl.Static id={name}>{values[name]}</FormControl.Static>
      )}
    </FormGroup>
  );
};

export default FormElement;
