import React from 'react';

class Forare extends React.Component {

    render() {
        return(<div>
            <h4 className="nomargin">Du råkar ut för olycka med personskador/passagerare/förare</h4>
            <p>Larma trafikledare eller DLC/ BC. Får du inte svar ring alltid 112.</p>
            <p className="small-text"><label>Kom ihåg: </label>
            Vart har olyckan skett<br />
                        Vägnummer<br />
                        Ort/plats<br />
                        Vad har skett<br />
                        Finns personskador<br />
                        Finns risk att skadorna kan förvärras (brand explosion kläm)<br />
                        Patrullnummer polis på plats.
            </p>

            <p>Säkra platsen</p>
            <p className="small-text"><label>Kom ihåg: </label>Sätt ut varningstriangel så att andra trafikanter ej förvärrar olyckan</p>

            <p>Vid behov inled akut räddningsarbete (L-ABC)</p>
            <p className="small-text"><label>Kom ihåg: </label>Kolla om sjukvårdspersonal finns bland resenärerna</p>

            <p>Ge besked till resenärerna om vad som händer</p>
            <p className="small-text"><label>Kom ihåg: </label>Alla resenärer sitter kvar i bussen och vill få lägesinformation</p>

            <p>Prata inte med tidningar, TV mm utan hänvisa till Västanhede Trafiks VD alt SamBus VD</p>
            <p className="small-text"><label>Kom ihåg: </label>Säg gärna att Ni inte orkar svara på frågor just nu, ett svar som brukar accepteras i ett krisläge.</p>

            <h4>Om <strong>skadan är ringa</strong> och du ej är i behov av akut hjälp (inga passagerare), eller vid <strong>incident/tillbud</strong></h4>
            <p>Meddela trafikledningen alternativt DLC/BC<br />
            <strong className="text-danger">Kontakta din närmaste Arbetsledare</strong></p>
            <p className="small-text"><label>Kom ihåg: </label>Strukturera upp händelsen<br/>
            Ev. förseningar, bussbyten
            <br />Dokumentera olycksplatsen ev skador med din mobilkamera
            </p>
            </div>)
    } 
}

export default Forare;
