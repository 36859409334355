import { BPLGODKANNATID_GET_TIDRAPPORT, 
  BPLGODKANNATID_GET_TIDRAPPORT_SUCCESS,
  BPLGODKANNATID_GET_TIDRAPPORT_FAILED,
  BPLGODKANNATID_SEND_TIDRAPPORT,
  BPLGODKANNATID_SEND_TIDRAPPORT_SUCCESS,
  BPLGODKANNATID_SEND_TIDRAPPORT_FAILED
} from "./actions";

const initialState = {
    tidrapport: null,
    isLoadingTidrapport: false,
    isLoadingTidrapportFailed: false,
    tidrapportTimestamp: null,

    acceptTidrapport: false,
    isLoadingAcceptTidrapport: false,
    loadingAcceptTidrapportFailed: false

  };

export default function(state = initialState, action) {
  switch (action.type) {
    case BPLGODKANNATID_GET_TIDRAPPORT:
      return {...state, isLoadingTidrapport: true, loadingTidrapportFailed: false } 
    case BPLGODKANNATID_GET_TIDRAPPORT_SUCCESS:
      return {...state, isLoadingTidrapport: false, tidrapport: action.payload.data.result, tidrapportTimestamp: action.payload.data.timestamp } 
    case BPLGODKANNATID_GET_TIDRAPPORT_FAILED:
      return {...state, isLoadingTidrapport: false,isLoadingTidrapportFailed: true, tidrapport: null, tidrapportTimestamp: null } 
    case BPLGODKANNATID_SEND_TIDRAPPORT:
      return { ...state, isLoadingAcceptTidrapport:true, loadingAcceptTidrapportFailed: false }
    case BPLGODKANNATID_SEND_TIDRAPPORT_SUCCESS:
      return { ...state, isLoadingAcceptTidrapport:false, acceptTidrapport: action.payload.data.result.status }
    case BPLGODKANNATID_SEND_TIDRAPPORT_FAILED:
      return { ...state, isLoadingAcceptTidrapport:false, loadingAcceptTidrapportFailed: true, acceptTidrapport: false }
    default:
      return state;
  }
}