import React from 'react';
import { Grid, Row, Col} from 'react-bootstrap';
import Card from 'components/Card/Card.jsx';
import DocumentList from './DocumentList';

class PolicyDocs extends React.Component {
    render(){ 
        return (<Grid fluid>
            <Row>
                <Col lg={12}><Card content={<DocumentList />} /></Col>
            </Row>
        </Grid>);
    }
}

export default PolicyDocs;