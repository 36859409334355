import React from 'react';
import { connect } from "react-redux";
import Busschaufforer from "./CheckLists/Busschaufforer";
import KommunikationSamordnare from "./CheckLists/KommunikationSamordnare";
import Trafikledare from "./CheckLists/Trafikledare";
import Krisledare from "./CheckLists/Krisledare";
import { getClaimArray } from "system/security";

class CheckList extends React.Component {
    
        renderChecklist(positions){
            

            // positions kan vara endera sträng eller sträng[]
            // update 20191214 positions hämtas nu som en array så vi kan TODO refactora denna kod att alltid kolla efter en array

            /* 
            Om en person har flera befattningar så gäller denna rangordning (alltså om en person är både VD och bussförare så ska till exempel bara krisledarechecklistan visas):
            Krisledare -> Kommunikationssamordnare -> Trafikledare -> Busschaufförer.

            Krisledare:
            - VD
            - Regionchef
            Kommunikation:
            - Samordnare PR- och marknadskommunikation
            - HR-chef
            Trafikledare
            - Områdeschef
            - Trafikledare
            - Driftledare
            Busschauför:
            - Bussförare

            // ytterligare bussförare
            Fordonstekniker
            Verkstadsansvarig
            Ekonom
            Ekonomiassistent
            Fastighetsansvarig
            Extern
            Förstemekaniker
            Löneadministratör
            Lönespecialist
            */

          // console.log('is array', Array.isArray(positions));

           if (Array.isArray(positions)) {
               if ((positions.indexOf("VD") > -1) || (positions.indexOf("Regionchef") > -1)) {
                   return <Krisledare />
               } else if ((positions.indexOf("Samordnare PR- och marknadskommunikation") > -1) || (positions.indexOf("HR-chef") > -1)) {
                    return <KommunikationSamordnare />
               } else if ((positions.indexOf("Områdeschef") > -1) || (positions.indexOf("Trafikledare") > -1)|| (positions.indexOf("Driftledare") > -1)) {
                   return <Trafikledare />
                   
               } else if ((positions.indexOf("Bussförare") > -1)
               || (positions.indexOf("Fordonstekniker") > -1)
               || (positions.indexOf("Verkstadsansvarig") > -1)
               || (positions.indexOf("Ekonom") > -1)
               || (positions.indexOf("Ekonomiassistent") > -1)
               || (positions.indexOf("Fastighetsansvarig") > -1)
               || (positions.indexOf("Extern") > -1)
               || (positions.indexOf("Förstemekaniker") > -1)
               || (positions.indexOf("Löneadministratör") > -1)
               || (positions.indexOf("Lönespecialist") > -1)               
               ) {
                return <Busschaufforer />
                   
               }
            } else {
                if (positions === "VD" || positions === "Regionchef") {
                    return <Krisledare />
                } else if (positions === "Samordnare PR- och marknadskommunikation" || positions === "HR-chef") {
                    return <KommunikationSamordnare />
                } else if (positions === "Trafikledare" || positions === "Trafikledare" || positions === "Driftledare") {
                    return <Trafikledare />
                } else if (positions === "Bussförare"
                    || positions === "Fordonstekniker"
                    || positions === "Verkstadsansvarig"
                    || positions === "Ekonom"
                    || positions === "Ekonomiassistent"
                    || positions === "Fastighetsansvarig"
                    || positions === "Extern"
                    || positions === "Förstemekaniker"
                    || positions === "Löneadministratör"
                    || positions === "Lönespecialist"
                ) {
                    return <Busschaufforer />
                } else {
                    return null;
                }
           }

        }

        render(){ 
            return (
                <div>{this.renderChecklist(getClaimArray("IntraPosition", this.props.userprofile))}</div>
            );
    }
}



function mapStateToProps(state) {
    return {
        userprofile: state.userprofile.userprofile,
        user: state.oidc.user
    };
  }
  
  export default connect(mapStateToProps)(CheckList);