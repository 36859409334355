import { infraConfig } from './infraConfig';
import logo from "assets/customer/falcks/logo-small.png";
import sideBarBg from "assets/customer/falcks/sidebar-bg.jpg";
import HeaderLinks from "./HeaderLinks";
import dashboardRoutes from './dashboard';

export const appConfig = {
    name:'falcksprod',
    instrumentationKey: '3491a8ed-dac2-4257-8a9e-ba19250ca866',
    title: "Min Sida", 
    infra: infraConfig,
    dashboard: dashboardRoutes,
    logoSmall: logo,
    sideBarBg: sideBarBg,
    headerLinks: HeaderLinks,
    disableFK: true
}