import {
    FORDONSKONTROLL_GET_VEHICLES, 
    FORDONSKONTROLL_GET_VEHICLES_SUCCESS, 
    FORDONSKONTROLL_GET_VEHICLES_FAILED,
    FORDONSKONTROLL_GET_VEHICLECATEGORIES,
    FORDONSKONTROLL_GET_VEHICLECATEGORIES_SUCCESS,
    FORDONSKONTROLL_GET_VEHICLECATEGORIES_FAILED,
    FORDONSKONTROLL_GET_VEHICLEFAULTS,
    FORDONSKONTROLL_GET_VEHICLEFAULTS_SUCCESS,
    FORDONSKONTROLL_GET_VEHICLEFAULTS_FAILED,
    FORDONSKONTROLL_ADD_VEHICLEFAULT,
    FORDONSKONTROLL_ADD_VEHICLEFAULT_SUCCESS,
    FORDONSKONTROLL_ADD_VEHICLEFAULT_FAILED,


} from "./actions";
  
const initialState = {
    vehicles: [],
    vehiclesTimestamp: null,
    isLoadingVehicles: false,
    loadingVehiclesFailed: false,

    vehicleCategories: [],
    vehicleCategoriesTimestamp: null,
    isLoadingVehicleCategories: false,
    loadingVehicleCategoriesFailed:false,

    vehicleFaults:[],
    vehicleFaultsTimestamp: null,
    isLoadingVehicleFaults: false,
    loadingVehicleFaultsFailed: false,

    addVehicleFaultResult: null,
    addVehicleFaultTimestamp: null,
    isLoadingAddVehicleFault: false,
    addVehicleFaultFailed: false,

};
  
export default function(state = initialState, action) {
    switch (action.type) {
        case FORDONSKONTROLL_GET_VEHICLES:
            return {...state, isLoadingVehicles: true, loadingVehiclesFailed: false } 
        case FORDONSKONTROLL_GET_VEHICLES_SUCCESS:
            return {...state, isLoadingVehicles: false, vehicles: action.payload.data.result, vehiclesTimestamp: action.payload.data.timestamp } 
        case FORDONSKONTROLL_GET_VEHICLES_FAILED:
            return {...state, isLoadingVehicles: false,loadingVehiclesFailed: true, vehicles: [], vehiclesTimestamp: null } 

        case FORDONSKONTROLL_GET_VEHICLECATEGORIES:
            return {...state, isLoadingVehicleCategories: true, loadingVehicleCategoriesFailed: false } 
        case FORDONSKONTROLL_GET_VEHICLECATEGORIES_SUCCESS:
            return {...state, isLoadingVehicleCategories: false, vehicleCategories: action.payload.data.result, vehicleCategoriesTimestamp: action.payload.data.timestamp } 
        case FORDONSKONTROLL_GET_VEHICLECATEGORIES_FAILED:
            return {...state, isLoadingVehicleCategories: false,loadingVehicleCategoriesFailed: true, vehicleCategories: [], vehicleCategoriesTimestamp: null } 

        case FORDONSKONTROLL_GET_VEHICLEFAULTS:
            return {...state, isLoadingVehicleFaults: true, loadingVehicleFaultsFailed: false, vehicleFaults:[] } 
        case FORDONSKONTROLL_GET_VEHICLEFAULTS_SUCCESS:
            return {...state, isLoadingVehicleFaults: false, vehicleFaults: action.payload.data.result, vehicleFaultsTimestamp: action.payload.data.timestamp } 
        case FORDONSKONTROLL_GET_VEHICLEFAULTS_FAILED:
            return {...state, isLoadingVehicleFaults: false,loadingVehicleFaultsFailed: true, vehicleFaults: [], vehicleFaultsTimestamp: null } 

        case FORDONSKONTROLL_ADD_VEHICLEFAULT:
            return {...state, isLoadingAddVehicleFault: true, addVehicleFaultFailed: false, addVehicleFaultResult:null } 
        case FORDONSKONTROLL_ADD_VEHICLEFAULT_SUCCESS:
            return {...state, isLoadingAddVehicleFault: false, addVehicleFaultResult: action.payload.data.result, addVehicleFaultTimestamp: action.payload.data.timestamp } 
        case FORDONSKONTROLL_ADD_VEHICLEFAULT_FAILED:
            return {...state, isLoadingAddVehicleFault: false,addVehicleFaultFailed: true, addVehicleFaultResult: null, addVehicleFaultTimestamp: null } 

        default:
            return state;
    }
}