import { appConfig } from "config/appConfig";

export const getClaimArray = (typename, claims) => {
  var matches = claims.filter((obj) => {
    return obj.type === typename;
  });

  var result = [];
  for (var i = 0; i < matches.length; i++) {
    result.push(matches[i].value);
  }
  return result;
}

export const getClaimSingle = (typename, claims) => {
 
  var matches = claims.filter((obj) => {
        return obj.type === typename;
      });

  if (matches.length > 0)
    return matches[0].value;
  else
    return "";
}


// used directly in sidebar to check if a route is accessible by user
export const checkDashboardAccess = (dashboardRoute, userprofile) => {
  var result = true;
  var profileClaim = undefined;

  if (dashboardRoute.requireClaim !== undefined) {

    // console.log('check security', dashboardRoute, user);
    // only supports one claim for now
    // route requires a certain claim to be present
    // console.log('vill ha claim',user.profile[dashboardRoute.requireClaim]);
    profileClaim = userprofile.filter(item => item.type === dashboardRoute.requireClaim)[0];
    if (profileClaim === undefined) {
      result = false;
    }

  }

  if (dashboardRoute.requireClaimValue !== undefined) {
    // console.log('check security', dashboardRoute, user);
    // only supports one claim for now
    // route requires a cenrtain claim value to be set

    profileClaim = userprofile.filter(item => item.type === dashboardRoute.requireClaimValue[0])[0];

    if (profileClaim === undefined)
    {
      result = false;
    } else {
      var claimValue = profileClaim.value; // fitter eller undefined

      if (claimValue !== dashboardRoute.requireClaimValue[1]){
        result = false;
      }
  
    }

    }

  return result;
}

export const checkDashboardRouteAccess = (routes, userprofile) => {
  console.log('check route', routes, userprofile);
  var returnValue = true;
  var foundReturnValue = false;
  
  routes.forEach((route) => {

    if (!foundReturnValue)
    {
      // find route and check if user has access, used in main to match location
      var matchingRoutes = appConfig.dashboard.filter((obj) => {
        return obj.path === route.path;
      });

      if (matchingRoutes.length > 0)
      {
        // we found a route that matches our dashboard array, should only be one, check if we have access to it
          console.log('route', matchingRoutes);
          returnValue = checkDashboardAccess(matchingRoutes[0], userprofile);
          foundReturnValue = true;
      }

    }

  });

  return returnValue; 

}