import {
  UTBILDNING_GET_INTYG,
  UTBILDNING_GET_INTYG_SUCCESS,
  UTBILDNING_GET_INTYG_FAILED,
  UTBILDNING_GET_UTVARDERING,
  UTBILDNING_GET_UTVARDERING_SUCCESS,
  UTBILDNING_GET_UTVARDERING_FAILED,
  UTBILDNING_GET_DOWNLOAD,
  UTBILDNING_GET_DOWNLOAD_SUCCESS,
  UTBILDNING_GET_DOWNLOAD_FAILED,
} from "./actions";
const initialState = {
    intyg: [],
    isLoadingintyg: false,
    loadingintygFailed: false,

    utvardering: [],
    isLoadingutvardering: false,
    loadingutvarderingFailed: false,
};
export default function(state = initialState, action) {
    switch (action.type) {
        case UTBILDNING_GET_INTYG:
            return {...state, isLoadingintyg: true, loadingintygFailed: false }
        case UTBILDNING_GET_INTYG_SUCCESS:
            return {...state, isLoadingintyg: false, intyg: action.payload.data.result }
        case UTBILDNING_GET_INTYG_FAILED:
            return {...state, isLoadingintyg: false,loadingintygFailed: true, intyg: [] }

            case UTBILDNING_GET_UTVARDERING:
                return {...state, isLoadingutvardering: true, loadingutvarderingFailed: false }
            case UTBILDNING_GET_UTVARDERING_SUCCESS:
                return {...state, isLoadingutvardering: false, utvardering: action.payload.data.result }
            case UTBILDNING_GET_UTVARDERING_FAILED:
                return {...state, isLoadingutvardering: false,loadingutvarderingFailed: true, utvardering: [] }

                case UTBILDNING_GET_DOWNLOAD:
                    return {...state, isLoadingdownload: true, loadingdownloadFailed: false }
                case UTBILDNING_GET_DOWNLOAD_SUCCESS:
                    return {...state, isLoadingdownload: false, download: action.payload.data.result }
                case UTBILDNING_GET_DOWNLOAD_FAILED:
                    return {...state, isLoadingdownload: false,loadingdownloadFailed: true, download: [] }

        default:
            return state;
    }
  }
