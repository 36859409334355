import React from 'react';
import { downloadFile } from "system/util";

class AttachmentListItem extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            isDownloading: false
        }
    }

    renderIcon(extension) {

        return extension === "pdf" 
            ? <i className="fa fa-file-pdf-o"></i>
            : extension === "docx" ? <i className="fa fa-file-word-o"></i>
            : <i className="fa fa-file-o"></i>
    }
    downloadAttachment(url) {
        
        console.log(url);
        downloadFile(url);

        // visa att vi försöker ladda ner
        this.setState({
            isDownloading: true

        }, () => {

            setTimeout(() => {
                this.setState({
                    isDownloading: false
                });
            
            },3000);
        })

    }
    render() {
        return this.state.isDownloading 
        ? <li className="list-group-item"><i className="fa fa-spin fa-spinner"></i> Laddar ner..</li> 
        : <li className="list-group-item list-group-item-download-file"
            onClick={() => { this.downloadAttachment(this.props.attachment.fileUrl)}}>{this.renderIcon(this.props.attachment.fileExtension)} {this.props.attachment.fileName}</li>
        }
}
export default AttachmentListItem;