import axios from "axios";
import  { infraConfig } from "config/infraConfig";
import { getAxiosConfig } from "system/axiosConfig";
import store from "system/store";
import { ERRORMESSAGES_ADD } from "system/actions";
// import { openFileWindow } from "system/util.js";


export const KONTEK_LONEBESKED_GET_LONESPEC = "LONEBESKED_KONTEK_LONEBESKED_GET_LONESPECGET_LONESPEC";
export const KONTEK_LONEBESKED_GET_LONESPEC_SUCCESS = "KONTEK_LONEBESKED_GET_LONESPEC_SUCCESS";
export const KONTEK_LONEBESKED_GET_LONESPEC_FAILED = "KONTEK_LONEBESKED_GET_LONESPEC_FAILED";

export function getLonespec() {
    return (dispatch) => {
        dispatch({type: KONTEK_LONEBESKED_GET_LONESPEC});

        const user = store.getState().oidc.user;
        axios.get(infraConfig.apiEndpoint.concat('/api/lonespec/kontek/getspeclist'), getAxiosConfig(user))
        .then((dataResult) => dispatch({type: KONTEK_LONEBESKED_GET_LONESPEC_SUCCESS, payload: dataResult}))
        .catch((error) => { dispatch({type: KONTEK_LONEBESKED_GET_LONESPEC_FAILED}); dispatch({type: ERRORMESSAGES_ADD, payload: { type: 'network', message: 'Hämtning av lönespecifikationer misslyckades', severity: 'warning'}});});

    }

}
