import React from 'react';
import UppdragTable from "./UppdragTable";
import HuvudUppdragTable from './HuvudUppdragTable';
import Card from 'components/Card/Card.jsx';
import { connect } from "react-redux";
import { fetchBplUppdrag } from "./actions";
import LoadingIcon from "components/utils/LoadingIcon";
import Date from "components/formatting/Date";
import Button from "components/CustomButtons/CustomButton";
import {ButtonGroup} from "react-bootstrap";
import BplExternalLink from "components/utils/BplExternalLink";
import MobileDesktop from "components/utils/MobileDesktop";
import UppdragCards from "./UppdragCards";
import HuvudUppdragCards from "./HuvudUppdragCards";
import {
    FormGroup
  } from "react-bootstrap";
  import { LinkContainer } from "react-router-bootstrap";

import Datetime from 'react-datetime';
import { getClaimSingle} from "system/security";
import moment from 'moment';
import 'moment/locale/sv';

moment().locale('sv');

class BplMinaUppdrag extends React.Component {
    constructor(props)
    {
        super(props);
        

        this.state = {
            showDetails: false,
            showFramAter: true,
            uppdragDate: new Date()
        }
        this.handleDetailsCheckbox = this.handleDetailsCheckbox.bind(this);
        this.handleFramAterCheckbox = this.handleFramAterCheckbox.bind(this);
        this.handleUppdragDateChange = this.handleUppdragDateChange.bind(this);
    }
    componentDidMount() {
        this.props.fetchBplUppdrag(moment(this.state.uppdragDate).toISOString());
    }
    handleDetailsCheckbox(){
        // console.log(this);
          
        this.setState(prevState => ({
            showDetails: !prevState.showDetails
          }));
    }
    handleFramAterCheckbox() {
        this.setState(prevState => ({
            showFramAter: !prevState.showFramAter
          }));
    }
    handleUppdragDateChange(date) {
        this.setState({
            uppdragDate: date
        }, () => {
            this.props.fetchBplUppdrag(moment(this.state.uppdragDate).toISOString());
        });
    }
    render(){ 
        const linkKororder = "client/utskrift/specifikation.aspx?personalid="
        .concat(getClaimSingle("BplUserId", this.props.userprofile))
        .concat("&datum=")
        .concat(moment(this.state.uppdragDate).format('YYYY-MM-DD'));    
        return (            
            <MobileDesktop desktopView = {
                    
                <Card title="Mina uppdrag"
                tableFullWidth                             
                category={this.state.showDetails ? <span>Klicka på en rad för att återrapportera</span> : <span>Visa detaljerad vy för att återrapportera</span>}
                commandBar={
                    <div className="form-inline">
                       <FormGroup>
                            <Datetime
                                inputProps={{ placeholder: "Datum", className: "form-control text-center" }}
                                dateFormat="YYYY-MM-DD"
                                timeFormat={false}
                                closeOnSelect={true}
                                onChange={this.handleUppdragDateChange}
                                value={this.state.uppdragDate}
                                className="datetime-width"
                                />
                       </FormGroup>
                       <FormGroup>
                           <BplExternalLink bsStyle="primary" to={linkKororder} title="Körorder" />&nbsp;
                       </FormGroup>
                       <FormGroup>
                           <LinkContainer to="/dashboard/schema"><Button fill bsStyle="primary">Schema</Button></LinkContainer>&nbsp;
                       </FormGroup>
                        <ButtonGroup>
                            <Button fill={this.state.showDetails} bsStyle="primary" onClick={this.handleDetailsCheckbox}><i className="fa fa-list" /> Detaljer</Button>
                            <Button fill={this.state.showFramAter} bsStyle="primary" onClick={this.handleFramAterCheckbox}><i className="fa fa-random" /> Fram-/återkörningar</Button>
                        </ButtonGroup>
                        </div>
                    }
                content={
                    this.state.showDetails ? <UppdragTable showFramAter={this.state.showFramAter} uppdrag={this.props.uppdrag} /> : <HuvudUppdragTable uppdrag={this.props.uppdrag} />
                }
                stats={<LoadingIcon text="Laddar uppdrag.." 
                    isLoading={this.props.isLoadingUppdrag} 
                    loadingFailed={this.props.loadingUppdragFailed} 
                    success={<span>Uppdaterat: <Date withTime value={this.props.uppdragTimestamp} /></span>} />}
                    />
                } 
                mobileView = {
                    <Card title="Mina uppdrag"                                             
                        category={this.state.showDetails ? <span>Klicka på en rad för att återrapportera</span> : <span>Visa detaljerad vy för att återrapportera</span>}                
                content={
                    <div>
                        <div>
                        <Datetime
                                inputProps={{ placeholder: "Datum", className: "form-control text-center" }}
                                dateFormat="YYYY-MM-DD"
                                timeFormat={false}
                                closeOnSelect={true}
                                onChange={this.handleUppdragDateChange}
                                value={this.state.uppdragDate}
                                className="datetime-width"
                                />
                            <BplExternalLink block bsStyle="primary" to={linkKororder} title="Körorder" />
                            <LinkContainer to="/dashboard/schema"><Button fill block bsStyle="primary">Schema</Button></LinkContainer>
                            <Button block fill={this.state.showDetails} bsStyle="primary" onClick={this.handleDetailsCheckbox}><i className="fa fa-list" /> Detaljer</Button>
                            <Button block fill={this.state.showFramAter} bsStyle="primary" onClick={this.handleFramAterCheckbox}><i className="fa fa-random" /> Fram-/återkörningar</Button>
                        </div><br />

                    {this.state.showDetails ? <UppdragCards showFramAter={this.state.showFramAter} uppdrag={this.props.uppdrag} /> : <HuvudUppdragCards uppdrag={this.props.uppdrag} />}
                    </div>
                }
                stats={<LoadingIcon text="Laddar uppdrag.." 
                    isLoading={this.props.isLoadingUppdrag} 
                    loadingFailed={this.props.loadingUppdragFailed} 
                    success={<span>Uppdaterat: <Date withTime value={this.props.uppdragTimestamp} /></span>} />}
                    />
                }  />);
    }
}

function mapStateToProps(state) {
     return {
        user: state.oidc.user,
        userprofile: state.userprofile.userprofile,
        uppdrag: state.bpluppdrag.uppdrag,
        uppdragTimestamp: state.bpluppdrag.uppdragtimestamp,
        isLoadingUppdrag: state.bpluppdrag.isLoadingUppdrag,
        loadingUppdragFailed: state.bpluppdrag.loadingUppdragFailed
    };
  }
  
  export default connect(mapStateToProps, { fetchBplUppdrag })(BplMinaUppdrag);