import React from "react";
import { connect } from "react-redux";
import { Grid, Col, Row } from "react-bootstrap";
import SimpleStandaloneHeader from "components/utils/SimpleStandaloneHeader";
import Reset from "components/Support/Reset"
// import Debuginfo from "components/Support/Debuginfo";

class HelpPage extends React.Component {
    
  render() {
    // just redirect to '/' in both cases
    return (
      <div className="main-content">
      <SimpleStandaloneHeader />
        <Grid fluid>
          <Row>
            <Col sm={12}>
            <Reset />
            </Col>
          </Row>
          {/* <Row>
            <Col sm={12}>
              <Debuginfo />
            </Col>
          </Row> */}
      </Grid>
      </div>
    );
  }
}

export default connect()(HelpPage);