import React, { Component } from "react";
import CustomButton from "../CustomButtons/CustomButton.jsx";
import LoadingIcon from "./LoadingIcon";
import  { infraConfig } from "config/infraConfig";
import { openInNewTabOrDont as openInNewTab } from "./openInNewTabOrDont";

class DocsExternalLink extends Component {

    constructor(props)
    {
        super(props);

        this.handleClick = this.handleClick.bind(this);
        this.state = {
            isLoading: false
        }
    }
    // rendera en länk som vars klick anropar linktoken, och sen login.aspx?bp
    handleClick() {
        // toggle the side-nav-bar in mobile mode
        document.documentElement.classList.toggle("nav-open");

        var url = infraConfig.documentsUrl.concat('/').concat(this.props.to);
        openInNewTab(url);
    }

    render() {
        return (
                this.props.showAsText ? <div onClick={this.handleClick}>{this.props.icon !== undefined ? this.props.icon : <i className="fa fa-external-link" />} {this.props.title } { this.state.isLoading ? <LoadingIcon  isLoading={this.state.isLoading} /> : null}</div> 
                : <CustomButton bsStyle={this.props.bsStyle} fill onClick={this.handleClick}>
                    {this.state.isLoading ? <LoadingIcon isLoading={this.state.isLoading} text="Öppnar.." /> :  this.props.title }
                    </CustomButton>
            
        );
    }
}

  export default DocsExternalLink;