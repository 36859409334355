import axios from "axios";
import  { infraConfig } from "config/infraConfig";
import { getAxiosConfig } from "system/axiosConfig";
import store from "system/store";
import { ERRORMESSAGES_ADD } from "system/actions";
import { openFileWindow } from "system/util.js";


export const LONEBESKED_GET_LONESPEC = "LONEBESKED_GET_LONESPEC";
export const LONEBESKED_GET_LONESPEC_SUCCESS = "LONEBESKED_GET_LONESPEC_SUCCESS";
export const LONEBESKED_GET_LONESPEC_FAILED = "LONEBESKED_GET_LONESPEC_FAILED";

export const LONEBESKED_GET_DOWNLOAD = "LONEBESKED_GET_DOWNLOAD";
export const LONEBESKED_GET_DOWNLOAD_SUCCESS = "LONEBESKED_GET_DOWNLOAD_SUCCESS";
export const LONEBESKED_GET_DOWNLOAD_FAILED = "LONEBESKED_GET_DOWNLOAD_FAILED";

export function getLonespec() {
    return (dispatch) => {
        dispatch({type: LONEBESKED_GET_LONESPEC});

        const user = store.getState().oidc.user;
        axios.get(infraConfig.apiEndpoint.concat('/api/lonespec/get'), getAxiosConfig(user))
        .then((dataResult) => dispatch({type: LONEBESKED_GET_LONESPEC_SUCCESS, payload: dataResult}))
        .catch((error) => { dispatch({type: LONEBESKED_GET_LONESPEC_FAILED}); dispatch({type: ERRORMESSAGES_ADD, payload: { type: 'network', message: 'Hämta Lönespec misslyckades', severity: 'warning'}});});

    }

}

export function downloadLonespec(filnamn) {
    return (dispatch) => {
        dispatch({type: LONEBESKED_GET_DOWNLOAD});

        const user = store.getState().oidc.user;
        axios.get(infraConfig.apiEndpoint.concat('/api/lonespec/download?filnamn=').concat(filnamn), getAxiosConfig(user))
        .then((dataResult) => {
            dispatch({type: LONEBESKED_GET_DOWNLOAD_SUCCESS, payload: dataResult});
            openFileWindow(dataResult.data.result);

        })
        .catch((error) => { dispatch({type: LONEBESKED_GET_DOWNLOAD_FAILED}); dispatch({type: ERRORMESSAGES_ADD, payload: { type: 'network', message: 'Hämta Lönespec misslyckades', severity: 'warning'}});});

    }

}
