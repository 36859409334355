import { connect } from "react-redux";
import NewsItem from "./NewsItem";
import React from 'react';
import Card from 'components/Card/Card';

import axios from "axios";
import  { infraConfig } from "config/infraConfig";
import { getAxiosConfig } from "system/axiosConfig";

class KoncernNewsShowFull extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            news: undefined,
            loading: false,
            loadingFailed: false
        }
    }

    componentDidMount() {        

        this.setState({
            loading: true,
            loadingFailed: false,
        }, () => {
            axios.get(infraConfig.apiEndpoint.concat('/api/nyheter/getkoncern/').concat(this.props.params.newsId), getAxiosConfig(this.props.user))
            .then((dataResult) => {
                console.log(dataResult);
    
                this.setState({
                    news: dataResult.data.result,
                    loading: false,
                    loadingFailed: false
                })
            })
            .catch((error) => { 
                this.setState({
                    loading: false,
                    loadingFailed: true
                })
            });
    
        });

    }
    render() {

        return <div className="container-fluid">
            <div className="row">
                <div className="col-xs-12">
                    <Card content={
                        <div>
                            { this.state.loadingFailed ? <p><i className="fa fa-exclamation-triangle text-danger"></i> Ett fel inträffade</p> : null}
                            { this.state.loading ? <p><i className="fa fa-spinner fa-spin"></i> Laddar nyhet..</p> : null}
                            { this.state.news ? <NewsItem item={this.state.news} /> : null }</div>
                    } />
                </div>               
            </div>
            </div>
    }
}


function mapStateToProps(state) {
    return {
        user: state.oidc.user,    
    };
  }
  
export default connect(mapStateToProps)(KoncernNewsShowFull);
