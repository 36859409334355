import React from 'react';
import { getIntyg, downloadIntyg } from "./actions";
import { connect } from "react-redux";
import Button from 'components/CustomButtons/CustomButton.jsx';
class ShowIntyg extends React.Component {
  handleSaveSubmit(test) {
    this.props.downloadIntyg(test);
  }
    render(){
        return (
              <table className="table table-striped">
                <tbody>
                    {this.props.intyg.map((item, key) => {
                        return <tr key={key}><td><p>{item.linkFilename}</p></td><td><Button fill bsStyle="primary" onClick={this.handleSaveSubmit.bind(this,item.url)} ><span className="fa fa-download"></span> Ladda ner</Button></td></tr>
                    })}
                    </tbody>
              </table>
            );
    }
}
function mapStateToProps(state) {
    return {

    };
  }
export default connect(mapStateToProps, { getIntyg, downloadIntyg })(ShowIntyg);
