// formuläret från startsidan (genvägen) det förenklade
import { connect } from "react-redux";
import axios from "axios";
import { infraConfig } from "config/infraConfig";
import { getAxiosConfig } from "system/axiosConfig";
import Switch from "react-bootstrap-switch";
import SelectVehicleForm from "./SelectVehicleForm";

import SavingButton from "components/CustomButtons/SavingButton.jsx";
import Datetime from "react-datetime";
import React, { Component } from "react";
import { FormGroup, Col, Row, FormControl, ControlLabel } from "react-bootstrap";
import Checkbox from "components/CustomCheckbox/CustomCheckbox.jsx";
import moment from "moment";
import "moment/locale/sv";

class FormFordonsskada extends Component {
	constructor(props) {
		super(props);

		moment().locale("sv");

		this.state = this.getInititalState();

		this.handleFormSave = this.handleFormSave.bind(this);
		this.handleSelectVehicle = this.handleSelectVehicle.bind(this);

		this.datumIsValid = true;
		this.tidIsValid = true;
		this.platsIsValid = true;
		this.forsakringskategoriIsValid = true;
		this.handelseBeskrivningIsValid = true;
		this.skadeplatskategoriIsValid = true;
		this.antalPassagerareIsvalid = true;
		this.matarstallningIsValid = true;
		this.fordonIsValid = true;
		this.vagensBreddIsvalid = true;
		this.estimatedDistanceRightHandSideOfRoadIsvalid = true;

		this.kategorispecOptions = [
			"Singelolycka",
			"Vilt eller djurolycka",
			"Parkeringsskada",
			"Kollision",
			"Smitningsolycka",
			"Motor eller elektronik",
			"Glasskada",
			"Skadegörelse",
			"Annat",
		];
	}

	getInititalState = () => {
		const initialState = {
			datumError: null,
			forsakringskategoriError: null,
			handelseBeskrivningError: null,
			datum: "",
			tid: "",
			tidError: null,
			plats: "",
			platsError: null,
			skadeplatskategori: [],
			skadeplatskategoriError: null,
			handelseBeskrivning: "",
			fordon: "",
			fordonError: null,
			polis: false,
			raddningstjanst: false,
			skadapafordon: false,
			blodprov: false,
			forsakringskategori: "",
			kategorispecificering: "",
			ersattningsfordon: false,
			synligaskadorbool: false,
			synligaskador: "",
			personskadabool: false,
			personskada: "",
			vittnen: "",
			matarstallning: "",
			matarstallningError: null,
			antalpassagerare: undefined,
			antalpassagerareError: null,
			motpartkontaktuppgifter: "",
			motpartkontaktuppgifterRegnr: "",
			kategori: "",
			savingStatus: 0,
			file: null,
			attachmentNames: [],
			vagensBredd: "",
			vagensBreddError: null,
			estimatedDistanceRightHandSideOfRoad: "",
			estimatedDistanceRightHandSideOfRoadError: null,
		};
		return initialState;
	};

	validatePlats() {
		if (this.state.plats.length > 0) {
			this.platsIsValid = true;
			this.setState({
				platsError: null,
			});
		} else {
			this.platsIsValid = false;
			this.setState({
				platsError: "Plats måste anges",
			});
		}
	}

	validateDatum() {
		var parsed = moment(this.state.datum, "YYYY-MM-DD", true);

		if (parsed.isValid() && this.state.datum !== undefined && this.state.datum !== "") {
			this.datumIsValid = true;
			this.setState({
				datumError: null,
			});
		} else {
			this.datumIsValid = false;
			this.setState({
				datumError: "Datum har fel format",
			});
		}
	}
	validateTid() {
		var parsed = moment(this.state.tid, "HH:mm", true);

		if (parsed.isValid() && this.state.tid !== undefined && this.state.tid !== "") {
			this.tidIsValid = true;
			this.setState({
				tidError: null,
			});
		} else {
			this.tidIsValid = false;
			this.setState({
				tidError: "Tid har fel format",
			});
		}
	}
	validateForsakringskategori() {
		if (this.state.forsakringskategori !== "" && this.state.forsakringskategori !== undefined) {
			this.forsakringskategoriIsValid = true;
			this.setState({
				forsakringskategoriError: null,
			});
		} else {
			this.forsakringskategoriIsValid = false;
			this.setState({
				forsakringskategoriError: "Försäkringskategori måste väljas",
			});
		}
	}

	validateHandelseBeskrivning() {
		if (this.state.handelseBeskrivning.length > 0) {
			this.handelseBeskrivningIsValid = true;
			this.setState({
				handelseBeskrivningError: null,
			});
		} else {
			this.handelseBeskrivningIsValid = false;
			this.setState({
				handelseBeskrivningError: "Händelsebeskrivning måste anges",
			});
		}
	}
	validateSkadeplatskategori() {
		if (this.state.skadeplatskategori.length > 0) {
			this.skadeplatskategoriIsValid = true;
			this.setState({
				skadeplatskategoriError: null,
			});
		} else {
			this.skadeplatskategoriIsValid = false;
			this.setState({
				skadeplatskategoriError: "Skadeplatskategori måste anges",
			});
		}
	}
	validateAntalPassagerare() {
		if (
			!isNaN(this.state.antalpassagerare) ||
			this.state.antalpassagerare === "" ||
			this.state.antalpassagerare === undefined ||
			this.state.antalpassagerare === null
		) {
			this.antalPassagerareIsvalid = true;
			this.setState({
				antalpassagerareError: null,
			});
		} else {
			this.antalPassagerareIsvalid = false;
			this.setState({
				antalpassagerareError: "Antal passagerare innehåller inte en giltig siffra",
			});
		}
	}
	validateVagensBredd() {
		if (!isNaN(this.state.vagensBredd) || this.state.vagensBredd === "" || this.state.vagensBredd === undefined || this.state.vagensBredd === null) {
			this.vagensBreddIsvalid = true;
			this.setState({
				vagensBreddError: null,
			});
		} else {
			this.vagensBreddIsvalid = false;
			this.setState({
				vagensBreddError: "Vägens ungefärliga bredd innehåller inte en giltig siffra",
			});
		}
	}
	validateEstimatedDistanceRightHandSideOfRoad() {
		if (
			!isNaN(this.state.estimatedDistanceRightHandSideOfRoad) ||
			this.state.estimatedDistanceRightHandSideOfRoad === "" ||
			this.state.estimatedDistanceRightHandSideOfRoad === undefined ||
			this.state.estimatedDistanceRightHandSideOfRoad === null
		) {
			this.estimatedDistanceRightHandSideOfRoadIsvalid = true;
			this.setState({
				estimatedDistanceRightHandSideOfRoadError: null,
			});
		} else {
			this.estimatedDistanceRightHandSideOfRoadIsvalid = false;
			this.setState({
				estimatedDistanceRightHandSideOfRoadError: "Ungefärligt avstånd till vägens högerkant innehåller inte en giltig siffra",
			});
		}
	}
	validateMatarstallning() {
		if (
			!isNaN(this.state.matarstallning) ||
			this.state.matarstallning === "" ||
			this.state.matarstallning === undefined ||
			this.state.matarstallning === null
		) {
			this.matarstallningIsValid = true;
			this.setState({
				matarstallningError: null,
			});
		} else {
			this.matarstallningIsValid = false;
			this.setState({
				matarstallningError: "Mätarställning innehåller inte en giltig siffra",
			});
		}
	}
	validateFordon() {
		console.log("validate fordon", this.state.fordon);
		if (!this.props.disableFK) {
			var vehicleExists = this.checkIfVehicleExists(this.state.fordon);

			if (vehicleExists) {
				this.fordonIsValid = true;
				this.setState({
					fordonError: null,
				});
			} else {
				this.fordonIsValid = false;
				this.setState({
					fordonError: "Ett giltigt fordon måste väljas från listan",
				});
			}
		} else {
			if (this.state.fordon === null || this.state.fordon === "" || this.state.fordon === undefined) {
				this.setState({
					fordonError: "Ange för vilket fordon skadan gäller",
				});
			} else {
				this.fordonIsValid = true;
			}
		}
	}

	handleSelectVehicle(vehicleName) {
		console.log("byta fordon", vehicleName);

		if (vehicleName !== this.state.fordon) {
			this.setState(
				{
					fordon: vehicleName,
				},
				() => {
					this.validateFordon();
				}
			);
		}
	}

	handleFordonChange = (e) => {
		this.setState({
			fordon: e.target.value,
			fordonError: null,
		});
	};

	handleDatumChange(date) {
		this.setState(
			{
				datum: date,
			},
			() => {
				this.validateDatum();
			}
		);
	}
	handleTidChange(date) {
		this.setState(
			{
				tid: date,
			},
			() => {
				this.validateTid();
			}
		);
	}

	handlePlatsChange(event) {
		this.setState(
			{
				plats: event.target.value,
			},
			() => {
				this.validatePlats();
			}
		);
	}

	handleHandelseBeskrivningChange(event) {
		this.setState({ handelseBeskrivning: event.target.value }, () => {
			this.validateHandelseBeskrivning();
		});
	}
	handleSkadeplatskategoriChange(event, rowItem) {
		var newSkadeplatskategori = this.state.skadeplatskategori;

		if (event.target.checked) {
			newSkadeplatskategori.push(rowItem);
		} else {
			newSkadeplatskategori = newSkadeplatskategori.filter((item) => item.id !== rowItem.id);
		}

		this.setState(
			{
				skadeplatskategori: newSkadeplatskategori,
			},
			() => {
				this.validateSkadeplatskategori();
			}
		);
	}

	handlePolisChange(el, value) {
		this.setState({
			polis: value,
		});
	}
	handleRaddningstjanstChange(el, value) {
		this.setState({
			raddningstjanst: value,
		});
	}
	handleSkadaPaFordonChange(el, value) {
		this.setState({
			skadapafordon: value,
		});
	}
	handleBlodProvChange(el, value) {
		this.setState({
			blodprov: value,
		});
	}
	handleErsattningsfordonChange(el, value) {
		this.setState({
			ersattningsfordon: value,
		});
	}
	handleForsakringskategoriChange(event) {
		this.setState(
			{
				forsakringskategori: event.target.value,
			},
			() => {
				this.validateForsakringskategori();
			}
		);
	}
	handleSynligaSkadorBoolChange(el, value) {
		this.setState({
			synligaskadorbool: value,
		});
	}
	handleSynligaSkadorChange(event) {
		this.setState({
			synligaskador: event.target.value,
		});
	}
	handlePersonSkadaBoolChange(el, value) {
		this.setState({
			personskadabool: value,
		});
	}
	handlePersonSkadaChange(event) {
		this.setState({
			personskada: event.target.value,
		});
	}
	handleVittnenChange(event) {
		this.setState({
			vittnen: event.target.value,
		});
	}
	handleAntalPassagerareChange(event) {
		this.setState(
			{
				antalpassagerare: event.target.value,
			},
			() => {
				this.validateAntalPassagerare();
			}
		);
	}
	handleVagensBreddChange(event) {
		this.setState(
			{
				vagensBredd: event.target.value,
			},
			() => {
				this.validateVagensBredd();
			}
		);
	}
	handleEstimatedDistanceRightHandSideOfRoadChange(event) {
		this.setState(
			{
				estimatedDistanceRightHandSideOfRoad: event.target.value,
			},
			() => {
				this.validateEstimatedDistanceRightHandSideOfRoad();
			}
		);
	}
	handleMatarstallningChange(event) {
		this.setState(
			{
				matarstallning: event.target.value,
			},
			() => {
				this.validateMatarstallning();
			}
		);
	}
	handleMotpartskontaktuppgifterChange(event) {
		this.setState({
			motpartkontaktuppgifter: event.target.value,
		});
	}
	handleMotpartskontaktuppgifterRegnrChange(event) {
		this.setState({
			motpartkontaktuppgifterRegnr: event.target.value,
		});
	}
	handleKategorispecificeringChange(event) {
		this.setState({
			kategorispecificering: event.target.value,
		});
	}
	getForsakringsKategoriName(id) {
		return this.props.formOptions.forsakringskategori.filter((item) => {
			return item.id === id;
		})[0].value;
	}

	checkIfVehicleExists(name) {
		var item = this.props.vehicles.filter((item) => {
			return item.name === name;
		})[0];
		return item !== undefined;
	}

	mergeContactUppgift = (regnr, text) => {
		let result = "";

		if (regnr.length > 0) {
			result += "Registreringsnummer: " + regnr + ", ";
		}

		result = result + text;

		return result;
	};

	handleFormSave() {
		this.validateDatum();
		this.validateTid();
		this.validatePlats();
		this.validateHandelseBeskrivning();
		this.validateForsakringskategori();
		this.validateSkadeplatskategori();
		this.validateAntalPassagerare();
		this.validateMatarstallning();
		this.validateFordon();
		this.validateVagensBredd();
		this.validateEstimatedDistanceRightHandSideOfRoad();

		if (
			this.datumIsValid &&
			this.tidIsValid &&
			this.fordonIsValid &&
			this.platsIsValid &&
			this.skadeplatskategoriIsValid &&
			this.handelseBeskrivningIsValid &&
			this.forsakringskategoriIsValid &&
			this.antalPassagerareIsvalid &&
			this.matarstallningIsValid &&
			this.vagensBreddIsvalid &&
			this.estimatedDistanceRightHandSideOfRoadIsvalid
		) {
			var saveData = {
				Datum: this.state.datum.format("YYYY-MM-DD") + this.state.tid.format(" HH:mm"),
				Plats: this.state.plats,
				HandelseBeskrivning: this.state.handelseBeskrivning,
				Fordon: this.state.fordon,
				Skadeplatskategori: this.state.skadeplatskategori,
				Polis: this.state.polis,
				Blodprov: this.state.blodprov,
				Vittnen: this.state.vittnen,
				Matarstallning: this.state.matarstallning,
				AntalPassagerare: this.state.antalpassagerare,
				Raddningstjanst: this.state.raddningstjanst,
				SkadaPaFordon: this.state.skadapafordon,
				ForsakringsKategori: this.getForsakringsKategoriName(this.state.forsakringskategori),
				KategoriSpecificering: this.state.kategorispecificering,
				Ersattningsfordon: this.state.ersattningsfordon,
				SynligaSkador: this.state.synligaskador,
				Personskadekontaktuppgifter: this.state.personskada,
				Motpartskontaktuppgifter: this.mergeContactUppgift(this.state.motpartkontaktuppgifterRegnr, this.state.motpartkontaktuppgifter),
				AttachmentUploadBlobNames: this.state.attachmentNames,
				VagensBredd: this.state.vagensBredd,
				AvstandTillVagensSida: this.state.estimatedDistanceRightHandSideOfRoad,
			};

			console.log("to save", saveData);

			this.addIncident(saveData);
		} else {
			//  console.log('spara validera icke', saveData);
		}
	}

	addIncident(incident) {
		this.setState(
			{
				savingStatus: 1,
			},
			() => {
				axios
					.post(infraConfig.apiEndpoint.concat("/api/incident/addfordonsskada"), incident, getAxiosConfig(this.props.user))
					.then((dataResult) => {
						console.log("after send success", dataResult);

						this.setState({
							savingStatus: 2,
						});

						this.resetForm();
					})
					.catch((error) => {
						this.setState({
							savingStatus: 3,
						});
						console.log("ERROR");
					});
			}
		);
	}

	onFileUploadChange(e) {
		e.preventDefault();

		this.setState({ file: e.target.files[0] });
	}

	onAddImageClick(e) {
		this.fileUpload(this.state.file);
	}
	onRemoveImageItem(name) {
		this.setState({
			attachmentNames: this.state.attachmentNames.filter((item) => item.name !== name),
		});
	}
	fileUpload(file) {
		var axiosConfig = getAxiosConfig(this.props.user);
		axiosConfig.headers["Content-Type"] = "multipart/form-data";

		const formData = new FormData();
		formData.append("file", file);

		axios
			.post(infraConfig.apiEndpoint.concat("/api/incident/uploadimage"), formData, axiosConfig)
			.then((dataResult) => {
				if (dataResult.data.result !== false) {
					this.setState(
						(prevState) => {
							const attachmentNames = prevState.attachmentNames.concat(dataResult.data.result);
							return {
								attachmentNames,
								file: null,
							};
						},
						() => {
							//console.log(this.state);
						}
					);
				}
			})
			.catch((error) => {
				// console.log('ERROR', error);
			});
	}
	triggerInputFile = () => this.fileInput.click();
	resetForm() {
		var savingStatus = this.state.savingStatus;
		this.setState(this.getInititalState(), () => {
			this.setState({ savingStatus: savingStatus });
		});

		document.getElementById("create-incident").reset();
	}

	render() {
		return (
			<div>
				{" "}
				{this.props.formOptions != null ? (
					<div>
						<div className="list-group">
							<div className="list-group-item active">Foton</div>
							<div className="list-group-item">
								<input ref={(fileInput) => (this.fileInput = fileInput)} onChange={(event) => this.onFileUploadChange(event)} type="file" className="hidden" />
								<div className="btn-group">
									<button className="btn btn-primary btn-fill" onClick={(event) => this.triggerInputFile(event)}>
										{" "}
										Välj fil {this.state.file ? <span>({this.state.file.name})</span> : null}
									</button>
									<button className="btn btn-primary btn-fill" onClick={(event) => this.onAddImageClick(event)} type="submit">
										Lägg till
									</button>
								</div>
							</div>
							{this.state.attachmentNames.map((item, key) => {
								return (
									<div className="list-group-item" key={key}>
										<button onClick={(event) => this.onRemoveImageItem(item.name)} className="btn btn-warning btn-fill">
											<span className="fa fa-remove" />
										</button>{" "}
										{decodeURI(item.shortName).replace("+", " ")}
									</div>
								);
							})}
						</div>

						<form id="create-incident">
							<FormGroup>
								<ControlLabel>
									Datum för incident <span className="star">*</span>
								</ControlLabel>
								<Datetime
									onChange={this.handleDatumChange.bind(this)}
									timeFormat={false}
									inputProps={{ placeholder: "Datum för incident", className: this.state.datumError !== null ? "form-control error" : "form-control" }}
									defaultValue={""}
								/>								
								<span className="text-danger">{this.state.datumError}</span>
							</FormGroup>
							<FormGroup>
								<ControlLabel>
									Tid för incident <span className="star">*</span>
								</ControlLabel>
								<Datetime
									onChange={this.handleTidChange.bind(this)}
									dateFormat={false}
									timeFormat={true}
									inputProps={{ placeholder: "Tid för incident", className: this.state.tidError !== null ? "form-control error" : "form-control" }}
									defaultValue={""}
								/>
								<span className="text-danger">{this.state.tidError}</span>
							</FormGroup>
							<FormGroup>
								<ControlLabel>
									Plats <span className="star">*</span>
								</ControlLabel>
								<FormControl
									type="text"
									name="plats"
									onChange={(event) => this.handlePlatsChange(event)}
									className={this.state.platsError !== null ? "error" : null}
								/>
								<span className="text-danger">{this.state.platsError}</span>
							</FormGroup>
							{!this.props.disableFK && (
								<Row>
									<Col xs={12}>
										<SelectVehicleForm vehicleError={this.state.fordonError} vehicles={this.props.vehicles} handleSelectVehicle={this.handleSelectVehicle} />
									</Col>
								</Row>
							)}
							{this.props.disableFK && (
								<FormGroup>
									<ControlLabel>
										Fordon <span className="star">*</span>
									</ControlLabel>
									<FormControl
										type="text"
										name="fordon"
										value={this.state.fordon}
										onChange={(event) => this.handleFordonChange(event)}
										className={this.state.fordonError !== null ? "error" : null}
									/>
									<span className="text-danger">{this.state.fordonError}</span>
								</FormGroup>
							)}

							<FormGroup>
								<ControlLabel>
									Skadeplatskategori <span className="star">*</span>
								</ControlLabel>

								<div>
									{this.props.formOptions != null
										? this.props.formOptions.skadeplatskategori.map((row, key) => {
												return <Checkbox onClick={(event) => this.handleSkadeplatskategoriChange(event, row)} label={row.label} number={row.id} key={key} />;
										  })
										: null}
								</div>
							</FormGroup>
							<span className="text-danger">{this.state.skadeplatskategoriError}</span>
							<FormGroup>
								<Switch onText="Ja" offText="Nej" defaultValue={this.state.polis} onChange={(el, state) => this.handlePolisChange(el, state)} /> Polis
								inkopplad?
							</FormGroup>
							<FormGroup>
								<Switch
									onText="Ja"
									offText="Nej"
									defaultValue={this.state.raddningstjanst}
									onChange={(el, state) => this.handleRaddningstjanstChange(el, state)}
								/>{" "}
								Räddningstjänst inkopplad?
							</FormGroup>
							<FormGroup>
								<Switch onText="Ja" offText="Nej" defaultValue={this.state.skadapafordon} onChange={(el, state) => this.handleSkadaPaFordonChange(el, state)} />{" "}
								Skador på fordon?
							</FormGroup>
							<FormGroup>
								<Switch onText="Ja" offText="Nej" defaultValue={this.state.blodprov} onChange={(el, state) => this.handleBlodProvChange(el, state)} /> Blodprov
								/ Utandingsprov?
							</FormGroup>
							<FormGroup>
								<ControlLabel>
									Försäkringskategori <span className="star">*</span>
								</ControlLabel>
								<FormControl
									type="select"
									componentClass="select"
									placeholder="select"
									name="forsakringskategori"
									className={this.state.forsakringskategoriError !== null ? "error" : null}
									onChange={(event) => this.handleForsakringskategoriChange(event)}
								>
									<option value="">-- Försäkringskategori --</option>
									{this.props.formOptions.forsakringskategori.map((item, key) => {
										return (
											<option key={key} value={item.id}>
												{item.label}
											</option>
										);
									})}
								</FormControl>
								<span className="text-danger">{this.state.forsakringskategoriError}</span>
							</FormGroup>
							{this.state.forsakringskategori !== "" ? (
								<div>
									{this.state.forsakringskategori === "Forsakringskategori_option1" ? (
										<FormGroup>
											<ControlLabel>Kategorispecificering</ControlLabel>
											<FormControl
												type="select"
												componentClass="select"
												placeholder="select"
												name="kategorispecificering"
												onChange={(event) => this.handleKategorispecificeringChange(event)}
											>
												<option value="">-- Kategorispecificering --</option>
												{this.kategorispecOptions.map((item, key) => {
													return (
														<option key={key} value={item}>
															{item}
														</option>
													);
												})}
											</FormControl>
										</FormGroup>
									) : this.state.forsakringskategori === "Forsakringskategori_option2" ? (
										<FormGroup>
											<ControlLabel>Vad har blivit stulet?</ControlLabel>
											<FormControl type="text" name="kategorispecificering" onChange={(event) => this.handleKategorispecificeringChange(event)} />
										</FormGroup>
									) : this.state.forsakringskategori === "Forsakringskategori_option3" ? (
										<FormGroup>
											<ControlLabel>Vilken typ av brand?</ControlLabel>
											<FormControl type="text" name="kategorispecificering" onChange={(event) => this.handleKategorispecificeringChange(event)} />
										</FormGroup>
									) : null}

									<FormGroup>
										<ControlLabel>
											Händelsebeskrivning (Vem har vållat skadan? Och varför?) <span className="star">*</span>
										</ControlLabel>
										<FormControl
											componentClass="textarea"
											type="textarea"
											placeholder=""
											className={this.state.handelseBeskrivningError !== null ? "error" : null}
											name="handelsebeskrivning"
											onChange={(event) => this.handleHandelseBeskrivningChange(event)}
										/>
										<span className="text-danger">{this.state.handelseBeskrivningError}</span>
									</FormGroup>
									{this.state.kategorispecificering === "Kollision" ? (
										<>
											<FormGroup>
												<ControlLabel>Vägens ungefärliga bredd (i meter)</ControlLabel>
												<FormControl
													type="text"
													name="vagensBredd"
													className={this.state.vagensBreddError !== null ? "error" : null}
													onChange={(event) => this.handleVagensBreddChange(event)}
												/>
												<span className="text-danger">{this.state.vagensBreddError}</span>
											</FormGroup>
											<FormGroup>
												<ControlLabel>Ungefärligt avstånd till vägens högerkant (i meter)</ControlLabel>
												<FormControl
													type="text"
													name="estimatedDistanceRightHandSideOfRoad"
													className={this.state.estimatedDistanceRightHandSideOfRoadError !== null ? "error" : null}
													onChange={(event) => this.handleEstimatedDistanceRightHandSideOfRoadChange(event)}
												/>
												<span className="text-danger">{this.state.estimatedDistanceRightHandSideOfRoadError}</span>
											</FormGroup>
										</>
									) : null}
									<FormGroup>
										<Switch
											onText="Ja"
											offText="Nej"
											defaultValue={this.state.ersattningsfordon}
											onChange={(el, state) => this.handleErsattningsfordonChange(el, state)}
										/>{" "}
										Anlitades ersättninsfordon?
									</FormGroup>
									<FormGroup>
										<Switch
											onText="Ja"
											offText="Nej"
											defaultValue={this.state.synligaskadorbool}
											onChange={(el, state) => this.handleSynligaSkadorBoolChange(el, state)}
										/>{" "}
										Synliga skador på buss eller andra föremål?
									</FormGroup>
									{this.state.synligaskadorbool ? (
										<FormGroup>
											<ControlLabel>Beskriv synliga skador:</ControlLabel>
											<FormControl
												componentClass="textarea"
												type="textarea"
												placeholder=""
												name="synligaskador"
												onChange={(event) => this.handleSynligaSkadorChange(event)}
											/>
										</FormGroup>
									) : null}

									{this.state.forsakringskategori !== "Forsakringskategori_option2" ? (
										<div>
											<FormGroup>
												<Switch
													onText="Ja"
													offText="Nej"
													defaultValue={this.state.personskadabool}
													onChange={(el, state) => this.handlePersonSkadaBoolChange(el, state)}
												/>{" "}
												Personskada?
											</FormGroup>
											{this.state.personskadabool ? (
												<FormGroup>
													<ControlLabel>Beskriv personskador: personnummer, namn, adress, telefon (om möjligt)</ControlLabel>
													<FormControl
														componentClass="textarea"
														type="textarea"
														placeholder=""
														name="personskada"
														onChange={(event) => this.handlePersonSkadaChange(event)}
													/>
												</FormGroup>
											) : null}
										</div>
									) : null}

									{this.state.forsakringskategori !== "Forsakringskategori_option3" ? (
										<FormGroup>
											<ControlLabel>
												Kontaktuppgifter motpart (Ägarens namn, Orgnr/personnr, adress, reg nr, försäkringsbolag, förare, kontaktuppgifter (telnr) till föraren)
											</ControlLabel>
											<FormControl
												componentClass="textarea"
												type="text"
												placeholder="Registreringsnummer"
												name="motpartskontaktuppgifterRegnr"
												onChange={(event) => this.handleMotpartskontaktuppgifterRegnrChange(event)}
											/>
											<div className="mt-2">
												<FormControl
													componentClass="textarea"
													type="textarea"
													placeholder="Övrig info"
													name="motpartskontaktuppgifter"
													onChange={(event) => this.handleMotpartskontaktuppgifterChange(event)}
												/>
											</div>
										</FormGroup>
									) : null}

									<FormGroup>
										<ControlLabel>Vittnen (Namn, telefonnummer)</ControlLabel>
										<FormControl
											componentClass="textarea"
											type="textarea"
											placeholder=""
											name="vittnen"
											onChange={(event) => this.handleVittnenChange(event)}
										/>
									</FormGroup>
									<FormGroup>
										<ControlLabel>Antal passagerare</ControlLabel>
										<FormControl
											type="text"
											name="antalpassagerare"
											className={this.state.antalpassagerareError !== null ? "error" : null}
											onChange={(event) => this.handleAntalPassagerareChange(event)}
										/>
										<span className="text-danger">{this.state.antalpassagerareError}</span>
									</FormGroup>
									<FormGroup>
										<ControlLabel>Mätarställning</ControlLabel>
										<FormControl
											type="text"
											name="matarstallning"
											className={this.state.matarstallningError !== null ? "error" : null}
											onChange={(event) => this.handleMatarstallningChange(event)}
										/>
										<span className="text-danger">{this.state.matarstallningError}</span>
									</FormGroup>
								</div>
							) : null}

							<FormGroup>
								<SavingButton
									text="Spara"
									inProgressText="Sparar.."
									completeMessage="Avvikelse har sparats"
									errorMessage="Ett fel inträffade"
									status={this.state.savingStatus}
									onClick={this.handleFormSave}
								/>
							</FormGroup>
						</form>
					</div>
				) : null}{" "}
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		user: state.oidc.user,
	};
}

export default connect(mapStateToProps)(FormFordonsskada);
