// formuläret från startsidan (genvägen) det förenklade
import { connect } from "react-redux";
import axios from "axios";
import { getUtvardering } from "./actions";
import  { infraConfig } from "config/infraConfig";
import { getAxiosConfig } from "system/axiosConfig";
import SavingButton from "components/CustomButtons/SavingButton.jsx"
import Datetime from "react-datetime";
import React, { Component} from "react";
import {
    FormGroup,
    FormControl,
    ControlLabel,
    Radio,
  } from "react-bootstrap";

  import moment from 'moment';
  import 'moment/locale/sv';
  

class Utvardering extends Component {

  constructor(props){
    super(props);

    moment().locale('sv');
    
    this.state = { validated: false };
    this.state = this.getInititalState();

    this.handleFormSave = this.handleFormSave.bind(this);
    this.ortIsValid = false;
    this.datumIsValid = false;
    this.val1IsValid = false;
    this.val2IsValid = false;
    this.val3IsValid = false;
    this.val4IsValid = false;
    this.val5IsValid = false;
    this.val6IsValid = false;
    this.val7IsValid = false;
    this.val8IsValid = false;

  }
  handleFormSave() {
    if(!this.val1IsValid){this.setState({val1Error:   'Måste anges'});}
    if(!this.val2IsValid){this.setState({val2Error:   'Måste anges'});}
    if(!this.val3IsValid){this.setState({val3Error:   'Måste anges'});}
    if(!this.val4IsValid){this.setState({val4Error:   'Måste anges'});}
    if(!this.val5IsValid){this.setState({val5Error:   'Måste anges'});}
    if(!this.val6IsValid){this.setState({val6Error:   'Måste anges'});}
    if(!this.val7IsValid){this.setState({val7Error:   'Måste anges'});}
    if(!this.val8IsValid){this.setState({val8Error:   'Måste anges'});}
    if(!this.datumIsValid){this.setState({datumError: 'Datum har fel format'});}
    if(this.state.utbildningsort.length > 0){this.ortIsValid = true;}else{this.setState({ortError: 'Utbildningsort måste anges'});}

    if (this.datumIsValid && this.val1IsValid && this.val2IsValid && this.val3IsValid && this.val4IsValid
      && this.val5IsValid && this.val6IsValid && this.val7IsValid && this.val8IsValid && this.ortIsValid)
    {
        var saveData = {
            utbildningsort: this.state.utbildningsort,
            datum: this.state.datum,
            val1: this.state.val1,
            val2: this.state.val2,
            val3: this.state.val3,
            val4: this.state.val4,
            val5: this.state.val5,
            val6: this.state.val6,
            val7: this.state.val7,
            val8: this.state.val8,
            synpunkter: this.state.synpunkter,
            ovrigt: this.state.ovrigt
        };
       // console.log(saveData);
        this.addUtvardering(saveData);
      }
      else{
        console.log("Error")
      }

}
addUtvardering(saveData) {
  console.log("Send!")
  this.setState({
      savingStatus: 1
  }, () => {

      axios.post(infraConfig.apiEndpoint.concat('/api/utvardering/send'), saveData, getAxiosConfig(this.props.user))
      .then((dataResult) => {
          //console.log('after send success', dataResult);

          this.setState({
              savingStatus: 2
          });
          this.resetForm();
      })
      .catch((error) => {
          this.setState({
              savingStatus: 3
          });
          console.log('ERROR');
         });
  });

}
validateDatum() {

    var parsed = moment(this.state.datum, 'YYYY-MM-DD');

    if (parsed.isValid()) {
        this.datumIsValid = true;
        this.setState({
            datumError: null
        });
    }
    else {
        this.datumIsValid = false;
        this.setState({
            datumError: 'Datum har fel format'
        });

    }
}
handleDate(date){
   this.setState({datum: date._d}, () => {this.validateDatum();});
   //console.log(this.state.datum);
};
validateVal1() {
    if (this.state.val1.length > 0) {
        this.val1IsValid = true;
        this.setState({
            va1Error: null
        });
        } else {
            this.val1IsValid = false;
            this.setState({
                va1Error: 'Måste anges'
            });

        }
}
resetForm() {
  var savingStatus = this.state.savingStatus;
  this.setState(this.getInititalState(), () => {
     this.setState({ savingStatus: savingStatus});
  });


    document.getElementById("create-utvardering").reset();
}
  getInititalState = () => {
    const initialState = {
        datumError: null,
        val1Error: null,
        utbildningsort: '',
        datum: '',
        val1: '',
        val2: '',
        val3: '',
        val4: '',
        val5: '',
        val6: '',
        val7: '',
        val8: '',
        synpunkter: '',
        ovrigt: '',
        savingStatus: 0
    }
    return initialState;
  }

render() {
    return (

<div id="content">
    <form
      id="create-utvardering"
       >
        <FormGroup>
        <ControlLabel>
                <b>Utbildningsort</b> <span className="star">*</span>
        </ControlLabel>
        <FormControl
            type="text"
            name="utbildningsort"
            onChange={event => {this.setState({utbildningsort: event.target.value});this.setState({ortError: ''});}}
        />
      <span className="text-danger">{this.state.ortError}</span>
        </FormGroup>
        <FormGroup>
            <ControlLabel>
                <b>Datum för utbildningstillfälle</b> <span className="star">*</span>
            </ControlLabel>
                  <Datetime
                    onChange={this.handleDate.bind(this)}
                    timeFormat={false}
                       inputProps={{ placeholder: "Datum för utbildning" ,className: this.state.datumError !== null ? 'form-control error' : 'form-control' }}
                    />
              <span className="text-danger">{this.state.datumError}</span>
        </FormGroup>
        <FormGroup>
        <ControlLabel>
                <b>Jag tycker kursinnehållet var bra</b> <span className="star">*</span>
        </ControlLabel>
        <br/>
        <Radio type='integer' value="Håller inte alls med" onClick={event => {this.setState({val1: event.target.value, val1Error: ''}); this.val1IsValid = true;}} inline name="Val1">Håller inte alls med</Radio><br/>
        <Radio type='integer' value="Håller inte med" onClick={event => {this.setState({val1: event.target.value, val1Error: ''}); this.val1IsValid = true;}} inline name="Val1">Håller inte med</Radio><br/>
        <Radio type='integer' value="Neutral" onClick={event => {this.setState({val1: event.target.value, val1Error: ''}); this.val1IsValid = true;}} inline name="Val1">Neutral</Radio><br/>
        <Radio type='integer' value="Håller med" onClick={event => {this.setState({val1: event.target.value, val1Error: ''}); this.val1IsValid = true;}} inline name="Val1">Håller med</Radio><br/>
        <Radio type='integer' value="Håller helt med" onClick={event => {this.setState({val1: event.target.value, val1Error: ''}); this.val1IsValid = true;}} inline name="Val1">Håller helt med</Radio><br/>
            <span className="text-danger">{this.state.val1Error}</span>
        </FormGroup>
        <FormGroup>
        <ControlLabel>
                <b>Jag fick tillfälle att bidra/delta med mina egna synpunkter och erfarenheter</b> <span className="star">*</span>
        </ControlLabel>
        <br/>
          <Radio type='integer' value="Håller inte alls med" onClick={event => {this.setState({val2: event.target.value, val2Error: ''});this.val2IsValid = true;}} inline name="Val2">Håller inte alls med</Radio><br/>
          <Radio type='integer' value="Håller inte med" onClick={event => {this.setState({val2: event.target.value, val2Error: ''});this.val2IsValid = true;}} inline name="Val2">Håller inte med</Radio><br/>
          <Radio type='integer' value="Neutral" onClick={event => {this.setState({val2: event.target.value, val2Error: ''});this.val2IsValid = true;}} inline name="Val2">Neutral</Radio><br/>
          <Radio type='integer' value="Håller med" onClick={event => {this.setState({val2: event.target.value, val2Error: ''});this.val2IsValid = true;}} inline name="Val2">Håller med</Radio><br/>
          <Radio type='integer' value="Håller helt med" onClick={event => {this.setState({val2: event.target.value, val2Error: ''});this.val2IsValid = true;}} inline name="Val2">Håller helt med</Radio><br/>
            <span className="text-danger">{this.state.val2Error}</span>
        </FormGroup>
        <FormGroup>
        <ControlLabel>
                <b>Jag är nöjd med min egen insats under dagen</b> <span className="star">*</span>
        </ControlLabel>
        <br/>
          <Radio type='integer' value="Håller inte alls med" onClick={event => {this.setState({val3: event.target.value, val3Error: ''});this.val3IsValid = true;}} inline name="Val3">Håller inte alls med</Radio><br/>
          <Radio type='integer' value="Håller inte med" onClick={event => {this.setState({val3: event.target.value, val3Error: ''});this.val3IsValid = true;}} inline name="Val3">Håller inte med</Radio><br/>
          <Radio type='integer' value="Neutral" onClick={event => {this.setState({val3: event.target.value, val3Error: ''});this.val3IsValid = true;}} inline name="Val3">Neutral</Radio><br/>
          <Radio type='integer' value="Håller med" onClick={event => {this.setState({val3: event.target.value, val3Error: ''});this.val3IsValid = true;}} inline name="Val3">Håller med</Radio><br/>
          <Radio type='integer' value="Håller helt med" onClick={event => {this.setState({val3: event.target.value, val3Error: ''});this.val3IsValid = true;}} inline name="Val3">Håller helt med</Radio><br/>
            <span className="text-danger">{this.state.val3Error}</span>
        </FormGroup>
        <FormGroup>
        <ControlLabel>
                <b>Jag tycker att utbildningsledaren förmedlade innehållet på ett bra sätt</b> <span className="star">*</span>
        </ControlLabel>
        <br/>
          <Radio type='integer' value="Håller inte alls med" onClick={event => {this.setState({val4: event.target.value, val4Error: ''});this.val4IsValid = true;}} inline name="Val4">Håller inte alls med</Radio><br/>
          <Radio type='integer' value="Håller inte med" onClick={event => {this.setState({val4: event.target.value, val4Error: ''});this.val4IsValid = true;}} inline name="Val4">Håller inte med</Radio><br/>
          <Radio type='integer' value="Neutral" onClick={event => {this.setState({val4: event.target.value, val4Error: ''});this.val4IsValid = true;}} inline name="Val4">Neutral</Radio><br/>
          <Radio type='integer' value="Håller med" onClick={event => {this.setState({val4: event.target.value, val4Error: ''});this.val4IsValid = true;}} inline name="Val4">Håller med</Radio><br/>
          <Radio type='integer' value="Håller helt med" onClick={event => {this.setState({val4: event.target.value, val4Error: ''});this.val4IsValid = true;}} inline name="Val4">Håller helt med</Radio><br/>
            <span className="text-danger">{this.state.val4Error}</span>
        </FormGroup>
        <FormGroup>
        <ControlLabel>
                <b>Informationen inför dagarna var bra (kallelse/inbjudan med program)</b> <span className="star">*</span>
        </ControlLabel>
        <br/>
          <Radio type='integer' value="Håller inte alls med" onClick={event => {this.setState({val5: event.target.value, val5Error: ''});this.val5IsValid = true;}} inline name="Val5">Håller inte alls med</Radio><br/>
          <Radio type='integer' value="Håller inte med" onClick={event => {this.setState({val5: event.target.value, val5Error: ''});this.val5IsValid = true;}} inline name="Val5">Håller inte med</Radio><br/>
          <Radio type='integer' value="Neutral" onClick={event => {this.setState({val5: event.target.value, val5Error: ''});this.val5IsValid = true;}} inline name="Val5">Neutral</Radio><br/>
          <Radio type='integer' value="Håller med" onClick={event => {this.setState({val5: event.target.value, val5Error: ''});this.val5IsValid = true;}} inline name="Val5">Håller med</Radio><br/>
          <Radio type='integer' value="Håller helt med" onClick={event => {this.setState({val5: event.target.value, val5Error: ''});this.val5IsValid = true;}} inline name="Val5">Håller helt med</Radio><br/>
            <span className="text-danger">{this.state.val5Error}</span>
        </FormGroup>
        <FormGroup>
        <ControlLabel>
                <b>Jag känner mig mer rustad för mitt vardagliga arbete efter utbildningsdagen</b> <span className="star">*</span>
        </ControlLabel>
        <br/>
          <Radio type='integer' value="Håller inte alls med" onClick={event => {this.setState({val6: event.target.value, val6Error: ''});this.val6IsValid = true;}} inline name="Val6">Håller inte alls med</Radio><br/>
          <Radio type='integer' value="Håller inte med" onClick={event => {this.setState({val6: event.target.value, val6Error: ''});this.val6IsValid = true;}} inline name="Val6">Håller inte med</Radio><br/>
          <Radio type='integer' value="Neutral" onClick={event => {this.setState({val6: event.target.value, val6Error: ''});this.val6IsValid = true;}} inline name="Val6">Neutral</Radio><br/>
          <Radio type='integer' value="Håller med" onClick={event => {this.setState({val6: event.target.value, val6Error: ''});this.val6IsValid = true;}} inline name="Val6">Håller med</Radio><br/>
          <Radio type='integer' value="Håller helt med" onClick={event => {this.setState({val6: event.target.value, val6Error: ''});this.val6IsValid = true;}} inline name="Val6">Håller helt med</Radio><br/>
            <span className="text-danger">{this.state.val6Error}</span>
        </FormGroup>
        <FormGroup>
        <ControlLabel>
                <b>Jag tyckte att lokalen var bra och väl anpassad för den här typen av aktivitet</b> <span className="star">*</span>
        </ControlLabel>
        <br/>
          <Radio type='integer' value="Håller inte alls med" onClick={event => {this.setState({val7: event.target.value, val7Error: ''});this.val7IsValid = true;}} inline name="Val7">Håller inte alls med</Radio><br/>
          <Radio type='integer' value="Håller inte med" onClick={event => {this.setState({val7: event.target.value, val7Error: ''});this.val7IsValid = true;}} inline name="Val7">Håller inte med</Radio><br/>
          <Radio type='integer' value="Neutral" onClick={event => {this.setState({val7: event.target.value, val7Error: ''});this.val7IsValid = true;}} inline name="Val7">Neutral</Radio><br/>
          <Radio type='integer' value="Håller med" onClick={event => {this.setState({val7: event.target.value, val7Error: ''});this.val7IsValid = true;}} inline name="Val7">Håller med</Radio><br/>
          <Radio type='integer' value="Håller helt med" onClick={event => {this.setState({val7: event.target.value, val7Error: ''});this.val7IsValid = true;}} inline name="Val7">Håller helt med</Radio><br/>
            <span className="text-danger">{this.state.val7Error}</span>
        </FormGroup>
        <FormGroup>
        <ControlLabel>
                <b>Jag tyckte att maten var bra</b> <span className="star">*</span>
        </ControlLabel>
        <br/>
          <Radio type='integer' value="Håller inte alls med" onClick={event => {this.setState({val8: event.target.value, val8Error: ''});this.val8IsValid = true;}} inline name="Val8">Håller inte alls med</Radio><br/>
          <Radio type='integer' value="Håller inte med" onClick={event => {this.setState({val8: event.target.value, val8Error: ''});this.val8IsValid = true;}} inline name="Val8">Håller inte med</Radio><br/>
          <Radio type='integer' value="Neutral" onClick={event => {this.setState({val8: event.target.value, val8Error: ''});this.val8IsValid = true;}} inline name="Val8">Neutral</Radio><br/>
          <Radio type='integer' value="Håller helt med" onClick={event => {this.setState({val8: event.target.value, val8Error: ''});this.val8IsValid = true;}} inline name="Val8">Håller helt med</Radio><br/>
          <Radio type='integer' value="Håller med" onClick={event => {this.setState({val8: event.target.value, val8Error: ''});this.val8IsValid = true;}} inline name="Val8">Håller med</Radio><br/>
            <span className="text-danger">{this.state.val8Error}</span>
        </FormGroup>
<FormGroup>
  <ControlLabel>
          <b>Om du har svarat "Håller inte med/Håller inte alls med" på någon av frågorna, är du välkommen att lämna dina synpunkter så vi kan förbättra vårt arbete</b>
  </ControlLabel>
  <FormControl
  componentClass="textarea" placeholder=""
  type="textarea"
  onChange={event => {this.setState({synpunkter: event.target.value});}}
  name="extraText"
  />
</FormGroup>
<FormGroup>
  <ControlLabel>
          <b>Övrigt om dagen som helhet som jag gärna vill framföra</b>
  </ControlLabel>
  <FormControl
  componentClass="textarea" placeholder=""
  type="textarea"
  onChange={event => {this.setState({ovrigt: event.target.value});}}
  name="ovrigtText"
  />
</FormGroup>
<FormGroup>
    <SavingButton
            text="Spara"
            inProgressText="Sparar.."
            completeMessage="Utvärderingen har sparats"
            errorMessage="Ett fel inträffade"
            status={this.state.savingStatus}
            onClick={this.handleFormSave}
        />
</FormGroup>

</form>
    </div>
    )
    }


}

function mapStateToProps(state) {
    return {
        user: state.oidc.user,
    };
  }

export default connect(mapStateToProps, { getUtvardering})(Utvardering);
