import React from 'react';
import Autosuggest from 'react-autosuggest';
import {
    FormGroup,
    ControlLabel
  } from "react-bootstrap";

import 'assets/css/react-autosuggest.css';

class SelectVehicleForm extends React.Component {
    constructor(props)
    {
        super(props);

        this.state = {
            vehicleError: null,
            suggestions: [],
            value: ''
        }
    }

    getSuggestionValue = suggestion => suggestion.name;

    renderSuggestion = suggestion => (
        <div>
        {suggestion.name}
        </div>
    );

    getSuggestions = value => {
        const inputValue = value.trim().toLowerCase();
    
        return this.props.vehicles.filter(item =>
            item.name.toLowerCase().includes(inputValue)
        );

    };

    componentDidMount() {
        console.log(this.props.vehicles);
        this.setState({
            suggestions: this.props.vehicles
        })
    }

    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: this.getSuggestions(value)
        });
    };

    onSuggestionsClearRequested = () => {
        console.log('clear requested');
    //     const vehicle = this.props.vehicles.filter(item => item.name === this.state.value)[0];

    //    this.props.handleSelectVehicle(vehicle ? vehicle.number : '');

        this.setState({
            suggestions: []
        });
    };

    onSuggestionSelected = (event, {suggestion, suggestionValue}) => {
        console.log('suggestion selected', event, suggestion, suggestionValue);

        const vehicle = this.props.vehicles.filter(item => item.name === suggestionValue)[0];
        console.log(vehicle);
        this.props.handleSelectVehicle(vehicle ? vehicle.number : '');

    }

    onChange = (event, { newValue }) => {
        // console.log(newValue);

        this.setState({
            value: newValue
        });
    };

    onBlur = (event) => {
        console.log('on blur', event);
        const vehicle = this.props.vehicles.filter(item => item.name === this.state.value)[0];
        console.log(vehicle);
        this.props.handleSelectVehicle(vehicle ? vehicle.number : '');
    }
    
    shouldRenderSuggestions(value) {
        return true;
    }
    render() {

        const { value, suggestions } = this.state;
        // Autosuggest will pass through all these props to the input.
        const inputProps = {
            placeholder: 'Sök fordon',
            value,
            onChange: this.onChange,
            onBlur: this.onBlur,
            className: this.state.vehicleError !== null ? 'form-control error' : 'form-control' 
        };

        return (
            <div>
                <FormGroup>
                    <ControlLabel>
                    Fordon: <span className="star">*</span>
                    </ControlLabel>
                    <Autosuggest
                        alwaysRenderSuggestions={false}
                        suggestions={suggestions}
                        shouldRenderSuggestions={this.shouldRenderSuggestions}
                        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                        onSuggestionSelected={this.onSuggestionSelected}
                        getSuggestionValue={this.getSuggestionValue}
                        renderSuggestion={this.renderSuggestion}
                        inputProps={inputProps}
                    />       
                     <span className="text-danger">{this.state.vehicleError}</span>             
                </FormGroup> 
            </div>);
    }
}

export default SelectVehicleForm;