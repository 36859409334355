import React, { Component} from "react";
import { connect } from "react-redux";
import IncidentListTable from "./IncidentListTable";
import IncidentListCards from "./IncidentListCards";
import SummaCard from "./SummaCard";
import { fetchForsakringUppfoljning } from '../actions';
import Currency from 'components/formatting/Currency';
import LoadingIcon from 'components/utils/LoadingIcon';
import ColViewSwitch from 'components/utils/ColViewSwitch';

import { Col, Row, FormGroup,
    FormControl,
} from 'react-bootstrap';

class ForsakringUppfoljning extends Component {
    constructor(props)
    {
        super(props);
        this.state = {
            yearError: null,
            year: new Date().getFullYear(),
            month: new Date().getMonth() + 1,
            monthError: null,
        }
        this.yearIsValid = true;
    }
    maxYear = new Date().getFullYear() + 5
    minYear = new Date().getFullYear() - 5

    componentDidMount() {
        this.props.fetchForsakringUppfoljning(this.state.year, this.state.month);
    }

    validateYear() {
        if (this.state.year >= this.minYear && this.state.year <= this.maxYear)
        {
            this.yearIsValid = true;
            this.setState({
                yearError: null
            });
        } 
        else 
        {
            this.yearIsValid = false;
            this.setState({
                yearError: 'Felaktigt årtal'
            });
        }
        
    }

    handleYearChange(event) {

        this.setState({
            year: event.target.value
        }, () => {
            this.validateYear();

            if (this.yearIsValid) {
                this.props.fetchForsakringUppfoljning(this.state.year, this.state.month);
            }

        });
    }

    handleMonthChange(event) {
        this.setState({
            month: event.target.value
        }, () => {
            if (this.yearIsValid) {
                this.props.fetchForsakringUppfoljning(this.state.year, this.state.month);
            }
        });
    }
    months = [
        { id: 1, name: 'Januari'},
        { id: 2, name: 'Februari'},
        { id: 3, name: 'Mars'},
        { id: 4, name: 'April'},
        { id: 5, name: 'Maj'},
        { id: 6, name: 'Juni'},
        { id: 7, name: 'Juli'},
        { id: 8, name: 'Augusti'},
        { id: 9, name: 'September'},
        { id: 10, name: 'Oktober'},
        { id: 11, name: 'November'},
        { id: 12, name: 'December'},
    ]
    renderSummaCard1() {
        return (<SummaCard title="Personliga" icon={<i className="fa fa-user-o" />} 
        value={this.props.isLoadingForsakringUppfoljning ? "-"
        : this.props.forsakringUppfoljning != null 
        ? <Currency value={this.props.forsakringUppfoljning.totalPersonal} />
        : "-"}
        stats= {
            <LoadingIcon isLoading={this.props.isLoadingForsakringUppfoljning}
            loadingFailed={this.props.loadingForsakringUppfoljningFailed}
            text="Laddar.." 
            success="Visar summa för dina personliga ärenden"
            />
        }

        />);
    }
    renderSummaCard2() {
        return (<SummaCard title="Trafikområde" icon={<i className="fa fa-users" />} 
        value={this.props.isLoadingForsakringUppfoljning ? "-" 
        : this.props.forsakringUppfoljning != null 
        ? <Currency value={this.props.forsakringUppfoljning.totalDepa} />
        : "-"}
        stats= {
            <LoadingIcon isLoading={this.props.isLoadingForsakringUppfoljning}
            loadingFailed={this.props.loadingForsakringUppfoljningFailed}
            text="Laddar.." 
            success="Visar summa för ärenden i ditt trafikområde"
            />
        }
    />);
    }
    renderCommandBar() {
        return (
            <div className="form-inline">
                <FormGroup>
                <FormControl
                    type="number"
                    name="year"
                    value={this.state.year}
                    className={this.state.yearError !== null ? 'error' : null}
                    onChange={event => this.handleYearChange(event)}
                />

                </FormGroup>
                <FormGroup>
                <FormControl
                    type="select"
                    componentClass="select" placeholder="select"
                    name="month"
                    value={this.state.month}
                    className={this.state.monthError !== null ? 'error' : null}
                    onChange={event => this.handleMonthChange(event)}
                    >
                    {this.months.map((item, key) => {
                        return <option key={key} value={item.id}>{item.name}</option>
                    })}
                    </FormControl>
                    <span className="text-danger">{this.state.monthError}</span>
                </FormGroup>
            </div>
        );
    }
    renderSmall() {
        return (<Row>
            <Col xs={12}>
                {this.renderSummaCard1()}
            </Col>
            <Col xs={12}>
                {this.renderSummaCard2()}
            </Col>
            <Col xs={12}>
                <IncidentListCards
                rows={this.props.forsakringUppfoljning}
                rowsIsLoading={this.props.isLoadingForsakringUppfoljning}
                rowsLoadingFailed={this.props.loadingForsakringUppfoljningFailed}
                commandBar={this.renderCommandBar() }
                title="Personliga försäkringsärenden" />
            </Col>                
    </Row>);
    }
    renderLarge() {
        return (<Row>
            <Col xs={9}>
                <IncidentListTable 
                rows={this.props.forsakringUppfoljning}
                rowsIsLoading={this.props.isLoadingForsakringUppfoljning}
                rowsLoadingFailed={this.props.loadingForsakringUppfoljningFailed}
                commandBar={this.renderCommandBar() }
                title="Personliga försäkringsärenden" />
            </Col>
            <Col xs={3}>
                <Row>
                    <Col xs={12}>
                        {this.renderSummaCard1()}
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                    {this.renderSummaCard2()}
                    </Col>
                </Row>
            </Col>
        </Row>);
    }
    render() {
        return <div className="container-fluid">
            <ColViewSwitch 
            xs={this.renderSmall()}
            sm={this.renderSmall()}
            md={this.renderSmall()}
            lg={this.renderLarge()}
            />
            
        </div>;
    }
}
function mapStateToProps(state) {
    return {
        user: state.oidc.user,
        forsakringUppfoljning: state.incident.forsakringUppfoljning,
        isLoadingForsakringUppfoljning: state.incident.isLoadingForsakringUppfoljning,
        loadingForsakringUppfoljningFailed: state.incident.loadingForsakringUppfoljningFailed,
    };
  }
export default connect(mapStateToProps, { fetchForsakringUppfoljning })(ForsakringUppfoljning);
