import React from "react";
import { connect } from "react-redux";
import Card from "components/Card/Card.jsx";
import {Grid, Col,Row } from "react-bootstrap";
import {appConfig} from "config/appConfig";
import ConfigDescription from './ConfigDescription';
import SimpleStandaloneHeader from "components/utils/SimpleStandaloneHeader";


class Diagnostics extends React.Component {
   
  componentDidMount() {
    
  
}

  render() {

    return (      
      <div className="main-content">
      <SimpleStandaloneHeader />
        <Grid fluid>
          <Row>
            <Col sm={12}>
            <br />
            <Card content={
              <div className="text-center">
                <h3>Diagnostik</h3>
              <div>client config: {appConfig.name}</div>
                { this.props.user ? <ConfigDescription /> : null}

              </div> 
            } />
            </Col>
          </Row>
      </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
      user: state.oidc.user
  };
}

export default connect(mapStateToProps)(Diagnostics);

