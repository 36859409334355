import React, { Component} from "react";
import {Grid, Col, Row} from 'react-bootstrap';
import Time from 'components/formatting/Time';
import 'assets/css/detailcard.css';
import DateC from 'components/formatting/Date';

class UppdragCards extends Component{

    renderCard(row, key) {
        return (<div className="card" key={key}><div className="content">

            <Grid fluid>
            <Row><Row>
                <Col xs={6}>
                        <div className="detailcard"><div className="detailcard-label">Datum</div>
                            <DateC value={row.starttid} />
                        </div>
                    </Col>
                </Row></Row>
                <Row><Row>
                    <Col xs={6}>
                        <div className="detailcard"><div className="detailcard-label">Start</div>
                            <Time value={row.starttid} />
                        </div>
                    </Col>
                    <Col xs={6}>
                        <div className="detailcard"><div className="detailcard-label">Slut</div>
                        <Time value={row.sluttid} />
                        </div>
                    </Col>
                    </Row></Row>
                <Row><Row>                    
                    <Col xs={12}>
                        <div className="detailcard"><div className="detailcard-label">Fordon</div>
                            {row.fordonStr}
                        </div>
                    </Col>
                    </Row></Row>
                    <Row><Row>                    
                    <Col xs={12}>
                        <div className="detailcard"><div className="detailcard-label">Tjänst</div>
                            {row.tjanst}
                        </div>
                    </Col>
                    </Row></Row>
                    <Row><Row>
                    <Col xs={12}>
                        <div className="detailcard"><div className="detailcard-label">Beskrivning</div>
                            {row.beskrivning}
                        </div>
                    </Col>
                    </Row></Row>
                    <Row><Row>
                    <Col xs={12}>
                        <div className="detailcard"><div className="detailcard-label">Startplats</div>
                            {row.startplats}
                        </div>
                    </Col>
                    </Row></Row>
                <Row><Row>
                    <Col xs={12}>
                        <div className="detailcard"><div className="detailcard-label">Slutplats</div>
                            {row.slutplats}
                        </div>
                    </Col>
                    </Row></Row>
                <Row><Row>
                    <Col xs={12}>
                        <div className="detailcard"><div className="detailcard-label">Notering</div>
                            {row.anmarkning}
                        </div>
                    </Col>
                    </Row></Row>
            </Grid>
        </div></div>)
    }
    renderList() {
          return (
                <div>
                                   
                    { this.props.uppdrag.length > 0 ? 
                        this.props.uppdrag.map((row, key) => {
                            return (row.uppdrag.map((subrow, key) => {
                                return (
                                    (!this.props.showFramAter && subrow.ejFramAterkorning) || this.props.showFramAter ? 
                                    this.renderCard(subrow,key)
                                    : null
                                )
                            })
                        )
                            }) : null
                            
                    }

                </div>
            );
      }

    render() {
       
        return (this.renderList());
    }
}

  export default UppdragCards;