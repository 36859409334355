import { DIAGNOSTICS_GETCONFIGDESCRIPTION
  } from "./actions";
  
  const initialState = {
      configDescription: '' 
    };
  
  export default function(state = initialState, action) {
    switch (action.type) {
      case DIAGNOSTICS_GETCONFIGDESCRIPTION:
        return {...state, configDescription: action.payload.data.result } 
      default:
        return state;
    }
  }