import { infraConfig as devConfig } from './dev/infraConfig';
import { infraConfig as bnprodConfig } from './bnprod/infraConfig';
import { infraConfig as bnprodConfigStaging } from './bnprod/infraConfigStaging';
import { infraConfig as tdprodConfig2 } from './td2prod/infraConfig';
import { infraConfig as tdprodConfigStaging2 } from './td2prod/infraConfigStaging';
import { infraConfig as vhprodConfig } from './vhprod/infraConfig';
import { infraConfig as vhprodConfigStaging } from './vhprod/infraConfigStaging';
import { infraConfig as falcksprodConfig } from './falcksprod/infraConfig';
import { infraConfig as falcksprodConfigStaging } from './falcksprod/infraConfigStaging';
import { infraConfig as localConfig } from './local/infraConfig';

var hostnameLookup = window.location.hostname;

const exportedInfraConfig = hostnameLookup === "minsidadev.azurewebsites.net" ? devConfig
: hostnameLookup === "minsidabn.azurewebsites.net" ? bnprodConfig
: hostnameLookup === "minsidabn-staging.azurewebsites.net" ? bnprodConfigStaging
: hostnameLookup === "minsida.bybergnordin.se" ? bnprodConfig
: hostnameLookup === "minsida.ppsp.se" ? tdprodConfig2
: hostnameLookup === "minsidatd2.azurewebsites.net" ? tdprodConfig2
: hostnameLookup === "minsidatd2-staging.azurewebsites.net" ? tdprodConfigStaging2
: hostnameLookup === "vastanhedeminsida.se" ? vhprodConfig
: hostnameLookup === "www.vastanhedeminsida.se" ? vhprodConfig
: hostnameLookup === "minsidavh.azurewebsites.net" ? vhprodConfig
: hostnameLookup === "minsidavh-staging.azurewebsites.net" ? vhprodConfigStaging
: hostnameLookup === "minsidafalcks.azurewebsites.net" ? falcksprodConfig
: hostnameLookup === "minsidafalcks-staging.azurewebsites.net" ? falcksprodConfigStaging
: localConfig;

export const infraConfig = exportedInfraConfig;