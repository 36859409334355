import React from 'react';
import { connect } from "react-redux";
import { fetchDocuments, downloadDocumentFile } from "./actions";
import Button from 'components/CustomButtons/CustomButton.jsx';
import LoadingIcon from "components/utils/LoadingIcon";

class DocumentList extends React.Component {

    constructor(props){
        super(props);

        this.handleDownloadFile.bind(this);
    }
    componentDidMount() {
        this.props.fetchDocuments();
    }

    handleDownloadFile(filename) {
        this.props.downloadDocumentFile(filename);
    }

    render(){ 
        return (<LoadingIcon success={<table className="table table-striped"><tbody>{this.props.documents.length > 0 ? this.props.documents.map((item, key) => {
            return (<tr key={key}><td><span>{item.filename}</span></td><td><Button fill bsStyle="primary" onClick={() => this.handleDownloadFile(item.filename)}><span className="fa fa-download"></span> Ladda ned</Button></td></tr>)
        }) : null}</tbody></table>} text="Laddar dokument.." isLoading={this.props.isLoadingDocuments} loadingFailed={this.props.loadingDocumentsFailed} />);
    }
}



function mapStateToProps(state) {
    return {
      documents: state.policyDocs.documents,
      documentsTimestamp: state.policyDocs.documentsTimestamp,
      isLoadingDocuments: state.policyDocs.isLoadingDocuments,
      loadingDocumentsFailed: state.policyDocs.loadingDocumentsFailed
    };
  }
  
  export default connect(mapStateToProps, { fetchDocuments, downloadDocumentFile })(DocumentList);