// ser ut ungefär som i sharepoint, men listar "mina" incidenter

import React, { Component} from "react";
import Date from "components/formatting/Date";
import {Modal } from 'react-bootstrap';
import Button from "components/CustomButtons/CustomButton.jsx";

class IncidentList extends Component {
    constructor(props){
        super(props);
        this.state = {
            showAtgard: false,
            atgardText: '',
            detaljerItem: null,
            showDetaljer:false
        };
    }
    
    showAtgardInfo(info) {
       // console.log('atgard', info);
        this.setState({
            atgardText: info,
            showAtgard: true
        });
    }
    showDetaljerInfo(item) {
       // console.log('detaljer', item);
        this.setState({
            detaljerItem: item,
            showDetaljer:true
        });
    }
    handleClose() {
        this.setState({
            atgardText: '',
            showAtgard:false
        });
    }
    handleDetaljerClose() {
        this.setState({
            detaljerItem: null,
            showDetaljer:false
        });
    }
    render() {
        return (
            <div>
            <table className="table table-striped">
            <thead>
                <tr>
                    <th>Rubrik</th>
                    <th>Bolag</th>
                    <th>Skapad</th>
                    <th>Avvikelsedatum</th>
                    <th>Status</th>
                    <th>Skapad Av</th>
                    <th>Ansvarig</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>                    
                {  this.props.incidentRapporter.length > 0 ? 
                    this.props.incidentRapporter.map((row, key) => {
                    return (
                        <tr key={key}>
                            <td>
                                {row.rubrik}
                            </td>
                            <td>
                                {row.bolag}
                            </td>
                            <td>
                                <Date value={row.skapadDatum} />
                            </td>
                            <td>
                                <Date value={row.incidentDatum} />
                            </td>
                            <td>
                                {row.status}
                            </td>
                            <td>
                                {row.skapadAv}
                            </td>
                            <td>
                                {row.incidentAnsvarig}
                            </td>
                            <td><div className="btn-group">{row.atgard !== null && row.atgard.length > 0 
                                ? <Button fill bsSize="small" bsStyle="primary" onClick={() => this.showAtgardInfo(row.atgard)} >Åtgärd</Button> 
                                : <Button fill bsSize="small" bsStyle="primary" disabled>Åtgärd</Button> }
                                <Button fill bsSize="small" bsStyle="primary" onClick={() => this.showDetaljerInfo(row)}>Detaljer</Button> 
                                </div>
                                </td>
                        </tr>
                    ) }) : null}
                
        </tbody>
        </table>
                
            <Modal show={this.state.showAtgard} onHide={() => this.handleClose()}>
            <Modal.Header closeButton>
              <Modal.Title>Åtgärd</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{this.state.atgardText}</p>
            </Modal.Body>
            <Modal.Footer>
              <Button fill bsStyle="primary" onClick={() => this.handleClose()}>Stäng</Button>
            </Modal.Footer>
          </Modal>
          <Modal dialogClassName="wider-modal" show={this.state.showDetaljer} onHide={() => this.handleDetaljerClose()}>
            <Modal.Header closeButton>
              <Modal.Title>Detaljer</Modal.Title>
            </Modal.Header>
            {this.state.detaljerItem ? 
            <Modal.Body>
                <label>Plats</label>
                <p>{this.state.detaljerItem.plats}</p>
                { this.renderDetaljer(this.state.detaljerItem) }
            </Modal.Body>
            : null }
            <Modal.Footer>
              <Button fill bsStyle="primary" onClick={() => this.handleDetaljerClose()}>Stäng</Button>
            </Modal.Footer>
          </Modal>
          </div>)
    }
    renderDetaljer(item) {
        switch (item.typ) {
            case "Fordonsskada":
                return <div>
                    <label>Fordon</label>
                    <p>{item.fordonsskada.fordon}</p>
                    <label>Skadeplatskategori</label>
                    <p>{item.fordonsskada.skadeplatskategori.map((row,key) => {
                        return <span key={key}>{row}<br /></span>
                    })} </p>
                    <label>Polis inkopplad</label>
                    <p>{item.fordonsskada.polis ? "Ja" : "Nej"}</p>
                    <label>Räddningstjänst inkopplad</label>
                    <p>{item.fordonsskada.raddningstjanst ? "Ja" : "Nej"}</p>
                    <label>Skador på fordon</label>
                    <p>{item.fordonsskada.skadaPaFordon ? "Ja" : "Nej"}</p>
                    <label>Försäkringskategori</label>
                    <p>{item.fordonsskada.forsakringsKategori}</p>
                    <label>Händelsebeskrivning</label>
                    <p>{item.fordonsskada.handelseBeskrivning}</p>
                    <label>Kategorispecificering</label>
                    <p>{item.fordonsskada.kategoriSpecificering}</p>
                    <label>Anlitades ersattningsfordon</label>
                    <p>{item.fordonsskada.ersattningsfordon ? "Ja" : "Nej"}</p>
                    <label>Beskrivning synliga skador</label>
                    <p>{item.fordonsskada.synligaSkador}</p>
                    <label>Personskada, kontaktuppgifter</label>
                    <p>{item.fordonsskada.personskadekontaktuppgifter}</p>
                    <label>Personskada, kontaktuppgifter motpart</label>
                    <p>{item.fordonsskada.motpartskontaktuppgifter}</p>
                    <label>Blodprov / utandningsprov </label>
                    <p>{item.fordonsskada.blodprov ? "Ja" : "Nej"}</p>
                    <label>Antal passagerare</label>
                    <p>{item.fordonsskada.antalPassagerare}</p>
                    <label>Vittnen</label>
                    <p>{item.fordonsskada.vittnen}</p>
                    <label>Mätarställning</label>
                    <p>{item.fordonsskada.matarstallning}</p>

                </div>;
            case "Trafikinformation":
                return <div>
                    <label>Kategori</label>
                    <p>{item.trafikinformation.hallplatskategori}</p>
                    <label>Händelsebeskrivning</label>
                    <p>{item.trafikinformation.handelseBeskrivning}</p>                
                </div>;
            case "Arbetsmiljöfrågor":
                return <div>
                    <label>Personskador</label>
                    <p>{item.arbetsMiljo.personskador ? "Ja" : "Nej"} </p>

                    <label>Arbetsmoment</label>
                    <p>{item.arbetsMiljo.arbetsmoment.map((row,key) => {
                        return <span key={key}>{row}<br /></span>
                    })} </p>
                    <label>Vad hände</label>
                    <p>{item.arbetsMiljo.vadHande.map((row,key) => {
                        return <span key={key}>{row}<br /></span>
                    })} </p>
                    <label>Var något tillfälligt annorlunda på platsen</label>
                    <p>{item.arbetsMiljo.varNagot.map((row,key) => {
                        return <span key={key}>{row}<br /></span>
                    })} </p>
                    <label>Förhållanden som var som de brukar men kan ha påverkat händelsen</label>
                    <p>{item.arbetsMiljo.forhallanden.map((row,key) => {
                        return <span key={key}>{row}<br /></span>
                    })} </p>
                    <label>Förslag till åtgärder så att liknande händelser undviks i framtiden</label>
                    <p>{item.arbetsMiljo.forslag.map((row,key) => {
                        return <span key={key}>{row}<br /></span>
                    })} </p>

                </div>;
            case "Hot och våld":
                return <div>
                    <label>Händelsebeskrivning</label>
                    <p>{item.hotOchVald.handelseBeskrivning}</p>
                    <label>Personskador information</label>
                    <p>{ item.hotOchVald.externaPersonskadorInfo }</p>
                </div>
                                                
            default: 
                return null;
        }
    }
    
}

export default IncidentList;