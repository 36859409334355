import React, { Component } from "react";
import { Grid, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import AktuelltUpddrag from "../BplMinaUppdrag/AktuelltUppdrag";
import BplMinaUppdrag from "../BplMinaUppdrag/BplMinaUppdrag";
import BplGodkannaTid from "../BplGodkannaTid/BplGodkannaTId";
import Card from "components/Card/Card.jsx";
import Nyheter from "../Nyheter/StartNewsListView";
import MedarbetarundersokningButton from "components/utils/MedarbetarundersokningButton";
import KrishanteringButton from "components/utils/KrishanteringButton";
import MobileDesktop from "components/utils/MobileDesktop";
import BuildNumber from "dashboard/Main/BuildNumber";
import { getClaimSingle } from "system/security";

class Start extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showNews: false,
			showTimereport: false,
			showMinaUppdrag: false,
		};
	}

	componentDidMount() {
		const blackListEnabledClaim = getClaimSingle(
			"BlacklistEnabled",
			this.props.userprofile
		);
		const blackListEnabled = blackListEnabledClaim !== "";
		console.log("blacklist enabled", blackListEnabled);

		const showMinaUppdrag =
			blackListEnabled &&
			getClaimSingle("MinaUppdragEnabled", this.props.userprofile);
		const showTimereport =
			blackListEnabled &&
			getClaimSingle("LinkTimereportEnabled", this.props.userprofile);
		const showNews =
			blackListEnabled && getClaimSingle("NewsEnabled", this.props.userprofile);

		if (showNews || !blackListEnabled) {
			this.setState({
				showNews: true,
			});
		}

		if (showTimereport || !blackListEnabled) {
			this.setState({
				showTimereport: true,
			});
		}
		if (showMinaUppdrag || !blackListEnabled) {
			this.setState({
				showMinaUppdrag: true,
			});
		}
	}

	render() {
		return (
			<Grid fluid>
				<Row>
					<Col sm={12}>
						<BuildNumber />
					</Col>
				</Row>
				<MobileDesktop
					mobileView={
						<Row>
							<div id="undersok">
								<Col sm={12} className="text-center">
									<Card content={<MedarbetarundersokningButton />} />
								</Col>
							</div>
							<Col sm={12} className="text-center">
								<Card content={<KrishanteringButton />} />
							</Col>
						</Row>
					}
				/>

				<Row>
					<Col sm={6}>
						{this.state.showMinaUppdrag ? <AktuelltUpddrag /> : null}
					</Col>
					<Col sm={6}>
						{this.state.showTimereport ? <BplGodkannaTid /> : null}
					</Col>
				</Row>

				{this.state.showMinaUppdrag ? (
					<Row>
						<Col sm={12}>
							<BplMinaUppdrag />
						</Col>
					</Row>
				) : null}
				{this.state.showNews ? (
					<Row>
						<Col sm={12}>
							<Nyheter />
						</Col>
					</Row>
				) : null}
			</Grid>
		);
	}
}

function mapStateToProps(state) {
	return {
		user: state.oidc.user,
		userprofile: state.userprofile.userprofile,
		channels: state.subscriptions.channels,
	};
}

export default connect(mapStateToProps)(Start);
