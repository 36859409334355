import React from 'react';
import Button from 'components/CustomButtons/CustomButton.jsx';
import axios from "axios";
import  { infraConfig } from "config/infraConfig";
import { getAxiosConfig } from "system/axiosConfig";

function downloadPDF(pdf, filename) {
  const linkSource = `data:application/pdf;base64,${pdf}`;
  const downloadLink = document.createElement("a");

  downloadLink.href = linkSource;
  downloadLink.download = filename;
  downloadLink.setAttribute("type", "hidden"); 
  document.body.appendChild(downloadLink);
  downloadLink.click();
  //downloadLink.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));
  // downloadLink.remove();


}

class LonebeskedButton extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      loading: false
    }
  }
  render() {
    return (
      <Button fill bsStyle="primary" onClick={ () => {           
            
        this.setState({loading:true});

          console.log('click download');
          
          axios.get(infraConfig.apiEndpoint.concat('/api/lonespec/kontek/downloadspec/?salaryPeriodId=').concat(this.props.dataItem.salaryPeriodId), getAxiosConfig(this.props.user))
          .then((dataResult) => {
            // console.log(dataResult);
            downloadPDF(dataResult.data.result.pdfStr, dataResult.data.result.salaryPeriodName+'.pdf');
            this.setState({loading:false});
            
          })
          .catch((error) => {
            this.setState({loading:false});
            //console.log(error)
          });
  
          }}>

        {this.state.loading ? <span><span className="fa fa-spin fa-spinner" />Laddar ner</span>:<span><span className="fa fa-download" />Ladda ner</span>} 
      </Button>
    )}       
}


export default LonebeskedButton;
