import { POLICYDOCS_GET_DOCUMENTS, 
    POLICYDOCS_GET_DOCUMENTS_SUCCESS,
    POLICYDOCS_GET_DOCUMENTS_FAILED,  
    POLICYDOCS_GET_FILE,
    POLICYDOCS_GET_FILE_SUCCESS,
    POLICYDOCS_GET_FILE_FAILED
  } from "./actions";
  
  const initialState = {
        documents: [],
        isLoadingDocuments: false,
        loadingDocumentsFailed: false,
        documentsTimestamp: null,

        downloadFile: '',
        isLoadingDownloadFile: false,
        loadingDownloadFileFailed: false,
        downloadFileTimestamp: null,
    };
  
  export default function(state = initialState, action) {
    switch (action.type) {
        case POLICYDOCS_GET_DOCUMENTS:
            return { ...state, isLoadingDocuments: true, loadingDocumentsFailed: false }
        case POLICYDOCS_GET_DOCUMENTS_SUCCESS:
            return { ...state, isLoadingDocuments: false, documents: action.payload.data.result, documentsTimestamp: action.payload.data.timestamp };
        case POLICYDOCS_GET_DOCUMENTS_FAILED:
            return { ...state, isLoadingDocuments: false, loadingDocumentsFailed: true, documents: [], documentsTimestamp: null };

        case POLICYDOCS_GET_FILE:
            return { ...state, isLoadingDownloadFile: true, loadingDownloadFileFailed: false }
        case POLICYDOCS_GET_FILE_SUCCESS:
            return { ...state, isLoadingDownloadFile: false, downloadFile: action.payload.data.result, downloadFileTimestamp: action.payload.data.timestamp };
        case POLICYDOCS_GET_FILE_FAILED:
            return { ...state, isLoadingDownloadFile: false, loadingDownloadFileFailed: true, downloadFile: [], downloadFileTimestamp: null };

        default:
            return state;
    }
  }