import React from 'react';
import LonebeskedButton from "./LonebeskedButton";

class LonebeskedList extends React.Component {

  render(){
        return (

  <table className="table table-striped">
    <thead>
      <tr>
        <th>Period</th>
        <th>Utbetalningsdag</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
        {this.props.lonebesked.map((item, key) => {
            return <tr key={key}>
              <td>{item.salaryPeriodName}</td>
              <td>{item.salaryPeriodPaymentDate}</td>
              <td><LonebeskedButton dataItem={item} user={this.props.user} /></td>
            </tr>
        })}
        </tbody>
</table>

            );
    }
}

export default LonebeskedList;
