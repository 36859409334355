import axios from "axios";
import  { infraConfig } from "config/infraConfig";
import { getAxiosConfig } from "system/axiosConfig";
import store from "system/store";
import { ERRORMESSAGES_ADD } from "system/actions";
import { openFileWindow } from "system/util.js";

export const KRISHANTERING_GET_DOCUMENTS = "KRISHANTERING_GET_DOCUMENTS";
export const KRISHANTERING_GET_DOCUMENTS_SUCCESS = "KRISHANTERING_GET_DOCUMENTS_SUCCESS";
export const KRISHANTERING_GET_DOCUMENTS_FAILED = "KRISHANTERING_GET_DOCUMENTS_FAILED";

export const KRISHANTERING_GET_FILE = "KRISHANTERING_GET_FILE";
export const KRISHANTERING_GET_FILE_SUCCESS = "KRISHANTERING_GET_FILE_SUCCESS";
export const KRISHANTERING_GET_FILE_FAILED = "KRISHANTERING_GET_FILE_FAILED";

export const KRISHANTERING_GET_BOSSINFO = "KRISHANTERING_GET_BOSSINFO";
export const KRISHANTERING_GET_BOSSINFO_SUCCESS = "KRISHANTERING_GET_BOSSINFO_SUCCESS";
export const KRISHANTERING_GET_BOSSINFO_FAILED = "KRISHANTERING_GET_BOSSINFO_FAILED";


export function fetchBossInfo() {
    
    return (dispatch) => {
        dispatch({type: KRISHANTERING_GET_BOSSINFO});

        const user = store.getState().oidc.user;
        axios.get(infraConfig.apiEndpoint.concat('/api/krishantering/bossinfo'), getAxiosConfig(user))
        .then((dataResult) => dispatch({type: KRISHANTERING_GET_BOSSINFO_SUCCESS, payload: dataResult}))
        .catch((error) => { dispatch({type: KRISHANTERING_GET_BOSSINFO_FAILED}); dispatch({type: ERRORMESSAGES_ADD, payload: { type: 'network', message: 'Hämta närmsta chef information misslyckades', severity: 'warning'}});});

    }
    
};

export function fetchDocumentsByCompanyArea() {
    
    return (dispatch) => {
        dispatch({type: KRISHANTERING_GET_DOCUMENTS});

        const user = store.getState().oidc.user;
        axios.get(infraConfig.apiEndpoint.concat('/api/krishantering/documents/bycompanyarea'), getAxiosConfig(user))
        .then((dataResult) => dispatch({type: KRISHANTERING_GET_DOCUMENTS_SUCCESS, payload: dataResult}))
        .catch((error) => { dispatch({type: KRISHANTERING_GET_DOCUMENTS_FAILED}); dispatch({type: ERRORMESSAGES_ADD, payload: { type: 'network', message: 'Hämta dokument misslyckades', severity: 'warning'}});});

    }
    
};

export function downloadDocumentFileByCompanyArea(filename) {
    return (dispatch) => {
        dispatch({type: KRISHANTERING_GET_FILE});

        const user = store.getState().oidc.user;
        axios.get(infraConfig.apiEndpoint.concat('/api/krishantering/downloadfile/bycompanyarea/?filename=').concat(filename), getAxiosConfig(user))
        .then((dataResult) => {
            dispatch({type: KRISHANTERING_GET_FILE_SUCCESS, payload: dataResult});
    
            //console.log('got file', dataResult);
            openFileWindow(dataResult.data.result);
        }
        
        )
        .catch((error) => { dispatch({type: KRISHANTERING_GET_FILE_FAILED}); dispatch({type: ERRORMESSAGES_ADD, payload: { type: 'network', message: 'Hämta dokumentfil misslyckades', severity: 'warning'}});});

    }

}

export function fetchDocumentsByCompanyPosition() {
    
    return (dispatch) => {
        dispatch({type: KRISHANTERING_GET_DOCUMENTS});

        const user = store.getState().oidc.user;
        axios.get(infraConfig.apiEndpoint.concat('/api/krishantering/documents/bycompanyposition'), getAxiosConfig(user))
        .then((dataResult) => dispatch({type: KRISHANTERING_GET_DOCUMENTS_SUCCESS, payload: dataResult}))
        .catch((error) => { dispatch({type: KRISHANTERING_GET_DOCUMENTS_FAILED}); dispatch({type: ERRORMESSAGES_ADD, payload: { type: 'network', message: 'Hämta dokument misslyckades', severity: 'warning'}});});

    }
    
};

export function downloadDocumentFileByCompanyPosition(filename) {
    return (dispatch) => {
        dispatch({type: KRISHANTERING_GET_FILE});

        const user = store.getState().oidc.user;
        axios.get(infraConfig.apiEndpoint.concat('/api/krishantering/downloadfile/bycompanyposition/?filename=').concat(filename), getAxiosConfig(user))
        .then((dataResult) => {
            dispatch({type: KRISHANTERING_GET_FILE_SUCCESS, payload: dataResult});
    
            //console.log('got file', dataResult);
            openFileWindow(dataResult.data.result);
        }
        
        )
        .catch((error) => { dispatch({type: KRISHANTERING_GET_FILE_FAILED}); dispatch({type: ERRORMESSAGES_ADD, payload: { type: 'network', message: 'Hämta dokumentfil misslyckades', severity: 'warning'}});});

    }

}

