import axios from "axios";
import  { infraConfig } from "config/infraConfig";
import { getAxiosConfig } from "system/axiosConfig";
import store from "system/store";
import { ERRORMESSAGES_ADD } from "system/actions";


export const ECODRIVING_GET_DETAIL ="ECODRIVING_GET_DETAIL";
export const ECODRIVING_GET_DETAIL_SUCCESS ="ECODRIVING_GET_DETAIL_SUCCESS";
export const ECODRIVING_GET_DETAIL_FAILED ="ECODRIVING_GET_DETAIL_FAILED";

export const ECODRIVING_GET_OVERVIEW ="ECODRIVING_GET_OVERVIEW";
export const ECODRIVING_GET_OVERVIEW_SUCCESS ="ECODRIVING_GET_OVERVIEW_SUCCESS";
export const ECODRIVING_GET_OVERVIEW_FAILED ="ECODRIVING_GET_OVERVIEW_FAILED";


export function fetchEcodrivingOverview() {
    
    return (dispatch) => {
        dispatch({type: ECODRIVING_GET_OVERVIEW});

        const user = store.getState().oidc.user;
        axios.get(infraConfig.apiEndpoint.concat('/api/bpl/ecodriving/overview'), getAxiosConfig(user))
        .then((dataResult) => dispatch({type: ECODRIVING_GET_OVERVIEW_SUCCESS, payload: dataResult}))
        .catch((error) => { dispatch({type: ECODRIVING_GET_OVERVIEW_FAILED}); dispatch({type: ERRORMESSAGES_ADD, payload: { type: 'network', message: 'Hämta ecodriving overview misslyckades', severity: 'warning'}});});        

    }
    
};
export function fetchEcodrivingDetail(date) {
    
    return (dispatch) => {
        dispatch({type: ECODRIVING_GET_DETAIL});

        const user = store.getState().oidc.user;
        axios.get(infraConfig.apiEndpoint.concat('/api/bpl/ecodriving/detail?date=').concat(date), getAxiosConfig(user))
        .then((dataResult) => dispatch({type: ECODRIVING_GET_DETAIL_SUCCESS, payload: dataResult}))
        .catch((error) => { dispatch({type: ECODRIVING_GET_DETAIL_FAILED}); dispatch({type: ERRORMESSAGES_ADD, payload: { type: 'network', message: 'Hämta ecodriving detail misslyckades', severity: 'warning'}});});        

    }
    
};
