
export const AUTH_LOGOUTINPROGRESS = "AUTH_LOGOUTINPROGRESS";
export const AUTH_LOGOUTFINISHED = "AUTH_LOGOUTFINISHED";

export function logoutInProgress() {
  return {
    type: AUTH_LOGOUTINPROGRESS
  };
}

export function logoutFinished() {
  return {
    type: AUTH_LOGOUTFINISHED
  };

}