import React from 'react';
import AttachmentListItem from "./AttachmentListItem";
import moment from 'moment';
import NewsComments from "./NewsComments";
import { connect } from "react-redux";
import axios from 'axios';
import  { infraConfig } from "config/infraConfig";
import { getAxiosConfig } from "system/axiosConfig";

// import 'moment/locale/sv';

// moment().locale('sv');

class NewsItem extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            a:false
        }
    }

    componentDidMount() {
        
        this.setState(prevState => ({
            a: !prevState.a
          }), () => {
            this.getImages();
          });

    }

    getImages() {
        console.log('component did mount',this.props.item.id);
        var newsImages = document.querySelectorAll('#newsitem' + this.props.item.id + ' .download-image');

        for (var i = 0; i < newsImages.length;i++) 
        {

            var newsId = newsImages[i].getAttribute('data-imageid')
            if (newsId.length > 0) {
                console.log('found image', newsId);

                axios.get(infraConfig.apiEndpoint.concat('/api/nyheter/getimage/').concat(newsId), getAxiosConfig(this.props.user))
                .then((dataResult) => {
                    
                    var el = document.querySelector('#newsImage' + dataResult.data.result.imageId);
                    if (el)
                    {
                        console.log('el style', el.style);

                        var newEl = document.createElement('img');
                        newEl.src = "data:" + dataResult.data.result.contentType +";base64, " + dataResult.data.result.data;
                        newEl.style.cssText = el.style.cssText;
                        newEl.style.margin = 0;
                        newEl.style.marginBottom = "5px";
                        
                        el.parentNode.replaceChild(newEl, el);    
                    } else {
                        console.log('image dataresult failed', dataResult);
                    }

                });    
            }
        }
    }

    render() {
        const publishedString = moment(this.props.item.publishedDate).format("LL");

        return <div>
            <p className="news-title">{this.props.item.title}</p>
            <div id={"newsitem" + this.props.item.id} className="news-body" dangerouslySetInnerHTML={ {__html: this.props.item.bodyText}}></div>
            { this.props.item.newsAttachments.length > 0 ? 
                <div className="news-attachments">
                    <ul className="list-group">
                        <li className="list-group-item">Bifogade filer</li>
                        { this.props.item.newsAttachments.map((attachment, key) => {
                            return <AttachmentListItem key={key} attachment={attachment} />
                        })}
                    </ul></div>
            : null}
            <div className="news-footer">
                Publicerad {publishedString} av {this.props.item.authorName}
            </div>
            <div className="news-comments">
                <NewsComments newsId={this.props.item.id} />
            </div>
        </div>;
    } 
}

function mapStateToProps(state) {
    return {
      user: state.oidc.user
    };
  }
export default connect(mapStateToProps)(NewsItem);
