import React from 'react';
import { connect } from "react-redux";
import { fetchBossInfo } from "./actions";
import LoadingIcon from "components/utils/LoadingIcon";
import Card from "components/Card/Card";

class BossInfo extends React.Component {

    componentDidMount() {
        this.props.fetchBossInfo();
    }

    render() { 
        return (<Card
        content={<LoadingIcon text="Laddar kontaktinformation närmsta chef.." isLoading={this.props.isLoadingBossInfo} loadingFailed={this.props.loadingBossInfoFailed} success={
            this.props.bossInfo !== null ? 
            <div><p>Kontaktinformation närmsta chef</p>
            <p><label>Namn: </label> {this.props.bossInfo.firstname} {this.props.bossInfo.lastname}</p>
            <p><label>Telefon: </label> {this.props.bossInfo.telephoneNumber}</p>
            <p><label>Mobil: </label> {this.props.bossInfo.mobileNumber}</p>
            
            </div>
        : null
        } />
        }
        
        />);
    }
}



function mapStateToProps(state) {
    return {
      bossInfo: state.krishantering.bossInfo,
      bossInfoTimestamp: state.krishantering.bossInfoTimestamp,
      isLoadingBossInfo: state.krishantering.isLoadingBossInfo,
      loadingBossInfoFailed: state.krishantering.loadingBossInfoFailed
    };
  }
  
  export default connect(mapStateToProps, { fetchBossInfo })(BossInfo);