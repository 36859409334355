import React from "react";
import { connect } from "react-redux";
import userManager from "../system/userManager";
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButtons/CustomButton.jsx";
import {Grid, Col, Row } from "react-bootstrap";
import LoadingIcon from "components/utils/LoadingIcon";
import SimpleStandaloneHeader from "../components/utils/SimpleStandaloneHeader";

class LoginPage extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      showRedirectText: false,
      redirectFailed: false
    }

  }
  
  componentDidMount() {

    this.login();

  }

  login() {

    this.setState({
      showRedirectText: false,
      redirectFailed: false
    }, () => {
      if (!this.props.logoutInProgress) {
        this.setState({
          showRedirectText: true
        }, () => {
          userManager.signinRedirect().then(respone => {
            console.log('after signinredirect');
          }).catch(e => {
            this.setState({
              redirectFailed: true
            });
            console.log('error signinredirect',e)
          });
        });
      }
    });


  }
  render() {

    return (      
      <div className="main-content">
      <SimpleStandaloneHeader />
        <Grid fluid>
          <Row>
            <Col sm={12}>
            <br />
            <Card content={
              <div className="text-center">
              {this.state.redirectFailed ? <div><h4><i className="fa fa-exclamation-triangle text-danger" />&nbsp;Ett fel inträffade.</h4>
              <p><Button fill block bsStyle="success" onClick={() => this.login()}>Försök igen</Button></p>
              </div> : <div>
                {this.state.showRedirectText ? <p><LoadingIcon isLoading={true} /> Loggar in..</p> : null }
                {this.props.logoutInProgress ? <p><LoadingIcon isLoading={true} /> Loggar ut..</p> : null }
                </div>
              }
              </div> 
            } />
            </Col>
          </Row>
      </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.oidc.user,
    logoutInProgress: state.authhelper.logoutInProgress
  };
}

export default connect(mapStateToProps)(LoginPage);
