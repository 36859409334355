
import React, { Component} from "react";
import {Grid, Row, Col} from "react-bootstrap";
import Date from "components/formatting/Date";
import 'assets/css/detailcard.css';

class ForslagCard extends Component {

    componentDidMount() {
    }
    render() {
        return (
        <div className="card"><div className="content">
        <Grid fluid>
            <Row>
                <Col xs={6}>
                    <div className="detailcard"><div className="detailcard-label">Datum</div>
                        <Date value={this.props.item.datumForslag} />
                    </div>
                    
                </Col>
                
                <Col xs={6}>
                    <div className="detailcard pull-right"><div className="detailcard-label">Status</div>
                        {this.props.item.status}
                    </div>
                    
                </Col>
            </Row>
           <Row>
           <Col xs={12}>
                <div className="detailcard"><div className="detailcard-label">Skapad av</div>
                    {this.props.item.skapadAv}
                </div>               
            </Col>
           </Row>
           <Row>
           <Col xs={12}>
                <div className="detailcard"><div className="detailcard-label">Beskrivning</div>
                    {this.props.item.beskrivning}
                </div>               
            </Col>
           </Row>
           <Row>
           <Col xs={12}>
                <div className="detailcard"><div className="detailcard-label">Kommentarer</div>
                    {this.props.item.kommentarer}
                </div>               
            </Col>
           </Row>
           <Row>
           <Col xs={12}>
                <div className="detailcard"><div className="detailcard-label">Åtgärder</div>
                    {this.props.item.atgarder}
                </div>               
            </Col>
           </Row>
        </Grid></div></div>)
    }
}


export default ForslagCard;