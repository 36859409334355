import React from 'react';

export default function LeafPercentageIcon(props) {

    // props.value är 1 för 100%
    const icon = props.value < 0.95 
    ? <i className="pe-7s-leaf text-success" />
    : props.value >= 0.95 && props.value <= 1.05
    ? <i className="pe-7s-leaf text-warning" />
    : props.value > 1.05
    ? <i className="pe-7s-leaf text-danger" /> : null

    return icon;
}