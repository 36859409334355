// formuläret från startsidan (genvägen) det förenklade
import { connect } from "react-redux";
import { fetchFormOptions } from "./actions";
import { getVehicles } from "../Fordonskontroll/actions";

import React, { Component} from "react";
import FormArbetsmiljo from "./FormArbetsmiljo";
import FormHotOchVald from "./FormHotOchVald";
import FormTrafikinformation from "./FormTrafikinformation";
import FormFordonsskada from "./FormFordonsskada";
import LoadingIcon from "components/utils/LoadingIcon";
import Button from "components/CustomButtons/CustomButton";
import Card from 'components/Card/Card.jsx';
import {
    Row,
    Col,
  } from "react-bootstrap";
  import {
    FormGroup,
  } from "react-bootstrap";
  import  { appConfig } from "config/appConfig";

class FormH extends Component {
constructor(props){
    super(props);

    this.state = {
        addNewMode: 0
    }
}

componentDidMount(){
    this.props.fetchFormOptions();
    if(!appConfig.disableFK) {
        this.props.getVehicles();
    }
}


switchAddNewForm(mode) {
    switch (mode) {
        case 1:
            return <Card title="Fordonsskada" content={<FormFordonsskada disableFK={!!appConfig.disableFK} formOptions={this.props.formOptions} vehicles={this.props.vehicles} />} />
        case 2:
            return <Card title="Hot och Våld" content={<FormHotOchVald formOptions={this.props.formOptions} vehicles={this.props.vehicles} />} />
        case 3:
            return <Card title="Arbetsmiljö" content={<FormArbetsmiljo formOptions={this.props.formOptions} vehicles={this.props.vehicles} />} />
        case 4:
                return <Card title="Trafik- och Kundinformation" content={<FormTrafikinformation formOptions={this.props.formOptions} />} />
        default:
            return null;
    }
}
selectAddNewMode(mode) {
    this.setState({
        addNewMode: mode
    });
}
  render() {
return (<div className="container-fluid"><Row><Col sm={12}><Card content={
<LoadingIcon text="Laddar formulärdata.." 
loadingFailed={ this.props.loadingFormOptionsFailed || this.props.loadingVehiclesFailed }
isLoading={ this.props.isLoadingFormOptions || this.props.isLoadingVehicles } 
success={<div>
    
    <Row>
        <Col lg={3}>
            <FormGroup>
                <Button fill block bsStyle="primary" onClick={() => { this.selectAddNewMode(1); }}>Fordonsskada</Button>
            </FormGroup>
        </Col>
        <Col lg={3}>
            <FormGroup>
                <Button fill block bsStyle="primary" onClick={() => { this.selectAddNewMode(2); }}>Hot och Våld</Button>
            </FormGroup>
        </Col>
        <Col lg={3}>
            <FormGroup>
                <Button fill block bsStyle="primary" onClick={() => { this.selectAddNewMode(3); }}>Arbetsmiljö</Button>
            </FormGroup>
        </Col>
        <Col lg={3}>
            <FormGroup>
                <Button fill block bsStyle="primary" onClick={() => { this.selectAddNewMode(4); }}>Trafik- och Kundinformation</Button>
            </FormGroup>
        </Col>
    </Row>
    </div>} />} />
    </Col>
    </Row>    
    <Row>
        <Col sm={12}>
            { this.switchAddNewForm(this.state.addNewMode) }
        </Col>
    </Row> 
    
    </div>)
  }
}

function mapStateToProps(state) {
    return {
        user: state.oidc.user,
        formOptions: state.incident.formOptions,
        formOptionsTimestamp: state.incident.formOptionstimestamp,
        isLoadingFormOptions: state.incident.isLoadingFormOptions,
        loadingFormOptionsFailed: state.incident.loadingFormOptionsFailed,
        vehicles: state.fordonskontroll.vehicles,
        isLoadingVehicles: state.fordonskontroll.isLoadingVehicles,
        loadingVehiclesFailed: state.fordonskontroll.loadingVehiclesFailed,    
    };
  }
  
export default connect(mapStateToProps, { fetchFormOptions, getVehicles })(FormH);
