import React, { Component } from "react";
import Sidebar from './Sidebar/Sidebar.jsx';
import Header from './Header/Header.jsx';
import Footer from './Footer/Footer.jsx';
import NoAccess from "./NoAccess.jsx"
import { checkDashboardRouteAccess } from "system/security";

// import PerfectScrollbar from "react-perfect-scrollbar";
// import 'react-perfect-scrollbar/dist/css/styles.css';

class Main extends Component {
    componentDidMount() {
        this.setState({ _notificationSystem: this.refs.notificationSystem });
      }

      componentWillMount() {

        if (document.documentElement.className.indexOf("nav-open") !== -1) {
          document.documentElement.classList.toggle("nav-open");
        }
      }

      render() {
      
      const childrenWithExtraProp = React.Children.map(this.props.children, child => {
        return React.cloneElement(child, {
          router: this.props.router,
          onUpdateSize: () => { this._scrollBarRef.updateScroll()}
        });
      });
      // console.log('main props', this.props);

      return (       
        <div className="wrapper">
            <Sidebar {...this.props} />                
            <div className="main-panel" ref="mainPanel">
              {/* <PerfectScrollbar ref={(ref) => { this._scrollBarRef = ref; }} > */}

            <Header {...this.props} />
            <div className="main-content">
              { 
                !checkDashboardRouteAccess(this.props.routes, this.props.userprofile) ? <NoAccess /> 
                : childrenWithExtraProp 
                }
            </div>
            <Footer />         
            {/* </PerfectScrollbar> */}
          </div>
        </div>
        );
    }
}


export default Main;

