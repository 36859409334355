import React, { Component} from "react";
import moment from 'moment';

class Time extends Component {

    render() {

        return (<span>{this.props.value ? 
            this.props.withSeconds 
            ? moment(this.props.value).format('HH:mm:ss') 
            : moment(this.props.value).format('HH:mm') : null}</span>);
    }

}
export default Time;
