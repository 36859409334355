import React from 'react';

class KommunikationsSamordnare extends React.Component {

    render() {
        return(<div>
        <p>1.	Producera och distribuera information (se rutiner för kriskommunikation)</p>
        <p>2.	Säkerställ att intern och extern information samordnas/synkas</p>
        <p>3.	Säkerställ att krisgruppen beslutar om vem som informerar arbetsmiljöverket, Länsstyrelse, Kommunens ledning och övriga intressenter såsom Länstrafikbolag, Kollektivtrafikmyndigheter m.fl. </p>
        <p>4.	Se till att Intranätet uppdateras ofta och regelbundet</p>
        <p>5.	Se till att växeln får tillgång till all information som skickas ut</p>
        <p>6.	Omvärldsbevaka via alla kanaler och förse krisledaren med information</p>
        <p>7.	Utvärdera informationsbehovet utifrån varje situation och rekommendera insatser</p>
        <p>8.	Vid behov/om nödvändigt, anordna pressträff:</p>
        <ul>
            <li>Se till att endast journalister deltar</li>
            <li>Undvik att hålla träffen i egna lokaler om möjligt (ingen logotyp i lokalen)</li>
            <li>Ta eventuellt in andra aktörer som medverkande, t ex räddningstjänst eller länsstyrelse</li>
            <li>Se till att pressmeddelande eller annan dokumentation kan delas ut till deltagarna</li>
        </ul>
        <p>9.	Sveriges Radios trafik- och samhällsinformation, tel 020-999 444, kan snabbt informera om händelser som behöver komma till allmänhetens kännedom</p>
        <p>10.	Vid större bussolycka meddela Sveriges Bussföretag om det inträffade samt vilka åtgärder som vidtagits. Fråga gärna om råd om fortsatt hantering av media. Det bistår i arbetet vid stora kriser och katastrofer</p>
        </div>)
    } 
}

export default KommunikationsSamordnare;
