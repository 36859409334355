import React from 'react';
import { connect } from "react-redux";
import Forare from "./CheckLists/Forare";
import Trafikledare from "./CheckLists/Trafikledare";
import { getClaimArray } from "system/security";

class CheckList extends React.Component {
    
        renderChecklist(positions){
           
          // console.log('is array', Array.isArray(positions));

           if (Array.isArray(positions)) {
               if ((positions.indexOf("Bussförare Linjetrafik") > -1) 
                    || (positions.indexOf("Bussförare Beställning") > -1)
                    || (positions.indexOf("Taxi- Sjukbilsförare") > -1)) 
               {
                   return <Forare />
               } else if ((positions.indexOf("Trafikansvarig Linje") > -1)
                    || (positions.indexOf("Trafikledare Beställning") > -1)
                    || (positions.indexOf("VD") > -1)
                    || (positions.indexOf("Vice VD") > -1)
                    || (positions.indexOf("Depåansvarig") > -1)
                    || (positions.indexOf("Verkstadschef") > -1)
                    || (positions.indexOf("Driftledare") > -1)
                ) 
                {
                    return <Trafikledare />
               } else {
                   return null;
               }

            } else {
                if (positions === "Bussförare Linjetrafik" || positions === "Bussförare Beställning" || positions === "Taxi- Sjukbilsförare") {
                    return <Forare />
                } else if (positions === "Trafikansvarig Linje" || positions === "Trafikledare Beställning" || positions === "VD" || positions === "Vice VD" || positions === "Depåansvarig" || positions === "Verkstadschef" || positions === "Driftledare") {
                    return <Trafikledare />
                } else {
                    return null;
                }
           }

        }

        render(){ 
            return (
                <div>{this.renderChecklist(getClaimArray("IntraPosition", this.props.userprofile))}</div>
            );
    }
}



function mapStateToProps(state) {
    return {
        user: state.oidc.user,
        userprofile: state.userprofile.userprofile
    };
  }
  
  export default connect(mapStateToProps)(CheckList);