import React, { Component } from "react";
import { connect } from "react-redux";
import Main from './Main.jsx';
import LoadingIcon from "components/utils/LoadingIcon";
import {Grid, Col, Row } from "react-bootstrap";
import Card from "components/Card/Card.jsx";

import { getUserProfile } from "system/userprofile/actions";
import SimpleStandaloneHeader from "../../components/utils/SimpleStandaloneHeader.jsx";
import Button from "components/CustomButtons/CustomButton";

class UserProfileHC extends Component {
   
      componentWillMount() {
        this.props.getUserProfile();

      }

    render() {
      
     
      return (
        this.props.userprofile ? 
        <Main {...this.props} />
        : <div className="main-content">
          <SimpleStandaloneHeader />
          <Grid fluid>
            <Row>
              <Col sm={12}>
                <Card content={
                  <div className="text-center">
                  <LoadingIcon isLoading={this.props.userprofileIsLoading} loadingFailed={this.props.userprofileLoadingFailed} text="Hämtar användarprofil" /> 
                  { this.props.userprofileLoadingFailed ? <p><Button bsStyle="primary" fill onClick={() => {
	                   window.location.href = '/login';
                    }}>Försök igen</Button></p> : null}
                  </div>
                } />
              </Col>
            </Row>
          </Grid>          
        </div>
        );
    }
}

function mapStateToProps(state) {
  return {
    userprofile: state.userprofile.userprofile,
    userprofileIsLoading: state.userprofile.userprofileIsLoading,
    userprofileLoadingFailed: state.userprofile.userprofileLoadingFailed,
  };
}

export default connect(mapStateToProps, { getUserProfile })(UserProfileHC);

