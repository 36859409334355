import React from 'react';
import { connect } from "react-redux";
import axios from 'axios';
import  { infraConfig } from "config/infraConfig";
import { getAxiosConfig } from "system/axiosConfig";
import Button from "components/CustomButtons/CustomButton";
import moment from 'moment';

class NewsComment extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            editMode: false,
            showDelete: false,
            isDeleting: false,
            isSaving: false,
            commentError: null,
            savingFailed: false
        }
        this.isCommentValid = true;
    }
    
    componentDidMount() {
        const { item  } = this.props;
        this.setState({
            bodyText: item.bodyText
        });
    }

    deleteComment() {

        console.log('deleting in comment');
        this.setState({
            isDeleting: true
        });
        axios.delete(infraConfig.apiEndpoint.concat('/api/nyheter/deletecomment?commentId=').concat(this.props.item.id), getAxiosConfig(this.props.user))
        .then((dataResult) => {            

            this.props._handleDelete(this.props.item.id);

        });

    }

    saveEditComment() {
        
        console.log('saving');

        this.validateComment();

        if (this.isCommentValid) {
            this.setState({
                isSaving: true,
                savingFailed: false
            });
    
            axios.post(infraConfig.apiEndpoint.concat('/api/nyheter/savecomment'), {
                id: this.props.item.id,
                bodyText: this.state.bodyText,
                newsItemId: this.props.item.newsItemId
            }, getAxiosConfig(this.props.user))
            .then((dataResult) => {
                
                  this.setState({
                    editMode: false,
                    showDelete: false,
                    isSaving: false,
                    savingFailed: false
                });
        
    
            }).catch(() => {
                this.setState({
                    isSaving: false,
                    savingFailed: true
                });
            });

        }

    }

    validateComment() {
        const { maxLength } = this.props;
        if (this.state.bodyText.length > 0 && this.state.bodyText.length <= maxLength) {
            this.isCommentValid = true;

            this.setState({
                commentError: null
            });
        } else {
            this.isCommentValid = false;
            this.setState({
                commentError: `Kommentar får inte vara tomt och inte överskrida ${String(maxLength)} tecken`
            })
        }
    }
    render() {
        const { maxLength } = this.props;
        return this.state.editMode ? <li className="list-group-item">
        <p className="news-comment-title">{this.props.item.authorName}</p>
        <div className="form-group">
            <label className="form-label">Redigera innehåll:</label>
            <div className="pull-right news-comment-charcounter">{this.state.bodyText.length} / {maxLength}</div>

            <textarea className={this.state.commentError !== null ? 'form-control error' : 'form-control'} value={this.state.bodyText} onChange={(event) => {
                this.setState({
                    bodyText: event.target.value.substring(0, maxLength)
                }, () => {
                    this.validateComment();
                });
            }}></textarea>
            <span className="text-danger">{this.state.commentError}</span>

        </div>
        { this.state.isSaving 
        ? <span><i className="fa fa-spinner fa-spin"></i> Sparar..</span>
        : <div className="form-group">
        <Button onClick={() => {
            this.saveEditComment();
        }} fill bsStyle="primary" bsSize="xs"><i className="fa fa-check"></i> Spara</Button>&nbsp;
            <Button onClick={() => {
                this.setState({
                    editMode: false,
                    bodyText: this.props.item.bodyText,
                    savingFailed: false
                    });
            }} fill bsStyle="warning" bsSize="xs"><i className="fa fa-times"></i> Avbryt</Button>
        </div>
        }
        { this.state.savingFailed ? <span className="text-danger"><i className="fa fa-times"></i>Ett fel inträffade när kommentaren skulle sparas</span> : null}

    </li>
    : <li className="list-group-item">
    <p className="news-comment-title">{this.props.item.authorName}</p>
    <p style={{overflowWrap: 'break-word'}}>{this.state.bodyText}</p>
    <p className="news-comment-footer">{moment(this.props.item.createdDate).format("LLL")}</p>                
    {this.props.item.canEdit && !this.state.showDelete ? 
        <div className="btn-group">
            <Button onClick={() => {
                this.setState({
                    editMode: true
                });
            }} fill bsStyle="warning" bsSize="xs"><i className="fa fa-edit"></i> Redigera</Button>
            <Button fill bsStyle="danger" bsSize="xs" onClick={() => {
                this.setState({
                    showDelete: true
                })
            }}><i className="fa fa-times"></i> Radera</Button>                
        </div> : null }
        { this.state.showDelete ? <div className="alert alert-danger">
            <p>Radera inlägget?</p>
            { this.state.isDeleting 
            ? <p><i className="fa fa-spinner fa-spin"></i> Raderar..</p> 
            : <div>
            <Button onClick={() => {
                this.deleteComment();
            }} fill bsStyle="danger" bsSize="xs"><i className="fa fa-check"></i> Ja</Button>&nbsp;
            <Button onClick={() => {
                this.setState({
                    showDelete: false
                });
            }} fill bsStyle="danger" bsSize="xs"><i className="fa fa-times"></i> Nej</Button>
            </div>
            }
            
        </div> : null }
    
        </li>

    }
}

function mapStateToProps(state) {
    return {
      user: state.oidc.user,
      userprofile: state.userprofile.userprofile
    };
  }
export default connect(mapStateToProps)(NewsComment);
