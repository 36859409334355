import React from 'react';
import { connect } from "react-redux";
import Card from 'components/Card/Card.jsx';
import LoadingIcon from 'components/utils/LoadingIcon';

class FordonsFelList extends React.Component {
    render(){ 
        return (<div>
            <LoadingIcon isLoading={this.props.isLoadingVehicleFaults} text="Laddar felrapporter.." loadingFailed={this.props.loadingVehicleFaultsFailed} />
            {this.props.vehicleFaults.map((item, key) => {
                return <Card plain key={key} content={
                    <div>
                        <p>{item.timestamp} <span className="text-uppercase">{item.faultCategoryName}</span></p>
                        <p>{item.text}</p>
                    </div>
                    
                } />
            })}

        </div>);
    }
}

function mapStateToProps(state) {
    return {
        vehicleFaults: state.fordonskontroll.vehicleFaults,
        isLoadingVehicleFaults: state.fordonskontroll.isLoadingVehicleFaults,
        loadingVehicleFaultsFailed: state.fordonskontroll.loadingVehicleFaultsFailed
    };
  }
  
  export default connect(mapStateToProps)(FordonsFelList);