import React from "react";
import { Navbar } from "react-bootstrap";
import {appConfig} from "config/appConfig";

const logoStyle = {
  height: '40px',
  marginTop: '5px',
  marginBottom: '5px'

};
const titleStyle = {
  lineHeight: '32px',
  fontSize: '22px',
  color: '#a0a0a0',
  display: 'inline-block',
  marginTop: '10px',
  marginLeft: '10px'
}


class SimpleStandaloneHeader extends React.Component {
 
  render() {

    return (      
      
      <Navbar fluid>
        <img src={appConfig.logoSmall} style={logoStyle} alt="react-logo" /><span style={titleStyle}>{appConfig.title}</span>
      </Navbar>       
    );
  }
}

export default SimpleStandaloneHeader;

