import React from 'react';
import moment from 'moment';
import {Link} from 'react-router';

class NewsItemCompact extends React.Component {

    render() {
        const publishedString = moment(this.props.item.publishedDate).format("LL");

    return <div>
        <p className="news-title">{this.props.item.title}</p>
            <div dangerouslySetInnerHTML={ {__html: this.props.item.introText}}></div>
            <div className="row">
                <div className="col-md-6 col-md-offset-6">
                    { this.props.type === "koncern" 
                    ? <p className="pull-right"><Link to={`/dashboard/nyheter/koncern/${this.props.item.id}`}>Läs mer..</Link></p>
                    : this.props.type === "region" ? <p className="pull-right"><Link to={`/dashboard/nyheter/region/${this.props.item.id}`}>Läs mer..</Link></p> 
                    : null}
                
                </div>
            </div>
            <div className="news-footer">
                Publicerad {publishedString} av {this.props.item.authorName}
            </div>
        </div>;
    } 
}
export default NewsItemCompact;