export const LOAD_SUBSCRIPTIONS_START =
  "redux-oidc-sample/LOAD_SUBSCRIPTIONS_START";
export const LOAD_SUBSCRIPTIONS_SUCCESS =
  "redux-oidc-sample/LOAD_SUBSCRIPTIONS_SUCCESS";


export function loadSubscriptionsStart() {
  return {
    type: LOAD_SUBSCRIPTIONS_START
  };
}

export function loadSubscriptionsSuccess(channels) {
  return {
    type: LOAD_SUBSCRIPTIONS_SUCCESS,
    payload: channels
  };
}

// felmeddelanden
export const ERRORMESSAGES_ADD = "ERRORMESSAGES_ADD";
export const ERRORMESSAGES_CLEAR = "ERRORMESSAGES_CLEAR";

export function clearErrors(){
  return {
    type: ERRORMESSAGES_CLEAR
  };
}

