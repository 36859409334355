import React, { Component} from "react";
import moment from 'moment';

class Date extends Component {


    render() {

        return (<span>{this.props.withTime 
            ? moment(this.props.value).format('YYYY-MM-DD HH:mm') 
                : this.props.withTimeSeconds ? moment(this.props.value).format('YYYY-MM-DD HH:mm:ss') 
                    : moment(this.props.value).format('YYYY-MM-DD') }</span>);
    }

}
export default Date;
