import React, { Component} from "react";
import {Col, Row} from 'react-bootstrap';
import Time from 'components/formatting/Time';
import 'assets/css/detailcard.css';
import DateC from 'components/formatting/Date';

class HuvudUppdragCards extends Component{

    renderCard(row, key) {
        return (<div className="card" key={key}><div className="content">
            <Row>
                <Col xs={6}>
                        <div className="detailcard"><div className="detailcard-label">Datum</div>
                            <DateC value={row.starttid} />
                        </div>
                    </Col>                
                </Row>
                <Row>
                    <Col xs={6}>
                        <div className="detailcard"><div className="detailcard-label">Start</div>
                            <Time value={row.starttid} />
                        </div>
                    </Col>
                    <Col xs={6}>
                        <div className="detailcard"><div className="detailcard-label">Slut</div>
                        <Time value={row.sluttid} />
                        </div>
                    </Col>
                    </Row>
                <Row>
                    <Col xs={6}>
                        <div className="detailcard"><div className="detailcard-label">Avg.tid</div>
                            <Time value={row.turStarttid} />
                        </div>
                    </Col>
                    <Col xs={6}>
                        <div className="detailcard"><div className="detailcard-label">Fordon</div>
                            {row.fordonStr}
                        </div>
                    </Col>
                    </Row>
                <Row>
                    <Col xs={12}>
                        <div className="detailcard"><div className="detailcard-label">Beskrivning</div>
                            {row.beskrivning}
                        </div>
                    </Col>
                    </Row>
                <Row>
                    <Col xs={12}>
                        <div className="detailcard"><div className="detailcard-label">Notering</div>
                            {row.anmarkning}
                        </div>
                    </Col>
                    </Row>
        </div></div>)
    }
    renderList() {
          return (
                <div>                    
                    {  this.props.uppdrag.length > 0 ? 
                        this.props.uppdrag.map((row, key) => {
                        return (
                            this.renderCard(row, key)                                
                        ) }) : null}
                    
                </div>
            );
      }

    render() {
       
        return (this.renderList());
    }
}
export default HuvudUppdragCards;