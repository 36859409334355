import axios from "axios";
import  { infraConfig } from "config/infraConfig";
import { getAxiosConfig } from "system/axiosConfig";
import store from "system/store";

export const DIAGNOSTICS_GETCONFIGDESCRIPTION = "DIAGNOSTICS_GETCONFIGDESCRIPTION";



export function getConfigurationDescription() {
    
    return (dispatch) => {

        const user = store.getState().oidc.user;
        axios.get(infraConfig.apiEndpoint.concat('/api/diagnostics/configdescription'), getAxiosConfig(user))
        .then((dataResult) => dispatch({type: DIAGNOSTICS_GETCONFIGDESCRIPTION, payload: dataResult}))
        .catch((error) => {
            console.log('error');
        });        

    }
    
};
