import React from 'react';
import { Grid, Row, Col} from 'react-bootstrap';
import Card from 'components/Card/Card.jsx';
import CheckList from './CheckList';
import DocumentList from './DocumentList';
import BossInfo from './BossInfo';

class Krishantering extends React.Component {
    render(){ 
        return (<Grid fluid>
        <Row>
            <Col sm={12}>
                <BossInfo />
            </Col>
        </Row>
            <Row>
                <Col sm={12}><Card content={<CheckList />} /></Col>
            </Row>
            <Row>
                <Col sm={12}><Card title="Dokument" content={<DocumentList />} /></Col>
            </Row>
        </Grid>);
    }
}
/*
dok verksaområd // claim IntraCompanyArea
befattning  checklista hk // claim IntraPosition


ingen bef få ingen modul
closetboss, inforuta/kontaktkort med namn-telnr


*/
export default Krishantering;