import { infraConfig } from './infraConfig';
import logo from "assets/customer/vh/logo-small.png";
import sideBarBg from "assets/customer/vh/sidebar-bg.jpg";
import HeaderLinks from "./HeaderLinks";
import dashboardRoutes from './dashboard';

export const appConfig = {
    name:'vhprod',
    instrumentationKey: '27008699-6380-4fc4-af45-e6f7219e7d33',
    title: "Min Sida", 
    infra: infraConfig,
    dashboard: dashboardRoutes,
    logoSmall: logo,
    sideBarBg: sideBarBg,
    headerLinks: HeaderLinks,
    utbildningUrl: 'https://vhwebutbkov.azurewebsites.net/'

}