import React from 'react';
import { connect } from "react-redux";
import { fetchSchema } from "./actions";
import Card from 'components/Card/Card';
import DateC from 'components/formatting/Date';
import Time from 'components/formatting/Time';

import { Col, Row, FormGroup,
    FormControl
} from 'react-bootstrap';
import moment from 'moment';
import 'moment/locale/sv';


class Schema extends React.Component {
    constructor(props)
    {
        super(props);
        
        moment().locale('sv');

        this.state = {
            yearError: null,
            year: new Date().getFullYear(),
            month: new Date().getMonth() + 1,
            monthError: null,
        }
        this.yearIsValid = true;
    }
    maxYear = new Date().getFullYear() + 5
    minYear = new Date().getFullYear() - 5
    componentDidMount() {

        this.props.fetchSchema(this.state.year, this.state.month);
    }

    validateYear() {
        if (this.state.year >= this.minYear && this.state.year <= this.maxYear)
        {
            this.yearIsValid = true;
            this.setState({
                yearError: null
            });
        } 
        else 
        {
            this.yearIsValid = false;
            this.setState({
                yearError: 'Felaktigt årtal'
            });
        }
        
    }

    handleYearChange(event) {

        this.setState({
            year: event.target.value
        }, () => {
            this.validateYear();

            if (this.yearIsValid) {
                this.props.fetchSchema(this.state.year, this.state.month);
            }

        });
    }

    handleMonthChange(event){
        this.setState({
            month: event.target.value
        }, () => {
            this.props.fetchSchema(this.state.year, this.state.month);
        });
    }
    months = [
        { id: 1, name: 'Januari'},
        { id: 2, name: 'Februari'},
        { id: 3, name: 'Mars'},
        { id: 4, name: 'April'},
        { id: 5, name: 'Maj'},
        { id: 6, name: 'Juni'},
        { id: 7, name: 'Juli'},
        { id: 8, name: 'Augusti'},
        { id: 9, name: 'September'},
        { id: 10, name: 'Oktober'},
        { id: 11, name: 'November'},
        { id: 12, name: 'December'},
    ]
    render(){ 


        return (<div className="container-fluid">
        <Row>
            <Col xs={12}>
                <div className="hidden-xs">
                <Card title="Schema" 
                commandBar={
                    <div className="form-inline">
                        <FormGroup>
                        <FormControl
                            type="number"
                            name="year"
                            value={this.state.year}
                            className={this.state.yearError !== null ? 'error' : null}
                            onChange={event => this.handleYearChange(event)}
                        />

                        </FormGroup>
                        <FormGroup>
                        <FormControl
                            type="select"
                            componentClass="select" placeholder="select"
                            name="month"
                            value={this.state.month}
                            className={this.state.monthError !== null ? 'error' : null}
                            onChange={event => this.handleMonthChange(event)}
                            >
                            {this.months.map((item, key) => {
                                return <option key={key} value={item.id}>{item.name}</option>
                            })}
                            </FormControl>
                            <span className="text-danger">{this.state.monthError}</span>
                        </FormGroup>
                    </div>            
                }
                content={
                    <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>Datum</th>
                            <th>Dag</th>
                            <th>Starttid</th>
                            <th>Sluttid</th>
                            <th>Beskrivning</th>
                        </tr>
                    </thead>
                    <tbody>
                        { this.props.isLoadingSchema 
                        ? <tr><td colspan={5}><i className="fa fa-spin fa-spinner"></i> Laddar schema..</td></tr> : null }
                        {!this.props.isLoadingSchema ?
                    this.props.schema.map((item, key) => {
                        return (<tr key={key}>
                            <td>
                                <DateC value={item.starttid} />
                            </td>
                            <td>
                                <span className="text-capitalize">{item.starttidDayName}</span>
                            </td>
                            <td>
                                <Time value={item.starttid} />
                            </td>
                            <td>
                                <Time value={item.sluttid} />
                            </td>
                            <td>
                                {item.beskrivning}
                            </td>
                        </tr>);
                    })
                    : null }
                    </tbody>
                    </table>
                }
                />
                </div>
                <div className="hidden-sm hidden-md hidden-lg">
                    <Card content={
                        <div>
                            <div className="form-inline">
                                <FormGroup>
                                <FormControl
                                    type="number"
                                    name="year"
                                    value={this.state.year}
                                    className={this.state.yearError !== null ? 'error' : null}
                                    onChange={event => this.handleYearChange(event)}
                                />

                                </FormGroup>
                                <FormGroup>
                                <FormControl
                                    type="select"
                                    componentClass="select" placeholder="select"
                                    name="month"
                                    value={this.state.month}
                                    className={this.state.monthError !== null ? 'error' : null}
                                    onChange={event => this.handleMonthChange(event)}
                                    >
                                    {this.months.map((item, key) => {
                                        return <option key={key} value={item.id}>{item.name}</option>
                                    })}
                                    </FormControl>
                                    <span className="text-danger">{this.state.monthError}</span>
                                </FormGroup>
                            </div> 
                            { this.props.isLoadingSchema 
                            ? <p><i className="fa fa-spin fa-spinner"></i> Laddar schema.. </p> : null }
                            { !this.props.isLoadingSchema
                                    ? this.props.schema.map((item, key) => {
                                        return <Card content={<div>
                                            <Row>
                                            <Col xs={6}>
                                                <div className="detailcard"><div className="detailcard-label">Datum</div>
                                                    <DateC value={item.starttid} />
                                                </div>
                                            </Col>
                                            <Col xs={6}>
                                                <div className="detailcard"><div className="detailcard-label">Dag</div>
                                                    <span className="text-capitalize">{item.starttidDayName}</span>
                                                </div>
                                            </Col>
                                            </Row>
                                            <Row>
                                            <Col xs={6}>
                                                <div className="detailcard"><div className="detailcard-label">Starttid</div>
                                                    <Time value={item.starttid} />
                                                </div>
                                            </Col>
                                            <Col xs={6}>
                                                <div className="detailcard"><div className="detailcard-label">Sluttid</div>
                                                    <Time value={item.sluttid} />
                                                </div>
                                            </Col>
                                            </Row>
                                            <Row>
                                            <Col xs={12}>
                                                <div className="detailcard"><div className="detailcard-label">Beskrivning</div>
                                                    {item.beskrivning}
                                                </div>
                                            </Col>
                                            </Row>
                                            </div>
                                        } />
                                    }) : null
                
                                    }
                        </div>
                    } />
                </div>            
            </Col>
        </Row>
        
        </div>);
    }
}

function mapStateToProps(state) {
    return {
      schema: state.bpluppdrag.schema,
      schemaTimestamp: state.bpluppdrag.schemaTimestamp,
      isLoadingSchema: state.bpluppdrag.isLoadingSchema,
      loadingSchemaFailed: state.bpluppdrag.loadingSchemaFailed
    };
  }
  
  export default connect(mapStateToProps, { fetchSchema })(Schema);