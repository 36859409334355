import {
    MEDARBETARE_GET_UNDERSOKNING,
    MEDARBETARE_GET_UNDERSOKNING_SUCCESS,
    MEDARBETARE_GET_UNDERSOKNING_FAILED,

} from "./actions";
const initialState = {
    undersokning: [],
    isLoadingundersokning: false,
    loadingundersokningFailed: false,
};
export default function(state = initialState, action) {
    switch (action.type) {
            case MEDARBETARE_GET_UNDERSOKNING:
                return {...state, isLoadingundersokning: true, loadingundersokningFailed: false }
            case MEDARBETARE_GET_UNDERSOKNING_SUCCESS:
                return {...state, isLoadingundersokning: false, undersokning: action.payload.data.result }
            case MEDARBETARE_GET_UNDERSOKNING_FAILED:
                return {...state, isLoadingundersokning: false,loadingundersokningFailed: true, undersokning: [] }
        default:
            return state;
    }
  }
