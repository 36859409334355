
import React, { Component} from "react";
import { Col, Row} from 'react-bootstrap';

import IncidentCard from "./IncidentCard";

class IncidentCardList extends Component {
    
    render() {
        return (
            <Row>
                {  this.props.incidentRapporter.length > 0 ? 
                    this.props.incidentRapporter.map((row, key) => {
                    return (
                        <Col lg={3} sm={4} key={key}><IncidentCard item={row} /></Col>
                        
                    ) }) : null}
                
            </Row>)
    }
}

export default IncidentCardList;