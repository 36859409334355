export function openInNewTabOrDont(url) {

    var standalone = window.navigator.standalone,
    userAgent = window.navigator.userAgent.toLowerCase(),
    safari = /safari/.test( userAgent ),
    ios = /iphone|ipod|ipad/.test( userAgent );

    var openInBlank = true;

    if( ios ) {
        if ( !standalone && safari ) {
            //browser
        } else if ( standalone && !safari ) {
            //standalone
        } else if ( !standalone && !safari ) {
            //uiwebview
            openInBlank = false;
        };
    } else {
        //not iOS
    };

    if (openInBlank)
        window.open(url, '_blank');
    else
        window.location.href = url;

}