import React from 'react';
import axios from "axios";
import Button from "components/CustomButtons/CustomButton.jsx";
import Card from "components/Card/Card";
import * as serviceWorker from 'serviceWorker';
import DebugInfo from "components/DebugInfo";

class BuildNumber extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            fileVersion: undefined,
            processVersion: process.env.REACT_APP_BUILD_DATE,
            isLoading: false,
            hasServiceWorker: false
        }
    }
    componentDidMount() {

      if ('serviceWorker' in navigator) {

        navigator.serviceWorker.getRegistration().then((reg) => {

          if (reg){
            this.setState({
              hasServiceWorker: true
            })
          }
        })
      }

      axios.get('/version.txt')
      .then((response) => {
          console.log(response);
          this.setState({
            fileVersion: response.data
          });
      }).catch(() => {
        this.setState({
          fileVersion: undefined
        })
      });   

    }

    handleNewAppClick() {
      if ('serviceWorker' in navigator) {

        navigator.serviceWorker.getRegistration().then((reg) => {
          if (reg)
          {
            reg.update().then(() => {

              this.setState({
                isLoading: true
              }, () => {

                window.localStorage.clear(); 
                serviceWorker.unregister();
    
                setTimeout(() => {
                  window.location.reload(true);
                }, 2000)
              })
            });
    
          } else {
            console.log('no service worker registered');
          }
        });
      }
    }
    
    render() {

        return <div>
          <DebugInfo content={
            <div className="debug-info"><p> serviceWorker: {this.state.hasServiceWorker ? 'true' : 'false' }</p>
            <p> fileVersion: {this.state.fileVersion}</p>
            <p> processVersion: {this.state.processVersion}</p>
            <Button fill bsStyle="warning" onClick={() => { this.handleNewAppClick(); }}>DEV Uppgradera</Button>
          </div>
          } />
          
          
        { this.state.hasServiceWorker && this.state.fileVersion && this.state.processVersion && this.state.processVersion !== this.state.fileVersion.toString() 
          ? <Card title="Ny version av Min Sida" 
          content={<div>
            <div className="hidden-sm hidden-md hidden-lg">
              <Button fill block bsStyle="warning" onClick={() => { this.handleNewAppClick(); }}>Ny version av Min Sida finns tillgänglig<br/>klicka här</Button>
            </div>
            <div className="hidden-xs">
              <Button fill bsStyle="warning" onClick={() => { this.handleNewAppClick(); }}>Ny version av Min Sida finns tillgänglig, klicka här</Button>
            </div>
            { this.state.isLoading ? <p><i className="fa fa-spinner fa-spin"></i> Hämtar ny version..</p> : null}
          </div>
            
        } /> : null }</div>
    }
}
export default BuildNumber;
