import React from "react";
import { appConfig } from "config/appConfig";

class DebugInfo extends React.Component {
  render() {
    if (!appConfig.showDebug)
      return null;

    return <div className="alert alert-info">{this.props.content}</div>;
  }
}

export default DebugInfo;
