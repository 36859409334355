import axios from "axios";
import  { infraConfig } from "config/infraConfig";
import { getAxiosConfig } from "system/axiosConfig";
import store from "system/store";
import { ERRORMESSAGES_ADD } from "system/actions";

export const FORSLAG_GET_FORSLAGLIST = "FORSLAG_GET_FORSLAGLIST";
export const FORSLAG_GET_FORSLAGLIST_SUCCESS = "FORSLAG_GET_FORSLAGLIST_SUCCESS";
export const FORSLAG_GET_FORSLAGLIST_FAILED = "FORSLAG_GET_FORSLAGLIST_FAILED";

export const FORSLAG_CREATE_FORSLAG = "FORSLAG_CREATE_FORSLAG";
export const FORSLAG_CREATE_FORSLAG_SUCCESS = "FORSLAG_CREATE_FORSLAG_SUCCESS";
export const FORSLAG_CREATE_FORSLAG_FAILED = "FORSLAG_CREATE_FORSLAG_FAILED";


export function fetchForslag() {
    return (dispatch) => {
        dispatch({type: FORSLAG_GET_FORSLAGLIST});

        const user = store.getState().oidc.user;
        axios.get(infraConfig.apiEndpoint.concat('/api/forslag/'), getAxiosConfig(user))
        .then((dataResult) => dispatch({type: FORSLAG_GET_FORSLAGLIST_SUCCESS, payload: dataResult}))
        .catch((error) => { dispatch({type: FORSLAG_GET_FORSLAGLIST_FAILED}); dispatch({type: ERRORMESSAGES_ADD, payload: { type: 'network', message: 'Hämta förslag misslyckades', severity: 'warning'}});});

    }

};

export function addForslag(forslag) {
    
    return (dispatch) => {

        dispatch({type: FORSLAG_CREATE_FORSLAG});

        const user = store.getState().oidc.user;
        axios.post(infraConfig.apiEndpoint.concat('/api/forslag/'), forslag, getAxiosConfig(user))
        .then((dataResult) => {
            dispatch({type: FORSLAG_CREATE_FORSLAG_SUCCESS, payload: dataResult}); 

            console.log('after send');
            
        })
        .catch((error) => { dispatch({type: FORSLAG_CREATE_FORSLAG_FAILED}); dispatch({type: ERRORMESSAGES_ADD, payload: { type: 'network', message: 'Skapa förslag misslyckades', severity: 'warning'}});});        
    
    }

};