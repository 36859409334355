import logo from "assets/customer/td/logo-small.png";
import sideBarBg from "assets/customer/td/sidebar-bg.jpg";
import HeaderLinks from "./HeaderLinks";
import dashboardRoutes from '../bnprod/dashboard';

export const appConfig = {
    name:'local',
    instrumentationKey: '',
    title: "Min Sida",
    dashboard: dashboardRoutes,
    logoSmall: logo,
    sideBarBg: sideBarBg,
    headerLinks: HeaderLinks,
    outputToConsole: true,
    showDebug: false
}