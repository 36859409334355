import React, { Component} from "react";
import { connect } from "react-redux";
import { fetchIncidentRapporter } from "./actions";
import LoadingIcon from "components/utils/LoadingIcon";

import { LinkContainer } from "react-router-bootstrap";

import IncidentCardList from "./IncidentCardList";
import IncidentList from "./IncidentList";
import Card from 'components/Card/Card.jsx';
import MobileDesktop from 'components/utils/MobileDesktop';
import {
    Row,
    Col,
  } from "react-bootstrap";
  import Button from "components/CustomButtons/CustomButton";
  
class Incidentrapporter extends Component {
    constructor(props){
        super(props);

        this.state = { showNewForm: false};
    }
    
    componentDidMount()
    {
        this.props.fetchIncidentRapporter();
    }

    render() {
        return (<div className="container-fluid">
        <Row>
            <Col sm={12}>                
                <Card title="Avvikelser" 
                commandBar={<div>
                    <LinkContainer to="/dashboard/nyavvikelse"><Button fill block bsStyle="primary"><span className="fa fa-plus" /> Skapa ny</Button></LinkContainer>
                </div>}
                content={<LoadingIcon text="Laddar avvikelser.." isLoading={this.props.isLoadingIncidentRapporter} loadingFailed={this.props.loadingIncidentRapporterFailed} 
                success={<MobileDesktop desktopView={<IncidentList incidentRapporter={this.props.incidentRapporter} />} mobileView={<IncidentCardList incidentRapporter={this.props.incidentRapporter} />} />}>
                </LoadingIcon>} />        
            </Col>
        </Row>
        </div>)
    }
}
function mapStateToProps(state) {
    return {
        user: state.oidc.user,
        formOptions: state.incident.formOptions,
        incidentRapporter: state.incident.incidentRapporter,
        incidentRapporterTimestamp: state.incident.incidentRapporterTimestamp,
        isLoadingIncidentRapporter: state.incident.isLoadingIncidentRapporter,
        loadingIncidentRapporterFailed: state.incident.loadingIncidentRapporterFailed,
    };
  }

export default connect(mapStateToProps, { fetchIncidentRapporter }) (Incidentrapporter)