import axios from "axios";
import  { infraConfig } from "config/infraConfig";
import { getAxiosConfig } from "system/axiosConfig";
import store from "system/store";
import { ERRORMESSAGES_ADD } from "system/actions";


export const BPLUPPDRAG_GET_UPPDRAG = "BPLUPPDRAG_GET_UPPDRAG";
export const BPLUPPDRAG_GET_UPPDRAG_SUCCESS = "BPLUPPDRAG_GET_UPPDRAG_SUCCESS";
export const BPLUPPDRAG_GET_UPPDRAG_FAILED = "BPLUPPDRAG_GET_UPPDRAG_FAILED";

export const BPLUPPDRAG_GET_SCHEMA = "BPLUPPDRAG_GET_SCHEMA";
export const BPLUPPDRAG_GET_SCHEMA_SUCCESS = "BPLUPPDRAG_GET_SCHEMA_SUCCESS";
export const BPLUPPDRAG_GET_SCHEMA_FAILED = "BPLUPPDRAG_GET_SCHEMA_FAILED";

export const BPLUPPDRAG_GET_AKTUELLTUPPDRAG = "BPLUPPDRAG_GET_AKTUELLTUPPDRAG";
export const BPLUPPDRAG_GET_AKTUELLTUPPDRAG_SUCCESS = "BPLUPPDRAG_GET_AKTUELLTUPPDRAG_SUCCESS";
export const BPLUPPDRAG_GET_AKTUELLTUPPDRAG_FAILED = "BPLUPPDRAG_GET_AKTUELLTUPPDRAG_FAILED";

export const BPLUPPDRAG_SEND_ACCEPTSCHEMA = "BPLUPPDRAG_SEND_ACCEPTSCHEMA";
export const BPLUPPDRAG_SEND_ACCEPTSCHEMA_SUCCESS = "BPLUPPDRAG_SEND_ACCEPTSCHEMA_SUCCESS";
export const BPLUPPDRAG_SEND_ACCEPTSCHEMA_FAILED = "BPLUPPDRAG_SEND_ACCEPTSCHEMA_FAILED";


// TODO: optimera så vi inte hämtar uppdrag hela tiden flera gånger
// aktuellt uppdrag kanske inte ens kommer från api utan från redan laddade uppdrag i store

export function fetchAktuelltBplUppdrag() {
    
    return (dispatch) => {
        dispatch({type: BPLUPPDRAG_GET_AKTUELLTUPPDRAG});

        const user = store.getState().oidc.user;
        axios.get(infraConfig.apiEndpoint.concat('/api/bpl/aktuelltuppdrag'), getAxiosConfig(user))
        .then((dataResult) => dispatch({type: BPLUPPDRAG_GET_AKTUELLTUPPDRAG_SUCCESS, payload: dataResult}))
        .catch((error) => { dispatch({type: BPLUPPDRAG_GET_AKTUELLTUPPDRAG_FAILED}); dispatch({type: ERRORMESSAGES_ADD, payload: { type: 'network', message: 'Hämta aktuellt uppdrag misslyckades', severity: 'warning'}});});        

    }
    
};


export function fetchBplUppdrag(date) {
    
    return (dispatch) => {
        dispatch({type: BPLUPPDRAG_GET_UPPDRAG});

        const user = store.getState().oidc.user;
        axios.get(infraConfig.apiEndpoint.concat('/api/bpl/uppdrag?date=').concat(date), getAxiosConfig(user))
        .then((dataResult) => dispatch({type: BPLUPPDRAG_GET_UPPDRAG_SUCCESS, payload: dataResult}))
        .catch((error) => { dispatch({type: BPLUPPDRAG_GET_UPPDRAG_FAILED}); dispatch({type: ERRORMESSAGES_ADD, payload: { type: 'network', message: 'Hämta uppdrag misslyckades', severity: 'warning'}});});        

    }
    
};

export function fetchSchema(year, month) {
    
    return (dispatch) => {
        dispatch({type: BPLUPPDRAG_GET_SCHEMA});

        const user = store.getState().oidc.user;
        axios.get(infraConfig.apiEndpoint.concat('/api/bpl/schema?year=').concat(year).concat('&month=').concat(month), getAxiosConfig(user))
        .then((dataResult) => dispatch({type: BPLUPPDRAG_GET_SCHEMA_SUCCESS, payload: dataResult}))
        .catch((error) => { dispatch({type: BPLUPPDRAG_GET_SCHEMA_FAILED}); dispatch({type: ERRORMESSAGES_ADD, payload: { type: 'network', message: 'Hämta schema misslyckades', severity: 'warning'}});});        

    }
    
};



export function sendAcceptSchema(schemaPostId, undo) {

    return (dispatch) => {

        dispatch({type: BPLUPPDRAG_SEND_ACCEPTSCHEMA});

        const user = store.getState().oidc.user;

        axios.post(infraConfig.apiEndpoint.concat('/api/bpl/schema/accept'), { schemaPostId:schemaPostId, undo: undo }, getAxiosConfig(user))
        .then((dataResult) => {
            dispatch({type: BPLUPPDRAG_SEND_ACCEPTSCHEMA_SUCCESS, payload: dataResult}); 

            console.log('after send');
            axios.get(infraConfig.apiEndpoint.concat('/api/bpl/aktuelltuppdrag'), getAxiosConfig(user))
            .then((dataResult) => dispatch({type: BPLUPPDRAG_GET_AKTUELLTUPPDRAG_SUCCESS, payload: dataResult}))
            .catch((error) => { dispatch({type: BPLUPPDRAG_GET_AKTUELLTUPPDRAG_FAILED}); dispatch({type: ERRORMESSAGES_ADD, payload: { type: 'network', message: 'Hämta aktuellt uppdrag misslyckades', severity: 'warning'}});});        
            
        })
        .catch((error) => { dispatch({type: BPLUPPDRAG_SEND_ACCEPTSCHEMA_FAILED}); dispatch({type: ERRORMESSAGES_ADD, payload: { type: 'network', message: 'Skicka acceptera uppdrag misslyckades', severity: 'warning'}});});        
    
    }

};