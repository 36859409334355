import Start from "dashboard/Start/Start";
import BplMinaUppdragPage from "dashboard/BplMinaUppdrag/BplMinaUppdragPage.jsx";
import Fordonskontroll from "dashboard/Fordonskontroll/Fordonskontroll.jsx";
import Incidentrapporter from "dashboard/Incidenter/Incidentrapporter.jsx";
import IncidenterFormH from "dashboard/Incidenter/FormH.jsx";
import UserInfo from "dashboard/Main/UserInfo";
import NyheterRegionList from "dashboard/Nyheter/RegionNewsListView.jsx";
import NyheterKoncernList from "dashboard/Nyheter/KoncernNewsListView.jsx";
import RegionNyhet from "dashboard/Nyheter/RegionNewsShowFull.jsx";
import KoncernNyhet from "dashboard/Nyheter/KoncernNewsShowFull.jsx";
import Utbildning from "dashboard/Utbildning/Utbildning.jsx";
import Forslagslada from "dashboard/Forslagslada/Forslagslada";
import KrishanteringVH from "dashboard/KrishanteringVH/KrishanteringVH.jsx";
import EcoDriving from "dashboard/EcoDriving/EcoDriving.jsx"
import BplSchema from "dashboard/BplMinaUppdrag/Schema.jsx";
import PolicyRutiner from "dashboard/PolicyDocs/PolicyDocs.jsx";
import DashboardHelp from "dashboard/Main/DashboardHelp";
import ForsakringUppfoljning from "dashboard/Incidenter/ForsakringUppfoljning/ForsakringUppfoljning";

var dashboardRoutes = [
  {
    path: "/dashboard/start",
    name: "Start",
    icon: "pe-7s-news-paper",
    component: Start
  },
  {
    path: "/dashboard/nyheter",
    name: "Nyheter",
    icon: "pe-7s-speaker",
    collapse: true,
    childRoutes: [
      {
        path: "/dashboard/nyheter/region",
        component: NyheterRegionList,
        mini: "R",
        name: "Region",
      },
      {
        path: "/dashboard/nyheter/koncern",
        component: NyheterKoncernList,
        mini: "K",
        name: "Koncern",
      },
      {
        path: "/dashboard/nyheter/koncern/:newsId",
        name: "Nyhet",
        icon: "pe-7s-speaker",
        component: KoncernNyhet,
        mini: "N",
        hideFromMenu: true
      },
      {
        path: "/dashboard/nyheter/region/:newsId",
        name: "Nyhet",
        icon: "pe-7s-speaker",
        component: RegionNyhet,
        mini: "N",
        hideFromMenu: true
      }
    ]
  },  
  {
    path: "/dashboard/minauppdrag",
    name: "Mina Uppdrag",
    icon: "pe-7s-bottom-arrow",
    component: BplMinaUppdragPage
  },
  {
    path: "/dashboard/schema",
    name: "Schema",
    icon: "pe-7s-note2",
    component: BplSchema
  },
  {
    path: "/dashboard/fordonskontroll",
    name: "Felrapportering",
    icon: "pe-7s-car",
    component: Fordonskontroll
  },
  {
    path: "/dashboard/avvikelser",
    name: "Avvikelser",
    icon: "pe-7s-light",
    component: Incidentrapporter
  },
  {
    path: "/dashboard/nyavvikelse",
    name: "Ny avvikelse",
    icon: "pe-7s-light",
    component: IncidenterFormH,
    hideFromMenu:true
  },
  {
    path: "/dashboard/krishantering",
    name: "Krishantering",
    icon: "pe-7s-help2",
    component: KrishanteringVH
  },
  {
    path: "/dashboard/forslag",
    name: "Förslagslåda",
    icon: "pe-7s-comment",
    component: Forslagslada
  },
  {
    path: "/dashboard/userinfo",
    name: "Användarinformation",
    icon: "pe-7s-user",
    component: UserInfo,
    hideFromMenu: true
  },
  {
    path: "/dashboard/ecodriving",
    name: "Sparsam körning",
    icon: "pe-7s-leaf",
    component: EcoDriving
  },
  {
    path: "/dashboard/forsakringuppfoljning",
    name: "Försäkringsuppföljning",
    icon: "pe-7s-loop",
    component: ForsakringUppfoljning
  },
  {
    //pe-7s-notebook
    path: "/dashboard/utbildning",
    name: "Utbildningsintyg",
    icon: "pe-7s-study",
    component: Utbildning
  },
  {
    path: "/dashboard/policy",
    name: "Policy & Rutiner",
    icon: "pe-7s-notebook",
    component: PolicyRutiner
  },  
  {
    path: "/dashboard/help",
    name: "Hjälp",
    icon: "pe-7s-help1",
    component: DashboardHelp,
  },

];
export default dashboardRoutes;
