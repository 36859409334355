import React, { Component} from "react";

class Percentage extends Component {

    render() {

        var percentageValue = this.props.value * 100;

        if (this.props.maxDecimals !== undefined) 
        {
            // parseFloat för vi vill ha 1, 1.01, 1.1 osv inte 1.00, 1.01, 1.10            
            percentageValue = parseFloat(percentageValue.toFixed(this.props.maxDecimals));
        }

        return (<span>{percentageValue} %</span>);
    }

}
export default Percentage;
