import React, { Component} from "react";
import Card from "components/Card/Card";
import DateC from 'components/formatting/Date';
import Currency from 'components/formatting/Currency';
import LoadingIcon from 'components/utils/LoadingIcon';

class IncidentListCards extends Component {
    render() {
        return <Card title={this.props.title} content={
        <div>
            {this.props.commandBar}
        
            { this.props.rowsIsLoading ? <LoadingIcon isLoading={true} text="Laddar.." loadingFailed={false} />
            
            : this.props.rowsLoadingFailed ? <LoadingIcon isLoading={false} loadingFailed={true} />
            : this.props.rows != null ? <div>
                { this.props.rows.resultList.map((item, key) => {
                    return (
                    <div key={key}>
                        <label>Rubrik</label>
                        <p>{item.rubrik}</p>
                        <label>Datum</label>
                        <p><DateC value={item.skapadDatum} /></p>
                        <label>Kategori</label>
                        <p>{item.fordonsskada.forsakringsKategori}</p>
                        <label>Beskrivning</label>
                        <p>{item.fordonsskada.handelseBeskrivning}</p>
                        <div className="text-right">
                            <label>Kostnad</label>
                            <p><Currency value={item.fordonsskada.reparationskostnad} /></p>
                        </div>
                        <hr />
                    </div>
                    )
                })}
                <div className="text-right">
                    <p>Summa: <Currency value={this.props.rows.totalPersonal} /></p>
                </div>
            </div>
            : null
            }
                
        </div>
                
       } />;
    }
}
export default IncidentListCards;