import React, { Component } from "react";
import { appConfig } from "config/appConfig";
import { getClaimSingle } from "system/security";

import userManager from "system/userManager";
import { Nav, NavDropdown, MenuItem, Navbar } from "react-bootstrap";
import { connect } from "react-redux";

// import Date from "components/formatting/Date.jsx";
import { clearErrors } from "system/actions";
import { logoutInProgress } from "system/actions/authHelper.js";

import { LinkContainer } from "react-router-bootstrap";

import BplExternalLink from "components/utils/BplExternalLink";
import DocsExternalLink from "components/utils/DocsExternalLink";
import ExternalLink from "components/utils/ExternalLink";
import { getClaimArray } from "system/security";
import KrishanteringButton from "components/utils/KrishanteringButton";
import MobileDesktop from "components/utils/MobileDesktop";
import UtbExternalLink from "components/utils/UtbExternalLink";

class HeaderLinks extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showLedigaTjanster: false,
			showTimereport: false,
			showForarTavlan: false,
			showPolicyRutiner: false,
			showBusskartor: false,
			showWebidha: false,
			extended: false,
		};
	}
	handleNotificationClick() {
		this.props.clearErrors();
	}
	handleLinkClick(path) {
		document.documentElement.classList.toggle("nav-open");
		// if (this.props.history !== undefined) this.props.history.push(path);
	}
	componentDidMount() {
		const showLedigaTjanster = getClaimSingle(
			"LinkLedigaTjansterEnabled",
			this.props.userprofile
		);
		if (showLedigaTjanster) {
			this.setState({
				showLedigaTjanster: true,
			});
		}

		const showTimereport = getClaimSingle(
			"LinkTimereportEnabled",
			this.props.userprofile
		);
		if (showTimereport) {
			this.setState({
				showTimereport: true,
			});
		}

		const showForarTavlan = getClaimSingle(
			"LinkForartavlanEnabled",
			this.props.userprofile
		);
		if (showForarTavlan) {
			this.setState({
				showForarTavlan: true,
			});
		}

		const showPolicyRutiner = getClaimSingle(
			"LinkPolicyRutinerEnabled",
			this.props.userprofile
		);
		if (showPolicyRutiner) {
			this.setState({
				showPolicyRutiner: true,
			});
		}

		const showBusskartor = getClaimSingle(
			"LinkBusskartsEnabled",
			this.props.userprofile
		);
		if (showBusskartor) {
			this.setState({
				showBusskartor: true,
			});
		}

		const showWebidha = getClaimSingle(
			"LinkWebidhaEnabled",
			this.props.userprofile
		);
		if (showWebidha) {
			this.setState({
				showWebidha: true,
			});
		}
	}

	render() {
		return (
			<div>
				<Nav pullRight={this.props.pullRight}>
					<NavDropdown
						onToggle={(isToggled) => {
							this.setState({
								extended: isToggled,
							});
						}}
						eventKey={4}
						title={
							<div>
								<i className="fa fa-window-restore" />
								<span className="hidden-xs hidden-sm"> Mina länkar</span>
								<p className="hidden-md hidden-lg">
									Mina länkar
									<b className="caret" />
								</p>
							</div>
						}
						noCaret
						id="basic-nav-dropdown-3"
						bsClass="dropdown-with-icons dropdown"
					>
						{this.state.extended ? (
							<>
								{this.state.showTimereport ? (
									<MenuItem eventKey={4.1}>
										<BplExternalLink
											to="Client/Tidrapport/"
											title="Tidrapport"
											icon={<i className="fa fa-clock-o" />}
											showAsText
										/>
									</MenuItem>
								) : null}

								{this.state.showForarTavlan ? (
									<MenuItem eventKey={4.2}>
										<BplExternalLink
											to="client/forartavla"
											title="Förartavlan"
											icon={<i className="fa fa-bus" />}
											showAsText
										/>
									</MenuItem>
								) : null}
								{this.state.showPolicyRutiner ? (
									<MenuItem eventKey={4.3}>
										<DocsExternalLink
											to="policy/"
											title="Policy &amp; Rutiner"
											icon={<i className="fa fa-book" />}
											showAsText
										/>
									</MenuItem>
								) : null}
								{this.state.showBusskartor ? (
									<MenuItem eventKey={4.4}>
										<DocsExternalLink
											to="policy/Busskartor- Hur fungerar bussen, en lathund.html"
											title="Busskartor"
											icon={<i className="fa fa-file-photo-o" />}
											showAsText
										/>
									</MenuItem>
								) : null}
								{this.state.showWebidha ? (
									<MenuItem>
										<ExternalLink
											to="https://online.idha.com/Login/Index?ReturnUrl=%2f"
											title="Web-idha"
											showAsText
										/>
									</MenuItem>
								) : null}
								{this.state.showLedigaTjanster ? (
									<MenuItem>
										<ExternalLink
											to={appConfig.lediaTjansterUrl}
											title="Lediga tjänster"
											showAsText
										/>
									</MenuItem>
								) : null}
							</>
						) : null}
					</NavDropdown>
					<NavDropdown
						eventKey={4}
						title={
							<div>
								<i className="fa fa-list" />
								<p className="hidden-md hidden-lg">
									Meny
									<b className="caret" />
								</p>
							</div>
						}
						noCaret
						id="basic-nav-dropdown-3"
						bsClass="dropdown-with-icons dropdown"
					>
						<LinkContainer
							onClick={() => this.handleLinkClick()}
							to="/dashboard/userinfo"
						>
							<MenuItem>
								<i className="fa fa-user" />
								Användarinfo
							</MenuItem>
						</LinkContainer>

						<MenuItem eventKey={4.6}>
							<div
								className="text-danger"
								onClick={() => {
									this.handleLinkClick();

									this.props.logoutInProgress();
									userManager.removeUser(); // removes the user data from sessionStorage
									userManager.signoutRedirect({
										id_token_hint: this.props.user.id_token,
									});
									window.localStorage.clear();
								}}
							>
								<i className="fa fa-sign-out" /> Logga ut
							</div>
						</MenuItem>
					</NavDropdown>
				</Nav>
				<Navbar.Collapse>
					<Navbar.Form pullRight>
						<MobileDesktop desktopView={<KrishanteringButton />} />
					</Navbar.Form>
				</Navbar.Collapse>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		user: state.oidc.user,
		userprofile: state.userprofile.userprofile,
		errors: state.errors.errors,
	};
}

export default connect(mapStateToProps, { clearErrors, logoutInProgress })(
	HeaderLinks
);
