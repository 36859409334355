import { createUserManager } from 'redux-oidc';
import  { infraConfig } from "config/infraConfig";
import { WebStorageStateStore } from 'oidc-client';

// user manager configuration object, see oidc-client-js documentation for details
const config = {
    client_id: infraConfig.clientId,
    redirect_uri: infraConfig.usePort ? `${window.location.protocol}//${window.location.hostname}:${window.location.port}/callback` : `${window.location.protocol}//${window.location.hostname}/callback`,
    response_type: 'id_token token',
    scope: 'openid profile',
    authority: infraConfig.identityServerEndpoint,
    post_logout_redirect_uri: infraConfig.usePort ? `${window.location.protocol}//${window.location.hostname}:${window.location.port}/loggedout` :`${window.location.protocol}//${window.location.hostname}/loggedout`,
    silent_redirect_uri: infraConfig.usePort ? `${window.location.protocol}//${window.location.hostname}:${window.location.port}/silent-renew` :`${window.location.protocol}//${window.location.hostname}/silent-renew`,
    automaticSilentRenew: true,
    filterProtocolClaims: true,
    loadUserInfo: true,
    userStore: new WebStorageStateStore({ store: window.localStorage }) 
  }
  
  // create a user manager instance
  const userManager = createUserManager(config);
  
  export default userManager;